import { defineMessages } from "react-intl";

const messages = defineMessages({
    globalType: {
        id: "ChangeGlobalColumnType.globalType",
        defaultMessage: "Global tag",
    },
});

export default messages;
