import { useElasticFields } from "@ignite-analytics/elastic-fields";
import { getTermsFilter, useFilters } from "@ignite-analytics/filters";
import { track } from "@ignite-analytics/track";
import { Download } from "@mui/icons-material";
import { Button, CardContent, CardHeader, Drawer, IconButton, Snackbar, Tooltip, Typography } from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import React, { useMemo, useState } from "react";
import { ExportHistoryPage } from "@/components/ExportDownloadPage";
import { hasValue } from "@/containers/CubeBuilderPage/components/ConnectionModal/helpers";
import { elasticIndexFromTableId } from "@/containers/Settings/ManageDataTablePage/RelativeDateSettings";
import { fm } from "@/contexts/IntlContext";
import { ExportFormats as NewExportFormat, useExportDataTableToFileMutation } from "@/generated/client";
import { mapFromFrontendFiltersToAnalysisFilter } from "@/helpers";
import { ExportFieldsSelection } from "./ExportFieldsSelection";
import messages from "./messages";

interface Props {
    searchTerm?: string;
    selectedDataTableViewId: string;
    total: number;
}
const EXPORT_DATA_LIMIT = 1_000_000;

export const ExportOptions: React.FC<Props> = ({ searchTerm, selectedDataTableViewId, total }: Props) => {
    const [openExportModal, setOpenExportModal] = useState(false);
    const [openConfigPanel, setOpenConfigPanel] = useState(false);
    const [loading, setLoading] = useState(false);
    const [openSnackbar, setOpenSnackbar] = useState(false);

    const initialFilters = useFilters() ?? [];
    const [exportDataTable] = useExportDataTableToFileMutation();

    const disabled = useMemo(() => total > EXPORT_DATA_LIMIT, [total]);

    const elasticFields = useElasticFields(elasticIndexFromTableId(selectedDataTableViewId), false);

    const searchFilter = getTermsFilter(searchTerm, elasticFields);
    const filters = [searchFilter, ...initialFilters].filter(hasValue);

    const handleExportDataClick = async (fields: string[], exportFormat: NewExportFormat): Promise<void> => {
        setLoading(true);
        await exportDataTable({
            input: {
                dataTableId: selectedDataTableViewId,
                filters: filters.map(mapFromFrontendFiltersToAnalysisFilter).filter(hasValue),
                exportFormat,
                fields,
            },
        });
        track("Data Table view: Start export", {
            downloadFormat: exportFormat,
            numberOfFields: fields.length,
            rows: total,
        });
        setOpenSnackbar(true);
        setOpenConfigPanel(false);
        setOpenExportModal(false);
        setLoading(false);
    };

    return (
        <>
            <Snackbar
                open={openSnackbar}
                color="success"
                autoHideDuration={8000}
                onClose={() => setOpenSnackbar(false)}
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            >
                <MuiAlert elevation={6} variant="filled" severity="success">
                    <Typography>{fm(messages.exportConfirmation)}</Typography>
                </MuiAlert>
            </Snackbar>
            <Drawer anchor="right" open={openExportModal} onClose={() => setOpenExportModal(false)}>
                <CardHeader />
                <CardContent sx={{ width: "400px" }}>
                    {!openConfigPanel && (
                        <Stack gap={3}>
                            <Stack gap={2}>
                                {disabled && (
                                    <MuiAlert elevation={6} variant="outlined" severity="warning">
                                        <Typography>{fm(messages.tooLarge, { limit: EXPORT_DATA_LIMIT })}</Typography>
                                    </MuiAlert>
                                )}
                                <Button
                                    color="secondary"
                                    onClick={() => setOpenConfigPanel(true)}
                                    startIcon={<Download />}
                                    disabled={disabled}
                                >
                                    {fm(messages.new)}
                                </Button>
                            </Stack>
                            <Stack overflow="auto">
                                <ExportHistoryPage dataTableId={selectedDataTableViewId} />
                            </Stack>
                        </Stack>
                    )}
                    {elasticFields && openConfigPanel && (
                        <ExportFieldsSelection
                            selectedDataTableViewId={selectedDataTableViewId}
                            elasticFields={elasticFields}
                            onConfirm={handleExportDataClick}
                            onClose={() => setOpenConfigPanel(false)}
                            loading={loading}
                        />
                    )}
                </CardContent>
            </Drawer>

            <Tooltip title={fm(messages.tooltip)}>
                <IconButton
                    onClick={() => {
                        track("Data Table view: Open export", {
                            dataTableId: selectedDataTableViewId,
                            filterCount: filters.length,
                            rows: total,
                        });
                        setOpenExportModal(true);
                    }}
                >
                    <Download />
                </IconButton>
            </Tooltip>
        </>
    );
};
