/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Card, Typography, AccordionSummary, AccordionDetails, Accordion, CardContent, Stack } from "@mui/material";
import { ExpandMore } from "@mui/icons-material";
import { orderBy } from "lodash-es";
import React, { useState } from "react";
import { DataRepository, DataRepositoryCollection } from "@/generated/client";
import { fm } from "@/contexts/IntlContext";
import messages from "./messages";

interface Props {
    onSelect: (dataRepository: DataRepository) => void;
    dataRepositories: DataRepository[];
    dataRepositoryCollections: DataRepositoryCollection[];
}

export const ConnectRepositoriesSideMenu = ({ onSelect, dataRepositories, dataRepositoryCollections }: Props) => {
    const [selectedRepositoryId, setSelectedRepositoryId] = useState<string>(dataRepositories[0].id);
    const handleClick = (repository: DataRepository) => {
        onSelect(repository);
        setSelectedRepositoryId(repository.id);
    };
    return (
        <Card sx={{ maxWidth: "300px", minWidth: "300px" }}>
            <CardContent>
                <Typography variant="h5" color="secondary">
                    {fm(messages.header)}
                </Typography>
                <Stack maxHeight="50vh" overflow="auto">
                    {dataRepositoryCollections.map((collection) => (
                        <Accordion defaultExpanded disableGutters sx={{ boxShadow: 0 }} key={collection.id}>
                            <AccordionSummary
                                sx={{
                                    borderBottom: "1px solid",
                                    borderColor: (theme) => theme.palette.grey[300],
                                    padding: "0",
                                }}
                                expandIcon={<ExpandMore />}
                            >
                                <Typography color="secondary" variant="subtitle2">
                                    {collection.name}
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails sx={{ padding: "1 0 0 1" }}>
                                {orderBy(
                                    dataRepositories.filter((r) => r.repositoryCollectionId === collection.id),
                                    ["name"],
                                    ["asc"]
                                ).map((repository) => (
                                    <Typography
                                        sx={{ cursor: "pointer" }}
                                        key={repository.id}
                                        variant="body2"
                                        onClick={() => handleClick(repository)}
                                        color={repository.id === selectedRepositoryId ? "primary" : undefined}
                                    >
                                        {repository.name}
                                    </Typography>
                                ))}
                            </AccordionDetails>
                        </Accordion>
                    ))}
                    <Accordion defaultExpanded disableGutters sx={{ boxShadow: 0 }}>
                        <AccordionSummary
                            sx={{
                                borderBottom: "1px solid",
                                borderColor: (theme) => theme.palette.grey[300],
                                padding: "0",
                            }}
                            expandIcon={<ExpandMore />}
                        >
                            <Typography color="secondary" variant="subtitle2">
                                {fm(messages.others)}
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails sx={{ padding: "1 0 0 1" }}>
                            {orderBy(
                                dataRepositories.filter((r) => r.repositoryCollectionId === "null"),
                                ["name"],
                                ["asc"]
                            ).map((repository) => (
                                <Typography
                                    sx={{ cursor: "pointer" }}
                                    key={repository.id}
                                    variant="body2"
                                    onClick={() => handleClick(repository)}
                                    color={repository.id === selectedRepositoryId ? "primary" : undefined}
                                >
                                    {repository.name}
                                </Typography>
                            ))}
                        </AccordionDetails>
                    </Accordion>
                </Stack>
            </CardContent>
        </Card>
    );
};
