import { EyeSlash, Pen, Trash } from "@ignite-analytics/icons";
import { BookmarkAdd } from "@mui/icons-material";
import { List, ListItemIcon, ListItemText, MenuItem } from "@mui/material";
import React from "react";

import { fm } from "@/contexts/IntlContext";
import globalMessages from "@/messages";

import messages from "./messages";

type Props = {
    onDeleteClick: () => void;
    onRenameClick: () => void;
    onGlobalTypeChangeClick: () => void;
    onHideClick: () => void;
};

export const CustomColumnMenuItems: React.FC<Props> = ({
    onHideClick,
    onDeleteClick,
    onGlobalTypeChangeClick,
    onRenameClick,
}) => (
    <List>
        <MenuItem onClick={onHideClick}>
            <ListItemIcon>
                <EyeSlash fontSize="small" />
            </ListItemIcon>
            <ListItemText>{fm(globalMessages.hide)}</ListItemText>
        </MenuItem>
        <MenuItem onClick={onRenameClick}>
            <ListItemIcon>
                <Pen fontSize="small" />
            </ListItemIcon>
            <ListItemText>{fm(globalMessages.rename)}</ListItemText>
        </MenuItem>
        <MenuItem onClick={onGlobalTypeChangeClick}>
            <ListItemIcon>
                <BookmarkAdd fontSize="small" />
            </ListItemIcon>
            <ListItemText>{fm(messages.globalType)}</ListItemText>
        </MenuItem>
        <MenuItem onClick={onDeleteClick}>
            <ListItemIcon>
                <Trash fontSize="small" />
            </ListItemIcon>
            <ListItemText>{fm(globalMessages.delete)}</ListItemText>
        </MenuItem>
    </List>
);
