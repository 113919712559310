import { defineMessages } from "react-intl";

const messages = defineMessages({
    newTableTooltip: {
        id: "uploadPage.createDataTableFromRepository.newTableTooltip",
        defaultMessage: "Create new table or collection",
    },
    newTable: {
        id: "uploadPage.createDataTableFromRepository.newTable",
        defaultMessage: "New Table",
    },
    unauthorizedTableCreator: {
        id: "uploadPage.createDataTableFromRepository.unauthorizedTableCreator",
        defaultMessage: "You do not have access to create tables",
    },
    fromRepo: {
        id: "uploadPage.createDataTableFromRepository.fromRepo",
        defaultMessage: "Create by repository",
    },
    fromSpec: {
        id: "uploadPage.createDataTableFromRepository.fromSpec",
        defaultMessage: "Create by template",
    },
    defaultAccenture: {
        id: "uploadPage.createDataTableFromRepository.defaultAccenture",
        defaultMessage: "Default Accenture",
    },
    defaultAccentureError: {
        id: "uploadPage.createDataTableFromRepository.defaultAccentureError",
        defaultMessage: "Action aborted. Tables with global types already exist.",
    },
    empty: {
        id: "uploadPage.createDataTableFromRepository.empty",
        defaultMessage: "Create empty table",
    },
    newCollection: {
        id: "uploadPage.createDataTableFromRepository.newCollection",
        defaultMessage: "New folder",
    },
    Table: {
        id: "uploadPage.createDataTableFromRepository.Table",
        defaultMessage: "Table",
    },
});

export default messages;
