import { Dialog, DialogContent, ListSubheader } from "@mui/material";
import { GridColumnMenu, GridColumnMenuProps } from "@mui/x-data-grid-pro";
import React from "react";

import { ChangeGlobalColumnType } from "@/components/DataColumnMenu/ChangeGlobalColumnType";
import { DeleteDataColumn } from "@/components/DataColumnMenu/DeleteDataColumn";
import { RenameDataColumn } from "@/components/DataColumnMenu/RenameDataColumn";
import { useDataColumnContext } from "@/contexts/EventContexts/DataColumnEventContext";
import { CustomColumnMenuItems } from "./CustomColumnMenuItems";
import { useHiddenColumnsContext } from "@/contexts/hiddenColumnsContext";

export const CustomColumnMenu: React.FC<GridColumnMenuProps> = (props: GridColumnMenuProps) => {
    const { colDef } = props;
    const { field } = colDef;

    const { dataColumnsResult } = useDataColumnContext();
    const dataColumn =
        dataColumnsResult.type === "success"
            ? dataColumnsResult.data.dataColumns.find((col) => col.id === field)
            : undefined;

    const { hideColumn } = useHiddenColumnsContext();
    const [action, setAction] = React.useState<undefined | "RENAME" | "GLOBAL_TAG" | "DELETE">(undefined);

    if (!dataColumn) {
        return null;
    }

    return (
        <>
            <Dialog open={action !== undefined} onClose={() => setAction(undefined)}>
                <DialogContent>
                    {action === "RENAME" && (
                        <RenameDataColumn dataColumn={dataColumn} onAction={() => setAction(undefined)} />
                    )}
                    {action === "GLOBAL_TAG" && (
                        <ChangeGlobalColumnType dataColumn={dataColumn} onAction={() => setAction(undefined)} />
                    )}
                    {action === "DELETE" && (
                        <DeleteDataColumn dataColumn={dataColumn} onAction={() => setAction(undefined)} />
                    )}
                </DialogContent>
            </Dialog>
            <GridColumnMenu
                {...props}
                slots={{
                    columnMenuColumnsItem: null,
                    columnMenuCustomItems: CustomColumnMenuItems,
                }}
                slotProps={{
                    columnMenuCustomItems: {
                        onHideClick: () => hideColumn(dataColumn.id),
                        onRenameClick: () => setAction("RENAME"),
                        onGlobalTypeChangeClick: () => setAction("GLOBAL_TAG"),
                        onDeleteClick: () => setAction("DELETE"),
                    },
                }}
            >
                <ListSubheader>{dataColumn.name}</ListSubheader>
            </GridColumnMenu>
        </>
    );
};
