import {
    DataPipelineOperation,
    DataPipelineOperationInputOutput,
    DataRepositoryField,
    LookupOperationAdapter,
} from "@/generated/client";

type LabeledLookupOperationAdapter = {
    lookupCriteria: {
        dataRepositoryFieldId: string;
        dataRepositoryFieldLabel?: string;
        operationInputId: string;
        operationInput?: DataPipelineOperationInputOutput;
    }[];
    lookupFieldOutputs: {
        dataRepositoryFieldId: string;
        dataRepositoryFieldLabel: string;
    }[];
};

export function fillLookupOperationAdapterWithLabels(
    operation: DataPipelineOperation & { lookupOperationAdapter: LookupOperationAdapter },
    dataRepositoryFields: DataRepositoryField[]
): LabeledLookupOperationAdapter {
    return {
        lookupCriteria: operation.lookupOperationAdapter.lookupCriteria.map((lookupCriterion) => {
            const dataRepositoryField = dataRepositoryFields.find(
                (field) => field.id === lookupCriterion.dataRepositoryFieldId
            );
            return {
                dataRepositoryFieldId: lookupCriterion.dataRepositoryFieldId,
                dataRepositoryFieldLabel: dataRepositoryField?.name,
                operationInputId: lookupCriterion.operationInputId,
                operationInput: operation.input.find((input) => input.label === dataRepositoryField?.name ?? ""),
            };
        }),
        lookupFieldOutputs: [],
    };
}
