import { GraphqlRequestContainer } from "@ignite-analytics/graphql-utilities";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import { Box, Skeleton, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import { useParams } from "react-router-dom";

import NewTableButton from "@/components/NewTableButton";
import { fm } from "@/contexts/IntlContext";
import { useManyDataTables } from "@/generated/DataTableEntity";
import {
    useGetAllDataTableCollectionsQuery,
    useGetOrCreateDataTableCollectionOrderQuery,
    useUpdateDataTableCollectionOrderMutation,
} from "@/generated/client";

import { TableCollection } from "./Components/TableCollection";
import { generateNewOrderConfig, sortCollections } from "./Components/helpers";
import messages from "./messages";
import { ExpandButton } from "./style";

const SIDEBAR_WIDTH = 260;
const SIDEBAR_HEIGHT = "calc(100vh - 65px)";

interface Props {
    onCreate?: () => void;
    sideMenuOpen: boolean;
    onMenuToggle: () => void;
}

const DataTableOverviewMenu: React.FC<Props> = ({ onCreate, sideMenuOpen, onMenuToggle }) => {
    const { dataTableId } = useParams<{ dataTableId: string }>();
    const manyDataTablesRequestState = useManyDataTables();
    const { result: dataTableCollectionResult, refetch: refetchDataTableCollection } =
        useGetAllDataTableCollectionsQuery({});
    const [borderPosition, setBorderPosition] = useState<"TOP" | "BOTTOM" | undefined>(undefined);
    const [draggedCollectionId, setdraggedCollectionId] = useState<string>();
    const [dragOverCollectionId, setDragOverCollectionId] = useState<string | undefined>(undefined);
    const { result: collectionOrder, refetch } = useGetOrCreateDataTableCollectionOrderQuery({});
    const [updateOrder] = useUpdateDataTableCollectionOrderMutation();

    const handleCollectionUpdate = () => {
        // TODO: FORCE UPDATE
    };

    const handleCreate = () => {
        if (onCreate) {
            onCreate();
        }
    };

    const handleOnDragStart = (collectionId: string) => {
        setdraggedCollectionId(collectionId);
    };

    const handleOnDrop = async (e: React.DragEvent<HTMLDivElement>) => {
        e.stopPropagation();

        const targetCollectionId = e.currentTarget.id;
        if (dataTableCollectionResult.type !== "success" || collectionOrder.type !== "success") return;

        const newOrder =
            draggedCollectionId &&
            generateNewOrderConfig(
                targetCollectionId,
                draggedCollectionId,
                borderPosition,
                sortCollections(
                    collectionOrder.data.collectionOrder,
                    dataTableCollectionResult.data.dataTableCollections
                )
            );

        newOrder && (await updateOrder({ input: { collectionOrder: newOrder } }));
        refetch({});
        setDragOverCollectionId(undefined);
        setBorderPosition(undefined);
    };

    const handleDragOver = (ev: React.DragEvent<HTMLDivElement>) => {
        ev.preventDefault();
        const rect = ev.currentTarget.getBoundingClientRect();
        const y = ev.clientY - rect.top;
        if (y > rect.height / 2 && borderPosition !== "BOTTOM") {
            setBorderPosition("BOTTOM");
        } else if (y < rect.height / 2 && borderPosition !== "TOP") {
            setBorderPosition("TOP");
        }
        if (draggedCollectionId && (!dragOverCollectionId || dragOverCollectionId !== ev.currentTarget.id)) {
            setDragOverCollectionId(ev.currentTarget.id ? ev.currentTarget.id : undefined);
        }
    };

    return (
        <Stack maxHeight={SIDEBAR_HEIGHT} paddingX={sideMenuOpen ? 2 : 0} paddingY={2} gap={1}>
            {!sideMenuOpen && (
                <Stack direction="row" justifyContent="flex-end">
                    <ExpandButton onClick={onMenuToggle}>
                        <ChevronRight />
                    </ExpandButton>
                </Stack>
            )}

            {sideMenuOpen && (
                <>
                    <Stack width={SIDEBAR_WIDTH} direction="row" alignItems="center">
                        <Typography variant="subtitle1">{fm(messages.dataTables)}</Typography>
                        <NewTableButton onCreate={handleCreate} />
                        <Box flexGrow={1} />

                        <Stack justifySelf="flex-end">
                            <ExpandButton onClick={onMenuToggle}>
                                <ChevronLeft />
                            </ExpandButton>
                        </Stack>
                    </Stack>
                    <GraphqlRequestContainer
                        asyncData={dataTableCollectionResult}
                        loading={<Skeleton variant="rectangular" width={SIDEBAR_WIDTH} height={SIDEBAR_HEIGHT} />}
                    >
                        {(dataTableCollection) => (
                            <GraphqlRequestContainer
                                asyncData={manyDataTablesRequestState}
                                loading={
                                    <Skeleton variant="rectangular" width={SIDEBAR_WIDTH} height={SIDEBAR_HEIGHT} />
                                }
                            >
                                {(result) => {
                                    const dataTables = result.filter((t) => !t.tableType);
                                    return (
                                        <GraphqlRequestContainer asyncData={collectionOrder}>
                                            {(collectionOrderResponse) => (
                                                <Stack overflow="auto">
                                                    {sortCollections(
                                                        collectionOrderResponse.collectionOrder,
                                                        dataTableCollection.dataTableCollections
                                                    ).map((collection) => (
                                                        <TableCollection
                                                            key={collection.id}
                                                            tables={dataTables}
                                                            collection={collection}
                                                            currentTableId={dataTableId}
                                                            onUpdate={handleCollectionUpdate}
                                                            onDelete={refetchDataTableCollection}
                                                            onDragStart={handleOnDragStart}
                                                            onDragOver={handleDragOver}
                                                            onDrop={handleOnDrop}
                                                        />
                                                    ))}
                                                    <TableCollection
                                                        tables={dataTables}
                                                        collection={{ name: "Others" }}
                                                        currentTableId={dataTableId}
                                                        onUpdate={handleCollectionUpdate}
                                                        onDelete={refetchDataTableCollection}
                                                        onDragStart={handleOnDragStart}
                                                        onDrop={handleOnDrop}
                                                        onDragOver={handleDragOver}
                                                    />
                                                </Stack>
                                            )}
                                        </GraphqlRequestContainer>
                                    );
                                }}
                            </GraphqlRequestContainer>
                        )}
                    </GraphqlRequestContainer>
                </>
            )}
        </Stack>
    );
};

export default DataTableOverviewMenu;
