import {
    Autocomplete,
    Button,
    Checkbox,
    CircularProgress,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField,
    Typography,
} from "@mui/material";
import React, { useState } from "react";
import { DataColumn } from "@/Types/DataColumn";
import { createPrimitiveColumnTypeMessages } from "@/components/CreateDataColumnForm/helpers";
import { fm } from "@/contexts/IntlContext";
import {
    DataColumnType,
    DataPipeline,
    DataRepository,
    DataTable,
    useCreateDataColumnsMutation,
    useCreateInputOutputConnectionMutation,
} from "@/generated/client";
import { cleanDataColumnName } from "@/helpers";
import messages from "../messages";
import { createRepositoryToTableConnectionInput, getInputOutputMatches } from "./helpers";

interface ColumnToCreate {
    fieldId: string;
    name: string;
    type: DataColumnType;
    included: boolean;
}

interface Props {
    repository: DataRepository;
    dataTable: DataTable;
    dataPipeline: DataPipeline;
    onCreate: () => void;
    columns: DataColumn[];
}

export const CreateColumnsStep: React.FC<Omit<Props, "columns">> = ({
    repository,
    dataTable,
    dataPipeline,
    onCreate,
}) => {
    const dataTypeOptions: DataColumnType[] = createPrimitiveColumnTypeMessages();
    const [loading, setLoading] = useState<boolean>(false);
    const [createDataColumns] = useCreateDataColumnsMutation({ refetchQueries: ["getAllDataColumns"] });
    const [createInputOutputConnection] = useCreateInputOutputConnectionMutation({
        refetchQueries: ["getInputOutputConnections"],
    });
    const [columnsToCreate, setColumnsToCreate] = useState<ColumnToCreate[]>(
        repository.fields.map((field) => ({
                fieldId: field.id,
                name: cleanDataColumnName(field.name),
                type: "TEXT",
                included: true,
            }))
    );

    const connectSources = async (newColumns: DataColumn[]) => {
        const matches = newColumns && getInputOutputMatches(newColumns, repository);
        const promises =
            matches &&
            matches.map((match) =>
                createInputOutputConnection(
                    createRepositoryToTableConnectionInput(
                        dataPipeline.id,
                        match.dataRepositoryField.id,
                        match.dataColumn.id,
                        "DATA_REPOSITORY_FIELD",
                        "DATA_TABLE_COLUMN"
                    )
                )
            );

        promises && (await Promise.all(promises));
        setLoading(false);
        onCreate();
    };

    const handleCreateColumns = async () => {
        setLoading(true);
        const mappedColumns = columnsToCreate
            .filter((column) => column.included)
            .map((column) => ({
                name: column.name,
                dataType: column.type,
            }));
        if (mappedColumns.length) {
            const columns = await createDataColumns({
                input: { dataTableId: dataTable.id, dataColumns: mappedColumns },
            }).then((res) => res.data?.createDataColumns.dataColumns);
            columns && (await connectSources(columns));
        }
        setLoading(false);
        onCreate();
    };

    const handleColumnChange = (editedColumn: ColumnToCreate) => {
        const newList = columnsToCreate.map((c) => {
            if (c.fieldId === editedColumn.fieldId) return editedColumn;
            return c;
        });
        setColumnsToCreate(newList);
    };

    return (
        <Stack flexGrow={1} gap={1}>
            <Stack>
                <Typography variant="h5">{fm(messages.create)}</Typography>

                <Typography>{fm(messages.noColumns)}</Typography>
            </Stack>

            <Stack maxHeight="50vh" minHeight="50vh" overflow="auto" padding={{ top: "xs" }}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>{fm(messages.include)}</TableCell>
                            <TableCell>{fm(messages.name)}</TableCell>
                            <TableCell>{fm(messages.dataType)}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {columnsToCreate.map((column) => (
                                <TableRow key={column.fieldId}>
                                    <TableCell padding="checkbox" align="center">
                                        <Checkbox
                                            checked={column.included}
                                            name="include"
                                            onChange={(e) =>
                                                handleColumnChange({ ...column, included: e.currentTarget.checked })
                                            }
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <TextField
                                            fullWidth
                                            value={column.name}
                                            type="text"
                                            name="name"
                                            label=""
                                            onChange={(e) =>
                                                handleColumnChange({ ...column, name: e.currentTarget.value })
                                            }
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <Autocomplete
                                            options={dataTypeOptions}
                                            onChange={(_, v) => handleColumnChange({ ...column, type: v || "TEXT" })}
                                            getOptionLabel={(option) => option}
                                            defaultValue={column.type}
                                            renderInput={(params) => <TextField {...params} />}
                                        />
                                    </TableCell>
                                </TableRow>
                            ))}
                    </TableBody>
                </Table>
            </Stack>
            <Stack direction="row" justifyContent="flex-end" gap={1}>
                <Button size="small" variant="text" onClick={onCreate}>
                    {fm(messages.notNow)}
                </Button>
                <Button size="small" onClick={handleCreateColumns}>
                    {loading && <CircularProgress />}
                    {fm(messages.create)}
                </Button>
            </Stack>
        </Stack>
    );
};
