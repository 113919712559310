import { Dialog, DialogContent } from "@mui/material";
import React from "react";
import DataRepositoryDataPreview from "@/components/SourceDataPreview";
import { DataRepository } from "@/generated/client";

interface Props {
    dataRepository: DataRepository;
    onClose: () => void;
}
export const RepositoryModal = ({ dataRepository, onClose }: Props) => (
        <Dialog open onClose={onClose} fullWidth maxWidth="xl">
            <DialogContent>
                <DataRepositoryDataPreview currentRepository={dataRepository} />
            </DialogContent>
        </Dialog>
    );
