import { defineMessages } from "react-intl";

const messages = defineMessages({
    input: {
        id: "dataTables.modals.OperationModal.input",
        defaultMessage: "Input: ",
    },
    configuration: {
        id: "dataTables.modals.OperationModal.configuration",
        defaultMessage: "Configuration: ",
    },
    output: {
        id: "dataTables.modals.OperationModal.output",
        defaultMessage: "Output columns: ",
    },
    newColumn: {
        id: "dataTables.modals.OperationModal.newColumn",
        defaultMessage: "New data column",
    },
    name: {
        id: "dataTables.modals.OperationModal.name",
        defaultMessage: "Name: {name}",
    },
    seeAllOutputs: {
        id: "dataTables.modals.OperationModal.seeAllOutputs",
        defaultMessage: "See {count} more",
    },
    selectOutput: {
        id: "dataTables.modals.OperationModal.selectOutput",
        defaultMessage: "Select output column",
    },
    usedInFilter: {
        id: "dataTables.modals.OperationModal.usedInFilter",
        defaultMessage: "In the data flow filter.",
    },
    asInputToColumns: {
        id: "dataTables.modals.OperationModal.asInputToColumns",
        defaultMessage: "As input to these columns: {columnNames}.",
    },
    inTheseOperations: {
        id: "dataTables.modals.OperationModal.inTheseOperations",
        defaultMessage: "As input to these operations: {operationNames}.",
    },
    usage: {
        id: "dataTables.modals.OperationModal.usage",
        defaultMessage: "Warning - This operation is still used: {filterMessage} {columnMessage} {operationMessage}",
    },
    unused: {
        id: "dataTables.modals.OperationModal.unused",
        defaultMessage: "This operation is not used anywhere - deletion will have zero consequences",
    },
});

export default messages;
