import React, { ReactNode, createContext, useContext, useEffect, useMemo, useState } from "react";

const cacheKey = (tenant: string) => `DISPLAY_CURRENCY_${tenant}`;

const validateCurrency = (currency: string | null): string | undefined => {
    if (!currency) return undefined;
    if (currency.length !== 3) return undefined;
    return currency;
};

interface ContextValue {
    currency: string | undefined;
}

const Context = createContext<ContextValue | null>(null);

export const CompanyCurrencyProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const tenant = localStorage.tenant ?? "";

    const [fetchedCurrency, setFetchedCurrency] = useState<string | undefined>(undefined);
    const cachedCurrency = validateCurrency(localStorage.getItem(cacheKey(tenant)));

    useEffect(() => {
        if (!tenant) return;
        let tenantHasChanged = false;
        fetch(
            `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_URL}/r/${tenant}/departments/1/department-settings/`,
            {
                credentials: "include",
            }
        ).then(async (response) => {
            const data = await response.json();
            const currency = data.display_currency;

            if (!currency) return;
            if (tenantHasChanged) return;

            localStorage.setItem(cacheKey(tenant), currency);
            setFetchedCurrency(currency);
        });
        return () => {
            tenantHasChanged = true;
        };
    }, [tenant]);

    const currency = fetchedCurrency || cachedCurrency;
    const value = useMemo(() => ({ currency }), [currency]);

    return <Context.Provider value={value}>{children}</Context.Provider>;
};

export const useCompanyCurrency = (): string | undefined => {
    const context = useContext(Context);
    if (!context) {
        throw new Error("useCompanyCurrency must be used within a CompanyCurrencyProvider");
    }
    return context.currency;
};
