import { DataPipelineOperation, OperationType } from "@/generated/client";
import { InputSourceItem } from "@/Types/InputSourceItem";

export const GLOBALLY_DISALLOWED_OPERATION_INPUT_TYPES: OperationType[] = ["GET_OR_CREATE", "MONETARY_AMOUNT"];

export const isValidOperationInput = (operationType: OperationType, inputSource: InputSourceItem) => {
    if (!inputSource.sourceOperationType) return true;
    if (GLOBALLY_DISALLOWED_OPERATION_INPUT_TYPES.includes(inputSource.sourceOperationType)) return false;
    if (operationType === "MATHEMATICAL_EXPRESSION") {
        if (
            inputSource.sourceOperationType === "LETTER_CASING" ||
            inputSource.sourceOperationType === "KEYWORD_TAGGER" ||
            inputSource.sourceOperationType === "DATE_PARSER"
        )
            return false;
    }
    return true;
};
export const isNotOutputOfSelf = (self: DataPipelineOperation, inputSource: InputSourceItem) =>
    !self.output.some((output) => output.id === inputSource.id);

export const removeInvalidOptions = (sources: InputSourceItem[], operation: DataPipelineOperation) =>
    sources.filter(
        (source) => isNotOutputOfSelf(operation, source) && isValidOperationInput(operation.operationType, source)
    );
