import {
    Button,
    CircularProgress,
    Dialog,
    DialogContent,
    DialogTitle,
    Stack,
    TextField,
    Typography,
} from "@mui/material";
import React, { useState } from "react";
import { fm } from "@/contexts/IntlContext";
import messages from "./messages";
import { TranslatedString } from "@/Types/translation";

interface Props {
    description?: TranslatedString;
    acceptText?: string;
    declineText?: string;
    isProcessing?: boolean;
    acceptTestId?: string;
    declineTestId?: string;
    open: boolean;
    header?: TranslatedString;
    writeConfirmation?: boolean;
    writeConfirmationText?: string;

    // Callbacks
    onAccept: () => void;
    onClose: () => void;

    children?: React.ReactNode;
}

/**
 * Simple prompt that can be used to present the user with a
 * chance to abort a request
 */

const Prompt: React.FC<Props> = ({
    description = fm(messages.defaultDescription),
    acceptText = fm(messages.acceptButton),
    declineText = fm(messages.declineButton),
    onAccept,
    onClose,
    open,
    header,
    isProcessing = false,
    children,
    acceptTestId,
    declineTestId,
    writeConfirmation,
    writeConfirmationText = fm(messages.deleteConfirmationText).toString(),
}) => {
    const [inputText, setInputText] = useState<string>();

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>{header}</DialogTitle>
            <DialogContent>
                <Stack gap={2}>
                    <Typography variant="h6">{description}</Typography>
                    {isProcessing ? (
                        <Stack direction="row" justifyContent="center" padding={{ vertical: "m" }}>
                            <CircularProgress />
                        </Stack>
                    ) : (
                        <>
                            {writeConfirmation && (
                                <Stack gap={1}>
                                    <Typography variant="body2">
                                        {fm(messages.typePrompt, { writeConfirmationText })}
                                    </Typography>
                                    <TextField
                                        size="small"
                                        label={fm(messages.confirmationMessage)}
                                        onChange={(e) => setInputText(e.target.value)}
                                    />
                                </Stack>
                            )}
                            <Stack direction="row" justifyContent="right" gap={1}>
                                <Button
                                    data-testid={declineTestId || "prompt-cancelButton"}
                                    onClick={onClose}
                                    color="secondary"
                                    size="small"
                                    variant="text"
                                >
                                    {declineText}
                                </Button>
                                <Button
                                    data-testid={acceptTestId || "prompt-confirmButton"}
                                    onClick={onAccept}
                                    variant="outlined"
                                    color="error"
                                    size="small"
                                    disabled={
                                        writeConfirmation &&
                                        inputText?.toLowerCase() !== writeConfirmationText.toLowerCase()
                                    }
                                >
                                    {acceptText}
                                </Button>
                            </Stack>
                        </>
                    )}
                    {children}
                </Stack>
            </DialogContent>
        </Dialog>
    );
};

export default Prompt;
