import { UnpopulatedFilter } from "@ignite-analytics/filters";
import { AggregationType, AggregationConfiguration as ApiAggregationConfiguration } from "@/generated/client";

export type AggregationConfiguration = Omit<ApiAggregationConfiguration, "__typename" | "filters"> & {
    name: string;
    filters: UnpopulatedFilter[];
};
export type SelectableField = { field: string; label: string; type: string };

export const aggregationTypes = [
    "AGGREGATION_SUM",
    "AGGREGATION_MINIMUM",
    "AGGREGATION_MAXIMUM",
    "AGGREGATION_AVERAGE",
    "AGGREGATION_COUNT",
    "AGGREGATION_COUNT_UNIQUE",
] as const;
export const aggregationTypeLabels: Record<AggregationType, string> = {
    AGGREGATION_SUM: "Sum",
    AGGREGATION_MINIMUM: "Minimum",
    AGGREGATION_MAXIMUM: "Maximum",
    AGGREGATION_AVERAGE: "Average",
    AGGREGATION_COUNT: "Count",
    AGGREGATION_COUNT_UNIQUE: "Unique count",
};

export const aggregationTypeOptions = aggregationTypes.map<{ type: AggregationType; label: string }>((type) => ({
    type,
    label: aggregationTypeLabels[type],
}));
