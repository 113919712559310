import { GraphqlRequestContainer } from "@ignite-analytics/graphql-utilities";
import { track } from "@ignite-analytics/track";
import { PlayArrow } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import React, { useState } from "react";
import { NotificationModal } from "@/components/NotificationModal";
import { testIdPrefix } from "@/components/testIdprefix";
import { fm } from "@/contexts/IntlContext";
import { useStartDataPipelinesByDataTableIdMutation } from "@/generated/client";
import messages from "./messages";

interface Props {
    dataTableId: string;
}
const StartAllPipelinesButton: React.FC<Props> = ({ dataTableId }) => {
    const [startDatapipelinesByDataTableId, mutation] = useStartDataPipelinesByDataTableIdMutation();
    const [notificationIds, setNotificationIds] = useState<string[]>();
    const [isShowingNotifications, setIsShowingNotifications] = useState(false);

    const handleClick = () => {
        if (mutation.result.type === "loading") {
            return;
        }
        startDatapipelinesByDataTableId({ input: { dataTableId } }).then((response) => {
            setIsShowingNotifications(true);
            return setNotificationIds(response.data?.startDataPipelinesByDataTableId.notificationIds);
        });
        track(`Data Table Admin Tab: Click Run All Pipelines`);
    };

    return (
        <>
            {notificationIds && (
                <NotificationModal
                    open={isShowingNotifications}
                    onClose={() => setIsShowingNotifications(false)}
                    title={fm(messages.runningPipelinesMessage)}
                    notificationIds={notificationIds}
                />
            )}
            <LoadingButton
                variant="outlined"
                color="secondary"
                onClick={handleClick}
                startIcon={<PlayArrow />}
                loading={mutation.result.type === "loading"}
                size="small"
                data-testid={`${testIdPrefix}-start-all-pipelines-button`}
            >
                {fm(messages.startAllPipelineButtonLabel)}
            </LoadingButton>
            <GraphqlRequestContainer loading={null} asyncData={mutation.result} />
        </>
    );
};

export default StartAllPipelinesButton;
