import { defineMessages } from "react-intl";

const messages = defineMessages({
    searchForFieldsInputLabel: {
        id: "dataTables.modals.OperationModal.lookupOperationView.searchForFieldsInputLabel",
        defaultMessage: "Search for fields",
    },
    selectColumnTableHeader: {
        id: "dataTables.modals.OperationModal.lookupOperationView.selectColumnTableHeader",
        defaultMessage: "Selected",
    },
    fieldColumnTableHeader: {
        id: "dataTables.modals.OperationModal.lookupOperationView.fieldColumnTableHeader",
        defaultMessage: "Field",
    },
    outputColumnTableHeader: {
        id: "dataTables.modals.OperationModal.lookupOperationView.outputColumnTableHeader",
        defaultMessage: "Output data column",
    },
    showAll: {
        id: "dataTables.modals.OperationModal.lookupOperationView.showAll",
        defaultMessage: "Show all ({number})",
    },
    showLess: {
        id: "dataTables.modals.OperationModal.lookupOperationView.showLess",
        defaultMessage: "Show less",
    },
});

export default messages;
