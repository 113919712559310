import { isNotNullish } from "@ignite-analytics/general-tools";
import { GraphqlRequestContainer } from "@ignite-analytics/graphql-utilities";
import { track } from "@ignite-analytics/track";
import { LoadingButton } from "@mui/lab";
import { Stack, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import { createInputSourceItems } from "@/containers/PipelinePageV2/PipelineUtils";
import { testIdPrefix } from "@/components/testIdprefix";
import { fm } from "@/contexts/IntlContext";
import {
    useCreateMathematicalExpressionDataPipelineOperationMutation,
    useGetAllDataRepositoriesQuery,
    useGetDataPipelineOperationsQuery,
} from "@/generated/client";
import { OperationComponentProps } from "..";
import { displayMathematicalExpression } from "../../OperationModal/OperationDetails/Default/helpers";
import { BuildExpression, MathematicalExpression } from "./BuildExpression";
import messages from "./messages";

const CreateMathematicalExpressionOperation: React.FC<OperationComponentProps> = ({
    dataPipeline,
    onFormSubmit,
    connectInputOutput,
}) => {
    const { result: dataPipelineOperations } = useGetDataPipelineOperationsQuery({
        input: { dataPipelineIds: [dataPipeline.id] },
    });

    const { result: sourceDataRepositories } = useGetAllDataRepositoriesQuery({});

    const [createOperation, asyncResult] = useCreateMathematicalExpressionDataPipelineOperationMutation({
        refetchQueries: ["getDataPipelineOperations"],
    });
    const [expression, setExpression] = useState<MathematicalExpression>();
    const [name, setName] = useState<string>("");

    if (dataPipelineOperations.type !== "success" || sourceDataRepositories.type !== "success") {
        return <>{fm(messages.missingContext)}</>;
    }

    const sources =
        dataPipelineOperations.type === "success" &&
        createInputSourceItems(
            dataPipelineOperations.data.dataPipelineOperations,
            sourceDataRepositories.data.dataRepositories.filter((repository) =>
                dataPipeline.sourceDataRepositoryIds.includes(repository.id)
            )
        );

    const handleCreate = async () => {
        const result = await createOperation({
            input: { dataPipelineId: dataPipeline.id, name, expressionJson: JSON.stringify(expression) },
        });
        track("Pipeline: Created Pipeline Operation", {
            operationType: "MATHEMATICAL_EXPRESSION",
        });
        const operation = result.data?.createMathematicalExpressionDataPipelineOperation.dataPipelineOperation;
        const promises = operation?.input
            .map((inputNode) => {
                const inputSource = sources && sources.find((s) => s.label === inputNode.key);
                return inputSource && operation && connectInputOutput
                    ? connectInputOutput({
                          dataPipelineId: dataPipeline.id,
                          from: { referencedId: inputSource.id, type: inputSource.type },
                          to: { referencedId: inputNode.id, type: "OPERATION" },
                      })
                    : undefined;
            })
            .filter(isNotNullish);
        promises && (await Promise.all(promises));

        onFormSubmit(result.data?.createMathematicalExpressionDataPipelineOperation.dataPipelineOperation);
    };

    return (
        <Stack gap={1}>
            <TextField
                label={fm(messages.operationName)}
                value={name}
                onChange={(e) => setName(e.target.value)}
                variant="outlined"
                margin="normal"
                fullWidth
            />

            <Typography variant="subtitle2"> {fm(messages.buildPrompt)} </Typography>
            <Stack minWidth="100%" maxWidth="40vw">
                {sources && <BuildExpression onChange={setExpression} sources={sources} />}
            </Stack>

            <Typography>{displayMathematicalExpression(expression)}</Typography>

            <LoadingButton
                size="small"
                loading={asyncResult.result.type === "loading"}
                type="submit"
                variant="contained"
                onClick={handleCreate}
                data-testid={`${testIdPrefix}-create-mathematical-operation`}
            >
                {fm(messages.create)}
            </LoadingButton>
            <GraphqlRequestContainer asyncData={asyncResult.result} />
        </Stack>
    );
};

export default CreateMathematicalExpressionOperation;
