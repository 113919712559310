import React from "react";
import { DotsVertical, Pen, Plus, Trash } from "@ignite-analytics/icons";
import { Button, IconButton, Menu, MenuItem, Stack } from "@mui/material";

import { fm } from "@/contexts/IntlContext";
import globalMessages from "@/messages";

import { DataTable, DataTableCollection, useDeleteDataTableCollectionMutation } from "@/generated/client";
import messages from "../messages";
import { AddTablesToCollection } from "./addTablesToCollection";
import { RenameCollection } from "./renameCollection";

interface Props {
    collection: DataTableCollection | { name: string };
    tables: DataTable[];
    editType: "ADD" | "DELETE" | "EDIT" | undefined;
    setEditType: React.Dispatch<React.SetStateAction<"ADD" | "DELETE" | "EDIT" | undefined>>;
    onUpdate: () => void;
    onDelete?: () => void;
}

export const EditDataTableCollection: React.FC<Props> = ({
    editType,
    setEditType,
    onUpdate,
    onDelete,
    collection,
    tables,
}) => {
    const [deleteCollection] = useDeleteDataTableCollectionMutation();

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
        setEditType(undefined);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleDelete = () => {
        onDelete &&
            "id" in collection &&
            deleteCollection({ input: { id: collection.id } }).then(() => {
                onDelete();
                onUpdate();
                setEditType(undefined);
            });
    };

    return (
        <>
            <IconButton size="small" color="secondary" onClick={handleClick}>
                <DotsVertical fontSize="small" />
            </IconButton>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    "aria-labelledby": "basic-button",
                }}
            >
                {!editType && (
                    <Stack>
                        <MenuItem
                            onClick={() => {
                                setEditType("ADD");
                            }}
                        >
                            <Stack direction="row" gap={2} width={150}>
                                <Plus fontSize="small" />
                                {fm(messages.addTables)}
                            </Stack>
                        </MenuItem>
                        {"id" in collection && (
                            <>
                                <MenuItem
                                    onClick={() => {
                                        setEditType("EDIT");
                                    }}
                                >
                                    <Stack direction="row" gap={2} width={150}>
                                        <Pen fontSize="small" />
                                        {fm(messages.rename)}
                                    </Stack>
                                </MenuItem>

                                <MenuItem
                                    onClick={() => {
                                        setEditType("DELETE");
                                    }}
                                >
                                    <Stack direction="row" gap={2} width={150}>
                                        <Trash fontSize="small" />
                                        {fm(globalMessages.delete)}
                                    </Stack>
                                </MenuItem>
                            </>
                        )}
                    </Stack>
                )}
                {editType === "ADD" && (
                    <AddTablesToCollection
                        collection={collection}
                        tables={tables}
                        onCancel={() => setEditType(undefined)}
                        onUpdate={onUpdate}
                    />
                )}
                {"id" in collection && editType === "EDIT" && (
                    <RenameCollection
                        collection={collection}
                        onCancel={() => setEditType(undefined)}
                        onUpdate={onUpdate}
                    />
                )}
                {"id" in collection && editType === "DELETE" && (
                    <Stack direction="row" padding={2} gap={1} justifyContent="space-between">
                        <Button size="small" variant="text" onClick={() => setEditType(undefined)}>
                            {fm(globalMessages.cancel)}
                        </Button>
                        <Button size="small" color="error" onClick={handleDelete}>
                            {fm(globalMessages.delete)}
                        </Button>
                    </Stack>
                )}
            </Menu>
        </>
    );
};
