import { Info } from "@mui/icons-material";
import { Stack, Tooltip, Typography } from "@mui/material";
import React from "react";
import { fm } from "@/contexts/IntlContext";
import { DataRepository } from "@/generated/client";
import messages from "./messages";

interface Props {
    dataRepository: DataRepository;
}
const MatchCriteria: React.FC<Props> = ({ dataRepository }) => (
        <Stack>
            <Stack direction="row" gap={1}>
                <Typography variant="subtitle2">{fm(messages.matchCriteriaHeader)}</Typography>
                <Tooltip title={fm(messages.matchCriteriaTooltip)}>
                    <Info />
                </Tooltip>
            </Stack>

            {dataRepository.uniqueIdentifierConfiguration &&
                dataRepository.uniqueIdentifierConfiguration.uniqueIdentifierFields.map((fieldId) => {
                    const field = dataRepository.fields.find((repoField) => repoField.id === fieldId);
                    if (!field) return;
                    return <Typography key={field.id}>{field.name}</Typography>;
                })}
        </Stack>
    );

export default MatchCriteria;
