import { openSocketConnection } from "@ignite-analytics/notifications";
import React, { useContext, useEffect, useRef } from "react";

export type IndexMappingChangeEvent = {
    elasticIndexShort: string; // Index that will be used for creating index alias
    elasticIndexFull: string; // Index that will be used for creating field alias
};

type Handler = (event: IndexMappingChangeEvent) => void;
type ContextValue = {
    addListener: (handler: Handler) => void;
};
const Context = React.createContext<ContextValue | null>(null);

type Props = {
    userId: string;
    channel: number;
    topic: string;

    children?: React.ReactNode;
};
export const IndexMappingChangeEventContextProvider: React.FC<Props> = ({ userId, channel, topic, children }) => {
    const listeners = useRef<Handler[]>([]);
    useEffect(
        () =>
            openSocketConnection(
                `${process.env.REACT_APP_WS_PROTOCOL || ""}://${
                    process.env.REACT_APP_NOTIFICATIONS_URL || ""
                }/ws/v2/${topic}/${localStorage.tenant}/${channel}/${userId}`,
                (message) => {
                    const event: IndexMappingChangeEvent = JSON.parse(message);
                    listeners.current?.forEach((fn) => fn(event));
                }
            ),
        [userId, channel, topic]
    );

    const addListener = (handler: Handler) => {
        listeners.current = listeners.current.concat(handler);
        return () => {
            listeners.current = listeners.current.filter((fn) => fn !== handler);
        };
    };

    return <Context.Provider value={{ addListener }}>{children}</Context.Provider>;
};

export const useIndexMappingChangeProcessEventListener = (handler: Handler) => {
    const context = useContext(Context);
    if (!context) {
        throw new Error(
            "Must use useIndexMappingChangeProcessEventListener within an IndexMappingChangeEventContextProvider"
        );
    }
    useEffect(() => context.addListener(handler), [context, handler]);
};
