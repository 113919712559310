import { ElasticFieldItem } from "@/generated/client";
import { SelectableField } from "./types";

export function mapToSelectableField(elasticField: ElasticFieldItem, dataTableId: string): SelectableField {
    return {
        type: elasticField.type,
        label: elasticField.label,
        field: elasticField.field,
        dataTableId,
    };
}
