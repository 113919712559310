

import * as Apollo from "@apollo/client";
import { defaultCache } from "@ignite-analytics/graphql-utilities";
import {
    
    CreateAggregatedViewDocument,
    CreateAggregatedViewMutation,
    CreateAggregatedViewMutationVariables,
    
    
    GetManyAggregatedViewsDocument,
    GetManyAggregatedViewsQuery,
    GetManyAggregatedViewsQueryVariables,
    
    UpdateAggregatedViewDocument,
    UpdateAggregatedViewMutation,
    UpdateAggregatedViewMutationVariables,
    
    AggregatedView,
    UpdatableAggregatedViewFieldsInput,
} from "./client";
import "react";
import { useCallback, useEffect, useMemo, useState } from "react";

import React, { useContext } from "react";
import { useApolloClient } from "@apollo/client";
import { GraphQLErrors } from "@apollo/client/errors";
import { isNotNullish } from "@ignite-analytics/general-tools";

type RequestState<T, E> =
    | { type: "not-asked" }
    | {
          type: "loading";
          data?: T;
      }
    | { type: "success"; data: T }
    | { type: "error"; error: E };

type ContextValue = {
	createAggregatedView: (input: CreateAggregatedViewMutationVariables["input"]) => Promise<CreateAggregatedViewMutation["createAggregatedView"]["entity"]>;
	
	
    getManyAggregatedViews: () => Promise<GetManyAggregatedViewsQuery["getManyAggregatedViews"]["entities"]>;
    getManyState: RequestState<AggregatedView[], Error>;
    updateAggregatedView: (id: string, update: UpdatableAggregatedViewFieldsInput) => Promise<UpdateAggregatedViewMutation["updateAggregatedView"]["entity"] | GraphQLErrors>;
	
	updateIterator: number;
};
const Context = React.createContext<ContextValue | null>(null);

type Event<Entity> = {
    id: string;
    model: Entity;
    type: "CREATED" | "UPDATED" | "DELETED";
};
type Handler<Entity> = (event: Event<Entity>) => void;
type Props<Entity> = { children: React.ReactNode; useChangeEvent?: (entity: Entity, handler: Handler<Entity>) => void };
export const AggregatedViewContext = <Entity extends string>({ children, useChangeEvent }: Props<Entity>) => {
    const apolloClient = useApolloClient();

    const [updateIterator, setUpdateIterator] = useState(0);
    const [createIterator, setCreateIterator] = useState(0);


    const createAggregatedView = useCallback(
        async (input: CreateAggregatedViewMutationVariables["input"]) => {
            const result = await apolloClient.mutate<CreateAggregatedViewMutation, CreateAggregatedViewMutationVariables>({
                mutation: CreateAggregatedViewDocument,
                variables: { input },
                fetchPolicy: "no-cache",
            });
            if (result.data) {
                defaultCache.put(result.data.createAggregatedView.entity);
                setCreateIterator((i) => i + 1);
                return result.data.createAggregatedView.entity;
            }
            if (result.errors) {
                throw result.errors[0];
            }
            throw new Error("Invalid state, should have error or data");
        },
        [apolloClient]
    );


    const getManyAggregatedViews = useCallback(async () => {
        const result = await apolloClient.query<GetManyAggregatedViewsQuery, GetManyAggregatedViewsQueryVariables>({
            query: GetManyAggregatedViewsDocument,
            fetchPolicy: "no-cache",
        });
        result.data.getManyAggregatedViews.entities.forEach((entity) => defaultCache.put(entity));
        return result.data.getManyAggregatedViews.entities;
    }, [apolloClient]);
    const [getManyState, setGetManyState] = useState<RequestState<AggregatedView[], Error>>({ type: "loading" });
    useEffect(() => {
        (async function () {
            setGetManyState({
                type: "loading",
                data: getManyState.type === "success" || getManyState.type === "loading" ? getManyState.data : undefined,
            });
            try {
                const result = await getManyAggregatedViews();
                setGetManyState({ type: "success", data: result });
            } catch (e) {
                setGetManyState({ type: "error", error: e as Error });
            }
        })();
    }, [getManyAggregatedViews, createIterator]);
    useEffect(() => {
        setGetManyState((prev) => {
            if (prev.type === "success") {
                return {
                    ...prev,
                    data: prev.data
                        .map((state) => defaultCache.get<AggregatedView>("AggregatedView", state.id))
                        .filter(isNotNullish),
                };
            }
            return prev;
        });
    }, [updateIterator])

    const updateAggregatedView = useCallback(
        async (id: string, update: UpdatableAggregatedViewFieldsInput) => {
            const mask = Object.keys(update);
            const result = await apolloClient.mutate<UpdateAggregatedViewMutation, UpdateAggregatedViewMutationVariables>({
                mutation: UpdateAggregatedViewDocument,
                variables: {
                    input: {
                        id,
                        update,
                        mask,
                    },
                },
            });
            if (result.data) {
                defaultCache.put(result.data.updateAggregatedView.entity);
                setUpdateIterator((i) => i + 1);
                return result.data.updateAggregatedView.entity;
            }
            if (result.errors) {
                return result.errors;
            }
            throw new Error("Invalid state, should have error or data");
        },
        [apolloClient]
    );


useChangeEvent?.("AggregatedView" as Entity, (e: Event<Entity>) => {
    
    
    if (e.type === "CREATED") {
        setCreateIterator((prev) => prev + 1);
    }
    
    if (e.type === "DELETED") {
        defaultCache.delete("AggregatedView", e.id);
        setUpdateIterator((prev) => prev + 1);
    }
});

    const context: ContextValue = {
        createAggregatedView,
        updateAggregatedView,
        
        
        getManyAggregatedViews,
        getManyState,
        
        updateIterator,
    };

    return <Context.Provider value={context}>{children}</Context.Provider>;
};

function useAggregatedViewContext() {
    const context = useContext(Context);
    if (!context) {
        throw new Error("useAggregatedViewContext must be wrapped in a AggregatedViewContext");
    }
    return context;
}


export const useCreateAggregatedViewAction = () => {
    const { createAggregatedView } = useAggregatedViewContext();
    const [state, setState] = useState<RequestState<AggregatedView, Error>>({ type: "not-asked" });
    const createAction = async (...args: Parameters<typeof createAggregatedView>) => {
        try {
            setState({ type: "loading" });
            const result = await createAggregatedView(...args);
            setState({ type: "success", data: result });
            return result;
        } catch (e) {
            setState({ type: "error", error: e as Error });
            throw e;
        }
    };
    return [
        createAction,
        {
            isLoading: state.type === "loading",
            data: state.type === "success" ? state.data : undefined,
            error: state.type === "error" ? state.error : undefined,
        },
    ] as const;
};

/* Get excluded */

export function useManyAggregatedViews() {
    const { getManyState } = useAggregatedViewContext();
    return useMemo(() => ({
        isLoading: getManyState.type === "loading",
        data: getManyState.type === "success" || getManyState.type === "loading"
            ? getManyState.data
            : undefined,
        error: getManyState.type === "error" ? getManyState.error : undefined,
    }), [getManyState]);
}


export const useUpdateAggregatedViewAction = () => {
    const { updateAggregatedView } = useAggregatedViewContext();
    const [state, setState] = useState<RequestState<boolean, Error>>({ type: "not-asked" });
    const updateAction = async (...args: Parameters<typeof updateAggregatedView>) => {
        try {
            setState({ type: "loading" });
            const result = await updateAggregatedView(...args);
            setState({ type: "success", data: true });
            return result;
        } catch (e) {
            setState({ type: "error", error: e as Error });
        }
    };
    return [
        updateAction,
        {
            isLoading: state.type === "loading",
            data: state.type === "success" ? state.data : undefined,
            error: state.type === "error" ? state.error : undefined,
        },
    ] as const;
};

/* Delete excluded */
