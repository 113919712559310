import { fm } from "@/contexts/IntlContext";
import { DataPipelineOperation } from "@/generated/client";
import messages from "./ChangeOperationDetailView/messages";

export enum MathematicalOperation {
    PLUS = "+",
    MULTIPLY = "*",
    DIVIDE = "/",
    SUBTRACT = "-",
    POWER = "pow",
}

export interface MathematicalExpressionVariableInput {
    inputKey: string;
}

export type MathematicalExpression =
    | {
          expression1: MathematicalExpression;
          expression2: MathematicalExpression;
          operation: MathematicalOperation;
      }
    | number
    | MathematicalExpressionVariableInput;

function isMathematicalExpression(obj: unknown): obj is {
    expression1: MathematicalExpression;
    expression2: MathematicalExpression;
    operation: MathematicalOperation;
} {
    if (typeof obj !== "object" || !obj) return false;
    return "expression1" in obj && "expression2" in obj && "operation" in obj;
}

function isMathematicalExpressionVariableInput(obj: unknown): obj is MathematicalExpressionVariableInput {
    if (typeof obj !== "object" || !obj) return false;
    const expr = obj as MathematicalExpressionVariableInput;
    return "inputKey" in expr && typeof expr.inputKey === "string";
}

export const displayMathematicalExpression = (obj: unknown): string => {
    if (typeof obj === "number") {
        return `${obj}`;
    }
    if (isMathematicalExpressionVariableInput(obj)) {
        return obj.inputKey;
    }
    if (isMathematicalExpression(obj)) {
        return `(${displayMathematicalExpression(obj.expression1)} ${obj.operation} ${displayMathematicalExpression(
            obj.expression2
        )})`;
    }
    return "";
};

export function getOperationSpecificDetails(operation: DataPipelineOperation) {
    switch (operation.operationType) {
        case "REGEX":
            return (
                operation.regexOperationAdapter && {
                    label: "regex",
                    displayValue: operation.regexOperationAdapter.regex,
                    value: [{ key: "regex", value: operation.regexOperationAdapter.regex }],
                    changeable: true,
                }
            );
        case "SPLIT":
            return (
                operation.splitOperationAdapter && {
                    label: "split string",
                    displayValue: operation.splitOperationAdapter.splitString,
                    value: [{ key: "splitString", value: operation.splitOperationAdapter.splitString }],
                    changeable: true,
                }
            );
        case "DATE_PARSER":
            return (
                operation.dateParserOperationAdapter && {
                    label: "date format",
                    displayValue: operation.dateParserOperationAdapter.dateFormat,
                    value: [{ key: "dateFormat", value: operation.dateParserOperationAdapter.dateFormat }],
                    changeable: true,
                }
            );
        case "LIST_PARSER":
            return (
                operation.listParserOperationAdapter && {
                    label: "list separator",
                    displayValue: operation.listParserOperationAdapter.splitString,
                    value: [{ key: "splitString", value: operation.listParserOperationAdapter.splitString }],
                    changeable: true,
                }
            );
        case "FILL_BLANKS":
            return (
                operation.fillBlanksOperationAdapter && {
                    label: "blank value",
                    displayValue: operation.fillBlanksOperationAdapter.withColumn
                        ? fm(messages.withColumn).toString()
                        : operation.fillBlanksOperationAdapter.blankValue ?? "",
                    value: [
                        {
                            key: "blankValue",
                            value: operation.fillBlanksOperationAdapter.withColumn
                                ? fm(messages.withColumn).toString()
                                : operation.fillBlanksOperationAdapter.blankValue ?? "",
                        },
                    ],
                    changeable: !operation.fillBlanksOperationAdapter.withColumn,
                }
            );
        case "REPLACE":
            return (
                operation.replaceOperationAdapter && {
                    label: "replace",
                    displayValue: `Replace ${operation.replaceOperationAdapter.replaceString} with ${operation.replaceOperationAdapter.withString}`,
                    value: [
                        { key: "replaceString", value: operation.replaceOperationAdapter.replaceString },
                        { key: "withString", value: operation.replaceOperationAdapter.withString },
                        {
                            key: "isRegex",
                            value: `${operation.replaceOperationAdapter.isRegex}`,
                            allowedValues: ["true", "false"],
                        },
                    ],
                    changeable: true,
                }
            );
        case "TEMPLATE_STRING":
            return (
                operation.templateStringOperationAdapter && {
                    label: "template string",
                    displayValue: undefined,
                    value: [{ key: "templateString", value: operation.templateStringOperationAdapter.templateString }],
                    changeable: true,
                }
            );
        case "LETTER_CASING":
            return (
                operation.letterCasingOperationAdapter && {
                    label: "letter casing",
                    displayValue: operation.letterCasingOperationAdapter?.letterCasing,
                    value: [
                        {
                            key: "letterCasing",
                            value: operation.letterCasingOperationAdapter?.letterCasing,
                            allowedValues: ["LOWER", "UPPER"],
                        },
                    ],
                    changeable: true,
                }
            );
        case "LOOKUP":
            return (
                operation.lookupOperationAdapter && {
                    label: "left join",
                    displayValue: operation.lookupOperationAdapter.dataRepositoryId,
                    changeable: false,
                }
            );
        case "GET_OR_CREATE":
            return (
                operation.getOrCreateOperationAdapter && {
                    label: "connection",
                    displayValue: operation.getOrCreateOperationAdapter.dataRepositoryId,
                    changeable: false,
                }
            );
        case "MONETARY_AMOUNT":
            return { label: "monetary amount", displayValue: undefined, changeable: false };
        case "CONVERT_COUNTRY":
            return { label: "convert country", displayValue: undefined, changeable: false };
        case "TRANSLATION":
            return { label: "TRANSLATION", displayValue: undefined, changeable: false };
        case "KEYWORD_TAGGER":
            return { label: "KEYWORD_TAGGER", displayValue: undefined, changeable: false };
        case "MATCH_COMPANY":
            return { label: "supplier enrichment", displayValue: undefined, changeable: false };
        case "MATHEMATICAL_EXPRESSION":
            return (
                operation.mathematicalExpressionOperationAdapter && {
                    label: "Mathematical expression",
                    displayValue: displayMathematicalExpression(
                        JSON.parse(operation.mathematicalExpressionOperationAdapter.expressionJson)
                    ),
                    value: [
                        {
                            key: "expressionJson",
                            value: operation.mathematicalExpressionOperationAdapter.expressionJson,
                        },
                    ],
                    changeable: false,
                }
            );
        default:
            return undefined;
    }
}
