const buildHiddenColumnsPreferencesKey = (userId: string, tableId: string) => `hidden-columns-${userId}-${tableId}`;

export const getHiddenColumnsPreferences: (userId: string, tableId: string) => string[] = (userId, tableId) => {
    const key = buildHiddenColumnsPreferencesKey(userId, tableId);
    const preferences = localStorage.getItem(key);
    if (!preferences) {
        return [];
    }
    const parsed = JSON.parse(preferences);
    if (Array.isArray(parsed) && parsed.every((el) => typeof el === "string")) return parsed;
    return [];
};

export const saveHiddenColumnsPreferences = (userId: string, tableId: string, hiddenColumnIds: string[]) => {
    const key = buildHiddenColumnsPreferencesKey(userId, tableId);
    localStorage.setItem(key, JSON.stringify(hiddenColumnIds));
};
