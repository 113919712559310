import {
    FilterContextProvider,
    FilterModal,
    useCompanyFavouriteFilters,
    useFilters,
    usePersonalFavouriteFilters,
    useUpsertPersonalFavouriteFilters,
} from "@ignite-analytics/filters";
import { isNotNullish } from "@ignite-analytics/general-tools";
import { Add, Edit } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
    Autocomplete,
    AutocompleteChangeReason,
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    IconButton,
    Stack,
    TextField,
    Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDataColumnsByDataTableId } from "@/contexts/EventContexts/DataColumnEventContext";
import { fm } from "@/contexts/IntlContext";
import { useUpdateAggregatedViewAction } from "@/generated/AggregatedViewEntity";
import { AggregatedView, DataTable } from "@/generated/client";
import {
    mapDataTableToFilterDataSource,
    mapFromAnalysisFilterToFrontendFilters,
    mapFromUnpopulatedFrontendFiltersToAnalysisFilter,
    useElasticFieldsForFilterContextProvider,
} from "@/helpers";
import EditAggregationConfiguration from "../CreateAggregatedView/AggregationConfigurationView";
import { AggregationConfiguration, SelectableField } from "../CreateAggregatedView/AggregationConfigurationView/types";
import { createEmptyAggregationConfiguration, isPossibleForGrouping } from "../CreateAggregatedView/helpers";
import messages from "../CreateAggregatedView/messages";
import { addLabelsToAggregationConfigurations, addLabelsToGroupingConfigurations } from "./helpers";

interface Props {
    aggregatedView: AggregatedView;
    resultDataTable: DataTable;
    selectableFields: SelectableField[];
    userId: string;
    dataTable: DataTable;
}

export const UpdateAggregatedViewModal: React.FC<Props> = ({
    aggregatedView,
    resultDataTable,
    selectableFields,
    userId,
    dataTable,
}) => {
    const [open, setOpen] = useState(false);
    const toggleOpen = () => setOpen((prev) => !prev);
    const columns = useDataColumnsByDataTableId(aggregatedView.resultDataTableId);
    const [updateAggregatedView, updateResult] = useUpdateAggregatedViewAction();
    const [groupingFieldsToDelete, setGroupingFieldsToDelete] = useState<string[]>([]);
    const [groupingFieldsToAdd, setGroupingFieldsToAdd] = useState<{ field: string; label: string }[]>([]);
    const [aggregationConfigurationsToDelete, setAggregationConfigurationsToDelete] = useState<string[]>([]);
    const [groupFieldsValidationError, setGroupFieldsValidationError] = useState<string | undefined>(undefined);
    const [aggregationConfigurationsToAdd, setAggregationConfigurationsToAdd] = useState<AggregationConfiguration[]>(
        []
    );
    const filters = useFilters();
    const filterDataSource = mapDataTableToFilterDataSource(dataTable);
    const usePersonalFavouriteFiltersForContext = () =>
        usePersonalFavouriteFilters(userId, process.env.REACT_APP_GRAPHQL_ROUTER_URL as string);
    const useCompanyFavouriteFiltersForContext = () =>
        useCompanyFavouriteFilters(process.env.REACT_APP_GRAPHQL_ROUTER_URL as string);

    const upsertPersonalFavouriteFilters = useUpsertPersonalFavouriteFilters(
        userId,
        process.env.REACT_APP_GRAPHQL_ROUTER_URL as string
    );

    const existingGroupConfigurationsWithLabel = addLabelsToGroupingConfigurations(
        aggregatedView,
        groupingFieldsToDelete,
        selectableFields
    );

    const existingtAggregationConfigurationsWithLabel = addLabelsToAggregationConfigurations(
        aggregatedView,
        aggregationConfigurationsToDelete,
        columns
    );

    const handleAddColumnClick = () => {
        const newAggregationConfiguration = createEmptyAggregationConfiguration(selectableFields);
        if (newAggregationConfiguration) {
            setAggregationConfigurationsToAdd((prev) => prev.concat(newAggregationConfiguration));
        }
    };

    const handleResetState = () => {
        setAggregationConfigurationsToAdd([]);
        setAggregationConfigurationsToDelete([]);
        setGroupingFieldsToAdd([]);
        setGroupingFieldsToDelete([]);
    };

    useEffect(() => {
        setGroupFieldsValidationError(undefined);
    }, [groupingFieldsToAdd]);

    const handleGroupByFieldsChange = (reason: AutocompleteChangeReason, option: { field: string; label: string }) => {
        const existingConfiguration = aggregatedView.groupingConfigurations.find(
            (group) => group.field === option.field
        );
        if (reason === "removeOption" && existingConfiguration) {
            setGroupingFieldsToDelete((prev) => [...prev, existingConfiguration.id]);
        }
        if (reason === "removeOption" && !existingConfiguration) {
            setGroupingFieldsToAdd((prev) => prev.filter((f) => f.field === option.field));
        }
        if (reason === "selectOption" && existingConfiguration) {
            setGroupingFieldsToDelete((prev) => prev.filter((id) => id === existingConfiguration.id));
        }
        if (reason === "selectOption" && !existingConfiguration && option?.label) {
            setGroupingFieldsToAdd((prev) => [...prev, option]);
        }
    };

    const handleSave = async () => {
        await updateAggregatedView(aggregatedView.id, {
            aggregationsToAdd: aggregationConfigurationsToAdd.map(({ id, ...withoutId }) => ({
                ...withoutId,
                filters:
                    withoutId.filters
                        ?.map((filter) => filter)
                        .map(mapFromUnpopulatedFrontendFiltersToAnalysisFilter)
                        .filter(isNotNullish) ?? [],
            })),
            aggregationsToDelete: aggregationConfigurationsToDelete,
            filters: filters?.map(mapFromUnpopulatedFrontendFiltersToAnalysisFilter).filter(isNotNullish) ?? [],
            groupByFieldsToAdd: groupingFieldsToAdd.map((g) => g.field),
            groupByFieldsToDelete: groupingFieldsToDelete,
        });
        handleResetState();
        toggleOpen();
    };

    return (
        <>
            <IconButton onClick={toggleOpen}>
                <Edit />
            </IconButton>
            <Dialog disablePortal open={open} maxWidth="md" fullWidth onClose={toggleOpen}>
                <DialogTitle>{resultDataTable.name}</DialogTitle>
                <DialogContent>
                    <Stack direction="column" gap={4}>
                        <Stack direction="column">
                            <Typography variant="subtitle1">{fm(messages.groupingFieldsHeaderText)}</Typography>
                            <Stack alignItems="center" direction="row" gap={2}>
                                <Autocomplete
                                    fullWidth
                                    value={[...existingGroupConfigurationsWithLabel, ...groupingFieldsToAdd]}
                                    getOptionLabel={(option) => option.label || ""}
                                    onChange={(_, __, reason, details) => {
                                        if (details?.option) {
                                            const label = details?.option.label;
                                            label &&
                                                handleGroupByFieldsChange(reason, {
                                                    field: details.option.field,
                                                    label,
                                                });
                                        }
                                    }}
                                    multiple
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            inputProps={{ ...params.inputProps }}
                                            onBlur={() => {
                                                if (
                                                    groupingFieldsToAdd.length === 0 &&
                                                    groupingFieldsToDelete.length ===
                                                        aggregatedView.groupingConfigurations.length
                                                ) {
                                                    setGroupFieldsValidationError(
                                                        fm(messages.noGroupFieldsValdiationError).toString()
                                                    );
                                                }
                                            }}
                                            helperText={groupFieldsValidationError}
                                            error={!!groupFieldsValidationError}
                                            label={fm(messages.groupingFieldsSelectLabel)}
                                        />
                                    )}
                                    options={selectableFields.filter(isPossibleForGrouping)}
                                />
                                <FilterModal
                                    title={fm(messages.groupingFieldsSelectLabel).toString()}
                                    placement="UpdateAggregatedView"
                                />
                            </Stack>
                        </Stack>
                        <Stack direction="column" gap={1}>
                            <Typography variant="subtitle1">{fm(messages.aggregatedColumnsHeaderText)}</Typography>
                            {existingtAggregationConfigurationsWithLabel.map((aggregation) => (
                                <FilterContextProvider
                                    key={aggregation.id}
                                    inherit={false}
                                    initialFilters={aggregation.filters
                                        .map(mapFromAnalysisFilterToFrontendFilters)
                                        .filter(isNotNullish)}
                                    dataSource={filterDataSource}
                                    disableDefaultCombination
                                    useDataFields={useElasticFieldsForFilterContextProvider}
                                    // hooks for favourite filters
                                    upsertPersonalFavouriteFilters={upsertPersonalFavouriteFilters}
                                    usePersonalFavouriteFilters={usePersonalFavouriteFiltersForContext}
                                    useCompanyFavouriteFilters={useCompanyFavouriteFiltersForContext}
                                >
                                    <EditAggregationConfiguration
                                        disableEdit
                                        selectableFields={selectableFields}
                                        aggregationConfiguration={{
                                            ...aggregation,
                                            filters: aggregation.filters
                                                .map(mapFromAnalysisFilterToFrontendFilters)
                                                .filter(isNotNullish),
                                        }}
                                        onChange={(changed) => changed}
                                        onDeleteClick={() => {
                                            setAggregationConfigurationsToDelete((prev) => [...prev, aggregation.id]);
                                        }}
                                    />
                                </FilterContextProvider>
                            ))}
                            {aggregationConfigurationsToAdd.map((aggregation) => (
                                <FilterContextProvider
                                    inherit={false}
                                    dataSource={filterDataSource}
                                    disableDefaultCombination
                                    key={aggregation.id}
                                    useDataFields={useElasticFieldsForFilterContextProvider}
                                    // hooks for favourite filters
                                    upsertPersonalFavouriteFilters={upsertPersonalFavouriteFilters}
                                    usePersonalFavouriteFilters={usePersonalFavouriteFiltersForContext}
                                    useCompanyFavouriteFilters={useCompanyFavouriteFiltersForContext}
                                >
                                    <EditAggregationConfiguration
                                        selectableFields={selectableFields}
                                        aggregationConfiguration={aggregation}
                                        onChange={(changed) =>
                                            setAggregationConfigurationsToAdd((prev) =>
                                                prev.map((prevAggregation) => {
                                                    if (prevAggregation === aggregation) {
                                                        return changed;
                                                    }
                                                    return prevAggregation;
                                                })
                                            )
                                        }
                                        onDeleteClick={() =>
                                            setAggregationConfigurationsToAdd((prev) =>
                                                prev.filter((prevAggregation) => prevAggregation.id !== aggregation.id)
                                            )
                                        }
                                    />
                                </FilterContextProvider>
                            ))}
                        </Stack>
                        <Stack direction="row">
                            <Button onClick={handleAddColumnClick} size="small" variant="text" startIcon={<Add />}>
                                {fm(messages.addAggregationConfigurationButtonText)}
                            </Button>
                        </Stack>
                    </Stack>
                    <Stack justifyContent="end" direction="row">
                        <Button onClick={toggleOpen} variant="text">
                            {fm(messages.cancelButtonText)}
                        </Button>
                        <LoadingButton variant="contained" loading={updateResult.isLoading} onClick={handleSave}>
                            {fm(messages.saveButtonText)}
                        </LoadingButton>
                    </Stack>
                </DialogContent>
            </Dialog>
        </>
    );
};
