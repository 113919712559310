import { GraphqlRequestContainer } from "@ignite-analytics/graphql-utilities";
import { Cancel, Check, Delete } from "@mui/icons-material";
import {
    Autocomplete,
    Select,
    MenuItem,
    ButtonGroup,
    Grid,
    IconButton,
    TextField,
    CircularProgress,
} from "@mui/material";
import React, { useState } from "react";
import { fm } from "@/contexts/IntlContext";
import {
    DataPipelineFilter,
    FilterDataType,
    FilterOperatorType,
    useCreatePipelineFilterMutation,
    useDeletePipelineFilterMutation,
    useUpdatePipelineFilterMutation,
} from "@/generated/client";
import { InputSourceItem } from "@/Types/InputSourceItem";
import messages from "./messages";

enum FilterOperatorOptions {
    EQUAL = "EQUAL",
    GREATER_THAN = "GREATER_THAN",
    GREATER_THAN_OR_EQUAL = "GREATER_THAN_OR_EQUAL",
    LESS_THAN = "LESS_THAN",
    LESS_THAN_OR_EQUAL = "LESS_THAN_OR_EQUAL",
    NOT_EQUAL = "NOT_EQUAL",
}

interface Props {
    filterCombinationId: string;
    filter?: DataPipelineFilter;
    availableSources: InputSourceItem[];
    editable: boolean;
    onComplete: () => void;
    newFilterDataType?: FilterDataType;
}

const mapFilterTypeToInputType = {
    TEXT: "text",
    DATE: "date",
    NUMBER: "number",
} as const;

const newInitialValues = {
    value: "",
    operator: FilterOperatorOptions.EQUAL,
    source: { id: undefined, type: undefined, label: undefined },
} as const;

const PipelineFilterRow: React.FC<Props> = ({
    filterCombinationId,
    filter,
    availableSources,
    editable,
    onComplete,
    newFilterDataType,
}) => {
    const [createFilter] = useCreatePipelineFilterMutation();
    const [updateFilter] = useUpdatePipelineFilterMutation();
    const [errors, setErrors] = useState<{ source?: string; value?: string; operator?: string }>();
    const [deleteFilter, { result: deleteResult }] = useDeletePipelineFilterMutation();
    const [hasChanged, setHasChanged] = useState(false);
    const [state, setState] = useState<{
        value: string | Date;
        operator: FilterOperatorType;
        source: Partial<InputSourceItem> | undefined;
    }>(
        filter
            ? {
                  source: availableSources.find((source) => source.id === filter?.sourceId),
                  operator: filter.operator,
                  value: filter.filterDataType === "DATE" ? new Date(filter.value) : filter.value,
              }
            : newInitialValues
    );

    const handleEdit = async () => {
        if (!filter) return;
        if (!state.source?.id || !state.source?.type) return;
        await updateFilter({
            input: {
                id: filter.id,
                filterDataType: filter.filterDataType,
                value: state.value.toString(),
                operator: state.operator,
                sourceId: state.source.id,
                sourceType: state.source.type,
            },
        });
        setHasChanged(false);
        onComplete();
        setErrors(undefined);
    };
    const handleCreate = async () => {
        if (!newFilterDataType) return;
        if (!state.source?.id || !state?.source?.type) return;
        await createFilter({
            input: {
                filterCombinationId,
                filterDataType: newFilterDataType,
                value: state.value.toString(),
                operator: state.operator,
                sourceId: state.source.id,
                sourceType: state.source.type,
            },
        });
        onComplete();
        setHasChanged(false);
        setErrors(undefined);
    };

    const handleReset = () => {
        onComplete();
        setHasChanged(false);
        setErrors(undefined);
    };

    const handleDelete = async () => {
        if (!filter) return;
        await deleteFilter({ input: { id: filter.id } });
    };

    const inputType =
        (filter && mapFilterTypeToInputType[filter.filterDataType]) ??
        (newFilterDataType && mapFilterTypeToInputType[newFilterDataType]) ??
        "text";

    return (
        <Grid container direction="row" alignItems="center" gap={1}>
            <Grid item sm={3}>
                <Autocomplete
                    fullWidth
                    options={availableSources}
                    value={availableSources.find((source) => source.id === filter?.sourceId)}
                    getOptionLabel={(option) => option.label || ""}
                    renderInput={(params) => (
                        <TextField {...params} label={fm(messages.select)} error={!!errors?.source} />
                    )}
                    onChange={(e, val) => {
                        setHasChanged(filter ? filter.sourceId !== val?.id : true);
                        setState((prev) => ({ ...prev, source: val ?? undefined }));
                    }}
                />
            </Grid>
            <Grid item sm={3}>
                <Select
                    fullWidth
                    label="Operator"
                    name="source"
                    error={!!errors?.operator}
                    value={state.operator ?? FilterOperatorOptions.EQUAL}
                    onChange={(e) => {
                        setHasChanged(filter ? filter.operator !== e.target.value : true);
                        e.target.value &&
                            setState((prev) => ({ ...prev, operator: e.target.value as FilterOperatorOptions }));
                    }}
                >
                    {Object.values(FilterOperatorOptions).map((op) => (
                        <MenuItem key={op} value={op}>
                            {op}
                        </MenuItem>
                    ))}
                </Select>
            </Grid>
            <Grid item sm={3}>
                <TextField
                    type={inputType}
                    label={inputType !== "date" && fm(messages.value)}
                    error={errors?.value !== undefined}
                    name="value"
                    defaultValue={filter?.value}
                    onChange={(e) => {
                        e.preventDefault();
                        if (!e.currentTarget.value) {
                            setHasChanged(true);
                            return;
                        }
                        setHasChanged(filter ? filter.value !== e.currentTarget.value : true);
                        setState({ ...state, value: e.target.value });
                    }}
                />
            </Grid>
            <Grid item sm={2}>
                <ButtonGroup>
                    {hasChanged && (
                        <IconButton onClick={editable ? handleEdit : handleCreate}>
                            <Check />
                        </IconButton>
                    )}
                    {editable && !hasChanged ? (
                        <GraphqlRequestContainer
                            asyncData={deleteResult}
                            loading={<CircularProgress />}
                            notAsked={
                                <IconButton onClick={handleDelete}>
                                    <Delete />
                                </IconButton>
                            }
                        />
                    ) : (
                        <IconButton onClick={handleReset} type="reset">
                            <Cancel />
                        </IconButton>
                    )}
                </ButtonGroup>
            </Grid>
        </Grid>
    );
};

export default PipelineFilterRow;
