import { defineMessages } from "react-intl";

const messages = defineMessages({
    areYouSure: {
        id: "areYouSure",
        defaultMessage: "Are you sure you want to delete data column?",
    },
    cantDelete: {
        id: "cantDelete",
        defaultMessage: "Can't delete data column",
    },
});

export default messages;
