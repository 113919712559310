import { FeatureToggleContainer } from "@ignite-analytics/feature-toggle";
import { File, Folder, Plus } from "@ignite-analytics/icons";
import { Error } from "@mui/icons-material";
import {
    CircularProgress,
    Dialog,
    DialogContent,
    DialogContentText,
    IconButton,
    Menu,
    MenuItem,
    Stack,
    Tooltip,
    Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";

import { testIdPrefix } from "@/components/testIdprefix";
import { useBasePath } from "@/contexts/BasePathContext";
import { fm } from "@/contexts/IntlContext";
import { useGetAllDataTableCollectionsQuery, useStartDefaultSetupMutation } from "@/generated/client";
import { PermissionContainer } from "@/lib/permissions";
import { CreateCollection } from "./CreateCollectionForm";
import CreateEmptyDataTable from "./CreateDataTableForm";
import { CreateTableFromFile } from "./CreateTableFromFile";
import { CreateTableFromSpec } from "./CreateTableFromSpec";
import messages from "./messages";

interface Props {
    onCreate: () => void;
}
const NewTableButton: React.FC<Props> = ({ onCreate }) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [showDefaultSetupError, setShowDefaultSetupError] = useState<boolean>(false);
    const [mode, setMode] = useState<"FROM_SPEC" | "EMPTY" | "COLLECTION" | "FROM_FILE">();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const basePath = useBasePath();
    const browserHistory = useHistory();
    const { refetch: refetchDataTableCollection } = useGetAllDataTableCollectionsQuery({});
    const [startDefaultSetup] = useStartDefaultSetupMutation();

    const handleCreate = (newTableId: string) => {
        onCreate();
        setMode(undefined);
        setAnchorEl(null);
        browserHistory.push(`${basePath}${newTableId}/connected-pipeline`);
    };

    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setMode(undefined);
        setAnchorEl(null);
    };

    const modeToComponent = {
        FROM_SPEC: (
            <CreateTableFromSpec
                onCreate={(dataTableId) => {
                    handleCreate(dataTableId);
                }}
            />
        ),
        EMPTY: (
            <CreateEmptyDataTable
                onCreate={(dataTable) => {
                    handleCreate(dataTable.id);
                }}
            />
        ),
        COLLECTION: (
            <CreateCollection
                onCreate={() => {
                    refetchDataTableCollection();
                    handleClose();
                }}
            />
        ),
        FROM_FILE: <CreateTableFromFile onClose={handleClose} onPickExistingDataSource={() => setMode("EMPTY")} />,
    };
    const handleDefaultAccentureSetup = async () => {
        setLoading(true);
        try {
            await startDefaultSetup({ input: { specification: "ACCENTURE", relate: true } });
        } catch (e) {
            setShowDefaultSetupError(true);
        }
        setLoading(false);
    };

    return (
        <>
            <Tooltip title={fm(messages.newTableTooltip)}>
                <IconButton color="ghostGray" onClick={handleClick} data-testid={`${testIdPrefix}-new-table-button`}>
                    <Plus fontSize="small" />
                </IconButton>
            </Tooltip>

            <Menu
                id="demo-positioned-menu"
                aria-labelledby="demo-positioned-button"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "left",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                }}
            >
                <PermissionContainer
                    namespace="data-tables"
                    relation={{ object: "privileged", relation: "write" }}
                    tooltipMsg={fm(messages.unauthorizedTableCreator).toString()}
                    unauthorizedComponent={
                        <MenuItem disabled>
                            <Stack direction="row" gap={1} alignItems="center">
                                <Plus fontSize="small" />
                                <Typography> {fm(messages.newTable)}</Typography>
                            </Stack>
                        </MenuItem>
                    }
                >
                    <MenuItem onClick={() => setMode("FROM_FILE")}>
                        <Stack direction="row" gap={1} alignItems="center">
                            <Plus fontSize="small" />
                            <Typography> {fm(messages.newTable)}</Typography>
                        </Stack>
                    </MenuItem>
                </PermissionContainer>
                <MenuItem onClick={() => setMode("COLLECTION")}>
                    <Stack direction="row" gap={1} alignItems="center">
                        <Folder fontSize="small" />
                        <Typography> {fm(messages.newCollection)}</Typography>
                    </Stack>
                </MenuItem>
                <FeatureToggleContainer featureKey="create-table-froms-spec">
                    <MenuItem onClick={() => setMode("FROM_SPEC")}>
                        <Stack direction="row" gap={1} alignItems="center">
                            <File fontSize="small" />
                            <Typography> {fm(messages.fromSpec)}</Typography>
                        </Stack>
                    </MenuItem>
                </FeatureToggleContainer>
                <FeatureToggleContainer featureKey="default-accenture-setup-button">
                    <MenuItem onClick={handleDefaultAccentureSetup}>
                        <Stack direction="row" gap={1} alignItems="center">
                            <Typography> {fm(messages.defaultAccenture)}</Typography>
                        </Stack>
                        {loading && <CircularProgress />}
                        {showDefaultSetupError && (
                            <Stack direction="row" p={1} sx={{ borderRadius: 1 }} alignItems="center" gap={1}>
                                <Error fontSize="small" color="error" />
                                <Stack>
                                    <Typography variant="body2">{fm(messages.defaultAccentureError)}</Typography>
                                </Stack>
                            </Stack>
                        )}
                    </MenuItem>
                </FeatureToggleContainer>
            </Menu>
            {mode && (
                <Dialog
                    maxWidth="xl"
                    onClose={() => {
                        setMode(undefined);
                    }}
                    open={mode !== undefined}
                >
                    <DialogContent>
                        <DialogContentText>{modeToComponent[mode]}</DialogContentText>
                    </DialogContent>
                </Dialog>
            )}
        </>
    );
};

export default NewTableButton;
