import { GraphqlRequestContainer } from "@ignite-analytics/graphql-utilities";
import { track } from "@ignite-analytics/track";
import { ArrowBack, FormatColorFill, Search } from "@mui/icons-material";
import {
    Alert,
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
} from "@mui/material";
import React, { useState } from "react";
import { InputSourceItem } from "@/Types/InputSourceItem";
import AutoFillConnectionsModalButton from "@/components/AutoFillConnectionsModalButton";
import { createInputSourceItems } from "@/containers/PipelinePageV2/PipelineUtils";
import { useDataColumnsByDataTableId } from "@/contexts/EventContexts/DataColumnEventContext";
import { fm } from "@/contexts/IntlContext";
import {
    DataColumnType,
    DataPipeline,
    DataPipelineOperation,
    DataRepository,
    DataTable,
    useCreateInputOutputConnectionMutation,
    useDeleteInputOutputConnectionMutation,
    useGetInputOutputConnectionsQuery,
} from "@/generated/client";
import globalMessages from "@/messages";
import { UnmappedSources } from "./components/UnmappedSources";
import messages from "./messages";
import { InputOutputMappingRow } from "./tableRow";

interface Props {
    open: boolean;
    dataTable: DataTable;
    dataPipeline: DataPipeline;
    operations: DataPipelineOperation[];
    dataRepositories: DataRepository[];
    onClose: () => void;
}

const UNMAPPABLE_TYPES: DataColumnType[] = ["AGGREGATION", "DATE_AGGREGATION", "FILE", "GROUP_STRUCTURE", "CONTACT"];
export const InputOutputMapping: React.FC<Props> = ({
    open,
    dataTable,
    dataPipeline,
    operations,
    dataRepositories,
    onClose,
}: Props) => {
    const columns = useDataColumnsByDataTableId(dataTable.id);
    const [unmappedMode, setUnmappedMode] = useState(false);
    const { result: inputOutputsResult, refetch: refetchInputOuptutConnections } = useGetInputOutputConnectionsQuery({
        input: { dataPipelineIds: [dataPipeline.id] },
    });
    const [connectInputOutput] = useCreateInputOutputConnectionMutation({ refetchQueries: ["GetAllPendingChanges"] });
    const [deleteInputOutputConnection] = useDeleteInputOutputConnectionMutation({
        refetchQueries: ["getAllPendingChanges"],
    });
    const [searchTerm, setSearchTerm] = useState("");

    const relevantRepositories = dataRepositories.filter((repository) =>
        dataPipeline.sourceDataRepositoryIds.includes(repository.id)
    );
    const sourcesByRepository: { [key: string]: InputSourceItem[] } = relevantRepositories.reduce(
        (acc, repository) => ({ ...acc, [repository.id]: createInputSourceItems(operations, [repository]) }),
        {}
    );
    const handleSourceSelection = async (
        from: InputSourceItem,
        toDataColumnId: string,
        currentInputOutputConnectionId?: string
    ) => {
        if (currentInputOutputConnectionId) {
            await deleteInputOutputConnection({
                input: { inputOutputConnectionId: currentInputOutputConnectionId },
            });
        }

        await connectInputOutput({
            input: {
                dataPipelineId: dataPipeline.id,
                from: {
                    referencedId: from.id,
                    type: from.type,
                },
                to: {
                    referencedId: toDataColumnId,
                    type: "DATA_TABLE_COLUMN",
                },
            },
        });
        track("Pipeline: Map Data Column input");

        refetchInputOuptutConnections({ input: { dataPipelineIds: [dataPipeline.id] } });
    };

    const handleSourceClear = (inputOutputConnectionId: string) => {
        deleteInputOutputConnection({ input: { inputOutputConnectionId } }).then(() =>
            refetchInputOuptutConnections({ input: { dataPipelineIds: [dataPipeline.id] } })
        );
    };
    const handleClose = () => {
        setSearchTerm("");
        onClose();
    };
    return (
        <Dialog open={open} onClose={handleClose} fullWidth maxWidth="lg">
            <DialogTitle>{fm(messages.mapping)}</DialogTitle>
            <DialogContent>
                <Stack gap={1}>
                    <Alert severity="info">{fm(messages.mappingText)}</Alert>

                    <Stack direction="row" justifyContent="space-between">
                        <TextField
                            // eslint-disable-next-line jsx-a11y/no-autofocus
                            autoFocus
                            size="small"
                            onChange={(e) => setSearchTerm(e.target.value)}
                            sx={{ width: "300px" }}
                            label={fm(messages.search)}
                            InputProps={{
                                endAdornment: <Search fontSize="small" />,
                            }}
                        />
                        <Button
                            color="secondary"
                            startIcon={unmappedMode ? <ArrowBack /> : <FormatColorFill />}
                            size="small"
                            variant="outlined"
                            onClick={() => setUnmappedMode((prev) => !prev)}
                        >
                            {unmappedMode ? fm(globalMessages.back) : fm(messages.showUnused)}
                        </Button>
                    </Stack>

                    <GraphqlRequestContainer asyncData={inputOutputsResult}>
                        {(inputOutputConnectionResponse) => {
                            if (unmappedMode || columns.length === 0)
                                return (
                                    <UnmappedSources
                                        dataPipeline={dataPipeline}
                                        sourcesOfPipeline={sourcesByRepository[dataPipeline.sourceDataRepositoryIds[0]]}
                                        inputOutputConnections={inputOutputConnectionResponse.inputOutputConnections}
                                        onColumnCreation={refetchInputOuptutConnections}
                                    />
                                );
                            return (
                                <TableContainer sx={{ maxHeight: "60vh" }}>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>{fm(globalMessages.dataColumn)}</TableCell>
                                                <TableCell>{fm(globalMessages.dataType)}</TableCell>
                                                {relevantRepositories.map((repository) => (
                                                    <TableCell key={repository.id}>
                                                        <Stack direction="row" justifyContent="space-between">
                                                            {fm(messages.sourceHeader, { repository: repository.name })}
                                                            <AutoFillConnectionsModalButton
                                                                dataPipelineId={dataPipeline.id}
                                                                dataColumns={columns}
                                                                dataRepository={repository}
                                                                inputOutputConnections={
                                                                    inputOutputConnectionResponse.inputOutputConnections
                                                                }
                                                                onInputOutputConnectionsCreated={
                                                                    refetchInputOuptutConnections
                                                                }
                                                            />
                                                        </Stack>
                                                    </TableCell>
                                                ))}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {columns
                                                .filter((dc) => !UNMAPPABLE_TYPES.includes(dc.dataType))
                                                .filter((dc) =>
                                                    searchTerm === ""
                                                        ? true
                                                        : dc.name.toLowerCase().includes(searchTerm.toLowerCase())
                                                )
                                                .map((dataColumn) => (
                                                    <InputOutputMappingRow
                                                        key={dataColumn.id}
                                                        dataColumn={dataColumn}
                                                        sourcesByRepository={sourcesByRepository}
                                                        dataRepositories={relevantRepositories}
                                                        inputOutputConnections={
                                                            inputOutputConnectionResponse.inputOutputConnections
                                                        }
                                                        handleSourceSelection={handleSourceSelection}
                                                        handleSourceClear={handleSourceClear}
                                                    />
                                                ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            );
                        }}
                    </GraphqlRequestContainer>
                </Stack>
            </DialogContent>
        </Dialog>
    );
};
