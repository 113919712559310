import { NotificationsList } from "@ignite-analytics/notifications";
import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import React, { useRef } from "react";
import { TranslatedString } from "@/Types/translation";
import { filterIsFirstOccurrence } from "./helpers";

export interface Props {
    open: boolean;
    onClose: () => void;
    notificationIds: string[];
    title: TranslatedString;
}
export const NotificationModal: React.FC<Props> = ({ open, onClose, notificationIds, title }: Props) => {
    const isFirstOccurrence = useRef(filterIsFirstOccurrence()).current;
    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                <NotificationsList
                    filter={(notif, i) =>
                        isFirstOccurrence(`${notif.id}`, i) && notificationIds.some((n) => n === `${notif.id}`)
                    }
                />
            </DialogContent>
        </Dialog>
    );
};
