import { FilterAlt } from "@mui/icons-material";
import { IconButton, Stack } from "@mui/material";
import React, { useState } from "react";
import { EdgeProps, getSmoothStepPath } from "reactflow";
import { DataPipeline } from "@/generated/client";
import DataPipelineFilterModal from "../../Modals/DataPipelineFilterModal";

const foreignObjectSize = 30;

export const FilterEdge: React.FC<EdgeProps<{ dataPipeline: DataPipeline }>> = ({
    id,
    sourceX,
    sourceY,
    targetX,
    targetY,
    sourcePosition,
    targetPosition,
    style = {},
    data,
    markerEnd,
}) => {
    const [modalOpen, setModalOpen] = useState(false);
    const [edgePath, edgeCenterX, edgeCenterY] = getSmoothStepPath({
        sourceX,
        sourceY,
        sourcePosition,
        targetX,
        targetY,
        targetPosition,
    });
    const toggleModal = () => setModalOpen((prev) => !prev);
    return (
        <>
            <path id={id} style={style} className="react-flow__edge-path" d={edgePath} markerEnd={markerEnd} />
            <foreignObject
                width={foreignObjectSize}
                height={foreignObjectSize}
                x={edgeCenterX - foreignObjectSize / 2}
                y={edgeCenterY - foreignObjectSize / 2}
            >
                <body>
                    <Stack
                        width={foreignObjectSize}
                        height={foreignObjectSize}
                        alignItems="center"
                        justifyContent="center"
                        sx={{
                            backgroundColor: (theme) => theme.palette.success.light,
                            border: "1px solid",
                            borderColor: (theme) => theme.palette.secondary.light,
                            borderRadius: "100%",
                        }}
                    >
                        <IconButton onClick={toggleModal}>
                            <FilterAlt />
                        </IconButton>
                    </Stack>
                </body>
            </foreignObject>
            {modalOpen && data && <DataPipelineFilterModal dataPipeline={data?.dataPipeline} onClose={toggleModal} />}
        </>
    );
};
