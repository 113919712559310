import { Add, Info } from "@mui/icons-material";
import {
    Button,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
} from "@mui/material";
import { orderBy } from "lodash-es";
import React, { useState } from "react";
import { fm } from "@/contexts/IntlContext";
import { DataPipelineOperation } from "@/generated/client";
import MappingElement from "./MappingElement";
import messages from "./messages";

interface Props {
    operation: DataPipelineOperation;
}
const MappingOperationDetailView: React.FC<Props> = ({ operation }) => {
    const [showEmpty, setShowEmpty] = useState(false);
    const mappings = operation.mappingOperationAdapter?.mappingElements ?? [];

    return (
        <Stack gap={1}>
            <TableContainer>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                <Stack direction="row" gap={1}>
                                    {fm(messages.from)}
                                    <Tooltip title={fm(messages.fromTooltip)}>
                                        <Info />
                                    </Tooltip>
                                </Stack>
                            </TableCell>
                            <TableCell>{fm(messages.to)}</TableCell>
                            <TableCell>{fm(messages.action)}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {orderBy(mappings, ["elementId"]).map((mapping) => (
                            <MappingElement
                                key={mapping.elementId}
                                mapping={mapping}
                                operationId={operation.id}
                                onDelete={() => null}
                            />
                        ))}
                        {showEmpty && (
                            <MappingElement
                                mapping={{ from: null, to: null }}
                                operationId={operation.id}
                                onAdd={() => setShowEmpty(false)}
                                onDelete={() => setShowEmpty(false)}
                            />
                        )}
                    </TableBody>
                </Table>
            </TableContainer>

            <Stack width="170px">
                <Button size="small" endIcon={<Add />} onClick={() => setShowEmpty(true)}>
                    {fm(messages.addMapping)}
                </Button>
            </Stack>
        </Stack>
    );
};

export default MappingOperationDetailView;
