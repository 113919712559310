import { defineMessages } from "react-intl";

const messages = defineMessages({
    dataFields: {
        id: "components.sourceDataPreview.dataFields",
        defaultMessage: "Data fields",
    },
    samples: {
        id: "components.sourceDataPreview.samples",
        defaultMessage: "Samples",
    },
    noData: {
        id: "components.sourceDataPreview.noData",
        defaultMessage: "No data",
    },
});

export default messages;
