import { GraphqlRequestContainer } from "@ignite-analytics/graphql-utilities";
import { Delete } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Tooltip } from "@mui/material";
import React, { useEffect, useState } from "react";
import { fm } from "@/contexts/IntlContext";
import { useDeleteDataTableAction } from "@/generated/DataTableEntity";
import globalMessages from "@/messages";
import messages from "./messages";

interface Props {
    dataTableId: string;
}
const DeleteAggregatedViewConfirmationDialog: React.FC<Props> = ({ dataTableId }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const [deleteDataTable, response] = useDeleteDataTableAction();

    useEffect(() => {
        if (response.data) {
            setIsModalOpen(false);
        }
    }, [response.data]);

    return (
        <>
            <Tooltip title={fm(messages.deleteAggregatedViewTooltip)}>
                <IconButton>
                    <Delete onClick={() => setIsModalOpen(true)} />
                </IconButton>
            </Tooltip>
            <Dialog open={isModalOpen} onClose={() => setIsModalOpen(false)}>
                <DialogTitle>{fm(messages.deleteConfirmTitle)}</DialogTitle>
                <DialogContent>{fm(messages.areYouSureText)}</DialogContent>
                <DialogActions>
                    <LoadingButton onClick={() => deleteDataTable(dataTableId)} loading={response.isLoading}>
                        {fm(globalMessages.delete)}
                    </LoadingButton>
                </DialogActions>
                <GraphqlRequestContainer asyncData={response} loading={null} />
            </Dialog>
        </>
    );
};

export default DeleteAggregatedViewConfirmationDialog;
