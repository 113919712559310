import { track } from "@ignite-analytics/track";
import { LoadingButton } from "@mui/lab";
import { Stack, TextField } from "@mui/material";
import React, { useState } from "react";
import { fm } from "@/contexts/IntlContext";
import { DataTable } from "@/generated/client";
import { useCreateDataTableAction } from "@/generated/DataTableEntity";
import globalMessages from "@/messages";
import messages from "./messages";

type Props = {
    onCreate: (dataTable: DataTable) => void;
};
const CreateEmptyDataTable: React.FC<Props> = ({ onCreate }) => {
    const [createDataTable, mutation] = useCreateDataTableAction();
    const [name, setName] = useState<string>("");
    const handleSubmit = () => {
        if (mutation.isLoading) {
            return;
        }
        name && createDataTable({ name }).then(onCreate);
        track("Data Table: Create", { type: "empty" });
    };

    return (
        <Stack gap={2} minWidth="20vw">
            <TextField
                onChange={(e) => setName(e.target.value)}
                label={fm(messages.nameInputLabel)}
                placeholder="Transactions"
                onKeyPress={(e) => e.key === "Enter" && handleSubmit()}
            />
            <LoadingButton variant="contained" onClick={handleSubmit} loading={mutation.isLoading}>
                {fm(globalMessages.submitButton)}
            </LoadingButton>
        </Stack>
    );
};

export default CreateEmptyDataTable;
