import { Filter, FilterModal, useFilters, useShortDescriptionOfFiltersInContext } from "@ignite-analytics/filters";
import { FilterAlt, FilterAltOff } from "@mui/icons-material";
import Delete from "@mui/icons-material/Delete";
import { Autocomplete, IconButton, Stack, TextField, Tooltip } from "@mui/material";
import React, { useEffect } from "react";
import { fm } from "@/contexts/IntlContext";
import { isPossibleForAggregationType } from "./helpers";
import messages from "./messages";
import { AggregationConfiguration, SelectableField, aggregationTypeLabels, aggregationTypeOptions } from "./types";

const emptyArray: Filter[] = [];

interface Props {
    aggregationConfiguration: AggregationConfiguration;
    onChange: (value: AggregationConfiguration) => void;
    onDeleteClick: () => void;
    selectableFields: SelectableField[];
    disableEdit?: boolean;
}
const EditAggregationConfiguration: React.FC<Props> = ({
    aggregationConfiguration,
    selectableFields: _selectableFields,
    onChange,
    onDeleteClick,
    disableEdit,
}) => {
    const selectedAggregationType = {
        type: aggregationConfiguration.type,
        label: aggregationTypeLabels[aggregationConfiguration.type],
    };
    const selectableFields = _selectableFields.filter(isPossibleForAggregationType(selectedAggregationType.type));

    const selectedField = selectableFields.find(
        (selectableField) => selectableField.field === aggregationConfiguration.field
    ) ?? { field: aggregationConfiguration.field, label: aggregationConfiguration.field };

    const filters = useFilters();

    useEffect(() => {
        if (filters !== aggregationConfiguration.filters && filters !== undefined) {
            onChange({ ...aggregationConfiguration, filters: filters ?? emptyArray });
        }
    }, [filters, aggregationConfiguration, onChange]);

    const shortDescription = useShortDescriptionOfFiltersInContext();
    return (
        <Stack alignItems="center" direction="row" gap={1.5}>
            <TextField
                label={fm(messages.columnNameTextLabel)}
                fullWidth
                value={aggregationConfiguration.name}
                onChange={(e) => onChange({ ...aggregationConfiguration, name: e.target.value })}
                disabled={disableEdit}
            />
            <Autocomplete
                fullWidth
                value={selectedAggregationType}
                onChange={(_, value) => value && onChange({ ...aggregationConfiguration, type: value.type })}
                options={aggregationTypeOptions}
                renderInput={(params) => <TextField {...params} inputProps={{ ...params.inputProps }} />}
                disabled={disableEdit}
            />
            <Autocomplete
                fullWidth
                value={selectedField}
                onChange={(_, value) => value && onChange({ ...aggregationConfiguration, field: value.field })}
                options={selectableFields}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        inputProps={{ ...params.inputProps }}
                        label={fm(messages.columnSelectLabel)}
                    />
                )}
                disabled={disableEdit}
            />
            {disableEdit ? (
                shortDescription ? (
                    <Tooltip title={shortDescription}>
                        <IconButton>
                            <FilterAlt />
                        </IconButton>
                    </Tooltip>
                ) : (
                    <IconButton>
                        <FilterAltOff />
                    </IconButton>
                )
            ) : (
                <FilterModal
                    title={`${fm(messages.columnSelectLabel).toString()}: ${selectedField.label}`}
                    placement="AggregationConfigurationView"
                />
            )}
            <IconButton onClick={onDeleteClick}>
                <Delete />
            </IconButton>
        </Stack>
    );
};

export default EditAggregationConfiguration;
