import { GraphqlRequestContainer } from "@ignite-analytics/graphql-utilities";
import { TextField } from "@mui/material";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";
import React from "react";
import { fm } from "@/contexts/IntlContext";
import { useGetAllGroupStructuresQuery } from "@/generated/client";
import messages from "./messages";

interface Props {
    onChange: (selected: string) => void;
    selectedId?: string;
}
const GroupStructureSelection: React.FC<Props> = ({ onChange }) => {
    const { result } = useGetAllGroupStructuresQuery({});
    if (result.type === "loading") {
        return <Skeleton variant="rectangular" width="100%" height={50} />;
    }
    const filterOptions = createFilterOptions<{ value: string; label: string }>({
        matchFrom: "start",
        stringify: (option) => option.label,
    });
    return (
        <GraphqlRequestContainer asyncData={result}>
            {({ groupStructures }) => (
                <Autocomplete
                    filterOptions={filterOptions}
                    options={groupStructures.map((structure) => ({ value: structure.id, label: structure.name }))}
                    onChange={(_, value) => {
                        if (value) {
                            onChange(value.value);
                        }
                    }}
                    renderOption={(props, option) => (
                        // As you might have duplicated group structure names, you need to use the id a key to avoid rendering issues with react
                        // Thats why we use render option instead of getOptionLabel
                        <Box component="li" {...props} key={option.value}>
                            {option.label}
                        </Box>
                    )}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            inputProps={{ ...params.inputProps }}
                            label={fm(messages.groupStructureLabel)}
                        />
                    )}
                />
            )}
        </GraphqlRequestContainer>
    );
};

export default GroupStructureSelection;
