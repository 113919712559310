import { defineMessages } from "react-intl";

const messages = defineMessages({
    nameHeader: {
        id: "uploadPage.dataRepositoryPage.manageGlobalColumnTypes.nameHeader",
        defaultMessage: "COLUMN NAME",
    },
    typeHeader: {
        id: "uploadPage.dataRepositoryPage.manageGlobalColumnTypes.typeHeader",
        defaultMessage: "DATA TYPE",
    },
    globalTypeHeader: {
        id: "uploadPage.dataRepositoryPage.manageGlobalColumnTypes.globalTypeHeader",
        defaultMessage: "GLOBAL TYPE",
    },
    actionsHeader: {
        id: "uploadPage.dataRepositoryPage.manageGlobalColumnTypes.actionsHeader",
        defaultMessage: "ACTIONS",
    },
    listTypeHeader: {
        id: "uploadPage.dataRepositoryPage.manageGlobalColumnTypes.listTypeHeader",
        defaultMessage: "LIST TYPE",
    },
    dataColumns: {
        id: "uploadPage.dataRepositoryPage.manageGlobalTypes.dataColumns",
        defaultMessage: "Data columns",
    },
    showHideColumn: {
        id: "uploadPage.dataRepositoryPage.manageGlobalTypes.showHideColumn",
        defaultMessage: "SHOW/HIDE COLUMN",
    },
    deleteDataColumnTitle: {
        id: "uploadDataPage.dataColumnModal.manageGlobalTypes.deleteDataColumnTitle",
        defaultMessage: "Delete data columns",
    },
    deleteDataColumnDescription: {
        id: "uploadDataPage.dataColumnModal.manageGlobalTypes.deleteDataColumnDescription",
        defaultMessage:
            "Are you certain that you want to delete the following {columnCount} columns? This action is irreversible, and will delete all data stored on the selected column(s).",
    },
    deleteDataColumnsButton: {
        id: "uploadDataPage.dataColumnModal.manageGlobalTypes.deleteDataColumnsButton",
        defaultMessage: "Delete",
    },
    deleteDataColumnsTooltip: {
        id: "uploadDataPage.dataColumnModal.manageGlobalTypes.deleteDataColumnsTooltip",
        defaultMessage: "Delete ({columnCount})  data columns",
    },
    searchDataColumns: {
        id: "uploadDataPage.dataColumnModal.manageGlobalTypes.searchDataColumns",
        defaultMessage: "Search data columns",
    },
    createDataColumnButtonLabel: {
        id: "uploadPage.dataRepositoryPage.manageGlobalColumnTypes.createDataColumnButtonLabel",
        defaultMessage: "Create",
    },
    createDataColumnButtonTooltip: {
        id: "uploadPage.dataRepositoryPage.manageGlobalColumnTypes.createDataColumnButtonTooltip",
        defaultMessage: "Create data column",
    },
    unauthorizedDeleteDataColumnsTooltip: {
        id: "uploadPage.dataRepositoryPage.manageGlobalColumnTypes.unauthorizedDeleteDataColumnsTooltip",
        defaultMessage: "Only administrators can delete data columns",
    },
});

export default messages;
