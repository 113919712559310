import React, { Reducer, useContext, useReducer } from "react";

type State = {
    createdConnectionColumns: string[];
    changedConnectionColumns: string[];
    columnsWithChangedCubeConfiguration: string[];
};

const defaultState: State = {
    createdConnectionColumns: [],
    changedConnectionColumns: [],
    columnsWithChangedCubeConfiguration: [],
};

type Action =
    | { type: "RESET" }
    | { type: "ADD_CHANGED"; changedConnectionColumnId: string }
    | { type: "ADD_CREATED"; createdConnectionColumnId: string }
    | { type: "INCLUDED_FIELDS_CHANGE"; columnsWithChangedCubeConfiguratioId: string };

type ContextValue = [State, React.Dispatch<Action>];
const Context = React.createContext<ContextValue | null>(null);

const reducer: Reducer<State, Action> = (prevState, action) => {
    switch (action.type) {
        case "RESET":
            return defaultState;
        case "ADD_CHANGED":
            return {
                ...prevState,
                changedConnectionColumns: [
                    ...prevState.changedConnectionColumns.filter((id) => id !== action.changedConnectionColumnId),
                    action.changedConnectionColumnId,
                ],
            };
        case "ADD_CREATED":
            return {
                ...prevState,

                createdConnectionColumns: [
                    ...prevState.changedConnectionColumns.filter((id) => id !== action.createdConnectionColumnId),
                    action.createdConnectionColumnId,
                ],
            };
        case "INCLUDED_FIELDS_CHANGE":
            return {
                ...prevState,

                columnsWithChangedCubeConfiguration: [
                    ...prevState.changedConnectionColumns.filter(
                        (id) => id !== action.columnsWithChangedCubeConfiguratioId
                    ),
                    action.columnsWithChangedCubeConfiguratioId,
                ],
            };
    }
};

const ChangedConnectionsContextProvider: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, defaultState);

    return <Context.Provider value={[state, dispatch]}>{children}</Context.Provider>;
};

export function useChangedConnectionsContext() {
    const context = useContext(Context);
    if (!context) {
        throw new Error(
            "Cannot use hook 'ChangedConnectionsPageContext' outside an  ChangedConnectionsContextProvider"
        );
    }
    return context;
}

export default ChangedConnectionsContextProvider;
