import { Cancel } from "@mui/icons-material";
import { Autocomplete, MenuItem, IconButton as MuiIconButton, Select, Stack, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { InputSourceItem } from "@/Types/InputSourceItem";
import { isValidOperationInput } from "@/containers/PipelinePageV2/Modals/OperationModal/InputMappingSection/helper";
import { fm } from "@/contexts/IntlContext";
import { BuildExpression, MathematicalExpression } from "../BuildExpression";
import messages from "../messages";

interface Props {
    sources: InputSourceItem[];
    onChange: (expression: MathematicalExpression) => void;
}

type ExpressionOptions = "number" | "input" | "expression";

export const ExpressionValueSelect: React.FC<Props> = ({ sources, onChange }: Props) => {
    const [selected, setSelected] = useState<ExpressionOptions>();
    const [expression, setExpression] = useState<MathematicalExpression>(0);

    useEffect(() => {
        onChange(expression);
    }, [expression, onChange]);

    return (
        <Stack direction="row" sx={{ flexWrap: "nowrap" }}>
            <Stack>
                {!selected && (
                    <Stack minWidth="200px">
                        <Select
                            fullWidth
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            label=""
                            sx={{ backgroundColor: (theme) => theme.palette.background.default }}
                            onChange={(e) => setSelected(e.target.value as ExpressionOptions)}
                        >
                            <MenuItem value="number">{fm(messages.constantNumber)}</MenuItem>
                            <MenuItem value="input">{fm(messages.input)}</MenuItem>
                            <MenuItem value="expression">{fm(messages.expresssion)}</MenuItem>
                        </Select>
                    </Stack>
                )}
                {selected === "number" && (
                    <Stack minWidth="100px">
                        <TextField
                            type="number"
                            label=""
                            value={typeof expression === "number" ? expression : 1}
                            // eslint-disable-next-line no-restricted-globals
                            onChange={(e) => !isNaN(Number(e.target.value)) && setExpression(Number(e.target.value))}
                            variant="outlined"
                            margin="normal"
                            fullWidth
                        />
                    </Stack>
                )}
                {selected === "input" && (
                    <Stack minWidth="150px">
                        <Autocomplete
                            fullWidth
                            value={
                                typeof expression !== "number" && "inputKey" in expression
                                    ? expression.inputKey
                                    : undefined
                            }
                            options={sources
                                .filter((s) => isValidOperationInput("MATHEMATICAL_EXPRESSION", s))
                                .map((s) => s.label)}
                            onChange={(_, v) => {
                                v && setExpression({ inputKey: v });
                            }}
                            getOptionLabel={(option) => option}
                            renderInput={(params) => <TextField {...params} label={fm(messages.selectInput)} />}
                        />
                    </Stack>
                )}
                {selected === "expression" && <BuildExpression onChange={setExpression} sources={sources} />}
            </Stack>
            {selected && (
                <MuiIconButton size="small" color="error" onClick={() => setSelected(undefined)}>
                    <Cancel />
                </MuiIconButton>
            )}
        </Stack>
    );
};
