import {
    FilterContextProvider,
    useCompanyFavouriteFilters,
    usePersonalFavouriteFilters,
    useUpsertPersonalFavouriteFilters,
} from "@ignite-analytics/filters";
import { GraphqlRequestContainer } from "@ignite-analytics/graphql-utilities";
import { track } from "@ignite-analytics/track";
import { LoadingButton } from "@mui/lab";
import { Alert, Button, Dialog, DialogContent, Stack } from "@mui/material";
import { uniqueId } from "lodash-es";
import React, { useState } from "react";
import { cubeBuilderPageTestIdPrefix } from "@/containers/CubeBuilderPage";
import { useChangedConnectionsContext } from "@/containers/CubeBuilderPage/context";
import { useEntityEventListener } from "@/contexts/EventContexts/EntityEventChangeContext";
import { fm } from "@/contexts/IntlContext";
import {
    DataTable,
    useAddDataTableConnectionCriterionMutation,
    useCreateDataColumnsMutation,
    useCreateDataTableConnectionMutation,
    useGetDataTableElasticFieldsLazyQuery,
    useGetDataTableElasticFieldsQuery,
} from "@/generated/client";
import { mapDataTableToFilterDataSource, useElasticFieldsForFilterContextProvider } from "@/helpers";
import { CreateConnectionModalHeader } from "../ConnectionModalHeader";
import { CriterionBuilder } from "../CriterionBuilder";
import TestConnectionResults from "../TestConnectionResults";
import { hasValue, toBackEndCriterion } from "../helpers";
import messages from "../messages";
import { Criterion, connectionCriterionOperators } from "../types";
import { useSessionContext } from "@/contexts/SessionContext";

interface Props {
    open: boolean;
    onClose: () => void;
    onCreate: () => void;
    baseTable: DataTable;
    dataTables: DataTable[];
}

export const CreateConnectionModal: React.FC<Props> = ({ open, onClose, onCreate, baseTable, dataTables }) => {
    const [criteria, setCriteria] = useState<Criterion[]>([
        {
            sourceField: undefined,
            targetField: undefined,
            type: connectionCriterionOperators[0],
            frontEndKey: uniqueId(),
            ignoreOnBlankTarget: false,
        },
    ]);
    const [, dispatch] = useChangedConnectionsContext();

    /** Error Handling */
    const [showError, setShowError] = useState(false);

    const [errorMessage, setErrorMessage] = useState<{ link?: string; message: string }>({
        link: undefined,
        message: "Something went wrong.",
    });
    const [targetTable, setTarget] = useState<DataTable>();
    const [getTargetFields, query] = useGetDataTableElasticFieldsLazyQuery();
    const [createDataTableConnection, connectionMutation] = useCreateDataTableConnectionMutation({
        refetchQueries: ["getAllDataTableConnections", "getDataCubeConfiguration"],
    });
    const [createDataColumns, { result: createColumnResult }] = useCreateDataColumnsMutation();
    const [addCriteria] = useAddDataTableConnectionCriterionMutation();
    const { result: baseFieldsResult } = useGetDataTableElasticFieldsQuery({
        input: { dataTableId: baseTable.id, withEnrichments: false },
    });
    const { id: userId } = useSessionContext();
    const filterDataSource = mapDataTableToFilterDataSource(targetTable);

    const usePersonalFavouriteFiltersForContext = () =>
        usePersonalFavouriteFilters(userId, process.env.REACT_APP_GRAPHQL_ROUTER_URL as string);
    const useCompanyFavouriteFiltersForContext = () =>
        useCompanyFavouriteFilters(process.env.REACT_APP_GRAPHQL_ROUTER_URL as string);
    const upsertPersonalFavouriteFilters = useUpsertPersonalFavouriteFilters(
        userId,
        process.env.REACT_APP_GRAPHQL_ROUTER_URL as string
    );

    const updateTargetFieldState = async (target: DataTable) => {
        target && (await getTargetFields({ input: { dataTableId: target.id, withEnrichments: false } }));
        setTarget(target);
    };

    useEntityEventListener("DataColumn", () => targetTable && updateTargetFieldState(targetTable));

    const handleCreate = async () => {
        if (!targetTable) return;
        try {
            const createDataColumnResponse = await createDataColumns({
                input: {
                    dataTableId: baseTable.id,
                    dataColumns: [
                        {
                            dataType: "TABLE_RELATION",
                            name: `${targetTable.name}-relation`,
                            referencedId: targetTable.id,
                        },
                    ],
                },
            });
            const createdColumn = createDataColumnResponse.data?.createDataColumns.dataColumns[0];
            createdColumn && dispatch({ type: "ADD_CREATED", createdConnectionColumnId: createdColumn.id });
            const createConnectionResult =
                createdColumn &&
                (await createDataTableConnection({
                    input: {
                        dataColumnId: createdColumn.id,
                        dataTableId: baseTable.id,
                        targetDataSourceIdentifier: targetTable.id,
                        targetDataSourceType: "DATA_TABLE",
                        criteria: [],
                    },
                }));
            const createdConnection = createConnectionResult?.data?.createDataTableConnection.dataTableConnection;

            const criteriaPromises =
                createdConnection &&
                criteria.map((criterion) =>
                    criterion.sourceField && criterion.targetField
                        ? addCriteria({
                              input: {
                                  dataTableConnectionId: createdConnection.id,
                                  sourceField: criterion.sourceField,
                                  targetField: criterion.targetField,
                                  type: criterion.type,
                                  ignoreOnBlankTarget: criterion.ignoreOnBlankTarget,
                              },
                          })
                        : undefined
                );
            criteriaPromises && (await Promise.all(criteriaPromises));
            track(`Table Connections: Create Table Connection`);
        } catch (e) {
            if (e instanceof Error && e.message.includes("optimize")) {
                setErrorMessage({
                    link: `/data-management/data-tables/${baseTable.id}/settings`,
                    message: `${e.message.split(":")[1]} Navigate to settings by clicking this error message.`,
                });
            } else if (e instanceof Error) {
                setErrorMessage({
                    message: e.message.split(":")[1],
                });
            }
            setShowError(true);
            return;
        }

        onCreate();
        setCriteria([
            {
                sourceField: undefined,
                targetField: undefined,
                type: connectionCriterionOperators[0],
                frontEndKey: uniqueId(),
                ignoreOnBlankTarget: false,
            },
        ]);
        setTarget(undefined);
    };

    return (
        <Dialog disablePortal open={open} fullWidth maxWidth="xl" onClose={onClose}>
            <CreateConnectionModalHeader
                dataTables={dataTables}
                targetTable={targetTable}
                baseTable={baseTable}
                updateTargetFieldState={updateTargetFieldState}
            />
            <DialogContent>
                <GraphqlRequestContainer asyncData={baseFieldsResult}>
                    {(baseFieldsResponse) => (
                        <GraphqlRequestContainer asyncData={query.result}>
                            {(targetFieldsResponse) => (
                                <Stack>
                                    <CriterionBuilder
                                        baseTable={baseTable}
                                        targetTable={targetTable}
                                        dependingDataColumnIds={[]}
                                        baseFieldsResponse={baseFieldsResponse}
                                        targetFieldsResponse={targetFieldsResponse}
                                        setCriteria={setCriteria}
                                        criteria={criteria}
                                    />
                                    <Stack direction="row" justifyContent="space-between">
                                        <Button size="small" variant="outlined" onClick={onClose}>
                                            {fm(messages.cancel)}
                                        </Button>

                                        <LoadingButton
                                            disabled={showError}
                                            size="small"
                                            color="success"
                                            variant="contained"
                                            onClick={handleCreate}
                                            loading={
                                                connectionMutation.result.type === "loading" ||
                                                createColumnResult.type === "loading"
                                            }
                                            data-testid={`${cubeBuilderPageTestIdPrefix}-create-connection-button`}
                                        >
                                            {fm(messages.createConnection)}
                                        </LoadingButton>
                                    </Stack>
                                    {showError &&
                                        (errorMessage.link ? (
                                            <a href={errorMessage.link} target="_parent">
                                                <Alert severity="error" title={errorMessage.message} />
                                            </a>
                                        ) : (
                                            <Alert severity="error" title={errorMessage.message} />
                                        ))}

                                    {targetTable && (
                                        <FilterContextProvider
                                            dataSource={filterDataSource}
                                            useDataFields={useElasticFieldsForFilterContextProvider}
                                            inherit={false}
                                            initialFilters={[]}
                                            disableDefaultCombination
                                            // hooks for favourite filters
                                            usePersonalFavouriteFilters={usePersonalFavouriteFiltersForContext}
                                            useCompanyFavouriteFilters={useCompanyFavouriteFiltersForContext}
                                            upsertPersonalFavouriteFilters={upsertPersonalFavouriteFilters}
                                        >
                                            <TestConnectionResults
                                                dataTableConnection={{
                                                    id: "non existant",
                                                    dataTableId: baseTable.id,
                                                    dataColumnId: "nonExistant",
                                                    targetDataSource: {
                                                        type: "DATA_TABLE",
                                                        identifier: targetTable.id,
                                                    },
                                                    criteria: criteria.map(toBackEndCriterion).filter(hasValue),
                                                }}
                                                baseTable={baseTable}
                                                targetTable={targetTable}
                                            />
                                        </FilterContextProvider>
                                    )}
                                </Stack>
                            )}
                        </GraphqlRequestContainer>
                    )}
                </GraphqlRequestContainer>
            </DialogContent>
        </Dialog>
    );
};
