import { defineMessages } from "react-intl";

const messages = defineMessages({
    selectLabel: {
        id: "uploadPage.PickNameColumn.selectLabel",
        defaultMessage: "Select name column",
    },
    selectPlaceholder: {
        id: "uploadPage.PickNameColumn.selectPlaceholder",
        defaultMessage: "Name column",
    },
    tooltip: {
        id: "uploadPage.PickNameColumn.tooltip",
        defaultMessage:
            "Name column will enable us to show more meaningful labels whenever you use relations to this table. (Supplier name instead of Supplier ID for example)",
    },
});

export default messages;
