// eslint-disable-next-line import/no-extraneous-dependencies
import { KeyboardArrowRight, KeyboardArrowUp } from "@mui/icons-material";
import { Box, Card, IconButton, Stack, Tooltip, Typography } from "@mui/material";
import React from "react";
import { Handle, Position, useStore } from "reactflow";
import { usePipelineCanvasContext } from "@/containers/PipelinePageV2/PipelineCanvas/context";
import { handleStyle, OPERATION_NODE_HEIGHT, SHOW_CONTENT_ZOOM } from "../constants";
import { basicNodeSx, styledIconHandleSx } from "./style";
import { TranslatedString } from "@/Types/translation";

interface Props {
    text: TranslatedString;
    color: string;
    name?: string;
    icon: React.ReactNode;
    onIconClick?: () => void;
    maxWidth?: string;
    minWidth?: string;
    handleRight?: boolean;
    handleLeft?: boolean;
    handleBottom?: boolean;
    disabled?: boolean;
}

export const HandleOverlay = ({
    position,
    type,
    id,
}: {
    position: Position;
    type: "source" | "target";
    id?: string;
}) => (
        <Box sx={styledIconHandleSx(position)} display="flex" alignItems="center" justifyContent="center">
            <Handle position={position} type={type} style={handleStyle} id={id} />
            {position === Position.Bottom ? (
                <KeyboardArrowUp fontSize="small" />
            ) : (
                <KeyboardArrowRight fontSize="small" />
            )}
        </Box>
    );

export const BasicNode = ({
    text,
    icon,
    onIconClick,
    color,
    name,
    maxWidth,
    minWidth,
    handleLeft,
    handleRight,
    handleBottom,
    disabled = false,
}: Props) => {
    const zoom = useStore((s) => s.transform[2]);
    const bigIcon = zoom >= SHOW_CONTENT_ZOOM;
    const [{ searchString }] = usePipelineCanvasContext();
    const searchMatch = searchString && name?.toLowerCase().includes(searchString?.toLowerCase());
    const opacity = !searchString ? 1 : searchMatch ? 1 : 0.4;

    return (
        <Box sx={{ opacity }}>
            <Card
                variant="outlined"
                sx={{
                    backgroundColor: color,
                    maxWidth: maxWidth || "100px",
                    minWidth: minWidth || "150px",
                    ...basicNodeSx,
                }}
            >
                {handleLeft && <HandleOverlay position={Position.Left} type="target" />}
                {handleRight && <HandleOverlay position={Position.Right} type="source" />}
                {handleBottom && <HandleOverlay position={Position.Bottom} type="target" id="bottom" />}
                <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="center"
                    minHeight={OPERATION_NODE_HEIGHT}
                    maxHeight={OPERATION_NODE_HEIGHT}
                >
                    <Tooltip title={name || undefined}>
                        <Stack
                            alignItems="center"
                            justifyContent="center"
                            padding={onIconClick || !bigIcon ? undefined : { top: "xs" }}
                        >
                            {bigIcon ? (
                                <>
                                    <IconButton disabled={disabled} onClick={onIconClick}>{icon}</IconButton>
                                    <Typography noWrap padding={{ top: "3xs" }}>
                                        {text}
                                    </Typography>
                                </>
                            ) : (
                                <Stack justifyContent="center" alignItems="center">
                                    <IconButton onClick={onIconClick}>{icon}</IconButton>{" "}
                                </Stack>
                            )}
                        </Stack>
                    </Tooltip>
                </Stack>
            </Card>
        </Box>
    );
};
