import React from "react";
import { Container, ContainerProps, styled } from "@mui/material";

type PageLayoutContainer = Omit<ContainerProps, "disableGutters" | "className">;

const PageLayoutContainer = styled(Container)({
    flex: "1 1 auto",
    display: "flex",
    flexDirection: "column",
    "*:where(html:not([data-new-layout])) &": {
        height: "fit-content",
    },
});

/**
 * Page Layout behaves as a regular page. It grows with the content, and fills the available space.
 */
export const PageLayout = ({ maxWidth = false, ...props }: PageLayoutContainer) => (
    <PageLayoutContainer className="PageLayout" disableGutters={false} maxWidth={maxWidth} {...props} />
);
