import { defineMessages } from "react-intl";

const messages = defineMessages({
    groupByFieldSelectLabel: {
        id: "components.dataColumns.aggregation.groupByFieldSelectLabel",
        defaultMessage: "Data table to aggregate",
    },
});

export default messages;
