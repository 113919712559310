import { defineMessages } from "react-intl";

const messages = defineMessages({
    addCriterion: {
        id: "dataTables.modals.OperationModal.lookupOperationView.addCriterion",
        defaultMessage: "Add criterion",
    },
    targetRepositoryFieldInputLabel: {
        id: "dataTables.modals.OperationModal.lookupOperationView.targetRepositoryFieldInputLabel",
        defaultMessage: "Target repository field",
    },
    hasToMatch: {
        id: "dataTables.modals.OperationModal.lookupOperationView.hasToMatch",
        defaultMessage: "=",
    },
    description: {
        id: "dataTables.modals.OperationModal.lookupOperationView.description",
        defaultMessage:
            "Select which input values (left hand side) that should match with the target repository fields (right hand side). The left join will return values from the first row that matches all criteria. If no row matches, the lookup will return empty fields. The field selection below will decide which fields are returned from the target repository.",
    },
    operationName: {
        id: "dataTables.modals.OperationModal.lookupOperationView.operationName",
        defaultMessage: "Name",
    },
});

export default messages;
