import { useDebounce } from "@ignite-analytics/general-tools";
import * as Sentry from "@sentry/react";
import { useCallback, useEffect, useState } from "react";

export function useOnUnhandledRejectionReporter() {
    useEffect(() => {
        const handler = (event: PromiseRejectionEvent) => {
            Sentry.captureException(event.reason, { tags: { app: "data-tables-app" } });
        };
        window.addEventListener("unhandledrejection", handler);
        return () => {
            window.removeEventListener("unhandledrejection", handler);
        };
    }, []);
}

function getOrDefault<T>(key: string, defaultValue: T): T {
    const item = localStorage.getItem(key);
    if (item) {
        return JSON.parse(item);
    }
    return defaultValue;
}
export function useSharedLocalStorageState<T>(key: string, defaultValue: T) {
    const [state, setState] = useState<T>(getOrDefault(key, defaultValue));
    useEffect(() => {
        setState(getOrDefault(key, defaultValue));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [key]);

    useEffect(() => {
        localStorage.setItem(key, JSON.stringify(state));
    }, [key, state]);

    const debouncedSetState = useDebounce(
        useCallback((newState: T) => setState(newState), []),
        200
    );
    useEffect(() => {
        const handler = (event: StorageEvent) => {
            if (event.key === key) {
                if (event.newValue) {
                    debouncedSetState(JSON.parse(event.newValue));
                }
            }
        };
        window.addEventListener("storage", handler);
        return () => {
            window.removeEventListener("storage", handler);
        };
    }, [key, debouncedSetState]);

    const setStateAndLocalStorage = useCallback(
        (newState: T) => {
            localStorage.setItem(key, JSON.stringify(newState));
            setState(newState);
        },
        [key, setState]
    );

    return [state, setStateAndLocalStorage] as const;
}
