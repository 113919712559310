import { DataPipeline, DataPipelineOperation, DataRepository, DataTable } from "@/generated/client";
import { BundleNode } from "./NodeComponents/bundleNode";
import { DataTableNode } from "./NodeComponents/dataTableNode";
import { FilterEdge } from "./EdgeComponents/filterEdge";
import { OperationNode } from "./NodeComponents/operationNode";
import { RepositoryNode } from "./NodeComponents/repositoryNode";
import { UnionNode } from "./NodeComponents/unionNode";
import { TextBoxNode } from "./NodeComponents/textBoxNode";

export const NODE_TYPES = {
    operationNode: OperationNode,
    repositoryNode: RepositoryNode,
    dataTableNode: DataTableNode,
    bundleNode: BundleNode,
    unionNode: UnionNode,
    textBoxNode: TextBoxNode,
};

export interface RepositoryNodeProps {
    dataRepository: DataRepository;
}

export interface DataTableNodeProps {
    dataTable: DataTable;
}

export interface OperationNodeProps {
    operation: DataPipelineOperation;
    allOperations: DataPipelineOperation[];
    dataPipeline: DataPipeline;
}
export interface TextBoxNodeProps {
    name: string;
}

export const EDGE_TYPES = {
    filterEdge: FilterEdge,
};
