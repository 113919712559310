import { TRANSACTION_VALUE_GT } from "@ignite-analytics/global-types";
import { isPossibleForAggregationType } from "@/components/AggregatedView/CreateAggregatedView/AggregationConfigurationView/helpers";
import { AggregationType, ElasticFieldItem } from "@/generated/client";
import { mapToSelectableField } from "../helpers";

const PREFERRED_GLOBAL_TYPES = [TRANSACTION_VALUE_GT];

export function getAggregationFieldOptions(
    elasticFields: ElasticFieldItem[],
    selectedAggregationType: AggregationType,
    dataTableId: string
) {
    // Sort is not on actual response but on a copy of it
    // eslint-disable-next-line fp/no-mutating-methods
    return elasticFields
        .filter(isPossibleForAggregationType(selectedAggregationType))
        .sort((a) => (PREFERRED_GLOBAL_TYPES.includes(a.globalTypeKey ?? "") ? -1 : 1))
        .map((field) => mapToSelectableField(field, dataTableId));
}
