import { ParseFileInput } from "@/generated/client";
import { FilePreviewData } from ".";

export const mapToDefaultParseFileMutationInput: (file: { name: string; url: string; id: string }) => ParseFileInput = (
    file
) => {
    if (file.name.toLowerCase().endsWith("csv")) {
        return {
            fileId: file.id,
            contentType: "CSV",
            csvConfiguration: {
                delimiter: "SEMICOLON",
                encoding: "UTF",
                escapeCharacter: "DOUBLE_QUOTE",
                quoteCharacter: "DOUBLE_QUOTE_QUOTING",
            },
        };
    }
    return {
        fileId: file.id,
        contentType: "XLSX",
        csvConfiguration: null,
        xlsxConfiguration: {
            dataStartIndex: 2,
            headerRowIndex: 1,
            numberOfEndLinesToDrop: 0,
        },
    };
};

export const getSampleRows = (samples: FilePreviewData, numSamples = 5) => {
    const rows: Record<string, string>[] = [];
    const maxNumSamples = samples.length ? Math.min(numSamples, samples[0].samples.length) : numSamples;
    for (let i = 0; i < maxNumSamples; i += 1) {
        const row: Record<string, string> = {};
        samples.forEach((sample) => {
            const example = sample.samples.length > i + 1 ? sample.samples[i] : "";
            row[sample.fieldKey] = example;
        });
        rows.push(row);
    }
    return rows;
};
