import { Stack, Table, TableContainer, TableBody, TableCell, TableRow, TableHead, Typography } from "@mui/material";
import { GraphqlRequestContainer } from "@ignite-analytics/graphql-utilities";
import React from "react";
import { fm } from "@/contexts/IntlContext";
import { DataRepository, useGetDataRepositoryRowsQuery } from "@/generated/client";
import messages from "./messages";

interface Props {
    currentRepository: DataRepository;
}
const DataRepositoryDataPreview: React.FC<Props> = ({ currentRepository }) => {
    const { result } = useGetDataRepositoryRowsQuery({
        input: { request: { dataRepositoryId: currentRepository.id }, range: 10, index: 0 },
    });

    return currentRepository.fields.length ? (
        <GraphqlRequestContainer asyncData={result}>
                {(repositoryRowsResponse) => {
                    const examplesList = repositoryRowsResponse.rows.map((row) => {
                        try {
                            return JSON.parse(row.dataJson);
                        } catch (error) {
                            return ["error"];
                        }
                    });
                    return (
                        <Stack maxHeight="60vh" minHeight="60vh">
                            <TableContainer>
                                <Table size="small">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell key="dataFields">{fm(messages.dataFields)}</TableCell>
                                            <TableCell key="examples">{fm(messages.samples)}</TableCell>
                                            {examplesList.map((example) => (
                                                <TableCell key={example} />
                                            ))}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {currentRepository.fields.map((field) => (
                                            <TableRow key={field.id}>
                                                <TableCell>{field.name}</TableCell>
                                                {examplesList.map((example) => (
                                                    <TableCell key={example}>{example[field.id]}</TableCell>
                                                ))}
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Stack>
                    );
                }}
            </GraphqlRequestContainer>
    ) : (
        <Stack justifyContent="center">
            <Typography>{fm(messages.noData)}</Typography>
        </Stack>
    );
};

export default DataRepositoryDataPreview;
