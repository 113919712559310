import { defineMessages } from "react-intl";

const messages = defineMessages({
    deleteConnectionTitle: {
        id: "cubeBuilderPage.connectionModal.deleteConnectionButton.deleteConnectionTitle",
        defaultMessage: "Delete connection",
    },
    deleteConnectionMessage: {
        id: "cubeBuilderPage.connectionModal.deleteConnectionButton.deleteConnectionMessage",
        defaultMessage: "Are you sure you want to delete this connection? This action is irreversible.",
    },
});

export default messages;
