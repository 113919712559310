import { Box, Skeleton } from "@mui/material";
import Stack from "@mui/material/Stack";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Typography from "@mui/material/Typography";
import React, { PropsWithChildren } from "react";
import { useHistory } from "react-router-dom";

export interface TabLink {
    path: string;
    name: string;
    active: boolean;
    featureToggle?: string;
}

type Props = PropsWithChildren<{
    tabLinks: TabLink[];
    loading?: boolean;
}>;
const ContentCard: React.FC<Props> = ({ children, tabLinks, loading }) => {
    const history = useHistory();
    const handleTabsClick = (path: string) => {
        history.push(path);
    };
    return (
        <Box
            style={{
                position: "relative",
                boxSizing: "border-box",
                height: "100%",
            }}
        >
            <Stack
                bgcolor="white"
                spacing={2}
                maxHeight="calc(100vh - 65px)"
                display="flex"
                minHeight="90vh"
                position="absolute"
                right={0}
                left={0}
                bottom={0}
                top={0}
                paddingLeft={2}
                gap={1}
            >
                {loading && (
                    <Stack>
                        {/* The height of the skeletons is based on the theming of the text it is a placeholder for with some adjustments to make it look better */}
                        <Skeleton width="20%" height={55} />
                        <Stack direction="row" width="100%" gap={1}>
                            <Skeleton height={30} width="100%" />
                            <Skeleton height={30} width="100%" />
                            <Skeleton height={30} width="100%" />
                            <Skeleton height={30} width="100%" />
                        </Stack>
                        <Skeleton height={5} width="100%" />
                    </Stack>
                )}
                {!loading && (
                    <Typography variant="subtitle2">
                        <Tabs
                            value={tabLinks.find((link) => link.active)?.path}
                            onChange={(_e, v) => handleTabsClick(v)}
                        >
                            {tabLinks.map((link) => (
                                <Tab key={link.name} value={link.path} label={link.name} wrapped />
                            ))}
                        </Tabs>
                    </Typography>
                )}
                {children}
            </Stack>
        </Box>
    );
};

export default ContentCard;
