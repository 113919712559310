import { defineMessages } from "react-intl";

const messages = defineMessages({
    title: {
        id: "setupPage.title",
        defaultMessage: "Welcome {user}! What would you like to get started with?",
    },
    or: {
        id: "setupPage.or",
        defaultMessage: "Or",
    },
});

export default messages;
