import { GraphqlRequestContainer } from "@ignite-analytics/graphql-utilities";
import { CardContent, Skeleton, Stack, Typography, capitalize } from "@mui/material";
import React from "react";
import { useDataColumnsByDataTableId } from "@/contexts/EventContexts/DataColumnEventContext";
import { useGetDataTableRowQuery } from "@/generated/client";

interface Props {
    rowId: string;
    relatedTableId: string;
    rowLabel: string;
}

export const RelationPopUp: React.FC<Props> = ({ rowId, relatedTableId, rowLabel }) => {
    const { result } = useGetDataTableRowQuery({
        input: {
            dataTableId: relatedTableId,
            id: rowId,
        },
    });
    const columns = useDataColumnsByDataTableId(relatedTableId);

    return (
        <GraphqlRequestContainer
            asyncData={result}
            loading={
                <CardContent>
                    <Skeleton height="25" width="200" />
                    <Skeleton height="25" width="200" />
                    <Skeleton height="25" width="200" />
                    <Skeleton height="25" width="200" />
                </CardContent>
            }
        >
            {(response) => {
                const row = JSON.parse(response.dataTableRow.dataJson);
                // NOTE: We filter out and do not render relational columns
                // TODO: Render it if it brings value somewhere
                const nonRelationalColumns = columns.filter((dc) => {
                    const cellData = row[dc.id];
                    if (typeof cellData === "object" && Object.prototype.hasOwnProperty.call(cellData, "id")) {
                        // Relational column
                        return false;
                    }
                    return true;
                });
                return (
                    <CardContent>
                        <Typography paddingBottom={1} variant="subtitle1">
                            {capitalize(rowLabel)}
                        </Typography>
                        <Stack sx={{ maxHeight: "80vh", overflow: "auto" }}>
                            {nonRelationalColumns.map((dc) => (
                                <Stack
                                    sx={{
                                        "&:hover": {
                                            backgroundColor: (theme) => theme.palette.action.focus,
                                        },
                                    }}
                                    key={dc.id}
                                    direction="row"
                                    justifyContent="space-between"
                                    padding={1}
                                    gap={2}
                                    borderRadius={1}
                                >
                                    <Typography color="GrayText">{dc.name}</Typography>
                                    <Typography>{row[dc.id]}</Typography>
                                </Stack>
                            ))}
                        </Stack>
                    </CardContent>
                );
            }}
        </GraphqlRequestContainer>
    );
};
