import Stack from "@mui/material/Stack";
import React from "react";
import { DataTable } from "@/generated/client";
import ManageGlobalColumnTypes from "./ManageGlobalColumnTypes";
import ManageGlobalTableType from "./ManageGlobalTableType";
import { PickNameColumn } from "./PickNameColumn";
import { RelativeDateSettings } from "./RelativeDateSettings";
import { InModalAdminPanel } from "../AdminPage/AdminPanelmodal";

interface Props {
    dataTable: DataTable;
}

const ManageDataTablePage: React.FC<Props> = ({ dataTable }) => (
    <Stack gap={1} paddingTop={1} justifyContent="center">
        <Stack direction="row" gap={1} alignItems="center" justifyContent="space-between">
            <Stack direction="row" gap={1} alignItems="start">
                <ManageGlobalTableType dataTable={dataTable} />
                <PickNameColumn dataTable={dataTable} />
                <RelativeDateSettings dataTable={dataTable} />
            </Stack>
            <InModalAdminPanel dataTable={dataTable} />
        </Stack>
        <ManageGlobalColumnTypes dataTable={dataTable} />
    </Stack>
);

export default ManageDataTablePage;
