import * as Sentry from "@sentry/react";
import React from "react";
import FatalErrorFallback from "./FatalErrorFallback";

/**
 * FatalErrorBoundry
 *
 * Use this for avoiding white-screens on the outermost level
 *
 */
const FatalErrorBoundary: React.FC<{ children?: React.ReactNode }> = ({ children }) => (
    <Sentry.ErrorBoundary
        beforeCapture={(scope) => {
            scope.setTag("app", "data-tables-app");
        }}
        fallback={<FatalErrorFallback />}
    >
        {children}
    </Sentry.ErrorBoundary>
);

export default FatalErrorBoundary;
