import React, { ReactNode, Reducer, useContext, useReducer } from "react";

type State = {
    searchString?: string;
    bundled: boolean;
};

const defaultState: State = {
    searchString: undefined,
    bundled: false,
};

type Action = { type: "setSearchString"; searchString: string } | { type: "setBundled"; bundled: boolean };

type ContextValue = [State, React.Dispatch<Action>];
const Context = React.createContext<ContextValue | null>(null);

const reducer: Reducer<State, Action> = (prevState, action) => {
    switch (action.type) {
        case "setSearchString":
            return { ...prevState, searchString: action.searchString };
        case "setBundled":
            return { ...prevState, bundled: action.bundled };
    }
};

const PipelineCanvasContextProvider: React.FC<{ children?: ReactNode }> = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, defaultState);

    return <Context.Provider value={[state, dispatch]}>{children}</Context.Provider>;
};

export function usePipelineCanvasContext() {
    const context = useContext(Context);
    if (!context) {
        throw new Error("Cannot use hook 'usePipelineCanvasContext' outside an PipelineCanvasContextProvider");
    }
    return context;
}

export default PipelineCanvasContextProvider;
