import { Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import React from "react";
import { PrimitiveDataColumnTypeInput } from "@/generated/client";
import { FilePreviewData } from "..";
import { getSampleRows } from "../helpers";
import { DataTypeSelection } from "./dataTypeSelection";

type StringOrNull = string | null;
export const FilePreviewTable: React.FC<{
    fields: FilePreviewData;
    highlights?: StringOrNull[];
    height?: string;
    dataTypeSelection?: boolean;
    setType?: (columnId: string, type: PrimitiveDataColumnTypeInput) => void;
    typeMappings?: Record<string, PrimitiveDataColumnTypeInput>;
}> = ({ fields, highlights, height, dataTypeSelection, setType, typeMappings }) => (
        <TableContainer sx={{ maxHeight: height, overflowY: "auto" }}>
            <Table stickyHeader>
                <TableHead>
                    <TableRow>
                        {fields.map((field) => (
                            <TableCell
                                key={field.fieldKey}
                                sx={{
                                    flexWrap: "nowrap",
                                    overflow: "clip",
                                    color: (theme) =>
                                        highlights?.includes(field.fieldKey) ? theme.palette.primary.main : undefined,
                                }}
                            >
                                <Stack direction="row" gap={1} alignItems="center">
                                    <Typography noWrap>{field.fieldKey}</Typography>
                                    {dataTypeSelection && typeMappings && setType && (
                                        <DataTypeSelection
                                            type={typeMappings[field.fieldKey] || "TEXT"}
                                            setType={(type) => setType(field.fieldKey, type)}
                                        />
                                    )}
                                </Stack>
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {getSampleRows(fields, 9).map((sample, i) => {
                        const ind = i;
                        return (
                            <TableRow key={ind}>
                                {fields.map((f) => (
                                    <TableCell key={`${f.fieldKey}`}>{sample[f.fieldKey]}</TableCell>
                                ))}
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    );
