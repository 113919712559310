import { FeatureToggleContainer } from "@ignite-analytics/feature-toggle";
import { CONTRACT_TABLE_GT, SUPPLIER_TABLE_GT, TRANSACTIONS_GT } from "@ignite-analytics/global-types";
import { Stack, Typography } from "@mui/material";
import React from "react";
import { Route, Switch } from "react-router-dom";
import { FileToTable, FileToTablePageContainer } from "@/components/SetUp/FileToTable";
import { SetupCard } from "@/components/SetUpCard";
import { XLedgerSetup } from "@/components/integrationsSetup/xLedger";
import { BlueBall } from "@/components/svgs";
import { OrangeBall } from "@/components/svgs/orangeBall";
import { fm } from "@/contexts/IntlContext";
import messages from "./messages";
import { useCurrentUser } from "@/entities/hooks";

const LandingPage = () => {
    const user = useCurrentUser();

    return (
        <Stack direction="row" height="calc(100vh - 200px)" alignItems="center">
            <Stack position="absolute" sx={{ right: "5px" }}>
                <OrangeBall />
            </Stack>
            <Stack position="absolute" sx={{ bottom: "5px", right: "30%" }}>
                <BlueBall />
            </Stack>
            <Stack flexGrow={1} alignItems="center" gap={4}>
                <Typography variant="h5">{fm(messages.title, { user: user?.firstName })}</Typography>
                <Stack direction="row" gap={4} justifyContent="center">
                    <SetupCard type={SUPPLIER_TABLE_GT} href="suppliers/" moduleHref="/suppliers/overview/" />
                    <SetupCard type={CONTRACT_TABLE_GT} href="contracts/" moduleHref="/contracts/" />
                </Stack>
                <Stack direction="row" gap={4} justifyContent="center">
                    <SetupCard type={TRANSACTIONS_GT} href="analytics/" moduleHref="/dashboard/overview/home" />
                    <SetupCard type="carbon" moduleHref="v2/carbon/overview/" />
                </Stack>
                <FeatureToggleContainer featureKey="integrationSetup">
                    <Stack>
                        <Typography>{fm(messages.or)}</Typography>
                        <SetupCard type="integrations" href="integrations/" />
                    </Stack>
                </FeatureToggleContainer>
            </Stack>
        </Stack>
    );
};

export const SetupPage: React.FC = () => (
    <Switch>
        <Route path={["*/analytics/"]}>
            <FileToTablePageContainer>
                <FileToTable type={TRANSACTIONS_GT} />
            </FileToTablePageContainer>
        </Route>
        <Route path={["*/suppliers/"]}>
            <FileToTablePageContainer>
                <FileToTable type={SUPPLIER_TABLE_GT} />
            </FileToTablePageContainer>
        </Route>
        <Route path={["*/contracts/"]}>
            <FileToTablePageContainer>
                <FileToTable type={CONTRACT_TABLE_GT} />
            </FileToTablePageContainer>
        </Route>
        <Route path={["*/custom/"]}>
            <FileToTablePageContainer>
                <FileToTable />
            </FileToTablePageContainer>
        </Route>

        <Route path={["*/integrations/"]}>
            <FileToTablePageContainer>
                <XLedgerSetup />
            </FileToTablePageContainer>
        </Route>
        <Route path={["*/"]}>
            <LandingPage />
        </Route>
    </Switch>
);

export default SetupPage;
