import { Alert, Button, FormControlLabel, LinearProgress, Stack, Switch, Typography } from "@mui/material";
import React, { useState } from "react";
import CreateImportConfiguration from "@/components/CreateImportConfiguration";
import { createMutationInput } from "@/components/CreateImportConfiguration/helpers";
import { ImportConfigurationValues } from "@/components/CreateImportConfiguration/types";
import { FileDropZone } from "@/components/FileDropZone";
import {
    useDataManagementProcessEventListener,
    ValidateFileData,
} from "@/contexts/EventContexts/DataManagementEventContext";
import { fm } from "@/contexts/IntlContext";
import { ParseFileInput, useParseFileMutation } from "@/generated/client";
import useFileManager from "@/managers/useFileManager";
import globalMessages from "@/messages/index";
import { mapToDefaultParseFileMutationInput } from "./helpers";
import { LoadingTable } from "./loadingTable";
import messages from "./messages";
import { FilePreviewTable } from "./Table";

export type FilePreviewData = {
    fieldKey: string;
    samples: string[];
}[];
export const FilePreview: React.FC<{
    onFileConfirmation: (
        fields: FilePreviewData,
        importConfig: Omit<ParseFileInput, "fileId">,
        fileId: string,
        file: File
    ) => void;
    onFileChange?: (file?: File) => void;
    height?: string;
    onBack?: () => void;
}> = ({ onFileConfirmation, onFileChange, height, onBack }) => {
    const [fileId, setFileId] = useState<string | undefined>(undefined);
    const [file, setFile] = useState<File>();
    const [uploadProgress, setUploadProgress] = useState<number>(0);
    const [parseFile, mutation] = useParseFileMutation();
    const [filePreview, setFilePreview] = useState<FilePreviewData>();
    const [importConfig, setImportConfig] = useState<Omit<ParseFileInput, "fileId">>();
    const [warnings, setWarnings] = useState<string>();
    const [errors, setErrors] = useState<string>();
    const [open, setOpen] = useState(false);
    const toggleOpen = () => setOpen((prev) => !prev);

    const fileManager = useFileManager("data-repository");
    // Uploads a file to file management and gets the parsed file results for preview
    const handleFile = async (selectedFile: File) => {
        if (!selectedFile) return;
        onFileChange?.(selectedFile);
        setFile(selectedFile);
        const fileResponse = await fileManager.uploadFile(selectedFile, (prog: number) =>
            setUploadProgress(Math.round(prog * 0.9))
        );
        if (!fileResponse.data) return;
        setFileId(fileResponse.data?.id);
        setImportConfig(mapToDefaultParseFileMutationInput(fileResponse.data));
        const result = await parseFile({ input: mapToDefaultParseFileMutationInput(fileResponse.data) });
        if (result.errors) {
            setErrors(result.errors.map((e) => e.message).join(", "));
            setUploadProgress(0);
        }
    };
    const onRemoveFile = () => {
        setFileId(undefined);
        setFile(undefined);
        setFilePreview(undefined);
        onFileChange?.(undefined);
    };
    useDataManagementProcessEventListener("ValidateFile", (e) => {
        const data: ValidateFileData | null = (e.data as ValidateFileData) || null;
        if (e.type === "PROCESS_FINISHED") {
            setWarnings(undefined);
            setErrors(undefined);
            setUploadProgress(0);
            if (data) {
                setFilePreview(data.fields);
                setFileId(e.id);
                data.warnings.length && setWarnings(data.warnings.join(", "));
            }
        }
        if (e.type === "FAILED") {
            setUploadProgress(0);
            setFilePreview(undefined);
            if (!data) {
                setErrors("Something went wrong.");
                return;
            }
            if (data.errors.length) setErrors(data.errors.join(", "));
            if (data.warnings.length) setWarnings(data.warnings.join(", "));
            setWarnings("Something went wrong");
        }
    });
    const handleImportConfigChange = async (values: ImportConfigurationValues) => {
        if (!fileId) return;
        const importSettings = createMutationInput(values);
        setImportConfig({ ...importSettings });
        const result = await parseFile({
            input: {
                fileId,
                csvConfiguration: importSettings.csvConfiguration || null,
                xlsxConfiguration: importSettings.xlsxConfiguration || null,
                contentType: importSettings.xlsxConfiguration ? "XLSX" : "CSV",
            },
        });
        if (result.errors) {
            setErrors(result.errors.map((e) => e.message).join(", "));
            setUploadProgress(0);
        }
    };
    const handleConfirm = () => {
        filePreview && fileId && importConfig && file && onFileConfirmation(filePreview, importConfig, fileId, file);
    };
    const isLoading = Boolean(uploadProgress && (!fileId || mutation.result.type === "loading"));

    return (
        <Stack gap={2}>
            <FileDropZone onFile={handleFile} fileTypes={["csv", "xlsx"]} onRemoveFile={onRemoveFile} />
            {errors && (
                <Alert title={errors} severity="error">
                    {errors}
                </Alert>
            )}
            {warnings && (
                <Alert title={warnings} severity="warning">
                    {warnings}
                </Alert>
            )}
            {isLoading && <LinearProgress variant="determinate" value={uploadProgress} />}
            {file && (
                <Stack gap={2}>
                    <Typography>{fm(messages.fileConfirmText)}</Typography>
                    {isLoading ? (
                        <LoadingTable />
                    ) : (
                        filePreview && <FilePreviewTable height={height} fields={filePreview} />
                    )}
                    <FormControlLabel
                        control={<Switch checked={open} onChange={toggleOpen} />}
                        label={fm(messages.showFileSettings)}
                    />

                    {open && (
                        <CreateImportConfiguration
                            fileName={file.name}
                            onChange={handleImportConfigChange}
                            horizontal
                        />
                    )}
                </Stack>
            )}
            <Stack direction="row" justifyContent={onBack ? "space-between" : "end"}>
                {onBack && (
                    <Button variant="outlined" color="secondary" size="small" onClick={onBack}>
                        {fm(globalMessages.back)}
                    </Button>
                )}
                <Button size="small" disabled={filePreview === undefined} onClick={handleConfirm}>
                    {fm(globalMessages.next)}
                </Button>
            </Stack>
        </Stack>
    );
};
