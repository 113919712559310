import { Button, Link } from "@mui/material";
import React from "react";
import { fm } from "@/contexts/IntlContext";
import messages from "../messages";

interface Props {
    type: "getStarted" | "goToModule";
    href?: string;
}
export const SetUpButton: React.FC<Props> = ({ type, href }) => (
    <Button size="small" color={type === "goToModule" ? "ghostGray" : undefined}>
        <Link href={href}>{type === "goToModule" ? fm(messages.goToModule) : fm(messages.getStarted)}</Link>
    </Button>
);
