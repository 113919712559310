import { Plus, Table } from "@ignite-analytics/icons";
import { Button, Chip, Grid, Stack, Typography } from "@mui/material";
import { uniqueId } from "lodash-es";
import React from "react";
import { fm } from "@/contexts/IntlContext";
import { DataTable, GetDataTableElasticFieldsResponse } from "@/generated/client";
import messages from "../messages";
import { Criterion, connectionCriterionOperators } from "../types";
import { CriterionRow } from "./CriterionRow";
import { getSelectableFields } from "./helpers";

interface Props {
    relationDataColumnId?: string;
    baseTable: DataTable;
    targetTable?: DataTable;
    dependingDataColumnIds: string[];
    baseFieldsResponse: GetDataTableElasticFieldsResponse;
    targetFieldsResponse: GetDataTableElasticFieldsResponse;
    setCriteria: (criteria: Criterion[]) => void;
    criteria: Criterion[];
}
export const CriterionBuilder: React.FC<Props> = ({
    relationDataColumnId,
    dependingDataColumnIds,
    baseFieldsResponse,
    targetFieldsResponse,
    criteria,
    setCriteria,
    baseTable,
    targetTable,
}) => {
    const handleNewCriterion = () => {
        setCriteria([
            ...criteria,
            {
                sourceField: undefined,
                targetField: undefined,
                type: connectionCriterionOperators[0],
                frontEndKey: uniqueId(),
                ignoreOnBlankTarget: false,
            },
        ]);
    };

    const handleDiscardCriterion = (index: number) => {
        setCriteria(criteria.filter((_c, i) => index !== i));
    };

    const baseSelectableFields = getSelectableFields(
        baseFieldsResponse.elasticFields,
        dependingDataColumnIds,
        relationDataColumnId
    );
    const targetSelectableFields = getSelectableFields(
        targetFieldsResponse.elasticFields,
        dependingDataColumnIds,
        relationDataColumnId
    );

    return (
        <Stack gap={1} paddingBottom={1}>
            <Grid container gap={1}>
                <Grid container direction="row" justifyContent="space-between" alignItems="center" padding={{ top: 3 }}>
                    <Grid item sm={4}>
                        <Stack direction="row" alignItems="center" gap={1}>
                            <Table fontSize="small" />
                            <Typography variant="h5">{baseTable.name}</Typography>
                            <Chip color="warning" label={fm(messages.base)} />
                        </Stack>
                    </Grid>
                    <Grid sm={3} />
                    <Grid sm={4} direction="row">
                        <Stack direction="row" alignItems="center" gap={1}>
                            <Table fontSize="small" />
                            <Typography variant="h5">{targetTable?.name}</Typography>
                        </Stack>
                    </Grid>
                </Grid>
                {criteria.map((criterion, i) => (
                    <CriterionRow
                        baseSelectableFields={baseSelectableFields}
                        targetSelectableFields={targetSelectableFields}
                        key={criterion.frontEndKey}
                        criterion={criterion}
                        onDelete={() => handleDiscardCriterion(i)}
                        onCriterionChange={(newCriterion) =>
                            setCriteria(criteria.map((c, index) => (index === i ? newCriterion : c)))
                        }
                    />
                ))}
                <Grid item>
                    <Button
                        size="small"
                        variant="text"
                        color="secondary"
                        startIcon={<Plus fontSize="small" />}
                        onClick={handleNewCriterion}
                    >
                        {fm(messages.addCriterion)}
                    </Button>
                </Grid>
            </Grid>
        </Stack>
    );
};
