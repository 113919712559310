import { defineMessages } from "react-intl";

const messages = defineMessages({
    additionalFieldsHeader: {
        id: "uploadPage.createOperationButton.getOrCreateOperationForm.additionalFieldCriteriaHeader",
        defaultMessage: "Additional fields",
    },
    additionalFieldsTooltip: {
        id: "uploadPage.createOperationButton.getOrCreateOperationForm.additionalFieldCriteriaTooltip",
        defaultMessage: "Additional fields allow you to populate fields beyond the match criteria.",
    },
    addGetOrCreateFieldSelectLabel: {
        id: "uploadPage.createOperationButton.getOrCreateOperationForm.addGetOrCreateFieldSelectLabel",
        defaultMessage: "Select data repository field",
    },
});

export default messages;
