import { fm } from "@/contexts/IntlContext";
import { SelectableField } from "../helpers";
import messages from "./messages";

export function getErrorText(baseSelectedField?: SelectableField, targetSelectedField?: SelectableField) {
    if (!baseSelectedField || !targetSelectedField) {
        return undefined;
    }
    if (baseSelectedField.type !== targetSelectedField.type) {
        return fm(messages.incompatibleTypesErrorMessage);
    }
}
