import { GraphqlRequestContainer } from "@ignite-analytics/graphql-utilities";
import { Autocomplete, Button, Stack, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import { fm } from "@/contexts/IntlContext";
import { DataTable, useCreateDataTableCollectionMutation } from "@/generated/client";
import { useManyDataTables, useUpdateDataTableAction } from "@/generated/DataTableEntity";
import messages from "./messages";

interface Props {
    onCreate?: () => void;
}

export const CreateCollection: React.FC<Props> = ({ onCreate }) => {
    const [selectedDataTables, setSelectedDataTables] = useState<DataTable[]>([]);
    const getManyDataTablesResult = useManyDataTables();
    const [createDataTableCollection, mutation] = useCreateDataTableCollectionMutation();
    const [updateDataTable] = useUpdateDataTableAction();
    const [name, setName] = useState<string>();

    const handleSubmit = () => {
        if (mutation.result.type === "loading") return;
        name &&
            createDataTableCollection({ input: { name } }).then((result) => {
                selectedDataTables.forEach((table) => {
                    result != null &&
                        result.data &&
                        updateDataTable(table.id, {
                            dataTableCollectionId: result.data.createDataTableCollection.dataTableCollection.id,
                        });
                });
                onCreate && onCreate();
            });
    };

    return (
        <Stack gap={1}>
            <Typography variant="h5">{fm(messages.header)}</Typography>
            <TextField label={fm(messages.name)} type="text" onChange={(e) => setName(e.target.value)} />
            <Typography variant="subtitle2">{fm(messages.dataTableToIncludePrompt)}</Typography>
            <GraphqlRequestContainer asyncData={getManyDataTablesResult}>
                {(response) => (
                    <Autocomplete
                        multiple
                        id="tags-standard"
                        options={response}
                        onChange={(_, v) => setSelectedDataTables(v)}
                        getOptionLabel={(option) => option.name}
                        defaultValue={[]}
                        onKeyPress={(e) => e.key === "Enter" && handleSubmit()}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="standard"
                                label={fm(messages.placeholder)}
                                placeholder="Favorites"
                            />
                        )}
                    />
                )}
            </GraphqlRequestContainer>

            <Button type="submit" onClick={handleSubmit}>
                {fm(messages.createButtonLabel)}
            </Button>
        </Stack>
    );
};
