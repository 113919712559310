import { defineMessages } from "react-intl";

const messages = defineMessages({
    columnName: {
        id: "dataTypeMapping.columnName",
        defaultMessage: "Data column",
    },
    dataType: {
        id: "dataTypeMapping.dataType",
        defaultMessage: "Data type",
    },
    samples: {
        id: "dataTypeMapping.samples",
        defaultMessage: "Samples",
    },
});

export default messages;
