import { Grid, Stack, Tooltip, Typography } from "@mui/material";
import { orderBy } from "lodash-es";
import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { testIdPrefix } from "@/components/testIdprefix";
import { useBasePath } from "@/contexts/BasePathContext";
import { DataTable, DataTableCollection } from "@/generated/client";
import { TableActionMenu } from "./TableActionMenu";
import { getUrl } from "./helpers";

interface Props {
    collection: DataTableCollection | { name: string };
    tables: DataTable[];
    currentTableId: string;
}

export const TableList = ({ collection, tables, currentTableId }: Props) => {
    const basePath = useBasePath();
    const location = useLocation();
    const [hoverId, setHoverOverId] = useState<string>();
    const tablesToRender =
        "id" in collection
            ? tables.filter((table) => table.DataTableCollectionId === collection.id)
            : tables.filter((table) => table.DataTableCollectionId === null);

    return (
        <Stack>
            {orderBy(tablesToRender, ["name"], ["asc"]).map((dataTable) => (
                <div
                    key={`${dataTable.id}-link`}
                    id={dataTable.id}
                    onFocus={() => null}
                    onMouseOver={(ev) => {
                        setHoverOverId(ev.currentTarget.id ?? undefined);
                    }}
                    onMouseLeave={() => {
                        setHoverOverId(undefined);
                    }}
                >
                    <Grid
                        container
                        height={40}
                        direction="row"
                        width="100%"
                        justifyContent="space-between"
                        paddingX={2}
                        sx={(theme) => ({
                            "&:hover": {
                                backgroundColor: theme.palette.primary.hover,
                            },
                        })}
                        alignItems="center"
                    >
                        <Grid item sm={10}>
                            <Link
                                to={`${basePath}${dataTable.id}/${getUrl(location.pathname, currentTableId)}`}
                                style={{ textDecoration: "none" }}
                            >
                                <Stack direction="row" alignItems="center" width="100%">
                                    <Tooltip title={dataTable.name.length > 17 ? dataTable.name : undefined}>
                                        <Typography
                                            data-testid={`${testIdPrefix}-side-menu-table-name`}
                                            variant="body2"
                                            noWrap
                                            color={dataTable.id === currentTableId ? "primary" : "black"}
                                        >
                                            {dataTable.name}
                                        </Typography>
                                    </Tooltip>
                                </Stack>
                            </Link>
                        </Grid>
                        <Grid item sm={2}>
                            {hoverId === dataTable.id && <TableActionMenu dataTable={dataTable} />}
                        </Grid>
                    </Grid>
                </div>
            ))}
        </Stack>
    );
};
