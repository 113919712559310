import { Autocomplete, ClickAwayListener, IconButton, Stack, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { InputSourceItem } from "@/Types/InputSourceItem";
import { ExpressionValueSelect } from "../ExpressionValueSelect";

interface Props {
    sources: InputSourceItem[];
    onChange: (expression: MathematicalExpression) => void;
}
export enum MathematicalOperation {
    PLUS = "+",
    MULTIPLY = "*",
    DIVIDE = "/",
    SUBTRACT = "-",
    POWER = "pow",
}

export interface MathematicalExpressionVariableInput {
    inputKey: string;
}

export type MathematicalExpression =
    | Partial<{
          expression1: MathematicalExpression;
          expression2: MathematicalExpression;
          operation: MathematicalOperation;
      }>
    | number
    | MathematicalExpressionVariableInput;

export const BuildExpression: React.FC<Props> = ({ sources, onChange }: Props) => {
    const [expression1, setExpression1] = useState<MathematicalExpression>();
    const [expression2, setExpression2] = useState<MathematicalExpression>();
    const [operation, setOperation] = useState<MathematicalOperation>(MathematicalOperation.PLUS);
    const [showOperation, setShowOperation] = useState<boolean>(true);

    useEffect(
        function popagateExpressionChangeUpwards() {
            onChange({ expression1, expression2, operation });
        },
        [expression1, expression2, operation, onChange]
    );

    return (
        <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            gap={1}
            sx={{
                flexWrap: "nowrap",
                overflowX: "auto",
                minHeight: "100px",
                overflowY: "hidden",
                minWidth: "15vw",
            }}
        >
            <Typography variant="subtitle2">(</Typography>
            <ExpressionValueSelect onChange={setExpression1} sources={sources} />
            {showOperation ? (
                <Stack direction="row" alignItems="center" justifyContent="center">
                    <IconButton onClick={() => setShowOperation(false)}>{operation}</IconButton>
                </Stack>
            ) : (
                <ClickAwayListener onClickAway={() => setShowOperation(true)}>
                    <Stack width="120px">
                        <Autocomplete
                            fullWidth
                            value={operation}
                            options={Object.values(MathematicalOperation)}
                            onChange={(_, v) => {
                                v && setOperation(v);
                                v && setShowOperation(true);
                            }}
                            getOptionLabel={(option) => option}
                            renderInput={(params) => <TextField {...params} />}
                        />
                    </Stack>
                </ClickAwayListener>
            )}
            <ExpressionValueSelect onChange={setExpression2} sources={sources} />
            <Typography variant="subtitle2">)</Typography>
        </Stack>
    );
};
