import { getLocale } from "@ignite-analytics/locale2";
import React, { useEffect } from "react";
import { createIntl, FormattedMessage, IntlProvider, MessageDescriptor } from "react-intl";
import { GlobalType } from "@ignite-analytics/global-types";
import { getMessages } from "@/languages";

type PrimitiveType = string | number | boolean | Date | null | undefined;

let intl = createIntl({
    locale: getLocale(),
    messages: getMessages(getLocale()),
    onError: () => {
        /* Do nothing */
    },
});

interface Props {
    locale: string;

    children?: React.ReactNode;
}

export const IntlContext: React.FC<Props> = ({ locale, children }) => {
    useEffect(() => {
        intl = createIntl({ locale, messages: getMessages(locale) });
    }, [locale]);

    return (
        <IntlProvider locale={locale} messages={getMessages(locale)} defaultLocale="en-US">
            {children}
        </IntlProvider>
    );
};

export const fm = (descriptor: MessageDescriptor, values?: Record<string, PrimitiveType>) => {
    const element = <FormattedMessage values={values} {...descriptor} />;
    return { ...element, toString: () => intl.formatMessage(descriptor, values) };
};

export const getGlobalTypeName = (globalType: Pick<GlobalType, "name" | "nameNo">) =>
    (intl.locale === "nb-NO" && globalType.nameNo) || globalType.name;

export const currentIntl = () => intl;
