import { GraphqlRequestContainer } from "@ignite-analytics/graphql-utilities";
import { Autocomplete, TextField } from "@mui/material";
import React, { useEffect } from "react";
import { fm } from "@/contexts/IntlContext";
import { AggregationType, useGetDataTableElasticFieldsQuery } from "@/generated/client";
import { SelectableField } from "../types";
import { getAggregationFieldOptions } from "./helpers";
import messages from "./messages";

interface Props {
    dataTableId: string;
    selectedField: SelectableField | null;
    selectedAggregationType: AggregationType;
    onChange: (value: SelectableField) => void;
}
export const AggregationFieldSelect: React.FC<Props> = ({
    dataTableId,
    selectedField,
    selectedAggregationType,
    onChange,
}) => {
    const { result: elasticFieldsResult, refetch } = useGetDataTableElasticFieldsQuery({
        input: { dataTableId, withEnrichments: false },
    });
    useEffect(() => {
        refetch();
    }, [refetch, dataTableId]);

    useEffect(() => {
        if (elasticFieldsResult.type === "success" && !selectedField) {
            const selectableFields = getAggregationFieldOptions(
                elasticFieldsResult.data.elasticFields,
                selectedAggregationType,
                dataTableId
            );
            if (selectableFields.length > 0) {
                onChange(selectableFields[0]);
            }
        }
    }, [dataTableId, onChange, selectedField, selectedAggregationType, elasticFieldsResult]);

    return (
        <GraphqlRequestContainer asyncData={elasticFieldsResult}>
            {({ elasticFields }) => {
                const selectableFields = getAggregationFieldOptions(
                    elasticFields,
                    selectedAggregationType,
                    dataTableId
                );
                const _selectedField =
                    (selectedField && selectableFields.find((field) => field.field === selectedField?.field)) ?? null;
                return (
                    <Autocomplete
                        value={_selectedField}
                        options={selectableFields}
                        onChange={(_, value) => value && onChange(value)}
                        renderInput={(params) => (
                            <TextField
                                label={fm(messages.fieldToAggregateSelectLabel)}
                                {...params}
                                inputProps={{ ...params.inputProps }}
                            />
                        )}
                    />
                );
            }}
        </GraphqlRequestContainer>
    );
};
