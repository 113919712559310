import { MarkerType, Node } from "reactflow";
import { DataColumn } from "@/Types/DataColumn";
import { SelectableCubeConfigurationField } from "@/containers/CubeBuilderPage/types";
import { DataCubeConfiguration, DataTable, DataTableConnection } from "@/generated/client";
import { DataTableNodeData } from "../DataTableNode";

const smartPlacing = (connections: DataTableConnection[], pipelineConnections: DataColumn[]) => {
    const numberOfSlices = connections.length + pipelineConnections.length;
    const distanceFromCenter = 550;
    const positionMap = new Map<string, { x: number; y: number }>([]);
    connections.forEach((connection, i) => {
        const x = Math.cos(2 * Math.PI * (i / numberOfSlices)) * distanceFromCenter;
        const y = Math.sin(2 * Math.PI * (i / numberOfSlices)) * distanceFromCenter;
        positionMap.set(connection.id, { x, y });
    });
    pipelineConnections.forEach((column, i) => {
        const x = Math.cos(2 * Math.PI * ((i + connections.length) / numberOfSlices)) * distanceFromCenter;
        const y = Math.sin(2 * Math.PI * ((i + connections.length) / numberOfSlices)) * distanceFromCenter;
        positionMap.set(column.id, { x, y });
    });
    return positionMap;
};

const fallbackPlacing = (i: number) => ({ x: 0 + (i + 1) * 400, y: 0 });

function isDataColumn(connection: DataTableConnection | DataColumn): connection is DataColumn {
    return "referencedId" in connection;
}

const constructDataTableNode = (
    connection: DataTableConnection | DataColumn,
    positionMap: Map<
        string,
        {
            x: number;
            y: number;
        }
    >,
    dataTables: DataTable[],
    i: number,
    baseDataCubeConfiguration: DataCubeConfiguration,
    selectableCubeConfigurationFields: SelectableCubeConfigurationField[]
): Node<DataTableNodeData> => {
    const positioning = positionMap.get(connection.id);
    const isPipeLineOperation = isDataColumn(connection);
    const connectedTableId = isPipeLineOperation ? connection.referencedId : connection.targetDataSource.identifier;
    return {
        id: connection.id,
        type: "tableNode",
        data: {
            relatedDataColumnId: isPipeLineOperation ? connection.id : connection.dataColumnId,
            dataTable: dataTables.find((table) => table.id === connectedTableId),
            base: false,
            connection: isPipeLineOperation ? undefined : connection,
            position: positioning ? { x: positioning.x, y: positioning.y } : fallbackPlacing(i),
            pipelineOperationOutputColumn: isPipeLineOperation ? connection : undefined,
            baseDataCubeConfiguration,
            selectableCubeConfigurationFields,
        },
        position: positioning ? { x: positioning.x, y: positioning.y } : fallbackPlacing(i),
    };
};

export const createNodes = (
    baseTable: DataTable,
    dataTables: DataTable[],
    connections: DataTableConnection[],
    baseTableColumns: DataColumn[],
    baseDataCubeConfiguration: DataCubeConfiguration,
    selectableCubeConfigurationFields: SelectableCubeConfigurationField[]
): Node<DataTableNodeData>[] => {
    const pipelineConnections = baseTableColumns.filter(
        (column) =>
            column.dataType === "TABLE_RELATION" &&
            !connections.some((connection) => connection.dataColumnId === column.id) &&
            column.dataTableId !== column.referencedId
    );
    const positionMap = smartPlacing(connections, pipelineConnections);
    const baseTableNode: Node<DataTableNodeData> = {
        id: baseTable.id,
        type: "tableNode",
        data: {
            dataTable: baseTable,
            base: true,
            baseDataCubeConfiguration,
            selectableCubeConfigurationFields,
            position: { x: 0, y: 0 },
        },
        position: { x: 0, y: 0 },
    };

    /* Returns a node for each dataTableConnection */
    const targetTables = connections.map<Node<DataTableNodeData>>((connection, i) =>
        constructDataTableNode(
            connection,
            positionMap,
            dataTables,
            i,
            baseDataCubeConfiguration,
            selectableCubeConfigurationFields
        )
    );

    /* Returns a node for each relation columns that does not have a connection.(GetOrCreate Operation ouput columns) */
    const pipelineConnectionNodes = pipelineConnections.map<Node<DataTableNodeData>>((column, i) =>
        constructDataTableNode(
            column,
            positionMap,
            dataTables,
            i,
            baseDataCubeConfiguration,
            selectableCubeConfigurationFields
        )
    );
    return [baseTableNode, ...targetTables, ...pipelineConnectionNodes];
};

export const createEdges = (
    connections: DataTableConnection[],
    onEdgeClick: (connection: DataTableConnection | undefined) => void,
    baseTableColumns: DataColumn[]
) => {
    const pipelineConnections = baseTableColumns.filter(
        (column) =>
            column.dataType === "TABLE_RELATION" &&
            !connections.some((connection) => connection.dataColumnId === column.id)
    );
    const positionMap = smartPlacing(connections, pipelineConnections);

    const connectionEdges = connections.map((connection) => {
        const position = positionMap.get(connection.id);

        return {
            id: `edge-${connection.id}`,
            target: connection.dataTableId,
            source: connection.id,
            animated: true,
            type: "stepWithButton",
            targetHandle: position && position.x < 0 ? "left" : "right",
            data: {
                text: "EDIT",
                onEdgeClick: () => {
                    onEdgeClick(connection);
                },
                onClose: () => {
                    onEdgeClick(undefined);
                },
            },
            markerEnd: {
                type: MarkerType.Arrow,
            },
        };
    });

    const pipelineConnectionEdges = pipelineConnections.map((pipelineConnection) => {
        const position = positionMap.get(pipelineConnection.id);

        return {
            id: `edge-${pipelineConnection.id}`,
            target: pipelineConnection.dataTableId,
            source: pipelineConnection.id,
            animated: true,
            type: "step",
            targetHandle: position && position.x < 0 ? "left" : "right",
            markerEnd: {
                type: MarkerType.Arrow,
            },
        };
    });
    return [...connectionEdges, ...pipelineConnectionEdges];
};
