export type ReferenceCell = {
    id: string;
    meta?: Record<string, unknown>;
};
export type MonetaryAmountCell = {
    amount: number;
    currency: string;
    date: Date;
};

export type EmissionsCell = {
    emissionFactorDescription: string;
    emissionFactorId: string;
    emissionFactor: number;
    emissions: number;
};

export interface GroupStructureMeta {
    type: "GroupStructureMeta";
    fullPath: { id: string; label: string }[];
}

export type RowCellMeta = GroupStructureMeta;
interface StringType {
    label: string;
    data: string;
}

interface NumberType {
    label: string;
    data: number;
}

interface MonetaryType {
    label: string;
    data: MonetaryAmountCell;
}

interface EmissionsType {
    label: string;
    data: EmissionsCell;
}

export interface ReferenceType {
    label: string;
    data: ReferenceCell;
    meta?: RowCellMeta;
}

interface BooleanType {
    label: string;
    data: boolean;
}

export const isReferenceCell = (data: DataType): data is ReferenceType[] => {
    if (data?.length === 0) {
        return false;
    }
    const value = data?.[0].data;
    if (typeof value !== "object" || value === null) {
        return false;
    }
    if ("meta" in value) {
        const metaValue = (value as { meta?: unknown }).meta;
        if (typeof metaValue !== "object") {
            return false;
        }
    }
    return "id" in value && typeof (value as { id: unknown }).id === "string";
};

export const isEmissionsCell = (data: DataType): data is EmissionsType[] => {
    if (data?.length === 0) {
        return false;
    }
    const value = data?.[0].data;
    if (typeof value !== "object" || value === null) {
        return false;
    }
    if (
        "emissionFactorDescription" in value &&
        "emissionFactorId" in value &&
        "emissionFactor" in value &&
        "emissions" in value
    ) {
        return true;
    }
    return false;
};

interface DateType {
    label: string;
    data: Date;
}

export type DataType =
    | StringType[]
    | NumberType[]
    | MonetaryType[]
    | ReferenceType[]
    | DateType[]
    | BooleanType[]
    | EmissionsType[]
    | undefined;

export type DataObjectType = Record<string, DataType>;
export interface Row {
    data: DataObjectType;
    id: string;
}
