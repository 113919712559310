import { defineMessages } from "react-intl";

const messages = defineMessages({
    value: {
        id: "pipelinePage.dataPipelineFilterModal.pipelineFilterRow.value",
        defaultMessage: "Value",
    },
    select: {
        id: "pipelinePage.dataPipelineFilterModal.pipelineFilterRow.select",
        defaultMessage: "Select filter field",
    },
});

export default messages;
