import { Collapse, Divider, Stack } from "@mui/material";
import React, { useState } from "react";
import { DataTable, DataTableCollection } from "@/generated/client";
import { CollectionHeader } from "./CollectionHeader";
import { TableList } from "./TableList";

interface Props {
    collection: DataTableCollection | { name: string };
    tables: DataTable[];
    currentTableId: string;
    onUpdate: () => void;
    onDelete?: () => void;
    onDragStart: (collectionId: string) => void;
    onDrop: (e: React.DragEvent<HTMLDivElement>) => void;
    onDragOver: (e: React.DragEvent<HTMLDivElement>) => void;
}

export const TableCollection = ({
    collection,
    tables,
    currentTableId,
    onUpdate,
    onDelete,
    onDragStart,
    onDrop,
    onDragOver,
}: Props) => {
    const [open, setOpen] = useState(true);

    return (
        <div
            id={"id" in collection ? collection.id : "Other"}
            draggable={"id" in collection}
            onDragStart={() => "id" in collection && onDragStart(collection.id)}
            onDrop={onDrop}
            onDragOver={onDragOver}
        >
            <Stack>
                <CollectionHeader
                    open={open}
                    collection={collection}
                    tables={tables}
                    onUpdate={onUpdate}
                    onDelete={onDelete}
                    onClick={() => setOpen((prev) => !prev)}
                />
                <Divider />
                <Collapse in={open}>
                    <TableList collection={collection} tables={tables} currentTableId={currentTableId} />
                </Collapse>
            </Stack>
        </div>
    );
};
