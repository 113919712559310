import { FilterModal, useShortDescriptionOfFiltersInContext } from "@ignite-analytics/filters";
import { Filter as FilterIcon } from "@ignite-analytics/icons";
import { IconButton, Stack, Tooltip, Typography } from "@mui/material";
import React from "react";

type TooltipTitleProps = {
    title?: string;
};

const TooltipTitle: React.FC<TooltipTitleProps> = ({ title }) => {
    const tooltipArray = title ? title.split("|") : [];
    if (tooltipArray.length === 0) return null;

    return (
        <>
            {tooltipArray.map((filterItem: string) => {
                const filterSplit = filterItem.split(":");
                if (filterSplit.length < 2) return;
                const filterName = `${filterSplit[0]}:`;
                const filterValues = filterSplit[1].split(",");
                return (
                    <Stack direction="row" key={filterSplit[0]} flexWrap="wrap">
                        <Typography fontWeight="bold" sx={{ paddingRight: 0.4 }}>
                            {filterName}
                        </Typography>
                        {filterValues.map((value: string, index: number) => (
                            <Typography key={value} paddingRight={0.4}>
                                {value + (filterValues.length - 1 > index ? "," : "")}
                            </Typography>
                        ))}
                    </Stack>
                );
            })}
        </>
    );
};

interface FilterButtonProps {
    name: string;
}

export const FilterButton: React.FC<FilterButtonProps> = ({ name }) => {
    // add margin if user is viewing the app through "ignite-ui" not "big-bang"
    const title = useShortDescriptionOfFiltersInContext("|");
    const hasTitle = !!title && title.split("|").length !== 0;

    return (
        <FilterModal
            title={name}
            button={
                <Tooltip
                    title={hasTitle ? <TooltipTitle title={title} /> : []}
                    arrow={hasTitle}
                    disableHoverListener={!hasTitle}
                >
                    <IconButton>
                        <FilterIcon fontSize="small" />
                    </IconButton>
                </Tooltip>
            }
            placement="DataTable"
        />
    );
};
