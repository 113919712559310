import { Tooltip } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import React from "react";

export type Relation = {
    object: "general" | "privileged";
    relation: "read" | "write";
};

const getPermission = async (namespace: string, relationTuples: Relation[]) => {
    const path = process.env.REACT_APP_AUTH_SIDECAR_API_URL;
    if (!path) {
        throw new Error("REACT_APP_AUTH_SIDECAR_API_URL not set");
    }
    const result = await fetch(`${path}/api/v1/permissions/check/${namespace}`, {
        method: "POST",
        body: JSON.stringify(relationTuples),
        credentials: "include",
    });
    if (result && !result.ok) {
        throw new Error(`Failed to check permissions: ${result.statusText}`);
    }

    return result.json();
};

const useCheckPermission = (
    namespace: string,
    relationTuples: Relation[]
): {
    permission: boolean[];
    error: unknown;
} => {
    const { data, error } = useQuery<boolean[]>({
        queryKey: ["permissionData"],
        queryFn: () => getPermission(namespace, relationTuples),
        staleTime: 60 * 1000,
        retry: (failureCount, err) => {
            if (err instanceof Response && err.status === 401) {
                return false;
            }
            return failureCount < 3;
        },
    });

    if (!data) {
        return {
            permission: Array(relationTuples.length).fill(false),
            error,
        };
    }
    return {
        permission: data,
        error,
    };
};

export function usePermission(namespace: string, relation: Relation): boolean {
    const { permission, error } = useCheckPermission(namespace, [relation]);
    if (error) return false;
    if (permission?.length === 1) return permission[0];
    return false;
}

interface Props {
    namespace: string;
    relation: Relation;
    unauthorizedComponent?: React.ReactElement | null;
    tooltipMsg?: string;
    children: React.ReactElement;
}

export const PermissionContainer: React.FC<Props> = ({
    children,
    namespace,
    relation,
    tooltipMsg,
    unauthorizedComponent,
}): React.ReactElement | null => {
    const hasPermission = usePermission(namespace, relation);
    if (!hasPermission) {
        if (tooltipMsg && unauthorizedComponent) {
            return (
                <Tooltip title={tooltipMsg}>
                    <span>{unauthorizedComponent}</span>
                </Tooltip>
            );
        }
        return unauthorizedComponent || null;
    }
    return children;
};

interface OptionalPermissionProps {
    namespace?: string;
    relation?: Relation;
    children: JSX.Element;
    unauthorizedComponent?: JSX.Element;
}

export const OptionalPermissionContainer: React.FC<OptionalPermissionProps> = ({
    namespace,
    relation,
    children,
    unauthorizedComponent,
}) => {
    if (namespace && relation){
        return (
            <PermissionContainer
                namespace={namespace}
                relation={relation}
                unauthorizedComponent={unauthorizedComponent}
            >
                {children}
            </PermissionContainer>
        );
    }
    return children;
};

