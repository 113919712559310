import { DataColumn } from "@/Types/DataColumn";
import { DataPipelineOperation } from "@/generated/client";

export const getRelevantColumns: (operation: DataPipelineOperation, dataColumns: DataColumn[]) => DataColumn[] = (
    operation,
    dataColumns
) => {
    const specialColumns = ["COMPANY", "MONETARY_AMOUNT", "TABLE_RELATION"];
    switch (operation.operationType) {
        case "MONETARY_AMOUNT": {
            return dataColumns.filter((dc) => dc.dataType === "MONETARY_AMOUNT");
        }
        case "MATCH_COMPANY": {
            return dataColumns.filter((dc) => dc.dataType === "COMPANY");
        }
        case "GET_OR_CREATE": {
            return dataColumns.filter((dc) => dc.dataType === "TABLE_RELATION");
        }
    }

    return dataColumns.filter((dc) => !specialColumns.includes(dc.dataType));
};
