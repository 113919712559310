import { GraphqlRequestContainer } from "@ignite-analytics/graphql-utilities";
import { LoadingButton } from "@mui/lab";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import React, { useState } from "react";
import { cubeBuilderPageTestIdPrefix } from "@/containers/CubeBuilderPage";
import { fm } from "@/contexts/IntlContext";
import { useDeleteDataColumnsMutation } from "@/generated/client";
import generalMessages from "@/messages";
import messages from "./messages";

type Props = {
    dataColumnId: string;
    dataTableId: string;
    onDelete: () => void;
};
export const DeleteConnectionButton: React.FC<Props> = ({ dataColumnId, dataTableId, onDelete }) => {
    const [isDialogOpen, setIsDialogOpen] = useState(false);

    const [deleteConnectionByDeletingDataColumn, deleteMutation] = useDeleteDataColumnsMutation({
        refetchQueries: ["getAllDataTableConnections", "getDataColumns", "getDataTableElasticFields"],
    });
    const handleDelete = async () => {
        await deleteConnectionByDeletingDataColumn({
            input: {
                dataColumnIds: [dataColumnId],
                dataTableId,
            },
        });
        setIsDialogOpen(false);
        onDelete();
    };
    return (
        <>
            <Dialog open={isDialogOpen}>
                <DialogTitle>{fm(messages.deleteConnectionTitle)}</DialogTitle>
                <DialogContent>{fm(messages.deleteConnectionMessage)}</DialogContent>
                <DialogActions>
                    <Button variant="outlined" onClick={() => setIsDialogOpen(false)}>
                        {fm(generalMessages.cancel)}
                    </Button>
                    <LoadingButton
                        color="error"
                        variant="contained"
                        onClick={handleDelete}
                        data-testid={`${cubeBuilderPageTestIdPrefix}-delete-connection-Button`}
                        loading={deleteMutation.result.type === "loading"}
                    >
                        {fm(generalMessages.delete)}
                    </LoadingButton>
                    <GraphqlRequestContainer asyncData={deleteMutation.result} />
                </DialogActions>
            </Dialog>
            <Button color="error" onClick={() => setIsDialogOpen(true)}>
                {fm(generalMessages.delete)}
            </Button>
        </>
    );
};
