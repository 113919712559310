import { Position } from "reactflow";
import { PIPELINE_COLORS } from "../constants";

const leftPlacing = { top: "50%", transform: "translateY(-50%)", left: "-23px" };
const rightPlacing = { top: "50%", transform: "translateY(-50%)", right: "-23px" };
const bottomPlacing = { left: "50%", transform: "translateX(-50%)", bottom: "-23px" };

const placing = (position: Position) => {
    if (position === Position.Left) return leftPlacing;
    if (position === Position.Right) return rightPlacing;
    if (position === Position.Bottom) return bottomPlacing;
};

export const styledIconHandleSx = (position: Position) => ({
    width: "20px",
    height: "18px",
    position: "fixed",
    borderRadius: "3px",
    display: "flex",
    alignContent: "center",
    justifyContent: "center",
    backgroundColor: PIPELINE_COLORS.join,
    ...placing(position),
});

export const basicNodeSx = {
    paddinTop: 1,
    paddingHorizontal: 1,
    boxShadow: `
0 0px 3.1px rgba(0, 0, 0, 0.005),
0 0.7px 5.4px rgba(0, 0, 0, 0.01),
0 2.6px 7.1px rgba(0, 0, 0, 0.016),
0 6.4px 8.9px rgba(0, 0, 0, 0.024),
0 14.2px 12.6px rgba(0, 0, 0, 0.035),
0 36px 46px rgba(0, 0, 0, 0.06)
;
`,
};
