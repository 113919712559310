import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useEntityEventListener } from "@/contexts/EventContexts/EntityEventChangeContext";
import { useGetAllDataColumnsQuery } from "@/generated/client";

type ContextValue = {
    dataColumnsResult: ReturnType<typeof useGetAllDataColumnsQuery>["result"];
    onDataColumnsChange: () => void;
    disabledColumnIds: string[];
    setDisabledColumnIds: (ids: string[]) => void;
    deletedIds: string[];
};

const Context = React.createContext<ContextValue | null>(null);

const DataColumnContextProvider: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
    const { result: dataColumnsResult, refetch: refetchDataColumns } = useGetAllDataColumnsQuery({});
    const [disabledColumnIds, setDisabledColumnIds] = React.useState<string[]>([]);
    const [deletedIds, setDeletedIds] = useState<string[]>([]);

    const handleDataColumnsChange = useCallback(() => refetchDataColumns(), [refetchDataColumns]);

    useEntityEventListener("DataColumn", (event) => {
        if (event.type === "CREATED" || event.type === "UPDATED") {
            refetchDataColumns();
        } else if (event.type === "DELETED") {
            setDeletedIds((ids) => ids.concat([event.id]));
        }
    });

    useEffect(() => {
        if (dataColumnsResult.type === "success" && disabledColumnIds.length) {
            const newIds = disabledColumnIds.filter((id) =>
                dataColumnsResult.data.dataColumns.find((column) => column.id === id)
            );
            if (newIds.length !== disabledColumnIds.length) {
                setDisabledColumnIds(newIds);
            }
        }
    }, [dataColumnsResult, disabledColumnIds]);

    return (
        <Context.Provider
            value={useMemo(
                () => ({
                    deletedIds,
                    dataColumnsResult,
                    disabledColumnIds,
                    onDataColumnsChange: handleDataColumnsChange,
                    setDisabledColumnIds,
                }),
                [deletedIds, dataColumnsResult, disabledColumnIds, handleDataColumnsChange]
            )}
        >
            {children}
        </Context.Provider>
    );
};

const useDataColumnContext = () => {
    const ctx = useContext(Context);
    if (!ctx) {
        throw new Error("DataTableContext must be used within a DataTablesProvider");
    }
    return ctx;
};

const useAllDataColumns = () => {
    const { dataColumnsResult, deletedIds } = useDataColumnContext();
    return useMemo(() => {
        if (dataColumnsResult.type !== "success") {
            return [];
        }
        return dataColumnsResult.data.dataColumns.filter((dc) => !deletedIds.includes(dc.id));
    }, [dataColumnsResult, deletedIds]);
};

const useDataColumnsByDataTableId = (dataTableId: string) =>
    useAllDataColumns().filter((column) => column.dataTableId === dataTableId);

const useGetDisabledColumnIds = () => {
    const { disabledColumnIds } = useDataColumnContext();
    return disabledColumnIds;
};

const useIsColumnDisabled = (columnId: string) => {
    const { disabledColumnIds } = useDataColumnContext();
    return disabledColumnIds.includes(columnId);
};
const useAddDisabledColumnIds = () => {
    const { setDisabledColumnIds, disabledColumnIds } = useDataColumnContext();
    const addIds = (newIds: string[]) => {
        const ids = disabledColumnIds.concat(newIds);
        setDisabledColumnIds(ids);
    };
    return addIds;
};

export {
    DataColumnContextProvider,
    useAllDataColumns,
    useDataColumnsByDataTableId,
    useDataColumnContext,
    useGetDisabledColumnIds,
    useIsColumnDisabled,
    useAddDisabledColumnIds,
};
