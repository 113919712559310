import { DataColumnType } from "@/generated/client";
import { InputSourceItem } from "@/Types/InputSourceItem";

export const isRelevantSource: (source: InputSourceItem, dataColumnType: DataColumnType) => boolean = (
    source,
    dataColumnType
) => {
    const specialSources: DataColumnType[] = ["MONETARY_AMOUNT", "TABLE_RELATION"];
    switch (source.sourceOperationType) {
        case "MONETARY_AMOUNT": {
            return dataColumnType === "MONETARY_AMOUNT";
        }
        case "GET_OR_CREATE": {
            return dataColumnType === "TABLE_RELATION";
        }
    }
    return !specialSources.includes(dataColumnType);
};
