import { useDebounce } from "@ignite-analytics/general-tools";
import { GraphqlRequestContainer } from "@ignite-analytics/graphql-utilities";
import { Card, Stack } from "@mui/material";
import Skeleton from "@mui/material/Skeleton";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import ReactFlow, {
    Background,
    Controls,
    Edge,
    EdgeChange,
    Node,
    NodeChange,
    applyEdgeChanges,
    applyNodeChanges,
} from "reactflow";
import {
    DataCubeConfiguration,
    DataTable,
    DataTableConnection,
    useGetDataCubeConfigurationQuery,
} from "@/generated/client";
import { useEntityEventListener } from "@/contexts/EventContexts/EntityEventChangeContext";
import { SelectableCubeConfigurationField } from "@/containers/CubeBuilderPage/types";
import { DataColumn } from "@/Types/DataColumn";
import { CreateConnectionModal, ViewConnectionModal } from "../ConnectionModal";
import { DataTableNodeData } from "../DataTableNode";
import { createEdges, createNodes } from "./helpers";
import { EDGE_TYPES, NODE_TYPES } from "./nodeTypes";

interface Props {
    baseTable: DataTable;
    baseDataCubeConfiguration: DataCubeConfiguration;
    baseTableColumns: DataColumn[];
    dataTables: DataTable[];
    connections: DataTableConnection[];
    createConnectionModalOpen: boolean;
    selectableCubeConfigurationFields: SelectableCubeConfigurationField[];
    setCreateConnectionModalOpen: (v: boolean) => void;
}

const WrappedCubeDetailPage: React.FC<Props> = ({
    baseTable,
    baseDataCubeConfiguration,
    baseTableColumns,
    dataTables,
    connections,
    createConnectionModalOpen,
    selectableCubeConfigurationFields: _selectableCubeConfigurationFields,
    setCreateConnectionModalOpen,
}) => {
    const selectableCubeConfigurationFields = useMemo(
        () => _selectableCubeConfigurationFields.filter((field) => field.field !== "_id"),
        [_selectableCubeConfigurationFields]
    );
    const [selectedConnection, setSelectedConnection] = useState<DataTableConnection>();
    useEffect(() => {
        if (selectedConnection) {
            const updatedSelectedConnection = connections.find((connection) => connection.id === selectedConnection.id);
            if (updatedSelectedConnection !== selectedConnection) {
                setSelectedConnection(updatedSelectedConnection);
            }
        }
    }, [connections, selectedConnection]);

    const selectedConnectionTable = dataTables.find(
        (table) => selectedConnection?.targetDataSource.identifier === table.id
    );

    const onCreate = () => {
        setCreateConnectionModalOpen(false);
    };

    const [nodes, setNodes] = useState<Node<DataTableNodeData>[]>(
        createNodes(
            baseTable,
            dataTables,
            connections,
            baseTableColumns,
            baseDataCubeConfiguration,
            selectableCubeConfigurationFields
        )
    );
    const [edges, setEdges] = useState<Edge[]>(createEdges(connections, setSelectedConnection, baseTableColumns));

    useEffect(() => {
        setNodes(
            createNodes(
                baseTable,
                dataTables,
                connections,
                baseTableColumns,
                baseDataCubeConfiguration,
                selectableCubeConfigurationFields
            )
        );
    }, [
        baseTable,
        dataTables,
        connections,
        baseTableColumns,
        baseDataCubeConfiguration,
        selectableCubeConfigurationFields,
    ]);

    useEffect(() => {
        setEdges(createEdges(connections, setSelectedConnection, baseTableColumns));
    }, [baseTable, dataTables, connections, baseTableColumns]);

    const onNodesChange = useCallback(
        (changes: NodeChange[]) => setNodes((nds) => applyNodeChanges(changes, nds)),
        [setNodes]
    );
    const onEdgesChange = useCallback(
        (changes: EdgeChange[]) => setEdges((eds) => applyEdgeChanges(changes, eds)),
        [setEdges]
    );

    const zoomFunctionRef = useRef<(e: WheelEvent) => void>();
    const handleMouseEnter = () => {
        const handleWheel = (e: WheelEvent) => {
            if (e.ctrlKey || e.metaKey) {
                e.preventDefault();
            }
        };
        zoomFunctionRef.current = handleWheel;
        window.addEventListener("wheel", handleWheel, { passive: false });
    };
    const handleMouseLeave = () => {
        if (zoomFunctionRef.current) {
            window.removeEventListener("wheel", zoomFunctionRef.current);
        }
    };

    return (
        <Card variant="outlined">
            <Stack direction="row" minHeight="70vh">
                <Stack minWidth="100%">
                    <ReactFlow
                        nodeTypes={NODE_TYPES}
                        edgeTypes={EDGE_TYPES}
                        nodes={nodes}
                        edges={edges}
                        onNodesChange={onNodesChange}
                        onEdgesChange={onEdgesChange}
                        fitView
                        fitViewOptions={{ minZoom: 0.2 }}
                        minZoom={0.2}
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                    >
                        <Background />
                        <Controls />
                    </ReactFlow>
                </Stack>
            </Stack>
            <CreateConnectionModal
                open={createConnectionModalOpen}
                onClose={() => setCreateConnectionModalOpen(false)}
                onCreate={onCreate}
                baseTable={baseTable}
                dataTables={dataTables}
            />
            {selectedConnectionTable && selectedConnection && (
                <ViewConnectionModal
                    open={selectedConnection !== undefined}
                    onClose={() => setSelectedConnection(undefined)}
                    baseTable={baseTable}
                    targetTable={selectedConnectionTable}
                    connection={selectedConnection}
                />
            )}
        </Card>
    );
};

const CubeDetailPage = ({
    baseTable,
    setCreateConnectionModalOpen,
    connections,
    baseTableColumns,
    ...props
}: Omit<Props, "baseDataCubeConfiguration">) => {
    const { result, refetch } = useGetDataCubeConfigurationQuery({
        input: { dataTableId: baseTable.id },
    });

    const debouncedRefetchDataCubeConfiguration = useDebounce(() => refetch(), 1000);

    useEntityEventListener("DataTable", (event) => {
        if (event.type === "UPDATED" && event.id === baseTable.id) {
            debouncedRefetchDataCubeConfiguration();
        }
    });

    const relatedDataColumnIds = baseTableColumns.filter((dc) => dc.dataType === "TABLE_RELATION").map((dc) => dc.id);
    const filteredDataTableConnections = connections.filter((connection) =>
        relatedDataColumnIds.includes(connection.dataColumnId)
    );

    return (
        <GraphqlRequestContainer asyncData={result} loading={<Skeleton width="100%" height="800px" />}>
            {(response) => (
                <WrappedCubeDetailPage
                    {...props}
                    baseTable={baseTable}
                    baseTableColumns={baseTableColumns}
                    connections={filteredDataTableConnections}
                    baseDataCubeConfiguration={response.DataCubeConfiguration}
                    setCreateConnectionModalOpen={(v: boolean) => {
                        setCreateConnectionModalOpen(v);
                        refetch();
                    }}
                />
            )}
        </GraphqlRequestContainer>
    );
};

export default CubeDetailPage;
