import React, { useCallback, useContext, useMemo } from "react";
import { useEntityEventListener } from "@/contexts/EventContexts/EntityEventChangeContext";
import { DataPipeline, useGetAllDataPipelinesQuery, useGetDataPipelineOperationLazyQuery } from "@/generated/client";

type ContextValue = {
    pipeLineResult: ReturnType<typeof useGetAllDataPipelinesQuery>["result"];
    onPipelinesChange: () => void;
};

const Context = React.createContext<ContextValue | null>(null);

const PipelineContextProvider: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
    const { result: pipeLineResult, refetch: refetchPipelines } = useGetAllDataPipelinesQuery({});
    const handlePipelinesChange = useCallback(() => refetchPipelines(), [refetchPipelines]);
    const [getDataPipelineOperation] = useGetDataPipelineOperationLazyQuery();

    useEntityEventListener("DataPipeline", (event) => {
        if (event.type === "CREATED" || event.type === "DELETED" || event.type === "UPDATED") {
            handlePipelinesChange();
        }
    });

    useEntityEventListener("DataPipelineOperation", (event) => {
        if (event.type === "UPDATED") {
            getDataPipelineOperation({ input: { id: event.id } });
        }
    });

    return (
        <Context.Provider
            value={useMemo(
                () => ({
                    pipeLineResult,
                    onPipelinesChange: handlePipelinesChange,
                }),
                [pipeLineResult, handlePipelinesChange]
            )}
        >
            {children}
        </Context.Provider>
    );
};

const usePipelineContext = () => {
    const ctx = useContext(Context);
    if (!ctx) {
        throw new Error("DataTableContext must be used within a DataTablesProvider");
    }
    return ctx;
};

const useAllDataPipelines = () => {
    const { pipeLineResult } = usePipelineContext();
    return pipeLineResult;
};

const useGetPipelinesByTableId = (dataTableId: string): DataPipeline[] => {
    const pipelinesResult = useAllDataPipelines();
    if (pipelinesResult.type !== "success") {
        return [];
    }
    return pipelinesResult.data.dataPipelines?.filter((pipeline) => pipeline.targetDataTableId === dataTableId);
};

export { PipelineContextProvider, useAllDataPipelines, useGetPipelinesByTableId, usePipelineContext };
