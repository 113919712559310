import { GraphqlRequestContainer } from "@ignite-analytics/graphql-utilities";
import { track } from "@ignite-analytics/track";
import {
    Autocomplete,
    Button,
    Checkbox,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Typography,
} from "@mui/material";
import { sortBy } from "lodash-es";
import React, { useState } from "react";
import { testIdPrefix } from "@/components/testIdprefix";
import { useEntityEventListener } from "@/contexts/EventContexts/EntityEventChangeContext";
import { fm } from "@/contexts/IntlContext";
import {
    DataRepository,
    DataRepositoryField,
    useCreateLookupDataPipelineOperationMutation,
    useGetAllDataRepositoriesQuery,
} from "@/generated/client";
import globalMessages from "@/messages";
import { OperationComponentProps } from "..";
import { LookupCriteriaSelect } from "./LookupCriteriaSelect";
import messages from "./messages";

export const initialNames = {
    LOOKUP: "Left join with",
};

const CreateLookupOperationForm: React.FC<OperationComponentProps> = ({ dataPipeline, onFormSubmit }) => {
    const { result, refetch: refetchRepositories } = useGetAllDataRepositoriesQuery({});
    const [createLookupDataPipelineOperation, mutation] = useCreateLookupDataPipelineOperationMutation({
        refetchQueries: ["getDataPipelineOperations"],
    });
    const [selectedDataRepository, setSelectedDataRepository] = useState<DataRepository>();
    const [name, setName] = useState<string>(initialNames.LOOKUP);

    const [lookupCriteria, setLookupCriteria] = useState<DataRepositoryField[]>([]);
    const [lookupFieldOutputs, setFieldOutputs] = useState<DataRepositoryField[]>([]);

    const [allChecked, setAllChecked] = useState<boolean>(true);

    const handleSelectAll = (selectAll: boolean) => {
        if (selectAll && selectedDataRepository) {
            setFieldOutputs([...selectedDataRepository.fields]);
        } else {
            setFieldOutputs([]);
        }
        setAllChecked(!allChecked);
    };

    useEntityEventListener("DataRepository", (e) => {
        if (e.type === "CREATED" || e.type === "DELETED") refetchRepositories();
    });

    const onInclude = (include: boolean, field: DataRepositoryField) => {
        if (include) {
            setFieldOutputs([...lookupFieldOutputs, field]);
        } else {
            const newFieldOutputs = lookupFieldOutputs.filter((includedField) => includedField.id !== field.id);
            setFieldOutputs(newFieldOutputs);
        }
    };
    const handleSubmit = async () => {
        if (
            !selectedDataRepository ||
            !name ||
            lookupCriteria.length === 0 ||
            lookupFieldOutputs.length === 0 ||
            mutation.result.type !== "not-asked"
        ) {
            return;
        }
        const createResult = await createLookupDataPipelineOperation({
            input: {
                targetDataRepositoryId: selectedDataRepository.id,
                dataPipelineId: dataPipeline.id,
                name,
                lookupCriteria: lookupCriteria.map((lookupCriterion) => ({
                    dataRepositoryFieldId: lookupCriterion.id,
                })),
                lookupFieldOutputs: lookupFieldOutputs.map((lookupFieldOutput) => ({
                    dataRepositoryFieldId: lookupFieldOutput.id,
                })),
            },
        });

        track("Pipeline: Created Pipeline Operation", {
            operationType: "LOOKUP",
        });

        onFormSubmit(createResult.data?.createLookupDataPipelineOperation.dataPipelineOperation);
    };

    return (
        <Stack gap={1}>
            <TextField
                type="text"
                label={fm(messages.nameOperation)}
                name="nameOperation"
                value={name}
                size="small"
                onChange={(e) => setName(e.target.value)}
            />

            <GraphqlRequestContainer asyncData={result}>
                {(dataRepositoriesResponse) => (
                    <Autocomplete
                        fullWidth
                        size="small"
                        value={selectedDataRepository}
                        options={dataRepositoriesResponse.dataRepositories.filter(
                            (dataRepository) =>
                                !dataPipeline.sourceDataRepositoryIds.some(
                                    (sourceDataRepositoryId) => sourceDataRepositoryId === dataRepository.id
                                )
                        )}
                        onChange={(_, v) => {
                            name === initialNames.LOOKUP && setName(`${name} ${v?.name}`);
                            setSelectedDataRepository(v || undefined);
                            setFieldOutputs(v?.fields || []);
                        }}
                        getOptionLabel={(option) => option.name}
                        renderInput={(params) => (
                            <TextField {...params} label={fm(messages.dataRepositorySelectLabel)} />
                        )}
                    />
                )}
            </GraphqlRequestContainer>
            {selectedDataRepository && (
                <>
                    <Typography variant="subtitle1">{fm(messages.lookupCriteriaHeader)}</Typography>
                    <LookupCriteriaSelect
                        selectedFields={lookupCriteria}
                        dataRepositoryFields={selectedDataRepository.fields}
                        onChange={setLookupCriteria}
                    />
                    <Stack maxHeight="30vh">
                        <TableContainer>
                            <Table stickyHeader size="small">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>
                                            <Checkbox
                                                name="selectAll"
                                                checked={allChecked}
                                                onChange={(e) => handleSelectAll(e.currentTarget.checked)}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <Typography variant="subtitle2">
                                                {fm(messages.lookupOutputFieldHeader)}
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {sortBy(selectedDataRepository.fields, [(f) => f.name]).map((field) => (
                                        <TableRow key={field.id}>
                                            <TableCell>
                                                <Checkbox
                                                    name="select"
                                                    checked={lookupFieldOutputs.includes(field)}
                                                    onChange={(e) => onInclude(e.currentTarget.checked, field)}
                                                />
                                            </TableCell>
                                            <TableCell>
                                                <Typography>{field.name}</Typography>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Stack>
                    <Button onClick={handleSubmit} data-testid={`${testIdPrefix}-create-lookup-operation`}>
                        {fm(globalMessages.submitButton)}
                    </Button>
                </>
            )}
        </Stack>
    );
};

export default CreateLookupOperationForm;
