import { defineMessages } from "react-intl";

const messages = defineMessages({
    title: {
        id: "CreateTableFromFile.title",
        defaultMessage: "Uploading data",
    },
    subTitle: {
        id: "CreateTableFromFile.subTitle",
        defaultMessage:
            "You can track the progress in notifications. We will also notify you after the process is completed",
    },
    dataTypes: {
        id: "CreateTableFromFile.dataTypes",
        defaultMessage: "Please verify that the data types below are correct",
    },
    createNewTable: {
        id: "CreateTableFromFile.createNewTable",
        defaultMessage: "Create new table",
    },
    buildfromExistingSources: {
        id: "CreateTableFromFile.buildfromExistingSources",
        defaultMessage: "Create empty table",
    },
});

export default messages;
