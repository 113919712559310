import { defineMessages } from "react-intl";

const messages = defineMessages({
    dataRepositorySelectLabel: {
        id: "uploadPage.createOperationButton.getOrCreateOperationForm.dataRepositorySelectLabel",
        defaultMessage: "Target data repository",
    },
    getOrCreateCriteriaHeader: {
        id: "uploadPage.createOperationButton.getOrCreateOperationForm.getOrCreateCriteriaHeader",
        defaultMessage: "Matching criteria",
    },
    getOrCreateCriteriaTooltip: {
        id: "uploadPage.createOperationButton.getOrCreateOperationForm.getOrCreateCriteriaTooltip",
        defaultMessage:
            "Matching criteria reflects the unique configuration of the target repository. This is necessary to ensure uniqueness and guarantee a match.",
    },
    additionalFieldsHeader: {
        id: "uploadPage.createOperationButton.getOrCreateOperationForm.additionalFieldCriteriaHeader",
        defaultMessage: "Additional fields",
    },
    addGetOrCreateFieldSelectLabel: {
        id: "uploadPage.createOperationButton.getOrCreateOperationForm.addGetOrCreateFieldSelectLabel",
        defaultMessage: "Select data repository field",
    },
    nameOperation: {
        id: "uploadPage.createOperationButton.getOrCreateOperationForm.nameOperation",
        defaultMessage: "Name",
    },
    newRepository: {
        id: "uploadPage.createOperationButton.getOrCreateOperationForm.newRepository",
        defaultMessage: "New Repository",
    },
    operationHeader: {
        id: "uploadPage.createOperationButton.getOrCreateOperationForm.operationHeader",
        defaultMessage: "Connect with external repository",
    },
    operationTooltip: {
        id: "uploadPage.createOperationButton.getOrCreateOperationForm.operationTooltip",
        defaultMessage:
            "The repository relation builder allows you to build relations between your current source repository and a target repostiory. This is the operation you would use to e.g. connect a row of spend data with a supplier.",
    },
    upsert: {
        id: "uploadPage.createOperationButton.getOrCreateOperationForm.upsert",
        defaultMessage: "Create",
    },
    upsertTooltip: {
        id: "uploadPage.createOperationButton.getOrCreateOperationForm.upsertTooltip",
        defaultMessage: "Create row in target data repository if no matching row is found.",
    },
});

export default messages;
