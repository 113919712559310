import { CONTRACT_TABLE_GT, SUPPLIER_TABLE_GT, TRANSACTIONS_GT } from "@ignite-analytics/global-types";
import { LoadingButton } from "@mui/lab";
import { Button, Card, CardContent, Stack } from "@mui/material";
import React, { useState } from "react";
import { FilePreviewData } from "@/components/FilePreview";
import { FilePreviewTable } from "@/components/FilePreview/Table";
import { fm } from "@/contexts/IntlContext";
import { PrimitiveDataColumnTypeInput } from "@/generated/client";
import globalMessages from "@/messages/index";
import { SimpleGlobalTagConfiguration, tableSpecificTags } from "./variations/Simple";

interface Props {
    fields: string[];
    tableType: typeof SUPPLIER_TABLE_GT | typeof TRANSACTIONS_GT | typeof CONTRACT_TABLE_GT;
    onComplete: (
        mappings: Record<string, string | null>,
        typeMappings: Record<string, PrimitiveDataColumnTypeInput>
    ) => void;
    onBack: () => void;
    loading: boolean;
    filePreviewData: FilePreviewData;
}

type STEPS = typeof TRANSACTIONS_GT | typeof SUPPLIER_TABLE_GT | typeof CONTRACT_TABLE_GT;

export const GlobalTagConfiguration: React.FC<Props> = ({
    fields,
    tableType,
    onComplete,
    onBack,
    loading,
    filePreviewData,
}) => {
    const [mappings, setMappings] = useState<Record<string, string | null>>({});
    const [typeMappings, setTypeMappings] = useState<Record<string, PrimitiveDataColumnTypeInput>>({});
    const [step, setStep] = useState<STEPS>(tableType);

    const validateStep = () => [...tableSpecificTags[step]].every(({ tag }) => tag in mappings);

    const handleNext = () => {
        step === TRANSACTIONS_GT && validateStep() && setStep(SUPPLIER_TABLE_GT);
        step === CONTRACT_TABLE_GT && validateStep() && setStep(SUPPLIER_TABLE_GT);

        step === SUPPLIER_TABLE_GT &&
            validateStep() &&
            onComplete(Object.fromEntries(Object.entries(mappings).map(([key, value]) => [value, key])), typeMappings);
    };

    const handleBack = () => {
        step === SUPPLIER_TABLE_GT && tableType === TRANSACTIONS_GT ? setStep(TRANSACTIONS_GT) : onBack();
    };

    const handleTypeSelect = (columnId: string, type: PrimitiveDataColumnTypeInput) => {
        setTypeMappings((prev) => ({ ...prev, [columnId]: type }));
    };

    return (
        <Stack gap={1}>
            <Stack>
                {step === TRANSACTIONS_GT && (
                    <SimpleGlobalTagConfiguration
                        fields={fields}
                        setMappings={setMappings}
                        mappings={mappings}
                        tableType={step}
                    />
                )}
                {step === CONTRACT_TABLE_GT && (
                    <SimpleGlobalTagConfiguration
                        fields={fields}
                        setMappings={setMappings}
                        mappings={mappings}
                        tableType={step}
                    />
                )}
                {step === SUPPLIER_TABLE_GT && (
                    <SimpleGlobalTagConfiguration
                        fields={fields}
                        setMappings={setMappings}
                        mappings={mappings}
                        tableType={step}
                    />
                )}

                <Stack maxHeight="450px">
                    <Card>
                        <CardContent>
                            <FilePreviewTable
                                height="50vh"
                                fields={filePreviewData}
                                highlights={Object.values(mappings)}
                                dataTypeSelection
                                setType={handleTypeSelect}
                                typeMappings={typeMappings}
                            />
                        </CardContent>
                    </Card>
                </Stack>
            </Stack>
            <Stack direction="row" justifyContent="space-between">
                <Button size="small" color="secondary" variant="outlined" onClick={handleBack}>
                    {fm(globalMessages.back)}
                </Button>
                <LoadingButton
                    variant="contained"
                    size="small"
                    color={step === SUPPLIER_TABLE_GT ? "success" : undefined}
                    disabled={!validateStep()}
                    onClick={handleNext}
                    loading={loading}
                >
                    {step === TRANSACTIONS_GT || step === CONTRACT_TABLE_GT
                        ? fm(globalMessages.next)
                        : fm(globalMessages.finish)}
                </LoadingButton>
            </Stack>
        </Stack>
    );
};
