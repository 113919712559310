import { defineMessages } from "react-intl";

const messages = defineMessages({
    inProgressChangesTitle: {
        id: "dataTables.applyChanges.inProgressChangesTitle",
        defaultMessage: "In progress",
    },
    noInProgressChanges: {
        id: "dataTables.applyChanges.noInProgressChanges",
        defaultMessage: "No changes are currently in progress",
    },
});

export default messages;
