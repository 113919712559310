import { defineMessages } from "react-intl";

const messages = defineMessages({
    dataRepositorySelectLabel: {
        id: "uploadPage.createOperationButton.getOrCreateOperationForm.dataRepositorySelectLabel",
        defaultMessage: "Target data repository",
    },
    matchCriteriaHeader: {
        id: "uploadPage.createOperationButton.getOrCreateOperationForm.matchCriteriaHeader",
        defaultMessage: "Matching criteria",
    },
    matchCriteriaTooltip: {
        id: "uploadPage.createOperationButton.getOrCreateOperationForm.matchCriteriaTooltip",
        defaultMessage:
            "Matching criteria reflects the unique configuration of the target repository. This is necessary to ensure uniqueness and guarantee a match. If no matching row is found, the operation will create a new row.",
    },
});

export default messages;
