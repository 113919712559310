import { Layers } from "@mui/icons-material";
import React, { useState } from "react";
import { Handle, Position } from "reactflow";
import { DataPipeline, DataPipelineOperation, DataRepository, DataTable } from "@/generated/client";
import { InputOutputMapping } from "../../Modals/InputOutputMapping";
import { handleStyle, PIPELINE_COLORS } from "../constants";
import { BasicNode } from "./basicNode";

interface Props {
    data: {
        dataTable?: DataTable;
        dataPipeline: DataPipeline;
        operations: DataPipelineOperation[];
        dataRepositories: DataRepository[];
    };
}

export const UnionNode = ({ data }: Props) => {
    const [mappingOpen, setMappingOpen] = useState(false);
    return (
        <>
            <Handle position={Position.Left} type="target" style={handleStyle} />
            <Handle position={Position.Right} type="source" style={handleStyle} />
            {data.dataTable && (
                <InputOutputMapping
                    open={mappingOpen}
                    dataTable={data.dataTable}
                    dataPipeline={data.dataPipeline}
                    operations={data.operations}
                    dataRepositories={data.dataRepositories}
                    onClose={() => setMappingOpen(false)}
                />
            )}
            <BasicNode
                text="union"
                icon={<Layers fontSize="small" />}
                onIconClick={() => setMappingOpen(true)}
                color={PIPELINE_COLORS.union}
                maxWidth="100px"
                minWidth="150px"
                handleLeft
                handleRight
            />
        </>
    );
};
