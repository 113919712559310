import { defineMessages } from "react-intl";

const messages = defineMessages({
    addTextFilter: {
        id: "pipelinePage.dataPipelineFilterModal.pipelineFilterRow.addTextFilter",
        defaultMessage: "Text filter",
    },
    addNumberFilter: {
        id: "pipelinePage.dataPipelineFilterModal.pipelineFilterRow.addNumberFilter",
        defaultMessage: "Number filter",
    },
    addDateFilter: {
        id: "pipelinePage.dataPipelineFilterModal.pipelineFilterRow.addDateFilter",
        defaultMessage: "Date filter",
    },
    deleteFilterCombination: {
        id: "pipelinePage.dataPipelineFilterModal.pipelineFilterRow.deleteFilterCombination",
        defaultMessage: "Delete filter combination",
    },
    deleteCombinationDescription: {
        id: "pipelinePage.dataPipelineFilterModal.pipelineFilterRow.deleteCombinationDescription",
        defaultMessage:
            "Are you sure about deleting the filter combination? You must still rerun the pipeline for the filter deletion to have an effect on your data.",
    },
    filterCombinationHeader: {
        id: "pipelinePage.dataPipelineFilterModal.pipelineFilterRow.filterCombinationHeader",
        defaultMessage: "{index}. Combination",
    },
    noFilters: {
        id: "pipelinePage.dataPipelineFilterModal.pipelineFilterRow.noFilters",
        defaultMessage: "Empty filter combination. Please create a new filter.",
    },
    and: {
        id: "pipelinePage.dataPipelineFilterModal.pipelineFilterRow.and",
        defaultMessage: "AND",
    },
});

export default messages;
