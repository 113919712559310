import { defineMessages } from "react-intl";

const messages = defineMessages({
    aggregationTypeSelectLabel: {
        id: "components.dataColumns.aggregation.aggregationTypeSelectLabel",
        defaultMessage: "Aggregation type",
    },
    uniqueCountEstimationWarning: {
        id: "components.dataColumns.aggregation.uniqueCountEstimationWarning",
        defaultMessage:
            "Our unique count aggregation is an estimation, and may not be accurate on datasets with large cardinality. Unique counts are guaranteed to be accurate for fields with up to 10,000 unique values, and can have a relative error of 0.5%. Contact support for more information.",
    },
    filter: {
        id: "dataTables.tableView.filter",
        defaultMessage: "Filter",
    },
    fieldToAggregateSelectLabel: {
        id: "components.dataColumns.aggregation.fieldToAggregateSelectLabel",
        defaultMessage: "Field to aggregate",
    },
});

export default messages;
