import { useElasticFields } from "@ignite-analytics/elastic-fields";
import { useFilterStateAction, useFilters } from "@ignite-analytics/filters";
import { useDebounce } from "@ignite-analytics/general-tools";
import { track } from "@ignite-analytics/track";
import { Stack, useTheme } from "@mui/material";
import React, { useCallback, useEffect, useRef, useState } from "react";

import { DataColumn } from "@/Types/DataColumn";
import { useBasePath } from "@/contexts/BasePathContext";
import { useDataManagementProcessEventListener } from "@/contexts/EventContexts/DataManagementEventContext";
import { useHiddenColumnsContext } from "@/contexts/hiddenColumnsContext";
import { useDataTable } from "@/generated/DataTableEntity";
import { DataTable, useGetRowsSearchWithLabelsLazyQuery } from "@/generated/client";

import { TableView } from "../Table";
import { DataTableListMenu } from "./DataTableListMenu";
import { useGetFilters } from "./hooks";

interface Props {
    baseDataTable: DataTable;
    dataColumns: DataColumn[];
    setSelectedDataTableViewId: (id: string) => void;
    selectedDataTableViewId: string;
}

export const TablePage: React.FC<Props> = ({
    baseDataTable,
    dataColumns,
    setSelectedDataTableViewId,
    selectedDataTableViewId,
}) => {
    const basePath = useBasePath();

    const filterAction = useFilterStateAction();

    const selectedDataTable = useDataTable(selectedDataTableViewId)?.data ?? baseDataTable;
    const selectedDataTableViewIdRef = useRef(selectedDataTableViewId);
    useEffect(() => {
        selectedDataTableViewIdRef.current = selectedDataTableViewId;
    }, [selectedDataTableViewId]);

    const elasticFields = useElasticFields(selectedDataTable.elasticIndex, false) ?? [];

    // Search and pagination
    const [page, setPage] = useState(0);
    const [searchTerm, setSearchTerm] = useState("");
    const filterJSON = useGetFilters(searchTerm, elasticFields);
    const filtersForTracking = useFilters();

    const debouncedTrack = useDebounce(track, 2000);

    const [getRows] = useGetRowsSearchWithLabelsLazyQuery({ fetchPolicy: "cache-and-network" });
    const [rows, setRows] = useState<{ id: string; data: any }[] | undefined>();
    const [total, setTotal] = useState<number | undefined>();
    useEffect(() => {
        setRows(undefined);
        setTotal(undefined);
    }, [selectedDataTableViewId]);

    const [rowsPerPage, setRowsPerPage] = useState(50);
    const { columnsToHide } = useHiddenColumnsContext();
    const [loading, setLoading] = useState(false);
    const [sortColumn, setSortColumn] = useState<{ dataColumnId: string; order: "asc" | "desc" } | undefined>(
        undefined
    );
    const fetchData = useCallback(() => {
        const sortQuery =
            sortColumn && dataColumns.some((col) => sortColumn.dataColumnId === col.id) ? [sortColumn] : [];
        setLoading(true);
        getRows({
            input: {
                index: page * rowsPerPage,
                range: rowsPerPage,
                request: {
                    dataTableId: selectedDataTableViewId,
                    filterJSON,
                    sortingArray: sortQuery,
                },
            },
        })
            .then((res) => {
                if (res.data && selectedDataTableViewId === selectedDataTableViewIdRef.current) {
                    setRows(
                        res.data.getRowsSearchWithLabels.dataTableRowsWithLabel.map(({ id, dataJsonWithLabel }) => ({
                            id,
                            data: JSON.parse(dataJsonWithLabel),
                        }))
                    );
                    setTotal(res.data.getRowsSearchWithLabels.total);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    }, [sortColumn, dataColumns, page, rowsPerPage, filterJSON, selectedDataTableViewId, getRows]);
    useDataManagementProcessEventListener("DataTableDataChange", (e) => {
        if (e.id === selectedDataTableViewId || !e.id) {
            fetchData();
        }
    });
    useEffect(() => {
        fetchData();
    }, [fetchData]);

    useEffect(() => {
        setSearchTerm("");
        filterAction && filterAction({ type: "RESET_FILTERS" });
    }, [selectedDataTableViewId, filterAction]);

    useEffect(
        function trackFiltering() {
            filtersForTracking?.length && track("Data Table view: Filter Data Table rows");
        },
        [filtersForTracking]
    );
    const handleSearch = (s: string) => {
        s !== "" && !searchTerm.startsWith(s) && debouncedTrack("Data Table view: Search Data Table rows");
        setSearchTerm(s);
    };

    const { spacing } = useTheme();

    useEffect(() => {
        setPage(0);
    }, [filterJSON, selectedDataTableViewId, searchTerm]);

    if (dataColumns.length === 0) {
        return null;
    }

    return (
        <Stack sx={{ marginTop: "0 !important" }} height={`calc(100% - 48px - ${spacing(3)})`} gap={2}>
            <DataTableListMenu
                baseDataTable={baseDataTable}
                selectedDataTable={selectedDataTable}
                setSelectedDataTableViewId={setSelectedDataTableViewId}
                selectedDataTableViewId={selectedDataTableViewId}
                searchTerm={searchTerm}
                handleSearch={handleSearch}
                total={total ?? 0}
            />
            <Stack sx={{ marginTop: "0 !important" }} height="100%" overflow="auto">
                <TableView
                    dataTable={selectedDataTable}
                    columns={dataColumns.filter((dc) => !columnsToHide.includes(dc.id))}
                    pagination={{
                        page,
                        setPage,
                        rowsPerPage,
                        setRowsPerPage,
                        total: total ?? 0,
                    }}
                    path={`${basePath}${selectedDataTable.id}/table-view`}
                    loading={loading}
                    rows={rows ?? []}
                    sortColumn={sortColumn}
                    onSort={(sorting) => {
                        if (!sorting) {
                            setSortColumn(undefined);
                            return;
                        }
                        setSortColumn({ dataColumnId: sorting.colId, order: sorting.direction });
                    }}
                />
            </Stack>
        </Stack>
    );
};
