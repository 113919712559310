import { DataTableConnectionCriterionTypeInput } from "@/generated/client";

export const connectionCriterionOperators: DataTableConnectionCriterionTypeInput[] = [
    "EQUAL",
    "GREATER_THAN",
    "GREATER_THAN_OR_EQUAL",
    "LESS_THAN",
    "LESS_THAN_OR_EQUAL",
];

export interface Criterion {
    frontEndKey: string;
    sourceField?: string;
    targetField?: string;
    type: DataTableConnectionCriterionTypeInput;
    ignoreOnBlankTarget: boolean;
}
