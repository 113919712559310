import { defineMessages } from "react-intl";

const messages = defineMessages({
    refreshAggregatedViewTooltipText: {
        id: "aggregatedView.refreshAggregatedViewTooltipText",
        defaultMessage: "Reapply aggregations",
    },
    confirmRefreshTitle: {
        id: "aggregatedView.confirmRefreshTitle",
        defaultMessage: "Reapply aggregations",
    },
    confirmRefreshContent: {
        id: "aggregatedView.confirmRefreshContent",
        defaultMessage: "This action will reapply the aggregations for this view. Are you sure you want to continue?",
    },
});

export default messages;
