import { useDebounce } from "@ignite-analytics/general-tools";
import { GraphqlRequestContainer } from "@ignite-analytics/graphql-utilities";
import { track } from "@ignite-analytics/track";
import { Add } from "@mui/icons-material";
import LoadingButton from "@mui/lab/LoadingButton";
import { Button, Skeleton, Stack, Tooltip } from "@mui/material";
import React, { useEffect, useState } from "react";

import { UserInfoSnackbar } from "@/components/UserInfoSnackBar";
import { useDataColumnsByDataTableId } from "@/contexts/EventContexts/DataColumnEventContext";
import { useEntityEventListener } from "@/contexts/EventContexts/EntityEventChangeContext";
import { fm } from "@/contexts/IntlContext";
import { useManyDataTables } from "@/generated/DataTableEntity";
import {
    DataTable,
    useApplyDataTableConnectionChangesMutation,
    useGetAllDataTableConnectionsQuery,
    useGetDataTableElasticFieldsQuery,
    useIndexDataTableToElasticMutation,
} from "@/generated/client";
import CubeDetailPage from "./components/CubeDetailPage";
import { useChangedConnectionsContext } from "./context";
import messages from "./messages";

export const cubeBuilderPageTestIdPrefix = "cube-builder";

type Props = { dataTable: DataTable };

const CubeBuilderPage: React.FC<Props> = ({ dataTable }: Props) => {
    const result = useManyDataTables();
    const [isSnackBarOpen, setIsSnackbarOpen] = useState(false);

    const [open, setOpen] = useState(false);
    const [{ changedConnectionColumns, createdConnectionColumns, columnsWithChangedCubeConfiguration }, dispatch] =
        useChangedConnectionsContext();
    const [applyDataTableConnectionChanges, applyConnectionsMutation] = useApplyDataTableConnectionChangesMutation();
    const [indexDataTableToElastic, indexDataTableMutation] = useIndexDataTableToElasticMutation();

    const { result: fieldsResult, refetch: refetchFields } = useGetDataTableElasticFieldsQuery({
        input: { dataTableId: dataTable.id, withEnrichments: false, includeHidden: true },
    });

    const debouncedRefetchFields = useDebounce(() => refetchFields(), 1000);

    useEntityEventListener("DataColumn", debouncedRefetchFields);

    useEffect(() => {
        refetchFields({ input: { dataTableId: dataTable.id, withEnrichments: false, includeHidden: true } });
    }, [dataTable.id, refetchFields, createdConnectionColumns]);

    const { result: connections, refetch: refetchConnections } = useGetAllDataTableConnectionsQuery({});

    const debouncedRefetchConnections = useDebounce(() => refetchConnections(), 1000);

    useEntityEventListener("DataTable", (event) => {
        if (event.type === "UPDATED" && event.id === dataTable.id) {
            debouncedRefetchConnections();
        }
    });

    const columns = useDataColumnsByDataTableId(dataTable.id);
    const handleApplyConnections = async () => {
        await applyDataTableConnectionChanges({
            input: { changedConnectionColumns, createdConnectionColumns, columnsWithChangedCubeConfiguration },
        });
        setIsSnackbarOpen(true);
        dispatch({ type: "RESET" });
        track(`Data Cube Builder: Click apply changes`);
    };

    const handleIndexDataTableToElastic = async () => {
        if (indexDataTableMutation.result.type === "loading") {
            return;
        }
        await indexDataTableToElastic({ input: { dataTableId: dataTable.id } });
        track(`Data Cube Builder: Click pending data table changes`);
    };

    const hasPerformedConnectionChanges =
        changedConnectionColumns.length ||
        createdConnectionColumns.length ||
        columnsWithChangedCubeConfiguration.length;
    return (
        <Stack>
            <UserInfoSnackbar
                open={isSnackBarOpen}
                setOpen={setIsSnackbarOpen}
                message={fm(messages.applyStartedMessage)}
                severity="success"
            />
            <GraphqlRequestContainer asyncData={fieldsResult} loading={null}>
                {(fieldsResponse) => (
                    <GraphqlRequestContainer asyncData={result} loading={<Skeleton width="100%" height="800px" />}>
                        {(response) => (
                            <>
                                <Stack direction="row" justifyContent="end" pb={1}>
                                    {hasPerformedConnectionChanges ? (
                                        <Tooltip title={fm(messages.applyTooltip)}>
                                            <LoadingButton
                                                size="small"
                                                variant="contained"
                                                color="success"
                                                onClick={handleApplyConnections}
                                                loading={applyConnectionsMutation.result.type === "loading"}
                                                data-testid={`${cubeBuilderPageTestIdPrefix}-apply-connection-changes-button`}
                                            >
                                                {fm(messages.applyConnections)}
                                                <GraphqlRequestContainer
                                                    asyncData={applyConnectionsMutation.result}
                                                    loading={null}
                                                />
                                            </LoadingButton>
                                        </Tooltip>
                                    ) : null}
                                    {dataTable.hasPendingConnectionChanges && !hasPerformedConnectionChanges ? (
                                        <Tooltip title={fm(messages.applyPendingChangesTooltip)}>
                                            <LoadingButton
                                                size="small"
                                                variant="contained"
                                                color="success"
                                                onClick={handleIndexDataTableToElastic}
                                                loading={indexDataTableMutation.result.type === "loading"}
                                                data-testid={`${cubeBuilderPageTestIdPrefix}-apply-pending-changes-button`}
                                            >
                                                {fm(messages.applyPendingChanges)}
                                                <GraphqlRequestContainer
                                                    asyncData={indexDataTableMutation.result}
                                                    loading={null}
                                                />
                                            </LoadingButton>
                                        </Tooltip>
                                    ) : null}
                                    <Button size="small" variant="text" onClick={() => setOpen(true)} endIcon={<Add />}>
                                        {fm(messages.addConnection)}
                                    </Button>
                                </Stack>

                                {dataTable && (
                                    <GraphqlRequestContainer
                                        asyncData={connections}
                                        loading={<Skeleton width="100%" height="800px" />}
                                    >
                                        {(connectionResponse) => (
                                            <CubeDetailPage
                                                baseTable={dataTable}
                                                baseTableColumns={columns}
                                                dataTables={response}
                                                connections={connectionResponse.dataTableConnections.filter(
                                                    (connection) => connection.dataTableId === dataTable.id
                                                )}
                                                createConnectionModalOpen={open}
                                                setCreateConnectionModalOpen={setOpen}
                                                selectableCubeConfigurationFields={fieldsResponse.elasticFields}
                                            />
                                        )}
                                    </GraphqlRequestContainer>
                                )}
                            </>
                        )}
                    </GraphqlRequestContainer>
                )}
            </GraphqlRequestContainer>
        </Stack>
    );
};

export default CubeBuilderPage;
