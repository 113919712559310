import {
    FilterContextProvider,
    FilterModal,
    useCompanyFavouriteFilters,
    useFilters,
    usePersonalFavouriteFilters,
    useUpsertPersonalFavouriteFilters,
} from "@ignite-analytics/filters";
import { isNotNullish } from "@ignite-analytics/general-tools";
import { GraphqlRequestContainer } from "@ignite-analytics/graphql-utilities";
import { track } from "@ignite-analytics/track";
import { Add } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Autocomplete, Button, Container, Stack, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { fm } from "@/contexts/IntlContext";
import { DataTable, useCreateAggregatedViewMutation } from "@/generated/client";
import {
    mapDataTableToFilterDataSource,
    mapFromUnpopulatedFrontendFiltersToAnalysisFilter,
    useElasticFieldsForFilterContextProvider,
} from "@/helpers";
import EditAggregationConfiguration from "./AggregationConfigurationView";
import { AggregationConfiguration, SelectableField } from "./AggregationConfigurationView/types";
import { createEmptyAggregationConfiguration, isPossibleForGrouping } from "./helpers";
import messages from "./messages";

interface Props {
    dataTableId: string;
    dataTable: DataTable;
    selectableFields: SelectableField[];
    userId: string;
    onCancelClick: () => void;
}
const CreateAggregatedView: React.FC<Props> = ({ userId, selectableFields, onCancelClick, dataTableId, dataTable }) => {
    const [createAggregatedView, createAggregatedViewMutation] = useCreateAggregatedViewMutation();

    const filterDataSource = mapDataTableToFilterDataSource(dataTable);

    const [name, setName] = useState("");
    const [groupingFields, setGroupingFields] = useState<SelectableField[]>([]);
    const [aggregations, setAggregations] = useState<AggregationConfiguration[]>(
        [createEmptyAggregationConfiguration(selectableFields)].filter(isNotNullish)
    );

    const usePersonalFavouriteFiltersForContext = () =>
        usePersonalFavouriteFilters(userId, process.env.REACT_APP_GRAPHQL_ROUTER_URL as string);
    const useCompanyFavouriteFiltersForContext = () =>
        useCompanyFavouriteFilters(process.env.REACT_APP_GRAPHQL_ROUTER_URL as string);

    const upsertPersonalFavouriteFilters = useUpsertPersonalFavouriteFilters(
        userId,
        process.env.REACT_APP_GRAPHQL_ROUTER_URL as string
    );

    const handleAddColumnClick = () => {
        const newAggregationConfiguration = createEmptyAggregationConfiguration(selectableFields);
        if (newAggregationConfiguration) {
            setAggregations((prev) => prev.concat(newAggregationConfiguration));
        }
    };

    const [nameValidationError, setNameValidationError] = useState<string | undefined>(undefined);
    useEffect(() => {
        setNameValidationError(undefined);
    }, [name]);

    const [groupFieldsValidationError, setGroupFieldsValidationError] = useState<string | undefined>(undefined);
    useEffect(() => {
        setGroupFieldsValidationError(undefined);
    }, [groupingFields]);

    const filters = useFilters();

    const handleSubmit = async () => {
        if (!name || groupingFields.length === 0) {
            return;
        }
        await createAggregatedView({
            input: {
                name,
                groupByFields: groupingFields.map((groupingField) => groupingField.field),
                aggregations: aggregations.map(({ id, ...withoutId }) => ({
                    ...withoutId,
                    filters:
                        withoutId.filters
                            ?.map(mapFromUnpopulatedFrontendFiltersToAnalysisFilter)
                            .filter(isNotNullish) ?? [],
                })),
                baseDataTableId: dataTableId,
                filters: filters?.map(mapFromUnpopulatedFrontendFiltersToAnalysisFilter).filter(isNotNullish) ?? [],
            },
        });
        track("Data table view: Created Aggregated table");
        onCancelClick();
    };

    return (
        <Container>
            <Stack direction="column" gap={2}>
                <TextField
                    autoFocus
                    label={fm(messages.nameLabel)}
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    helperText={nameValidationError}
                    error={!!nameValidationError}
                    onBlur={() => {
                        if (!name) setNameValidationError(fm(messages.missingNameValidationErrorText).toString());
                    }}
                />
                <Stack direction="column">
                    <Typography variant="subtitle1">{fm(messages.groupingFieldsHeaderText)}</Typography>
                    <Stack alignItems="center" direction="row" gap={2}>
                        <Autocomplete
                            fullWidth
                            value={groupingFields}
                            onChange={(_, value) => setGroupingFields(value)}
                            multiple
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    onBlur={() => {
                                        if (groupingFields.length === 0)
                                            setGroupFieldsValidationError(
                                                fm(messages.noGroupFieldsValdiationError).toString()
                                            );
                                    }}
                                    inputProps={{ ...params.inputProps }}
                                    helperText={groupFieldsValidationError}
                                    error={!!groupFieldsValidationError}
                                    label={fm(messages.groupingFieldsSelectLabel)}
                                />
                            )}
                            options={selectableFields.filter(isPossibleForGrouping)}
                        />
                        <FilterModal
                            title={fm(messages.groupingFieldsSelectLabel).toString()}
                            placement="CreateAggregatedView"
                        />
                    </Stack>
                </Stack>
                <Stack direction="column" gap={1}>
                    <Typography variant="subtitle1">{fm(messages.aggregatedColumnsHeaderText)}</Typography>
                    {aggregations.map((aggregation) => (
                        <FilterContextProvider
                            inherit={false}
                            initialFilters={aggregation.filters}
                            key={aggregation.id}
                            dataSource={filterDataSource}
                            disableDefaultCombination
                            useDataFields={useElasticFieldsForFilterContextProvider}
                            // hooks for favourite filters
                            usePersonalFavouriteFilters={usePersonalFavouriteFiltersForContext}
                            upsertPersonalFavouriteFilters={upsertPersonalFavouriteFilters}
                            useCompanyFavouriteFilters={useCompanyFavouriteFiltersForContext}
                        >
                            <EditAggregationConfiguration
                                selectableFields={selectableFields}
                                aggregationConfiguration={aggregation}
                                onChange={(changed) =>
                                    setAggregations((prev) =>
                                        prev.map((prevAggregation) => {
                                            if (prevAggregation === aggregation) {
                                                return changed;
                                            }
                                            return prevAggregation;
                                        })
                                    )
                                }
                                onDeleteClick={() =>
                                    setAggregations((prev) =>
                                        prev.filter((prevAggregation) => prevAggregation !== aggregation)
                                    )
                                }
                            />
                        </FilterContextProvider>
                    ))}
                </Stack>
                <Stack direction="row">
                    <Button
                        onClick={handleAddColumnClick}
                        size="small"
                        color="secondary"
                        variant="text"
                        startIcon={<Add />}
                    >
                        {fm(messages.addAggregationConfigurationButtonText)}
                    </Button>
                </Stack>
            </Stack>
            <Stack justifyContent="end" direction="row" gap={1}>
                <Button onClick={onCancelClick} variant="text" color="secondary">
                    {fm(messages.cancelButtonText)}
                </Button>
                <LoadingButton
                    variant="contained"
                    loading={createAggregatedViewMutation.result.type === "loading"}
                    onClick={handleSubmit}
                >
                    {fm(messages.createAggregatedViewButtonText)}
                </LoadingButton>
            </Stack>
            <GraphqlRequestContainer loading={null} asyncData={createAggregatedViewMutation.result} />
        </Container>
    );
};

export default CreateAggregatedView;
