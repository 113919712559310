import { ElasticField } from "@ignite-analytics/elastic-fields";
import { DataColumn } from "@/Types/DataColumn";
import { DataColumnType } from "@/generated/client";

export function matchElasticFieldToColumn(dataColumnId: string, field: string): boolean {
    const hits = field.match(/data_column_[0-9]+/g);
    if (!hits || hits.length === 0) {
        return false;
    }
    const withoutPrefix = hits[0].replace("data_column_", "");
    const firstNumber = Number(withoutPrefix.split("_")[0]);
    return `${firstNumber}` === dataColumnId;
}

export const groupFieldsByColumn = (columns: DataColumn[], elasticFields: ElasticField[], searchTerm: string) => {
    const lowerCased = searchTerm.toLowerCase();
    const columnMap: Record<string, { fields: ElasticField[]; columnType: DataColumnType }> = {};
    columns.forEach((dc) => {
        const fieldsOfCOlumn = elasticFields.filter(
            (field) =>
                matchElasticFieldToColumn(dc.id, field.field) &&
                (dc.name.toLowerCase().includes(lowerCased) || field.label?.toLowerCase()?.includes(lowerCased))
        );
        if (fieldsOfCOlumn.length) columnMap[dc.id] = { fields: fieldsOfCOlumn, columnType: dc.dataType };
    });

    return columnMap;
};
