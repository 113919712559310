import { Container, Stack, Typography } from "@mui/material";
import Delete from "@mui/icons-material/Delete";
import React, { DragEventHandler, useState } from "react";
import { Upload } from "@mui/icons-material";
import { fm } from "@/contexts/IntlContext";
import messages from "./messages";
import { formatFileSize } from "./helpers";

interface Props {
    initialFile?: File;
    onFile: (file: File) => void;
    fileTypes: string[];
    onRemoveFile?: () => void;
    disabled?: boolean;
}

export const FileDropZone = ({ initialFile, onFile, fileTypes, onRemoveFile, disabled }: Props) => {
    const [file, setFile] = useState<File | undefined>(initialFile);
    const dropHandler: DragEventHandler = (ev) => {
        onFile(ev.dataTransfer.files[0]);
        setFile(ev.dataTransfer.files[0]);
        ev.preventDefault();
    };

    const dragOverHandler: DragEventHandler = (ev) => {
        // Prevent default behavior (Prevent file from being opened)
        ev.preventDefault();
    };

    const handleRemoveFile = () => {
        setFile(undefined);
        onRemoveFile?.();
    };

    return (
        <Stack justifyContent="center" textAlign="center">
            {!file && (
                <Stack
                    justifyContent="center"
                    textAlign="center"
                    sx={{
                        width: "100%",
                        height: "400px",
                        border: "2px dashed",
                        borderRadius: "10px",
                        borderColor: "#848080",
                        backgroundColor: disabled ? "#F6F6F7" : "white",
                        cursor: disabled ? "default" : "pointer",
                    }}
                    id="drop_zone"
                    onDrop={dropHandler}
                    onDragOver={dragOverHandler}
                    onClick={() => {
                        document.getElementById("fileInputField")?.click();
                    }}
                >
                    <Stack direction="row" justifyContent="center">
                        <Upload />
                    </Stack>
                    <Typography variant="subtitle1">{fm(messages.prompt)}</Typography>
                    <Typography>{fm(messages.browsePrompt)}</Typography>
                    <Typography color="tertiary">{`${fileTypes.join(", ")}...`}</Typography>
                    <Container sx={{ opacity: 0, cursor: disabled ? "default" : "pointer" }}>
                        <input
                            disabled={!!disabled}
                            type="file"
                            id="fileInputField"
                            onChange={(ev) => {
                                if (ev.target.files?.length) {
                                    setFile(ev.target.files[0]);
                                    onFile(ev.target.files[0]);
                                }
                            }}
                        />
                    </Container>
                </Stack>
            )}
            {file && (
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    width="320px"
                    gap={1}
                    padding={1}
                    sx={{
                        backgroundColor: (theme) => theme.palette.action.hover,
                        borderRadius: (theme) => theme.shape.borderRadius,
                    }}
                >
                    <Stack direction="row" gap={2} alignItems="center">
                        <Typography color="primary">{file.name}</Typography>
                        <Typography variant="caption">{formatFileSize(file.size)}</Typography>
                    </Stack>

                    <Delete sx={{ cursor: "pointer" }} onClick={handleRemoveFile} />
                </Stack>
            )}
        </Stack>
    );
};
