import { Dialog, DialogContent } from "@mui/material";
import React from "react";
import { RenameTable } from "@/components/RenameTable";
import { DataTable } from "@/generated/client";

interface Props {
    dataTable: DataTable;
    open: boolean;
    onAction: () => void;
}
export const RenameDataTableModal: React.FC<Props> = ({ open, onAction, dataTable }) => (
        <Dialog open={open} onClose={onAction}>
            <DialogContent>
                <RenameTable dataTable={dataTable} onUpdate={onAction} />
            </DialogContent>
        </Dialog>
    );
