import React from "react";
import ButtonGroup from "@mui/material/ButtonGroup";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";

import EditIcon from "@mui/icons-material/Edit";
import ClearIcon from "@mui/icons-material/Clear";
import CheckIcon from "@mui/icons-material/Check";
import { testIdPrefix } from "@/components/testIdprefix";
import { fm } from "@/contexts/IntlContext";
import messages from "../messages";

interface Props {
    editState: boolean;
    setEditState: (previousValue: boolean) => void;
    handleSaveColumn: () => void;
}

export const EditButtons: React.FC<Props> = ({ editState, setEditState, handleSaveColumn }) => (
        <Stack direction="row" alignItems="flex-end" justifyContent="flex-end">
            <ButtonGroup style={{ padding: 1 }}>
                {editState && (
                    <IconButton
                        style={{ padding: 1 }}
                        data-testid={`${testIdPrefix}-rename-data-column`}
                        onClick={handleSaveColumn}
                    >
                        <CheckIcon />
                    </IconButton>
                )}
                <Tooltip title={editState ? fm(messages.discardChanges) : fm(messages.editColumnName)} arrow={false}>
                    <IconButton style={{ padding: 1 }} onClick={() => setEditState(!editState)}>
                        {editState ? <ClearIcon /> : <EditIcon />}
                    </IconButton>
                </Tooltip>
            </ButtonGroup>
        </Stack>
    );
