import React from "react";
import { DataColumnType } from "@/generated/client";
import { DataColumn } from "@/Types/DataColumn";
import { CreateAggregatedColumn } from "./Aggregated";
import { CreateDefaultColumn } from "./Default";
import { CreateGroupStructureColumn } from "./GroupStructure";

import { CreateTableRelationColumn } from "./TableRelation";
import { CreateTagColumn } from "./Tag";

interface Props {
    dataTableId: string;
    onDataColumnCreated?: (newColumn?: DataColumn) => void;
    dataType: DataColumnType;
    name?: string;
    isList: boolean;
}

export const CreateDataColumn: React.FC<Props> = ({ dataTableId, onDataColumnCreated, name, isList, dataType }) => {
    if (dataType === "TABLE_RELATION") {
        return (
            <CreateTableRelationColumn
                name={name}
                dataTableId={dataTableId}
                onDataColumnCreated={onDataColumnCreated}
                isList={isList}
            />
        );
    }
    if (dataType === "TAG") {
        return (
            <CreateTagColumn
                name={name}
                dataTableId={dataTableId}
                onDataColumnCreated={onDataColumnCreated}
                isList={isList}
            />
        );
    }
    if (dataType === "GROUP_STRUCTURE") {
        return (
            <CreateGroupStructureColumn
                name={name}
                dataTableId={dataTableId}
                onDataColumnCreated={onDataColumnCreated}
                isList={isList}
            />
        );
    }
    if (dataType === "AGGREGATION") {
        return (
            <CreateAggregatedColumn
                name={name}
                dataTableId={dataTableId}
                onDataColumnCreated={onDataColumnCreated}
                isList={isList}
            />
        );
    }
    return (
        <CreateDefaultColumn
            name={name}
            dataTableId={dataTableId}
            onDataColumnCreated={onDataColumnCreated}
            isList={isList}
            dataType={dataType}
        />
    );
};
