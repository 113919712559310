import React from "react";
import { GlobalColumnType } from "@ignite-analytics/global-types";
import { Autocomplete, TextField } from "@mui/material";
import { fm } from "@/contexts/IntlContext";
import messages from "../messages";

interface Props {
    relevantGlobalColumnTypes: GlobalColumnType[];
    selectedGlobalType?: string;
    onValueChange: (globalTypeKey: string | null) => void;
    isDisabled?: boolean;
}

export const GlobalColumnSelector = ({
    relevantGlobalColumnTypes,
    selectedGlobalType,
    onValueChange,
    isDisabled,
}: Props) => (
        <Autocomplete
            disabled={isDisabled}
            style={{ paddingTop: 0, paddingBottom: 0 }}
            fullWidth
            value={selectedGlobalType}
            options={relevantGlobalColumnTypes.map((globalType) => globalType.key)}
            getOptionLabel={(option) => relevantGlobalColumnTypes.find((globalType) => globalType.key === option)?.name ?? ""}
            renderInput={(params) => (
                <TextField {...params} fullWidth size="small" placeholder={fm(messages.globalType).toString()} />
            )}
            onChange={(_, value) => {
                onValueChange(value);
            }}
        />
    )
