import { fm } from "@/contexts/IntlContext";
import { DataPipelineOperationInputOutput, InputOutputConnection } from "@/generated/client";
import messages from "./messages";

interface ParsedPipelineExecutionRow {
    key: unknown;
    name: string;
    sourceLabel: string;
    inputs: unknown[][];
    operationInputs: Record<string, unknown>;
    outputs: unknown[];
}

export const findOperationInputValue: (
    operationInputNode: DataPipelineOperationInputOutput,
    inputOutputConnections: InputOutputConnection[],
    row: ParsedPipelineExecutionRow
) => string = (operationInputNode, inputOutputConnections, row) => {
    const correspondingIONode = inputOutputConnections.find(
        (inputOutputConnection) => inputOutputConnection.to.referencedId === operationInputNode.id
    );
    const inputValueFromOperation = Object.keys(row.operationInputs).find(
        (rowInput) => correspondingIONode?.from.referencedId === rowInput
    );
    if (inputValueFromOperation) return `${row.operationInputs[inputValueFromOperation]}`;

    const inputValueFromRepositoryField = row.inputs.find(
        ([, , fieldId]) => correspondingIONode?.from.referencedId === fieldId
    );
    if (inputValueFromRepositoryField) return `${inputValueFromRepositoryField[1]}`;
    return fm(messages.missing).toString();
};
