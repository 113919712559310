import { Refresh } from "@mui/icons-material";
import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Tooltip } from "@mui/material";
import React, { useState } from "react";
import { GraphqlRequestContainer } from "@ignite-analytics/graphql-utilities";
import { LoadingButton } from "@mui/lab";
import { useStartAggregatedViewMutation } from "@/generated/client";
import { fm } from "@/contexts/IntlContext";
import globalMessages from "@/messages";
import messages from "./messages";

interface Props {
    aggregatedViewId: string;
}
const RefreshAggregatedView: React.FC<Props> = ({ aggregatedViewId }) => {
    const [startAggregatedView, result] = useStartAggregatedViewMutation();
    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleRefreshClick = () => {
        startAggregatedView({
            input: {
                id: aggregatedViewId,
            },
        }).then(() => {
            setIsModalOpen(false);
        });
    };

    return (
        <>
            <Tooltip title={fm(messages.refreshAggregatedViewTooltipText)}>
                <IconButton>
                    <Refresh onClick={() => setIsModalOpen(true)} />
                </IconButton>
            </Tooltip>
            <Dialog open={isModalOpen} onClose={() => setIsModalOpen(false)}>
                <DialogTitle>{fm(messages.confirmRefreshTitle)}</DialogTitle>
                <DialogContent>{fm(messages.confirmRefreshContent)}</DialogContent>
                <DialogActions>
                    <LoadingButton onClick={handleRefreshClick} loading={result.result.type === "loading"}>
                        {fm(globalMessages.submitButton)}
                    </LoadingButton>
                </DialogActions>
                <GraphqlRequestContainer loading={null} asyncData={result.result} />
            </Dialog>
        </>
    );
};

export default RefreshAggregatedView;
