import { GlobalTableType } from "@ignite-analytics/global-types";
import { createDetailResource, createEntityContext, createListResource } from "@ignite-analytics/entities";

const listCreateResource = createListResource<GlobalTableType>`/global-types/tables/`;
const detailResource = createDetailResource<GlobalTableType>`/global-types/tables/${(params) => params.id}/`;

export const { Provider: GlobalTableTypeContextProvider, useAll: useAllGlobalTableTypes } = createEntityContext(
    listCreateResource,
    detailResource,
    {
        interchangableTypes: true,
        name: "GlobalTableType fields",
    }
);
