import { NodeInformation, OPERATION_NODE_HEIGHT } from "../Nodes/constants";

export const createDragElement = (e: React.DragEvent<HTMLDivElement>, content: NodeInformation, key: string) => {
    e.dataTransfer.setData("menuDropItemKey", key);
    const ghostEle = document.createElement("div");
    ghostEle.id = "dragElement";

    // ghostEle.classList.add("dragging");
    ghostEle.style.backgroundColor = content.color || "primary";
    ghostEle.style.width = `220px`;
    ghostEle.draggable = true;
    ghostEle.style.left = "-50%";
    ghostEle.style.top = "-50%";
    ghostEle.style.height = `${OPERATION_NODE_HEIGHT}px`;
    ghostEle.style.display = "flex";
    ghostEle.style.alignItems = "center";
    ghostEle.style.transition = "opacity 2s ease-in";
    ghostEle.style.cursor = "grab";
    ghostEle.style.whiteSpace = "nowrap";
    ghostEle.style.justifyItems = "center";
    ghostEle.style.paddingRight = "10px";
    ghostEle.style.paddingLeft = "10px";
    ghostEle.style.borderRadius = "8px";
    ghostEle.style.position= "absolute";

    ghostEle.innerHTML = e.currentTarget.innerHTML;
    document.body.appendChild(ghostEle);
    e.dataTransfer.setDragImage(ghostEle, 0, 0);
};
