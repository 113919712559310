import { defineMessages } from "react-intl";

const messages = defineMessages({
    nameInputLabel: {
        id: "uploadPage.createDataTableForm.nameInputLabel",
        defaultMessage: "Data table name",
    },
    globalTypeKey: {
        id: "uploadPage.createDataTableForm.globalTypeKey",
        defaultMessage: "Global type key",
        description: "label",
    },
});

export default messages;
