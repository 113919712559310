import { defineMessages } from "react-intl";

const messages = defineMessages({
    header: {
        id: "uploadPage.createOperationButton.getOrCreateOperationForm.CreateRepositoryForm.header",
        defaultMessage: "New data repository",
    },
    fieldsHeader: {
        id: "uploadPage.createOperationButton.getOrCreateOperationForm.CreateRepositoryForm.fieldsHeader",
        defaultMessage: "Please add fields you want in the new repostiory",
    },
    nameLabel: {
        id: "uploadPage.createOperationButton.getOrCreateOperationForm.CreateRepositoryForm.nameLabel",
        defaultMessage: "Name",
    },
    uniqueLabel: {
        id: "uploadPage.createOperationButton.getOrCreateOperationForm.CreateRepositoryForm.uniqueLabel",
        defaultMessage: "Unique identifier field",
    },
    create: {
        id: "uploadPage.createOperationButton.getOrCreateOperationForm.CreateRepositoryForm.create",
        defaultMessage: "Create",
    },
});

export default messages;
