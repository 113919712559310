import { track } from "@ignite-analytics/track";
import {
    Autocomplete,
    Button,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormHelperText,
    Stack,
    Switch,
    TextField,
} from "@mui/material";
import React, { useState } from "react";
import { fm } from "@/contexts/IntlContext";
import { OperationType } from "@/generated/client";
import globalMessages from "@/messages";
import messages from "./messages";
import { ObjectSpec } from "./types";

interface ObjectFormProps {
    objectSpec: ObjectSpec;
    mapFormvaluesToMutationInput: (data: FormData) => void;
    operationType: OperationType;
}
export interface FormData {
    [key: string]: string | number | boolean;
}

const ObjectForm: React.FC<ObjectFormProps> = ({ objectSpec, mapFormvaluesToMutationInput, operationType }) => {
    const [formData, setFormData] = useState<FormData>({});
    const [missingValues, setMissingValues] = useState<{ [key: string]: boolean }>({});

    const handleChange = (event: { name: string; value: string | number | boolean }) => {
        const { name, value } = event;
        setFormData({ ...formData, [name]: value });
    };

    const getHelpText = (property: string) => {
        if ("explanation" in objectSpec[property]) return objectSpec[property].explanation?.toString();
        return undefined;
    };

    const validate = () => {
        const missing = Object.keys(objectSpec).reduce((cum, currentProperty) => {
            if (objectSpec[currentProperty].optional) return { ...cum, [currentProperty]: false };
            if (!(objectSpec[currentProperty].type === "boolean") && !formData[currentProperty])
                return { ...cum, [currentProperty]: true };
            return { ...cum, [currentProperty]: false };
        }, {});
        setMissingValues(missing);
        if (Object.values(missing).some((v) => v === true)) {
            return "error";
        }
        return "valid";
    };

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (validate() === "error") return;
        mapFormvaluesToMutationInput(formData);
        track("Pipeline: Created Pipeline Operation", {
            operationType,
        });
    };

    const formFields = Object.keys(objectSpec).map((property) => {
        if (objectSpec[property].hideFn?.(formData)) return null;
        if (objectSpec[property].type === "boolean") {
            return (
                <Stack direction="row" key={property} alignItems="center">
                    <FormControlLabel
                        control={
                            <Switch
                                checked={formData[property] === true}
                                onChange={(e) => handleChange({ name: e.target.name, value: e.target.checked })}
                                name={property}
                                color="primary"
                            />
                        }
                        label={objectSpec[property].label}
                        labelPlacement="start"
                    />
                    <FormHelperText>{getHelpText(property)}</FormHelperText>
                </Stack>
            );
        }

        const { allowedValues } = objectSpec[property];
        if (allowedValues) {
            return (
                <FormControl key={property}>
                    <Autocomplete
                        fullWidth
                        options={allowedValues}
                        onChange={(_, v) => v && handleChange({ value: v, name: property })}
                        getOptionLabel={(option) => option}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                error={missingValues[property]}
                                label={missingValues[property] ? fm(messages.required) : objectSpec[property].label}
                                placeholder={objectSpec[property].label.toString()}
                                helperText={getHelpText(property)}
                            />
                        )}
                    />
                </FormControl>
            );
        }

        return (
            <Stack key={property} direction="row" gap={1}>
                <TextField
                    error={missingValues[property]}
                    type={objectSpec[property].type}
                    name={property}
                    id={property}
                    label={missingValues[property] ? fm(messages.required) : objectSpec[property].label}
                    value={formData[property] || ""}
                    placeholder={objectSpec[property].label.toString()}
                    onChange={(e) => handleChange(e.target)}
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    helperText={getHelpText(property)}
                />
                {objectSpec[property].helperComponent}
            </Stack>
        );
    });

    return (
        <form onSubmit={handleSubmit}>
            <FormControl sx={{ width: "100%" }}>
                <FormGroup>{formFields}</FormGroup>
                <Button variant="contained" color="primary" type="submit">
                    {fm(globalMessages.submitButton)}
                </Button>
            </FormControl>
        </form>
    );
};

export default ObjectForm;
