import { Avatar, AvatarGroup, Tooltip } from "@mui/material";
import React from "react";
import { useUsers } from "@/hooks/useUsers";

interface Props {
    userIds: (string | undefined | null)[];
    disableTooltip?: boolean;
    size?: number;
}
export const UserList: React.FC<Props> = ({ userIds, disableTooltip, size }) => {
    const { users } = useUsers();
    return (
        <AvatarGroup max={3}>
            {users
                .filter((user) => userIds.find((id) => user.id === id))
                .map((user) => (
                    <Tooltip key={user.id} title={disableTooltip ? null : `${user.firstName} ${user.lastName}`}>
                        <Avatar
                            sx={{
                                width: size,
                                height: size,
                            }}
                            sizes="small"
                            alt={`${user.firstName} ${user.lastName}`}
                        />
                    </Tooltip>
                ))}
        </AvatarGroup>
    );
};
