import { GetDataTableElasticFieldsResponse } from "@/generated/client";
import { hasSubfieldValue } from "@/helpers";

type SelectableFieldType = "id" | "text" | "number" | "date" | "group" | "boolean";
export type SelectableField = {
    field: string;
    label: string;
    isSelectable: boolean;
    type?: SelectableFieldType;
};

function reformatGroupL1ToStructureLabel(groupl1Label: string): string {
    return `${groupl1Label.replace(" L1", "")}`;
}

function getFirstMatchGroupOfRegExp(value: string, regexp: RegExp): string | undefined {
    const result = regexp.exec(value);
    if (!result || result.length < 2) {
        return undefined;
    }
    return result[1];
}

function getTypeOfField(
    field: GetDataTableElasticFieldsResponse["elasticFields"][number]
): SelectableFieldType | undefined {
    if (field.labelField) {
        return "id";
    }
    if (field.type === "boolean") {
        return "boolean";
    }
    if (field.type === "date") {
        return "date";
    }
    if (["integer", "float"].includes(field.type)) {
        return "number";
    }
    if (["text", "keyword"].includes(field.type)) {
        return "text";
    }
    return undefined;
}
function isRelationField(field: GetDataTableElasticFieldsResponse["elasticFields"][number]) {
    if (getTypeOfField(field) !== "id") {
        return false;
    }
    if (!field.labelField) {
        return false;
    }
    return new RegExp(`${field.field}-data_column_[0-9]+`).test(field.labelField);
}

export function appendNumberToDuplicateLabels<T extends { label: string }>() {
    const labelCountMap = new Map<string, number>();
    return (option: T): T => {
        const count = labelCountMap.get(option.label) ?? 0;
        labelCountMap.set(option.label, count + 1);
        return {
            ...option,
            label: count > 0 ? `${option.label} (${count})` : option.label,
        };
    };
}

export function getSelectableFields(
    fields: GetDataTableElasticFieldsResponse["elasticFields"],
    dependingDataColumnIds: string[],
    selfDataColumnId?: string
): SelectableField[] {
    const groupFieldRegexp = new RegExp("data_column_[0-9]+_l[0-9]+_id");
    const groupFieldLevelExtractRegexp = new RegExp("data_column_[0-9]+_l([0-9]+)_id");
    const groupFields = fields
        .filter((field) => groupFieldRegexp.test(field.field))
        /* Filter out all but L1 fields */
        .filter((field) => Number(getFirstMatchGroupOfRegExp(field.field, groupFieldLevelExtractRegexp)) === 1)
        .map((field) => ({
            field: field.field,
            label: reformatGroupL1ToStructureLabel(field.label),
            type: "group" as const,
        }));
    const relationNameFields: SelectableField[] = fields
        .filter(isRelationField)
        .map((field) => ({
            field: field.labelField,
            isSelectable: true,
            type: "text" as const,
            label: field.label,
        }))
        .filter(hasSubfieldValue("field"));

    // sort happens at last state, no danger in mutation
    // eslint-disable-next-line fp/no-mutating-methods
    return fields
        .filter((field) => /^data_column_.*/.test(field.field))
        .map((field) => {
            if (groupFieldRegexp.test(field.field)) {
                if (field.labelField) {
                    return [{ field: field.labelField, label: field.label, type: "text" as SelectableFieldType }];
                }
            }
            return [{ ...field, type: getTypeOfField(field) }];
        })
        .flat()
        .filter((field) => !groupFieldRegexp.test(field.field))
        .filter((field) => {
            if (!selfDataColumnId) {
                return true;
            }
            const ids = (field.field.match(/data_column_[0-9]+/g) ?? []).map((f) => f.replace("data_column_", ""));
            return !ids.some((id) => id === selfDataColumnId);
        })
        .concat(groupFields)
        .map<SelectableField>((field) => {
            const isValidDependencies = dependingDataColumnIds.every((dependingDataColumnId) => {
                const ids = field.field.match(new RegExp(`data_column_[0-9]+`, "g")) ?? [];
                return !ids.some((id) => id.replace("data_column_", "") === dependingDataColumnId);
            });
            return {
                ...field,
                isSelectable: isValidDependencies,
            };
        })
        .concat(relationNameFields)
        .map(appendNumberToDuplicateLabels())
        .sort((a, b) => a.label.localeCompare(b.label));
}
