import { ElasticField } from "@ignite-analytics/elastic-fields";
import { Checkbox, Divider, List, ListItem, Stack, Typography } from "@mui/material";
import React from "react";
import { DataColumn } from "@/Types/DataColumn";
import { TYPE_ICONS } from "@/components/DataTypeIcons";
import { fm } from "@/contexts/IntlContext";
import { DataColumnType } from "@/generated/client";
import messages from "./messages";

interface Props {
    handleSelect: (fields: string[], checked: boolean) => void;
    handleSelectAll: (value: boolean) => void;
    selected: string[];
    columns: DataColumn[];
    orderedFields: Record<string, { fields: ElasticField[]; columnType: DataColumnType }>;
}

export const FieldSelectionList: React.FC<Props> = ({
    handleSelect,
    handleSelectAll,
    selected,
    columns,
    orderedFields,
}) => (
        <List disablePadding>
            <ListItem disablePadding>
                <Stack direction="row" alignItems="center" gap={2}>
                    <Checkbox onChange={(_e, v) => handleSelectAll(v)} />
                    <Typography variant="subtitle2">{fm(messages.selectAll)}</Typography>
                </Stack>
            </ListItem>
            <Divider />
            {Object.entries(orderedFields)
                .sort((a, b) => a[1].fields.length - b[1].fields.length)
                .map(([k, v]) => {
                    if (!v.fields.length) return null;
                    if (v.fields.length > 1)
                        return (
                            <Stack key={k} paddingTop={3}>
                                <Stack direction="row" gap={1} alignItems="center">
                                    <Checkbox
                                        checked={v.fields.every((f) => selected.includes(f.field))}
                                        onChange={(_e, checked) =>
                                            handleSelect(
                                                v.fields.map((f) => f.field),
                                                checked
                                            )
                                        }
                                    />
                                    <Stack
                                        alignItems="center"
                                        width="100%"
                                        direction="row"
                                        justifyContent="space-between"
                                    >
                                        <Typography variant="subtitle2">
                                            {columns.find((dc) => dc.id === k)?.name}
                                        </Typography>
                                        <Stack>{TYPE_ICONS[v.columnType]}</Stack>
                                    </Stack>
                                </Stack>
                                <Divider />
                                {v.fields.map((field) => (
                                        <ListItem
                                            sx={{ cursor: "pointer" }}
                                            key={field.field}
                                            disablePadding
                                            onClick={() => handleSelect([field.field], !selected.includes(field.field))}
                                        >
                                            <Stack paddingLeft={5} direction="row" alignItems="center" gap={2}>
                                                <Checkbox checked={selected.includes(field.field)} />

                                                <Typography>{field.label}</Typography>
                                            </Stack>
                                        </ListItem>
                                    ))}
                            </Stack>
                        );

                    return (
                        <ListItem
                            disablePadding
                            onClick={() => handleSelect([v.fields[0].field], !selected.includes(v.fields[0]?.field))}
                            key={v.fields[0]?.field}
                        >
                            <Stack direction="row" alignItems="center" gap={1}>
                                <Checkbox checked={selected.includes(v.fields[0]?.field)} />
                                <Typography>{v.fields[0]?.label}</Typography>
                            </Stack>
                        </ListItem>
                    );
                })}
        </List>
    );
