import { defineMessages } from "react-intl";

const messages = defineMessages({
    changingType: {
        id: "uploadPage.dataRepositoryPage.changeDataType.changingType",
        defaultMessage: "Replacing column with new column of type {newDataType}",
    },
    description: {
        id: "uploadPage.dataRepositoryPage.changeDataType.description",
        defaultMessage: "Changing data type can be a time consuming process. Proceed?",
    },
    supplierTable: {
        id: "uploadPage.dataRepositoryPage.changeDataType.supplierTable",
        defaultMessage: "Not allowed to edit column types on supplier table",
    },
    notPrimitive: {
        id: "uploadPage.dataRepositoryPage.changeDataType.notPrimitive",
        defaultMessage:
            "You can only modify the data types of columns that are of primitive types such as text, number, date, or boolean.",
    },
});

export default messages;
