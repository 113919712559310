import { formatDatetime } from "@ignite-analytics/locale2";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import {
    Box,
    Checkbox,
    Collapse,
    IconButton,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
} from "@mui/material";
import React, { useState } from "react";
import { UserList } from "@/components/User/UserList";
import { fm } from "@/contexts/IntlContext";
import { Change } from "../../../ApplyChangesButtons/helpers";
import messages from "../../../ApplyChangesButtons/messages";

interface Props {
    onSelect: () => void;
    pipelineId: string;
    selectedPipelineIds: string[];
    changes: Change[];
}

export const ChangeRow: React.FC<Props> = ({ onSelect, pipelineId, changes, selectedPipelineIds }) => {
    const checked = selectedPipelineIds.includes(pipelineId);
    const [open, setOpen] = useState(false);
    return (
        <>
            <TableRow sx={{ "& > *": { borderBottom: "unset" } }} selected={checked}>
                <TableCell padding="checkbox">
                    <Checkbox checked={checked} onChange={onSelect} />
                </TableCell>
                <TableCell onClick={() => setOpen(!open)}>
                    <Stack direction="row" justifyContent="space-between" alignItems="center">
                        <Typography>{changes[0]?.pipelineInfo.sourceName}</Typography>
                        <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                            {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                        </IconButton>
                    </Stack>
                </TableCell>
            </TableRow>
            <TableRow hover={false}>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box sx={{ margin: 1 }}>
                            <Table size="small" aria-label="purchases" sx={{ border: 0 }}>
                                <TableHead>
                                    <TableRow hover={false}>
                                        <TableCell width="60%">
                                            <Typography>{fm(messages.description)}</Typography>
                                        </TableCell>
                                        <TableCell width="30%">
                                            <Typography>{fm(messages.date)}</Typography>
                                        </TableCell>
                                        <TableCell width="10%">
                                            <Typography>{fm(messages.user)}</Typography>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {changes
                                        .sort(
                                            (a, b) => new Date(b.createdAt).valueOf() - new Date(a.createdAt).valueOf()
                                        )
                                        .map((change) => (
                                            <TableRow key={change.id} hover={false}>
                                                <TableCell component="th" scope="row">
                                                    <Stack direction="row" alignItems="center" gap={1}>
                                                        {change.description.icon}
                                                        <Typography>{change.description.text}</Typography>
                                                    </Stack>
                                                </TableCell>
                                                <TableCell component="th" scope="row">
                                                    <Typography>{formatDatetime(change.createdAt)}</Typography>
                                                </TableCell>
                                                <TableCell>
                                                    <Stack direction="row" justifyContent="center">
                                                        <UserList size={24} userIds={[change.userId]} />
                                                    </Stack>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    );
};
