import React from "react";
import { Card, Stack } from "@mui/material";

interface NodeProps {
    color: string;
    iconComponent?: React.ReactNode;
}


export const TinyIcon = ({ color, iconComponent }: NodeProps) => (
    <Card
        sx={{
            borderRadius: "3px",
            backgroundColor: color,
            minWidth: "30px",
            maxWidth: "30px",
        }}
    >
        <Stack padding={0.2} justifyContent="center" alignItems="center">
            {iconComponent}
        </Stack>
    </Card>
);
