import { DataColumnType, OperationType } from "@/generated/client";
import messages from "./messages";

export function createColumnTypeMessages(): Record<
    Exclude<DataColumnType, "DATE_AGGREGATION">,
    { labelMessage: { id: string; defaultMessage: string } }
> {
    return {
        TEXT: {
            labelMessage: messages.textLabel,
        },
        NUMBER: {
            labelMessage: messages.numberLabel,
        },
        DATE: {
            labelMessage: messages.dateLabel,
        },
        MONETARY_AMOUNT: {
            labelMessage: messages.monetaryAmountLabel,
        },
        GROUP_STRUCTURE: {
            labelMessage: messages.groupStructureLabel,
        },
        TABLE_RELATION: {
            labelMessage: messages.tableRelationLabel,
        },
        COMPANY: {
            labelMessage: messages.companyLabel,
        },
        USER: {
            labelMessage: messages.userLabel,
        },
        CONTACT: {
            labelMessage: messages.contactLabel,
        },
        FILE: {
            labelMessage: messages.fileLabel,
        },
        BOOLEAN: {
            labelMessage: messages.booleanLabel,
        },
        TAG: {
            labelMessage: messages.tagLabel,
        },
        AGGREGATION: {
            labelMessage: messages.aggregationLabel,
        },
        EMISSIONS: {
            labelMessage: messages.emissionsLabel,
        },
    };
}

export function createPrimitiveColumnTypeMessages(): DataColumnType[] {
    return ["TEXT", "NUMBER", "DATE"];
}

export const getRelevantTypes: (operationType: OperationType) => string[] = (operationType): DataColumnType[] => {
    const specialColumns: DataColumnType[] = ["COMPANY", "MONETARY_AMOUNT", "TABLE_RELATION"];
    const types = Object.keys(createColumnTypeMessages()) as (keyof ReturnType<typeof createColumnTypeMessages>)[];
    switch (operationType) {
        case "MONETARY_AMOUNT": {
            return ["MONETARY_AMOUNT"];
        }
        case "MATCH_COMPANY": {
            return ["COMPANY"];
        }
        case "GET_OR_CREATE": {
            return ["TABLE_RELATION"];
        }
    }
    return types.filter((type) => !specialColumns.includes(type));
};
