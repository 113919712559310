import { defineMessages } from "react-intl";

export default defineMessages({
    applySelectedChanges: {
        id: "dataTables.applyChanges.applySelectedChanges",
        defaultMessage: "Run selected pipelines",
    },
    unAppliedChangesTitle: {
        id: "dataTables.applyChanges.unAppliedChangesTitle",
        defaultMessage: "Unapplied changes",
    },
    unAppliedChangesDescription: {
        id: "dataTables.applyChanges.unAppliedChangesDescription",
        defaultMessage:
            "This is a list of the changes made to your data flows that are not yet applied to the data. You can run a single data flow or select multiple to run at the same time.",
    },
    applyStarted: {
        id: "dataTables.applyChanges.applyStarted",
        defaultMessage:
            "Successfully started applying changes. Follow the process in the notifications center in the upper right corner",
    },
});
