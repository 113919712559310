import { ElasticField, useElasticFields } from "@ignite-analytics/elastic-fields";
import { DateOptionPeriod, Filter, UnpopulatedFilter } from "@ignite-analytics/filters";
import { capitalize } from "lodash-es";
import { DataColumn } from "@/Types/DataColumn";
import { fm } from "@/contexts/IntlContext";
import { AnalysisFilterInput, DataTable } from "@/generated/client";
import messages from "./messages";

export function cleanDataColumnName(name: string) {
    return name.replace(/_/g, " ");
}

export function formatDataColumnType(dataColumn: DataColumn) {
    const name = capitalize(dataColumn.dataType.replace("_", " "));
    return dataColumn.isList ? `${name} ${fm(messages.list).toString()}` : name;
}

export function hasSubfieldValue<T extends { [field in Field]?: T[field] }, Field extends keyof T>(subfield: Field) {
    return (item: T): item is T & { [field in Field]: NonNullable<T[field]> } => {
        const { [subfield]: field } = item;
        return field !== undefined;
    };
}

/**
 * Removes Error Code from message
 * Example:
 * 3 INVALID ARGUMENT: Invalid deletion, table is used as base for aggregated view.
 * -> Invalid deletion, table is used as base for aggregated view.
 */
export function formatGrpcErrorMessage(message: string) {
    return message.split(":").slice(1).join(": ");
}

export const useElasticFieldsForFilterContextProvider = (
    indexNames: string[] | undefined
): ElasticField[] | undefined => useElasticFields(indexNames, true);

export const mapDataTableToFilterDataSource = (dataTable?: DataTable) =>
    dataTable
        ? {
              name: dataTable.elasticIndex,
              label: dataTable.name,
              globalTypeKey: typeof dataTable.globalTypeKey === "string" ? dataTable.globalTypeKey : undefined,
          }
        : undefined;

const base = {
    exclude: [],
    include: [],
    max: null,
    min: null,
    offset: null,
    periodUnit: null,
    periodLength: null,
    start: null,
    end: null,
    field: "",
    exists: null,
    boolean: null,
};

export function mapFromFrontendFiltersToAnalysisFilter(filter: Filter): AnalysisFilterInput | undefined {
    switch (filter.filterType) {
        case "datefilter":
            return {
                ...base,
                field: filter.field,
                filterType: filter.filterType,
                start: filter.start,
                end: filter.end,
            };
        case "relativedatefilter":
            return {
                ...base,
                field: filter.field,
                filterType: filter.filterType,
                offset: filter.offset,
                periodUnit: filter.periodUnit,
                periodLength: filter.periodLength,
                start: filter.start,
                end: filter.end,
            };
        case "rangefilter":
            return {
                ...base,
                field: filter.field,
                filterType: filter.filterType,
                min: filter.min,
                max: filter.max,
            };
        case "includefilter":
            return {
                ...base,
                field: filter.field,
                filterType: filter.filterType,
                include: filter.include,
                includeBlanks: filter.includeBlanks,
            };
        case "excludefilter":
            return {
                ...base,
                field: filter.field,
                filterType: filter.filterType,
                exclude: filter.exclude,
                excludeBlanks: filter.excludeBlanks,
            };
        case "searchtermfilter":
            return {
                ...base,
                field: filter.field,
                filterType: filter.filterType,
                searchTerm: filter.searchTerm,
            };
        case "existsfilter":
            return {
                ...base,
                field: filter.field,
                filterType: filter.filterType,
                exists: filter.exists,
            };
        case "booleanfilter":
            return {
                ...base,
                field: filter.field,
                filterType: filter.filterType,
                boolean: filter.value,
            };
        default:
            throw new Error(`Unsupported filter type - ${filter.filterType}`);
    }
}

export function mapFromUnpopulatedFrontendFiltersToAnalysisFilter(
    filter: UnpopulatedFilter
): AnalysisFilterInput | undefined {
    if (filter.filterType === "relativedatefilter") {
        return {
            ...base,
            field: filter.field,
            filterType: filter.filterType,
            offset: filter.offset,
            periodUnit: filter.periodUnit,
            periodLength: filter.periodLength,
        };
    }
    return mapFromFrontendFiltersToAnalysisFilter(filter);
}

export function mapFromAnalysisFilterToFrontendFilters(filter: AnalysisFilterInput): UnpopulatedFilter | undefined {
    switch (filter.filterType) {
        case "datefilter":
            if (!filter.end || !filter.start || !filter.offset) return undefined;
            return {
                type: "date",
                filterType: "datefilter",
                start: filter.start,
                end: filter.end,
                fieldId: filter.field,
                field: filter.field,
            };
        case "relativedatefilter":
            if (!filter.periodUnit || !filter.periodLength || filter.offset === undefined || filter.offset === null)
                return undefined;
            return {
                field: filter.field,
                type: "date",
                filterType: "relativedatefilter",
                fieldId: filter.field,
                periodLength: filter.periodLength,
                periodUnit: filter.periodUnit as DateOptionPeriod,
                offset: filter.offset,
            };
        case "rangefilter":
            if (!filter.filterType || !filter.min || !filter.max) return undefined;
            return {
                field: filter.field,
                type: "float",
                fieldId: filter.field,
                filterType: "rangefilter",
                min: filter.min,
                max: filter.max,
            };
        case "includefilter":
            return {
                filterType: "includefilter",
                type: "keyword",
                fieldId: filter.field,
                include: filter.include,
                field: filter.field,
                includeBlanks: filter.includeBlanks?.valueOf(),
            };
        case "excludefilter":
            return {
                field: filter.field,
                filterType: "excludefilter",
                exclude: filter.exclude,
                type: "keyword",
                fieldId: filter.field,
                excludeBlanks: filter.excludeBlanks?.valueOf(),
            };
        default:
            return undefined;
    }
}
