import { GraphqlRequestContainer } from "@ignite-analytics/graphql-utilities";
import { Autocomplete, TextField } from "@mui/material";
import React from "react";
import { DataColumn } from "@/Types/DataColumn";
import { fm } from "@/contexts/IntlContext";
import { useAllGlobalColumnTypes } from "@/entities/globalColumnType";
import { useUpdateDataColumnMutation } from "@/generated/client";
import messages from "./messages";

interface Props {
    dataColumn: DataColumn;
    onAction: () => void;
}

export const ChangeGlobalColumnType: React.FC<Props> = ({ dataColumn, onAction }) => {
    const globalColumnTypes = useAllGlobalColumnTypes(undefined, { service: "dashboards" });

    const [updateDataColumn, { result: updateResult }] = useUpdateDataColumnMutation();
    const handleGlobalTypeSelection = async (globalTypeKey: string | null) => {
        const { dataTableId, dataType, id, referencedId, name } = dataColumn;
        await updateDataColumn({
            input: {
                dataTableId,
                dataType,
                globalTypeKey: globalTypeKey || null,
                id,
                name,
                referencedId,
            },
        });
        onAction();
    };

    return (
        <>
            <Autocomplete
                fullWidth
                sx={{ minWidth: 300 }}
                value={dataColumn.globalTypeKey ?? null}
                options={globalColumnTypes.map((gt) => gt.key)}
                getOptionLabel={(option) =>
                    globalColumnTypes.find((globalType) => globalType.key === option)?.name ?? ""
                }
                renderInput={(params) => (
                    <TextField
                        {...params}
                        fullWidth
                        size="small"
                        placeholder={fm(messages.globalType).toString()}
                        label={fm(messages.globalType)}
                    />
                )}
                onChange={(_, value) => {
                    handleGlobalTypeSelection(value ?? null);
                }}
            />
            <GraphqlRequestContainer asyncData={updateResult} loading={null} />
        </>
    );
};
