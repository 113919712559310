import React from "react";

import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { Row } from "@/components/DataTableRow/interfaces";
import { Cell } from "@/components/DataTableRow/Cell";
import { DataColumn } from "@/Types/DataColumn";

interface Props {
    row: Row;
    numMatches: number;
    expandedId?: string;
    columns: DataColumn[];
}

export const ResultsTableRow = ({ row, expandedId, numMatches, columns }: Props) => (
    <TableRow key={`target rowid-${row.id}`}>
        <TableCell>
            <Typography color={numMatches ? "primary" : "error"} variant="body2" noWrap maxWidth={100}>
                {expandedId === row.id ? " Back" : `${numMatches} matches`}
            </Typography>
        </TableCell>
        {columns.map((c) => (
            <TableCell key={`${row.id}-${c.id}`}>
                <Tooltip title="id">
                    <Cell rowId={row.id} column={c} data={row.data[c.id] ?? []} />
                </Tooltip>
            </TableCell>
        ))}
    </TableRow>
);
