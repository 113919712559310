import { GraphqlRequestContainer } from "@ignite-analytics/graphql-utilities";
import LoadingButton from "@mui/lab/LoadingButton";
import Stack from "@mui/material/Stack";
import React, { useState } from "react";
import { DataColumn } from "@/Types/DataColumn";
import { fm } from "@/contexts/IntlContext";
import { useCreateDataColumnsMutation } from "@/generated/client";
import globalMessages from "@/messages";
import GroupStructureSelection from "../../GroupStructureSelection";

interface Props {
    dataTableId: string;
    onDataColumnCreated?: (newColumn?: DataColumn) => void;
    name?: string;
    isList?: boolean;
}

export const CreateGroupStructureColumn: React.FC<Props> = ({ dataTableId, onDataColumnCreated, isList, name }) => {
    const [createDataColumns, mutation] = useCreateDataColumnsMutation({ refetchQueries: ["getAllDataColumns"] });
    const [selectedGroupStructureId, setSelectedGroupStructureId] = useState<string>();

    const onSubmit = () => {
        if (mutation.result.type === "loading" || !name || !selectedGroupStructureId) {
            return;
        }
        createDataColumns({
            input: {
                dataTableId,
                dataColumns: [
                    {
                        dataType: "GROUP_STRUCTURE",
                        name,
                        referencedId: selectedGroupStructureId,
                        isList,
                    },
                ],
            },
        }).then((res) => onDataColumnCreated && onDataColumnCreated(res.data?.createDataColumns.dataColumns.at(0)));
    };
    return (
        <Stack gap={1}>
                <GroupStructureSelection onChange={setSelectedGroupStructureId} selectedId={selectedGroupStructureId} />
                <LoadingButton
                    disabled={!name}
                    loading={mutation.result.type === "loading"}
                    variant="outlined"
                    onClick={onSubmit}
                >
                    {fm(globalMessages.submitButton)}
                </LoadingButton>
                <GraphqlRequestContainer asyncData={mutation.result} loading={null} />
            </Stack>
    );
};
