import { Delete } from "@mui/icons-material";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import { Chip, CircularProgress, Stack, Tooltip, Typography } from "@mui/material";
import React, { useCallback, useState } from "react";

import { DataColumn } from "@/Types/DataColumn";
import { NotificationModal } from "@/components/NotificationModal";
import DeletePrompt from "@/components/Prompt/DeletePrompt";
import { fm } from "@/contexts/IntlContext";
import messages from "./messages";

interface Props {
    dataTableName: string | undefined;
    handleDelete: () => void;
    pipelineOperationOutputColumn?: DataColumn;
    base?: boolean;
    loading?: boolean;
}

export const NodeHeader: React.FC<Props> = ({
    dataTableName,
    pipelineOperationOutputColumn,
    handleDelete,
    base,
    loading,
}: Props) => {
    const [notification, setNotification] = useState<string>();
    const [openPrompt, setOpenPrompt] = useState(false);
    const togglePrompt = useCallback(() => setOpenPrompt((prev) => !prev), [setOpenPrompt]);

    return (
        <Stack direction="row" justifyContent="space-between" alignContent="center">
            <Stack direction="row" gap={1} maxWidth="70%" alignItems="center">
                <Tooltip title={dataTableName ?? fm(messages.unknownDataTable)}>
                    <Typography noWrap variant="h6">
                        {dataTableName ?? fm(messages.unknownDataTable)}
                    </Typography>
                </Tooltip>
            </Stack>
            <Stack direction="row" gap={1} alignItems="center">
                {pipelineOperationOutputColumn ? (
                    <Tooltip title={fm(messages.pipelineOperation)}>
                        <AccountTreeIcon />
                    </Tooltip>
                ) : base ? (
                    <Chip variant="status" color="warning" label={fm(messages.base)} />
                ) : (
                    <>{loading ? <CircularProgress /> : <Delete onClick={togglePrompt} />}</>
                )}
            </Stack>
            <NotificationModal
                notificationIds={notification ? [notification] : []}
                open={!!notification}
                onClose={() => setNotification(undefined)}
                title={fm(messages.deleteTaskStarted)}
            />
            <DeletePrompt open={openPrompt} onCancel={togglePrompt} onDelete={handleDelete} />
        </Stack>
    );
};
