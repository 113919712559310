import { defineMessages } from "react-intl";

const messages = defineMessages({
    input: {
        id: "xLedger.input",
        defaultMessage: "XLedger token",
    },
    integrate: {
        id: "xLedger.integrate",
        defaultMessage: "Integrate",
    },
    header: {
        id: "xLedger.header",
        defaultMessage: "Setup XLedger integration",
    },
});

export default messages;
