import { FeatureToggleContainer, useFeatureToggle } from "@ignite-analytics/feature-toggle";
import { GraphqlRequestContainer } from "@ignite-analytics/graphql-utilities";
import { track } from "@ignite-analytics/track";
import { Close, Delete } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Alert, Button, Card, CardContent, Divider, IconButton, Snackbar, Stack, Typography } from "@mui/material";
import Skeleton from "@mui/material/Skeleton";
import React, { SyntheticEvent, UIEvent, useEffect, useState } from "react";
import { NotificationModal } from "@/components/NotificationModal";
import DeletePrompt from "@/components/Prompt/DeletePrompt";
import { testIdPrefix } from "@/components/testIdprefix";
import { useDataManagementProcessEventListener } from "@/contexts/EventContexts/DataManagementEventContext";
import { fm } from "@/contexts/IntlContext";
import { useDeleteDataTableAction } from "@/generated/DataTableEntity";
import { DataTable, useGetDataTableValidationSummaryQuery, useRemoveGhostRowsMutation } from "@/generated/client";
import { formatGrpcErrorMessage } from "@/helpers";
import IndexTableButton from "./IndexTableButton";
import OptimizeTableButton from "./OptimizeTableButton";
import StartAllPipelinesButton from "./StartAllPipelinesButton";
import messages from "./messages";

interface Props {
    dataTable: DataTable;
}

export const AdminPage: React.FC<Props> = ({ dataTable }) => {
    const removeGhostFeatureToggle = useFeatureToggle("remove-ghost-data-button");
    const [deleteTableModal, setDeleteDataTableModal] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [notification, setNotification] = useState<string>();
    const [removeGhostRowsModal, setRemoveGhostRowsModal] = useState(false);
    const [removeGhostRows] = useRemoveGhostRowsMutation();

    const { result: summaryResult, refetch: refetchDataTableSummary } = useGetDataTableValidationSummaryQuery({
        input: { dataTableId: dataTable.id },
    });

    const [deleteDataTable, deleteTableResult] = useDeleteDataTableAction();

    const handleDelete = async () => {
        await deleteDataTable(dataTable.id);
        track("Data Table: Delete", { from: "admin-page" });
        setDeleteDataTableModal(false);
    };

    const handleRemoveGhostRows = async () => {
        const response = await removeGhostRows({ input: { dataTableId: dataTable.id } });
        setNotification(response.data?.removeGhostRows.notificationId);
        setRemoveGhostRowsModal(false);
    };

    useDataManagementProcessEventListener("OptimizeTable", (event) => {
        if (event.type === "PROCESS_FINISHED" && event?.id === dataTable.id) refetchDataTableSummary();
    });

    useEffect(() => {
        if (deleteTableResult.error) {
            setSnackbarOpen(true);
        }
    }, [deleteTableResult]);

    const handleClose = (_event: SyntheticEvent | UIEvent, reason?: string) => {
        if (reason === "clickaway") return;
        setSnackbarOpen(false);
    };

    return (
        <Stack gap={1}>
            <Card variant="outlined">
                <CardContent>
                    <GraphqlRequestContainer
                        asyncData={summaryResult}
                        loading={<Skeleton width="100%" height="6rem" />}
                    >
                        {({ indexFieldCount, cubeConfigurationCount }) => (
                            <Stack direction="row" alignItems="center" justifyContent="space-between">
                                <Stack>
                                    <Typography variant="subtitle1">{fm(messages.optimizeHeader)}</Typography>
                                    <Typography variant="body2">
                                        {fm(messages.optimizeDescription, {
                                            indexFieldCount,
                                            cubeConfigurationCount,
                                            difference: indexFieldCount - cubeConfigurationCount,
                                        })}
                                    </Typography>
                                </Stack>
                                <Stack minWidth="22%">
                                    <OptimizeTableButton dataTableId={dataTable.id} />
                                </Stack>
                            </Stack>
                        )}
                    </GraphqlRequestContainer>
                    <Stack sx={{ py: 1 }}>
                        <Divider variant="middle" />
                    </Stack>
                    <Stack direction="row" alignItems="center" justifyContent="space-between">
                        <Stack>
                            <Typography variant="subtitle1">{fm(messages.resyncHeader)}</Typography>
                            <Typography variant="body2">{fm(messages.resyncDescription)}</Typography>
                        </Stack>
                        <Stack minWidth="22%">
                            <IndexTableButton dataTableId={dataTable.id} />
                        </Stack>
                    </Stack>
                    <FeatureToggleContainer featureKey="show-run-all-pipelines">
                        <>
                            <Stack sx={{ py: 1 }}>
                                <Divider variant="middle" />
                            </Stack>
                            <Stack direction="row" alignItems="center" justifyContent="space-between">
                                <Stack>
                                    <Typography variant="subtitle1">{fm(messages.runAllPipelinesHeader)}</Typography>
                                    <Typography variant="body2">{fm(messages.runAllPipelinesDescription)}</Typography>
                                </Stack>
                                <Stack minWidth="22%">
                                    <StartAllPipelinesButton dataTableId={dataTable.id} />
                                </Stack>
                            </Stack>
                        </>
                    </FeatureToggleContainer>
                </CardContent>
            </Card>
            <Stack direction="row" alignItems="center">
                <Typography variant="subtitle1">{fm(messages.dangerZone)}</Typography>
            </Stack>
            <Card variant="outlined" sx={{ borderColor: (theme) => theme.palette.error.main }}>
                <CardContent>
                    <Stack direction="row" alignItems="center" justifyContent="space-between">
                        <Stack>
                            <Typography variant="subtitle1">{fm(messages.deleteDataTableHeader)}</Typography>
                            <Typography variant="body2">{fm(messages.deleteDataTableDescription)}</Typography>
                        </Stack>
                        <Stack minWidth="22%">
                            <LoadingButton
                                variant="outlined"
                                color="error"
                                startIcon={<Delete />}
                                onClick={() => setDeleteDataTableModal(true)}
                                size="small"
                                loading={deleteTableResult.isLoading}
                                data-testid={`${testIdPrefix}-delete-data-table-button`}
                            >
                                {fm(messages.deleteDataTableButton)}
                            </LoadingButton>
                        </Stack>
                    </Stack>
                    {removeGhostFeatureToggle && (
                        <>
                            <Stack sx={{ py: 1 }}>
                                <Divider variant="middle" />
                            </Stack>
                            <Stack direction="row" alignItems="center" justifyContent="space-between">
                                <Typography variant="subtitle1">{fm(messages.removeGhostRowsHeader)}</Typography>
                                <Stack>
                                    <Typography variant="body2">{fm(messages.removeGhostRowsDescription)}</Typography>
                                </Stack>
                                <Stack minWidth="22%">
                                    <Button
                                        variant="outlined"
                                        color="warning"
                                        startIcon={<Delete />}
                                        onClick={() => setRemoveGhostRowsModal(true)}
                                        size="small"
                                    >
                                        {fm(messages.removeGhostRowsButton)}
                                    </Button>
                                </Stack>
                            </Stack>
                        </>
                    )}
                </CardContent>
            </Card>
            <DeletePrompt
                title={fm(messages.removeGhostRowsHeader)}
                description={fm(messages.deletePromptDescription, { name: "invalid data" })}
                onCancel={() => setRemoveGhostRowsModal(false)}
                onDelete={handleRemoveGhostRows}
                open={removeGhostRowsModal}
            />
            <DeletePrompt
                title={fm(messages.deleteDataTableHeader)}
                description={fm(messages.deletePromptDescription, { name: dataTable.name })}
                onCancel={() => setDeleteDataTableModal(false)}
                onDelete={handleDelete}
                open={deleteTableModal}
            />
            <NotificationModal
                open={!!notification}
                onClose={() => setNotification(undefined)}
                notificationIds={notification ? [notification] : []}
                title=""
            />
            <Snackbar open={snackbarOpen} anchorOrigin={{ vertical: "bottom", horizontal: "right" }}>
                <Alert variant="filled" severity="error">
                    <Stack direction="row" alignItems="center">
                        {deleteTableResult.error && (
                            <Typography>{formatGrpcErrorMessage(deleteTableResult.error.message)}</Typography>
                        )}
                        <IconButton size="small" aria-label="close" onClick={handleClose}>
                            <Close />
                        </IconButton>
                    </Stack>
                </Alert>
            </Snackbar>
        </Stack>
    );
};
