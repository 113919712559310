import { Button, Stack, TextField } from "@mui/material";
import React, { useState } from "react";
import { fm } from "@/contexts/IntlContext";
import globalMessages from "@/messages";

import { DataTableCollection, useUpdateDataTableCollectionMutation } from "@/generated/client";
import messages from "../messages";

interface Props {
    collection: DataTableCollection | { name: string };
    onCancel: () => void;
    onUpdate: () => void;
}

export const RenameCollection: React.FC<Props> = ({ onCancel, onUpdate, collection }) => {
    const [updateCollection] = useUpdateDataTableCollectionMutation();
    const [name, setname] = useState(collection.name);

    const handleRename = () => {
        if (name === collection.name) return;

        if ("id" in collection) {
            updateCollection({ input: { id: collection.id, name } }).then(() => {
                onUpdate();
                onCancel();
            });
        }
    };

    return (
        <Stack gap={1} paddingX={2}>
            <TextField
                label={fm(messages.rename)}
                name="collectionName"
                value={name}
                type="text"
                onChange={(e) => setname(e.target.value)}
                onKeyDown={(e) => e.key === "Enter" && handleRename()}
            />

            <Stack gap={1} direction="row" justifyContent="right">
                <Button size="small" variant="text" onClick={onCancel}>
                    {fm(globalMessages.cancel)}
                </Button>
                <Button size="small" color="primary" type="submit" onClick={handleRename}>
                    {fm(messages.rename)}
                </Button>
            </Stack>
        </Stack>
    );
};
