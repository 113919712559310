import { defineMessages } from "react-intl";

const messages = defineMessages({
    setOperationName: {
        id: "uploadPage.createOperationButton.fillBlanksForm.name",
        defaultMessage: "Set operation name",
    },
    required: {
        id: "uploadPage.createOperation.required",
        defaultMessage: "Required",
    },
    withColumnToggle: {
        id: "uploadPage.createOperationButton.fillBlanksForm.withColumnToggle",
        defaultMessage: "With value from other column",
    },
    withColumnTooltip: {
        id: "uploadPage.createOperationButton.fillBlanksForm.withColumnTooltip",
        defaultMessage: "Or select replacement column as input to operation after submit",
    },
    isRegex: {
        id: "uploadPage.createOperation.isRegex",
        defaultMessage: "Is replace string regex expression?",
    },
    blankValue: {
        id: "uploadPage.createOperationButton.fillBlanksForm.blankValue",
        defaultMessage: "Blank value",
    },
    replace: {
        id: "uploadPage.createOperationButton.dateParserForm.replace",
        defaultMessage: "Replace",
    },
    with: {
        id: "uploadPage.createOperationButton.dateParserForm.with",
        defaultMessage: "With",
    },
    dateFormat: {
        id: "uploadPage.createOperationButton.dateParserForm.dateFormat",
        defaultMessage: "Select date format",
    },
    letterCasing: {
        id: "uploadPage.createOperationButton.letterCasing",
        defaultMessage: "Select casing format",
    },
    otherFormat: {
        id: "uploadPage.createOperationButton.dateParserForm.otherFormat",
        defaultMessage: "Specify format",
    },
    splitString: {
        id: "uploadPage.createOperationButton.splitOperationForm.splitString",
        defaultMessage: "Set split string",
    },
    templateString: {
        id: "uploadPage.createOperationButton.templateStringOperationForm.templateString",
        defaultMessage: "Create template string",
    },
    templateStringExplanation: {
        id: "uploadPage.createOperationButton.templateStringOperationForm.templateStringExplanation",
        defaultMessage: "Example: I get input from {template}",
    },

    expression: {
        id: "uploadPage.createOperation.regex",
        defaultMessage: "Expression",
    },
});

export default messages;
