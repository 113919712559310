import { defineMessages } from "react-intl";

const messages = defineMessages({
    inProcessChangesButton: {
        id: "dataTables.pipelinePageV2.toolbar.inProcessChangesButton",
        defaultMessage: "In progress changes",
    },
    changesButton: {
        id: "dataTables.pipelinePageV2.toolbar.changesButton",
        defaultMessage: "Unapplied changes",
    },
    changes: {
        id: "dataTables.pipelinePageV2.toolbar.changes",
        defaultMessage: "Changes",
    },
    changesButtonTooltip: {
        id: "dataTables.pipelinePageV2.toolbar.changesButtonTooltip",
        defaultMessage: "You have unapplied changes to your data tables",
    },
    noChangesButtonTooltip: {
        id: "dataTables.pipelinePageV2.toolbar.noChangesButtonTooltip",
        defaultMessage: "You have no unapplied changes to your data tables",
    },
    applyAllChangesWarningBold: {
        id: "dataTables.applyChanges.applySelectedChangesWarningHeader",
        defaultMessage: "This might take a while.",
    },
    cancel: {
        id: "dataTables.applyChanges.cancel",
        defaultMessage: "Cancel",
    },
    apply: {
        id: "dataTables.applyChanges.apply",
        defaultMessage: "Apply",
    },
    applyStarted: {
        id: "dataTables.applyChanges.applyStarted",
        defaultMessage:
            "Successfully started applying changes. Follow the process in the notifications center in the upper right corner",
    },
    applyFailed: {
        id: "dataTables.applyChanges.applyFailed",
        defaultMessage: "Failed to start data flows",
    },
    newImport: {
        id: "dataTables.applyChanges.newImport",
        defaultMessage: "New import {importName}",
    },
    operationChange: {
        id: "dataTables.applyChanges.operationChange",
        defaultMessage: "{operationName} changed",
    },
    filterChange: {
        id: "dataTables.applyChanges.filterChange",
        defaultMessage: "Filter changed",
    },
    mappingChange: {
        id: "dataTables.applyChanges.mappingChange",
        defaultMessage: "Updated mapping {from} to {to}",
    },
    source: {
        id: "dataTables.applyChanges.source",
        defaultMessage: "Data pipeline from source",
    },
    users: {
        id: "dataTables.applyChanges.users",
        defaultMessage: "Users",
    },
    user: {
        id: "dataTables.applyChanges.user",
        defaultMessage: "User",
    },
    date: {
        id: "dataTables.applyChanges.date",
        defaultMessage: "Date",
    },
    description: {
        id: "dataTables.applyChanges.description",
        defaultMessage: "Description of changes",
    },
    impactedColumn: {
        id: "dataTables.applyChanges.impactedColumn",
        defaultMessage: "Impacted column",
    },
});

export default messages;
