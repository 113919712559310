import { Add, Insights } from "@mui/icons-material";
import { Card, CardContent, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import { Position, useStore } from "reactflow";
import { DeletePipelineButton } from "@/components/DeletePipelineButton";
import { CreateOperation } from "@/containers/PipelinePageV2/Modals/CreateOperation";
import OperationModal from "@/containers/PipelinePageV2/Modals/OperationModal";
import { fm } from "@/contexts/IntlContext";
import { DataPipeline, DataPipelineOperation, OperationType } from "@/generated/client";
import messages from "../../messages";
import { BUNDLE_HEIGHT, OPERATION_NODE_IMPLEMENTATION, SHOW_CONTENT_ZOOM } from "../constants";
import { HandleOverlay } from "./basicNode";
import { basicNodeSx } from "./style";

interface Props {
    data: {
        dataPipeline: DataPipeline;
        operations: DataPipelineOperation[];
        bundledPipelines: DataPipeline[];
        setbundledPipelines: (dataPipelineId: string[]) => void;
    };
}

export const BundleNode: React.FC<Props> = ({ data }) => {
    const [modalComponent, setModalComponent] = useState<JSX.Element>();
    const [newOperationId, setNewOperationId] = useState<string | undefined>();
    const [show, setShow] = useState(false);

    const bundled = !!data.bundledPipelines.find((dp) => dp.id === data.dataPipeline.id);
    const zoom = useStore((s) => s.transform[2]);
    const bigIcon = zoom >= SHOW_CONTENT_ZOOM;

    const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
        const menuDropItemKey = event.dataTransfer.getData("menuDropItemKey");
        event.preventDefault();
        if (Object.keys(OPERATION_NODE_IMPLEMENTATION).includes(menuDropItemKey)) {
            setModalComponent(
                <CreateOperation
                    dataPipeline={data.dataPipeline}
                    operationType={menuDropItemKey as OperationType}
                    onClose={(newlyCreatedOperation) => {
                        setModalComponent(undefined);
                        setNewOperationId(newlyCreatedOperation?.id);
                    }}
                />
            );
        }
    };

    const operationsText =
        data.operations.length === 1
            ? fm(messages.singleOperation)
            : fm(messages.nOperations, { n: data.operations.length });

    const emptyPipeline = (
        <Stack sx={{ opacity: 0.4 }} minHeight="100%" justifyContent="center">
            <Card
                sx={{
                    cursor: "pointer",
                    backgroundColor: (theme) => theme.palette.primary.light,
                    boxShadow: basicNodeSx.boxShadow,
                    padding: 1,
                }}
            >
                {show && (
                    <div style={{ position: "absolute", right: -15, top: 30 }}>
                        <DeletePipelineButton id={data.dataPipeline.id} />
                    </div>
                )}
                <Stack alignItems="center">
                    <Add />
                    <Typography variant="body2" noWrap justifyContent="center">
                        {fm(messages.emptyPrompt1)}
                        <br />
                        {fm(messages.emptyPrompt2)}
                    </Typography>
                </Stack>
            </Card>
        </Stack>
    );

    return (
        <>
            <HandleOverlay position={Position.Left} type="target" />
            <HandleOverlay position={Position.Right} type="source" />
            <div
                style={{ height: `${BUNDLE_HEIGHT}px` }}
                onDragOver={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                }}
                onDrop={(e) => handleDrop(e)}
                onMouseEnter={() => setShow(true)}
                onMouseLeave={() => setShow(false)}
            >
                {data.operations.length !== 0 && show && (
                    <Stack direction="row" justifyContent="end">
                        <DeletePipelineButton id={data.dataPipeline.id} />
                    </Stack>
                )}
                {newOperationId && (
                    <OperationModal
                        operationId={newOperationId}
                        allOperations={data.operations}
                        dataPipeline={data.dataPipeline}
                        onClose={() => setNewOperationId(undefined)}
                    />
                )}
                {bundled && (
                    <Stack sx={{ opacity: 0.4 }} minHeight="100%" justifyContent="center">
                        <Card
                            sx={{
                                cursor: "pointer",
                                backgroundColor: (theme) => theme.palette.primary.light,
                                boxShadow: basicNodeSx.boxShadow,
                            }}
                        >
                            <CardContent>
                                <Stack alignItems="center">
                                    {bigIcon ? (
                                        <>
                                            <Insights />
                                            <Typography noWrap>{fm(messages.bundled)}</Typography>
                                            <Typography noWrap>{operationsText}</Typography>
                                        </>
                                    ) : (
                                        <Insights />
                                    )}
                                </Stack>
                            </CardContent>
                        </Card>
                    </Stack>
                )}
                {!data.operations.length && !bundled && emptyPipeline}
                {modalComponent}
            </div>
        </>
    );
};
