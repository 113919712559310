import React from "react";
import { DataColumn } from "@/Types/DataColumn";
import { DataPipeline, DataRepository, DataTable } from "@/generated/client";
import { CreateColumnsStep } from "./createColumns";
import { MapColumnsStep } from "./mapColumns";

interface Props {
    repository: DataRepository;
    dataTable: DataTable;
    dataPipeline: DataPipeline;
    onCreate: () => void;
    columns: DataColumn[];
}

export const CreateOrMapColumnsStep: React.FC<Props> = ({ columns, ...props }) => {
    if (columns.length) return <MapColumnsStep {...props} columns={columns} />;
    return <CreateColumnsStep {...props} />;
};
