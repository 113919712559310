import { DataTableConnection, DataTableConnectionCriterionInput, DataTableConnectionInput } from "@/generated/client";
import { Criterion } from "./types";

export function hasValue<T>(arg: T | undefined | null): arg is T {
    return arg !== undefined && arg !== null;
}

export const toBackEndCriterion: (c: Criterion) => DataTableConnectionCriterionInput | undefined = (c) => {
    if (!c.sourceField || !c.targetField) {
        return undefined;
    }
    return {
        type: c.type,
        targetField: c.targetField,
        sourceField: c.sourceField,
        id: c.frontEndKey,
        ignoreOnBlankTarget: c.ignoreOnBlankTarget,
    };
};

export const toDataTableConnectionInput: (
    connection: DataTableConnection,
    criteria: Criterion[]
) => DataTableConnectionInput = (connection, criteria) => {
    const { __typename, ...targetDataSource } = connection.targetDataSource;
    return {
        id: connection.id,
        targetDataSource,
        dataTableId: connection.dataTableId,
        criteria: criteria.map(toBackEndCriterion).filter(hasValue),
        dataColumnId: connection.dataColumnId,
    };
};
