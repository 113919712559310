import { defineMessages } from "react-intl";

const messages = defineMessages({
    leftJoin: {
        id: "dataTables.dataFlow.nodes.leftJoin",
        defaultMessage: "Left join",
    },
    fillBlanks: {
        id: "dataTables.dataFlow.nodes.fillBlanks",
        defaultMessage: "Fill blanks",
    },
    convertCasing: {
        id: "dataTables.dataFlow.nodes.convertCasing",
        defaultMessage: "Convert casing",
    },
    arithmeticOperation: {
        id: "dataTables.dataFlow.nodes.arithmeticOperation",
        defaultMessage: "Arithmetic op.",
    },
    convertCountryCode: {
        id: "dataTables.dataFlow.nodes.convertCountryCode",
        defaultMessage: "Convert country code",
    },
    dateParser: {
        id: "dataTables.dataFlow.nodes.dateParser",
        defaultMessage: "Date parser",
    },
    createTableRelation: {
        id: "dataTables.dataFlow.nodes.createTableRelation",
        defaultMessage: "Create table relation",
    },
    keywordTagger: {
        id: "dataTables.dataFlow.nodes.keywordTagger",
        defaultMessage: "Keyword tagger",
    },
    supplierEnrichment: {
        id: "dataTables.dataFlow.nodes.supplierEnrichment",
        defaultMessage: "Supplier enrichment",
    },
    convertCurrency: {
        id: "dataTables.dataFlow.nodes.convertCurrency",
        defaultMessage: "Convert currency",
    },
    regex: {
        id: "dataTables.dataFlow.nodes.regex",
        defaultMessage: "Regex.",
    },
    replace: {
        id: "dataTables.dataFlow.nodes.replace",
        defaultMessage: "Replace",
    },
    splitString: {
        id: "dataTables.dataFlow.nodes.splitString",
        defaultMessage: "Split string",
    },
    templateString: {
        id: "dataTables.dataFlow.nodes.templateString",
        defaultMessage: "Template string",
    },
    translate: {
        id: "dataTables.dataFlow.nodes.translate",
        defaultMessage: "Translate",
    },
    mapper: {
        id: "dataTables.dataFlow.nodes.mapper",
        defaultMessage: "Mapper",
    },
    leftJoinExplanation: {
        id: "pipelineOperation.leftJoinExplanation",
        defaultMessage: "Left join rows from another data source",
    },
    fillBlanksExplanation: {
        id: "pipelineOperation.fillBlanksExplanation",
        defaultMessage: "Fill empty cells with a default value or values from other columns",
    },
    convertCasingExplanation: {
        id: "pipelineOperation.convertCasingExplanation",
        defaultMessage: "Convert to upper or lowercase",
    },
    arithmeticOperationExplanation: {
        id: "pipelineOperation.arithmeticOperationExplanation",
        defaultMessage: "Do arithmetic with values from a row",
    },
    convertCountryCodeExplanation: {
        id: "pipelineOperation.convertCountryCodeExplanation",
        defaultMessage: "Maps country name to country codes",
    },
    dateParserExplanation: {
        id: "pipelineOperation.dateParserExplanation",
        defaultMessage: "Parse date strings from a specified format to Ignite standard date",
    },
    createTableRelationExplanation: {
        id: "pipelineOperation.createTableRelationExplanation",
        defaultMessage:
            "Create a connection to another table in ignite. Can also be used to generate rows in a data repository",
    },
    keywordTaggerExplanation: {
        id: "pipelineOperation.keywordTaggerExplanation",
        defaultMessage: "Extracts important keywords from texts",
    },
    supplierEnrichmentExplanation: {
        id: "pipelineOperation.supplierEnrichmentExplanation",
        defaultMessage: "Enrich your data with public financial data from Enin",
    },
    convertCurrencyExplanation: {
        id: "pipelineOperation.convertCurrencyExplanation",
        defaultMessage: "Enrich number cells with currency and date to enable view in any currency",
    },
    regexExplanation: {
        id: "pipelineOperation.regexExplanation",
        defaultMessage: "Extract specific values from a cell with a regular expression",
    },
    replaceExplanation: {
        id: "pipelineOperation.replaceExplanation",
        defaultMessage: "Replace cell values",
    },
    splitStringExplanation: {
        id: "pipelineOperation.splitStringExplanation",
        defaultMessage: "Split a cell into two values on a specified string",
    },
    templateStringExplanation: {
        id: "pipelineOperation.templateStringExplanation",
        defaultMessage: "Compose a new cell value from other values in your row",
    },
    translateExplanation: {
        id: "pipelineOperation.translateExplanation",
        defaultMessage: "Translate cells into any language",
    },
    mapperExplanation: {
        id: "pipelineOperation.mapperExplanation",
        defaultMessage: "Map values to other values",
    },
    listParser: {
        id: "dataTables.dataFlow.nodes.listParser",
        defaultMessage: "List parser",
    },
    listParserExplanation: {
        id: "pipelineOperation.listParserExplanation",
        defaultMessage: "Parse a list of values into a format accepted by a list type column",
    },
    noPermission: {
        id: "pipelineOperation.noPermission",
        defaultMessage: "You don't have permision for this component",
    },
});

export default messages;
