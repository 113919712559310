import Dialog from "@mui/material/Dialog";
import React, { useMemo, useState } from "react";

import { track } from "@ignite-analytics/track";
import { Add, Cancel } from "@mui/icons-material";
import { Autocomplete, Button, IconButton, ListItem, Stack, TextField, Typography } from "@mui/material";
import { DataColumn } from "@/Types/DataColumn";
import CreateDataColumnForm from "@/components/CreateDataColumnForm";
import { fm } from "@/contexts/IntlContext";
import {
    DataPipeline,
    DataPipelineOperation,
    DataPipelineOperationInputOutput,
    InputOutputConnection,
    useCreateInputOutputConnectionMutation,
    useDeleteInputOutputConnectionMutation,
} from "@/generated/client";
import messages from "../messages";
import { getRelevantColumns } from "./helpers";

interface Props {
    operation: DataPipelineOperation;
    inputOutputConnections: InputOutputConnection[];
    operationOutput: DataPipelineOperationInputOutput;
    dataPipeline: DataPipeline;
    dataColumns: DataColumn[];
}

const NEW_COLUMN_OPTION = {
    __typename: "DataColumn",
    dataTableId: "NEW COLUMN",
    dataType: "TEXT",
    id: "NEW COLUMN",
    name: "NEW COLUMN",
    isList: false,
} as DataColumn;

export const OperationOutputMapping: React.FC<Props> = ({
    operation,
    operationOutput,
    dataPipeline,
    inputOutputConnections,
    dataColumns,
}: Props) => {
    const [newOutput, setNewOutput] = useState(false);
    const [newColumnOpen, setNewColumnOpen] = useState(false);

    const relevantConnections = useMemo(
        () => inputOutputConnections.filter((ioc) => ioc.from.referencedId === operationOutput.id),
        [inputOutputConnections, operationOutput.id]
    );
    const relevantColumnsForOperationType = useMemo(
        () => getRelevantColumns(operation, dataColumns),
        [operation, dataColumns]
    );
    const outPuts = useMemo(
        () =>
            dataColumns.reduce((acc: { dataColumn: DataColumn; connectionId: string }[], dc) => {
                const matchingConnection = relevantConnections.find(
                    (connection) => connection.to.referencedId === dc.id
                );
                return matchingConnection ? [...acc, { dataColumn: dc, connectionId: matchingConnection.id }] : acc;
            }, []),
        [dataColumns, relevantConnections]
    );

    const [connectInputOutput] = useCreateInputOutputConnectionMutation({
        refetchQueries: ["getInputOutputConnections"],
    });
    const [deleteInputOutputConnection] = useDeleteInputOutputConnectionMutation({
        refetchQueries: ["getInputOutputConnections"],
    });
    const onSelection = async (dataColumn: DataColumn, currentInputOutputConnectionId?: string) => {
        if (currentInputOutputConnectionId) {
            await deleteInputOutputConnection({ input: { inputOutputConnectionId: currentInputOutputConnectionId } }); // THIS SHOULD BE ADDED TO APPLY CHANGES!
        }

        await connectInputOutput({
            input: {
                dataPipelineId: dataPipeline.id,
                from: {
                    referencedId: operationOutput.id,
                    type: "OPERATION",
                },
                to: {
                    referencedId: dataColumn.id,
                    type: "DATA_TABLE_COLUMN",
                },
            },
        });
    };

    const onClear = async (inputOutputConnectionId: string) => {
        await deleteInputOutputConnection({ input: { inputOutputConnectionId } });
    };
    const handleValueChange = (value: DataColumn | null, connectionId?: string) => {
        if (value?.id === "NEW COLUMN") {
            setNewColumnOpen(true);
        } else {
            value
                ? onSelection(value , connectionId).then(() => setNewOutput(false))
                : connectionId && onClear(connectionId);
        }
    };

    const handleColumnCreated = (newDataColumn?: DataColumn) => {
        newDataColumn &&
            onSelection(newDataColumn).then(() => {
                setNewColumnOpen(false);
                setNewOutput(false);
            });
        track("Pipeline Operation ouput: Created Data Column");
    };

    const options = useMemo(
        () => [NEW_COLUMN_OPTION, ...relevantColumnsForOperationType],
        [relevantColumnsForOperationType]
    );

    return (
        <Stack key={operationOutput.id} minWidth="200px">
            <Dialog disablePortal open={newColumnOpen} onClose={() => setNewColumnOpen(false)} fullWidth maxWidth="md">
                <CreateDataColumnForm
                    onDataColumnCreated={handleColumnCreated}
                    dataTableId={dataPipeline.targetDataTableId}
                    operationType={operation.operationType}
                />
            </Dialog>
            <Stack direction="row" alignItems="center" gap={1}>
                {outPuts.map(({ dataColumn, connectionId }) => (
                    <Autocomplete
                        key={connectionId}
                        fullWidth
                        size="small"
                        options={options}
                        onChange={(_, value) => handleValueChange(value, connectionId)}
                        value={dataColumn}
                        getOptionLabel={(option) => option.name}
                        renderInput={(params) => (
                            <TextField
                                label={
                                    operationOutput.key?.replace(/_/g, " ") || operationOutput.label?.replace(/_/g, " ")
                                }
                                {...params}
                            />
                        )}
                        renderOption={(params, option) => (
                            <ListItem {...params}>
                                {option.id === "NEW COLUMN" ? (
                                    <Button variant="text" size="small">
                                        {option.id}
                                    </Button>
                                ) : (
                                    <Typography color="success">{option.name}</Typography>
                                )}
                            </ListItem>
                        )}
                    />
                ))}
                {(newOutput || outPuts.length === 0) && (
                    <Autocomplete
                        fullWidth
                        size="small"
                        options={[NEW_COLUMN_OPTION, ...relevantColumnsForOperationType]}
                        getOptionLabel={(option) => option.name}
                        onChange={(_, value) => handleValueChange(value)}
                        renderOption={(params, option) => (
                            <ListItem {...params}>
                                {option.id === "NEW COLUMN" ? (
                                    <Button variant="text" size="small">
                                        {option.id}
                                    </Button>
                                ) : (
                                    <Typography color="success">{option.name}</Typography>
                                )}
                            </ListItem>
                        )}
                        renderInput={(params) => (
                            <TextField placeholder={fm(messages.selectOutput).toString()} {...params} />
                        )}
                    />
                )}
                {outPuts.length > 0 && (
                    <IconButton color={newOutput ? "warning" : "success"} onClick={() => setNewOutput(!newOutput)}>
                        {newOutput ? <Cancel /> : <Add />}
                    </IconButton>
                )}
            </Stack>
        </Stack>
    );
};
