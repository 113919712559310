import { CONTRACT_TABLE_GT, SUPPLIER_TABLE_GT } from "@ignite-analytics/global-types";
import { GraphqlRequestContainer } from "@ignite-analytics/graphql-utilities";
import { track } from "@ignite-analytics/track";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import Search from "@mui/icons-material/Search";
import {
    Button,
    Checkbox,
    Dialog,
    Grid,
    Paper,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Tooltip,
    tableRowClasses,
} from "@mui/material";
import { sortBy } from "lodash-es";
import React, { useEffect, useState } from "react";
import { DataColumn } from "@/Types/DataColumn";
import CreateDataColumnForm from "@/components/CreateDataColumnForm";
import DeletePrompt from "@/components/Prompt/DeletePrompt";
import { testIdPrefix } from "@/components/testIdprefix";
import { getRelevantGlobalColumnTypes } from "@/containers/PipelinePageV2/PipelineUtils";
import { useAddDisabledColumnIds, useDataColumnsByDataTableId } from "@/contexts/EventContexts/DataColumnEventContext";
import { fm } from "@/contexts/IntlContext";
import { useAllGlobalColumnTypes } from "@/entities/globalColumnType";
import { DataTable, useDeleteDataColumnsMutation } from "@/generated/client";
import { SUPPLIER_NORMALIZATION_GLOBAL_TYPE_KEY } from "../constants";
import DataColumnDeleteInfoItem from "./DataColumnDeleteInfoItem";
import DataColumnTableRow from "./DataColumnTableRow";
import { searchRelevantColumn } from "./helpers";
import messages from "./messages";

interface Props {
    dataTable: DataTable;
}

const CONTRACT_AND_SUPPLIER = [CONTRACT_TABLE_GT, SUPPLIER_TABLE_GT];
const ManageGlobalColumnTypes: React.FC<Props> = ({ dataTable }) => {
    const allowedToHide = !(dataTable.globalTypeKey && CONTRACT_AND_SUPPLIER.includes(dataTable.globalTypeKey));
    const [searchTerm, setSearchTerm] = useState<string | undefined>();
    const [idsToDelete, setIdsToDelete] = useState<string[]>([]);
    const [deleteModal, toggleDeleteModal] = useState<boolean>(false);
    const [isCreateDataColumnModalOpen, setIsCreateDataColumnModalOpen] = useState(false);
    const [selectAll, toggleSelectAll] = useState<boolean>(false);
    const globalColumnTypes = useAllGlobalColumnTypes(undefined, { service: "dashboards" });
    const addDisabledColumns = useAddDisabledColumnIds();
    const [deleteDataColumns, { result: deleteResult }] = useDeleteDataColumnsMutation();
    const columns = useDataColumnsByDataTableId(dataTable.id);
    const handleDataColumnCreated = () => {
        track("Manage table: Created Data Column", {});
        setIsCreateDataColumnModalOpen(false);
    };

    useEffect(() => setIdsToDelete([]), [dataTable.id]);

    const onDeleteCheck = (dataColumnId: string) => {
        if (idsToDelete.includes(dataColumnId)) {
            setIdsToDelete((currentIds) => currentIds.filter((id) => id !== dataColumnId));
        } else {
            setIdsToDelete((currentIds) => [...currentIds, dataColumnId]);
        }
    };

    const onDelete = async () => {
        const deletedResponse = await deleteDataColumns({
            input: { dataTableId: dataTable.id, dataColumnIds: idsToDelete },
        });
        track("Manage table: Delete Data Columns", { numberOfColumns: idsToDelete.length });

        if (!deletedResponse.errors?.length) {
            addDisabledColumns(idsToDelete);
        }
        toggleDeleteModal(false);
        setIdsToDelete([]);
    };

    const handleSelectAll = (select: boolean, dataColumns: DataColumn[]) => {
        if (select) {
            const relevantColumnIds = dataColumns
                .filter((column) => searchRelevantColumn(column, searchTerm))
                .map((column) => column.id);
            setIdsToDelete(relevantColumnIds);
        } else {
            setIdsToDelete([]);
        }
    };

    return (
        <>
            <Stack direction="row">
                <TextField
                    sx={{ minWidth: 220, maxWidth: 220 }}
                    fullWidth
                    size="small"
                    data-testid={`${testIdPrefix}-column-search`}
                    placeholder={fm(messages.searchDataColumns).toString()}
                    onChange={(e) => {
                        setSearchTerm(e.target.value.toLowerCase());
                    }}
                    InputProps={{
                        endAdornment: <Search fontSize="small" />,
                    }}
                />
                <Grid container justifyContent="flex-end" alignItems="center">
                    <Stack direction="row" gap={1}>
                        <Tooltip title={fm(messages.createDataColumnButtonTooltip)}>
                            <Button
                                color="primary"
                                aria-label="delete"
                                onClick={() => setIsCreateDataColumnModalOpen(true)}
                            >
                                <AddIcon fontSize="inherit" />
                                {fm(messages.createDataColumnButtonLabel)}
                            </Button>
                        </Tooltip>
                        <Tooltip title={fm(messages.deleteDataColumnsTooltip, { columnCount: idsToDelete.length })}>
                            <span>
                                <Button
                                    variant="contained"
                                    aria-label="delete"
                                    color="error"
                                    onClick={() => toggleDeleteModal(true)}
                                    disabled={!idsToDelete.length}
                                >
                                    <DeleteIcon fontSize="inherit" />
                                    {fm(messages.deleteDataColumnsButton)}
                                </Button>
                            </span>
                        </Tooltip>
                    </Stack>
                    <GraphqlRequestContainer asyncData={deleteResult} loading={null} />
                </Grid>
            </Stack>
            <TableContainer
                sx={{ maxWidth: "100%", maxHeight: "75vh", overflow: "auto" }}
                component={Paper}
                elevation={0}
            >
                <Table stickyHeader size="small">
                    <TableHead>
                        <TableRow
                            sx={{
                                [`&.${tableRowClasses.head}`]: {
                                    whiteSpace: "nowrap",
                                },
                            }}
                        >
                            <TableCell>
                                <Checkbox
                                    size="small"
                                    style={{ padding: 0 }}
                                    name="selectAll"
                                    checked={selectAll}
                                    onChange={(event) => {
                                        handleSelectAll(event.target.checked, columns);
                                        toggleSelectAll(event.target.checked);
                                    }}
                                />
                            </TableCell>
                            <TableCell>{fm(messages.nameHeader)}</TableCell>
                            <TableCell>{fm(messages.typeHeader)}</TableCell>
                            <TableCell>{fm(messages.globalTypeHeader)}</TableCell>
                            <TableCell>
                                <Stack direction="row" justifyContent="flex-end">
                                    {fm(messages.actionsHeader)}
                                </Stack>
                            </TableCell>
                            {allowedToHide && <TableCell>{fm(messages.showHideColumn)}</TableCell>}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {sortBy(columns, [(column) => column.name.toLowerCase()])
                            .filter((dataColumn) => dataColumn.globalTypeKey !== SUPPLIER_NORMALIZATION_GLOBAL_TYPE_KEY)
                            .map((dataColumn) => {
                                const relevantGlobalTypes = getRelevantGlobalColumnTypes(globalColumnTypes, dataColumn);
                                return searchRelevantColumn(dataColumn, searchTerm) ? (
                                    <DataColumnTableRow
                                        key={dataColumn.id}
                                        dataColumn={dataColumn}
                                        relevantGlobalTypes={relevantGlobalTypes}
                                        checked={idsToDelete.includes(dataColumn.id)}
                                        onCheck={onDeleteCheck}
                                        dataTable={dataTable}
                                    />
                                ) : null;
                            })}
                    </TableBody>
                </Table>
            </TableContainer>
            <DeletePrompt
                title={fm(messages.deleteDataColumnTitle)}
                description={fm(messages.deleteDataColumnDescription, { columnCount: idsToDelete.length })}
                onCancel={() => toggleDeleteModal(false)}
                onDelete={() => onDelete()}
                isProcessing={deleteResult.type === "loading"}
                open={deleteModal}
                acceptTestId={`${testIdPrefix}-delete-data-columns`}
            >
                <Stack>
                    {columns
                        .filter((column) => idsToDelete.includes(column.id))
                        .map((column, index) => (
                            <DataColumnDeleteInfoItem number={index + 1} key={column.id} dataColumn={column} />
                        ))}
                </Stack>
                <GraphqlRequestContainer asyncData={deleteResult} />
            </DeletePrompt>
            <Dialog
                fullWidth
                maxWidth="md"
                open={isCreateDataColumnModalOpen}
                onClose={() => setIsCreateDataColumnModalOpen(false)}
                disablePortal
            >
                <CreateDataColumnForm onDataColumnCreated={handleDataColumnCreated} dataTableId={dataTable.id} />
            </Dialog>
        </>
    );
};

export default ManageGlobalColumnTypes;
