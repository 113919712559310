import { defineMessages } from "react-intl";

const messages = defineMessages({
    incompatibleTypesErrorMessage: {
        id: "cubeBuilderPage.connectionModal.criterionBuilder.criterionRow.incompatibleTypesErrorMessage",
        defaultMessage: "Incompatible field types",
    },
});

export default messages;
