import { defineMessages } from "react-intl";

const messages = defineMessages({
    goToTable: {
        id: "SuccessStep.goToTable",
        defaultMessage: "Go to table",
    },
    createChart: {
        id: "SuccessStep.createChart",
        defaultMessage: "Create analysis",
    },
    browseDashboards: {
        id: "SuccessStep.browseDashboards",
        defaultMessage: "Browse dashboard library",
    },
    classify: {
        id: "SuccessStep.classify",
        defaultMessage: "Classify data",
    },
});

export default messages;
