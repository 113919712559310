import { defineMessages } from "react-intl";

const messages = defineMessages({
    header: {
        id: "dataTables.modals.ConnectRepositorySideMenu.header",
        defaultMessage: "Data sources",
    },
    others: {
        id: "dataTables.modals.ConnectRepositorySideMenu.others",
        defaultMessage: "Others",
    },
});

export default messages;
