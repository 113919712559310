import { GraphqlRequestContainer } from "@ignite-analytics/graphql-utilities";
import { X } from "@ignite-analytics/icons";
import { Add, Check, Delete, Edit } from "@mui/icons-material";
import { Autocomplete, Button, Grid, IconButton, MenuItem, Select, Stack, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import { DataColumn } from "@/Types/DataColumn";
import { InputSourceItem } from "@/Types/InputSourceItem";
import { fm } from "@/contexts/IntlContext";
import {
    DataPipeline,
    DataPipelineOperation,
    DataRepository,
    DataRepositoryField,
    InputOutputConnection,
    LookupOperationAdapter,
    useAddLookupOperationCriteriaMutation,
    useRemoveLookupOperationCriteriaMutation,
    useUpdateDataPipelineOperationMutation,
} from "@/generated/client";
import { InputSelect } from "../InputMappingSection/InputSelect";
import { LookupOperationFieldOutputsView } from "./LookupOperationFieldOutputsView";
import { fillLookupOperationAdapterWithLabels } from "./helpers";
import messages from "./messages";

interface LookupOperationView {
    operation: DataPipelineOperation & { lookupOperationAdapter: LookupOperationAdapter };
    targetDataRepository: DataRepository;
    inputSources: InputSourceItem[];
    inputOutputConnections: InputOutputConnection[];
    dataPipeline: DataPipeline;
    outputDataTableColumns: DataColumn[];
}

export const LookupOperationView: React.FC<LookupOperationView> = ({
    operation,
    targetDataRepository,
    inputSources,
    inputOutputConnections,
    dataPipeline,
    outputDataTableColumns,
}) => {
    const [updatedName, setUpdatedName] = useState(operation.name);
    const [editNameState, setEditNameState] = useState(false);
    const labeledAdapter = fillLookupOperationAdapterWithLabels(operation, targetDataRepository.fields);

    const [newCriterionField, setNewCriterionField] = useState<DataRepositoryField | undefined>();
    const possibleTargetRepositoryFields = targetDataRepository.fields.filter(
        (field) =>
            !operation.lookupOperationAdapter.lookupCriteria.some(
                (criterion) => criterion.dataRepositoryFieldId === field.id
            )
    );
    const handleAddCriterionClick = () => {
        if (possibleTargetRepositoryFields.length > 0) {
            setNewCriterionField(possibleTargetRepositoryFields[0]);
        }
    };

    const [updateOperation] = useUpdateDataPipelineOperationMutation();
    const [addLookupOperationCriterion, addMutation] = useAddLookupOperationCriteriaMutation();
    const [removeLookupOperationCriterion, removeMutation] = useRemoveLookupOperationCriteriaMutation();

    const handleConfirmAddCriterionClick = () => {
        if (newCriterionField) {
            addLookupOperationCriterion({
                input: {
                    dataPipelineOperationId: operation.id,
                    dataRepositoryFieldIds: [newCriterionField.id],
                },
            });
            setNewCriterionField(undefined);
        }
    };

    const handleDeleteClick = (dataRepositoryFieldId: string) => {
        removeLookupOperationCriterion({
            input: {
                dataPipelineOperationId: operation.id,
                dataRepositoryFieldIds: [dataRepositoryFieldId],
            },
        });
    };

    const editNameClick = async () => {
        if (editNameState) {
            setEditNameState((prev) => !prev);
            if (!updatedName.length || updatedName === operation.name) return;
            await updateOperation({
                input: {
                    id: operation.id,
                    operationType: operation.operationType,
                    operationSpecific: [],
                    name: updatedName,
                },
            });
            return;
        }
        setEditNameState((prev) => !prev);
    };

    return (
        <Stack gap={1}>
            <Stack direction="row" alignItems="center" spacing={2} width="40%">
                <TextField
                    name="name"
                    value={updatedName}
                    label={fm(messages.operationName)}
                    size="small"
                    fullWidth
                    disabled={!editNameState}
                    onChange={(e) => setUpdatedName(e.target.value)}
                />
                <IconButton onClick={editNameClick} size="small">
                    {editNameState ? <Check /> : <Edit />}
                </IconButton>
            </Stack>
            <Typography>{fm(messages.description)}</Typography>
            <Grid alignItems="center" container>
                {labeledAdapter.lookupCriteria.map((criteria) => (
                    <>
                        <Grid key={criteria.dataRepositoryFieldId} item md={5}>
                            {criteria.operationInput && (
                                <InputSelect
                                    inputOutputConnections={inputOutputConnections}
                                    input={criteria.operationInput}
                                    sources={inputSources}
                                    operation={operation}
                                />
                            )}
                        </Grid>
                        <Grid display="flex" justifyContent="center" item md={1}>
                            <Typography variant="h3" alignItems="center">
                                {fm(messages.hasToMatch)}
                            </Typography>
                        </Grid>
                        <Grid item md={5}>
                            <Select
                                fullWidth
                                size="small"
                                label={fm(messages.targetRepositoryFieldInputLabel)}
                                disabled
                                value={criteria.dataRepositoryFieldId}
                            >
                                <MenuItem value={criteria.dataRepositoryFieldId}>
                                    {criteria.dataRepositoryFieldLabel}
                                </MenuItem>
                            </Select>
                        </Grid>
                        <Grid display="flex" item md={1}>
                            <IconButton
                                disabled={labeledAdapter.lookupCriteria.length === 1}
                                onClick={() => handleDeleteClick(criteria.dataRepositoryFieldId)}
                            >
                                <Delete />
                            </IconButton>
                        </Grid>
                    </>
                ))}
            </Grid>
            {newCriterionField !== undefined ? (
                <Stack alignSelf="center" direction="row">
                    <Autocomplete
                        sx={{ minWidth: "300px" }}
                        size="small"
                        options={possibleTargetRepositoryFields}
                        onChange={(_, value) => value && setNewCriterionField(value)}
                        getOptionLabel={(option) => option.name}
                        value={newCriterionField}
                        renderInput={(params) => (
                            <TextField autoFocus label={fm(messages.targetRepositoryFieldInputLabel)} {...params} />
                        )}
                    />
                    <IconButton onClick={handleConfirmAddCriterionClick}>
                        <Check />
                    </IconButton>
                    <IconButton onClick={() => setNewCriterionField(undefined)}>
                        <X fontSize="small" />
                    </IconButton>
                </Stack>
            ) : (
                <Button startIcon={<Add />} onClick={handleAddCriterionClick} variant="text">
                    {fm(messages.addCriterion)}
                </Button>
            )}
            <GraphqlRequestContainer asyncData={addMutation.result} />
            <GraphqlRequestContainer asyncData={removeMutation.result} loading={null} />

            <LookupOperationFieldOutputsView
                dataPipeline={dataPipeline}
                operation={operation}
                targetDataRepository={targetDataRepository}
                inputOutputConnections={inputOutputConnections}
                outputDataTableColumns={outputDataTableColumns}
            />
        </Stack>
    );
};
