import { GraphqlRequestContainer } from "@ignite-analytics/graphql-utilities";
import React from "react";
import { Stack, Typography } from "@mui/material";
import { fm } from "@/contexts/IntlContext";
import { useGetDataRepositoryQuery } from "@/generated/client";
import messages from "./messages";

interface Props {
    repositoryId: string;
}
const ExternalRepositoryDetails: React.FC<Props> = ({ repositoryId }) => {
    const { result } = useGetDataRepositoryQuery({ input: { id: repositoryId } });
    return (
        <GraphqlRequestContainer asyncData={result}>
            {(repositoryResponse) => (
                <Stack>
                    <Typography variant="buttonSmall">{fm(messages.targetRepository)}</Typography>
                    <Typography variant="subtitle2">{repositoryResponse.dataRepository.name}</Typography>
                </Stack>
            )}
        </GraphqlRequestContainer>
    );
};

export default ExternalRepositoryDetails;
