import { TextField } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import Checkbox from "@mui/material/Checkbox";
import DialogContent from "@mui/material/DialogContent";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import React, { useState } from "react";

import { DataColumn } from "@/Types/DataColumn";
import { fm } from "@/contexts/IntlContext";
import { DataColumnType, OperationType } from "@/generated/client";

import { CreateDataColumn } from "./CreateVariants";
import { createColumnTypeMessages, getRelevantTypes } from "./helpers";
import messages from "./messages";

interface Props {
    dataTableId: string;
    onDataColumnCreated?: (newColumn?: DataColumn) => void;
    operationType?: OperationType;
}

const CreateDataColumnForm: React.FC<Props> = ({ dataTableId, onDataColumnCreated, operationType }) => {
    const [isList, setIsList] = useState(false);
    const relevantTypesForOperation = operationType && getRelevantTypes(operationType);
    const dataTypeOptions = Object.entries(createColumnTypeMessages()).filter(
        ([value]) => !relevantTypesForOperation || relevantTypesForOperation.includes(value)
    );
    const [selectedDataType, setDataType] = useState<DataColumnType>();
    const [name, setName] = useState<string>();
    const listColumns: DataColumnType[] = ["TEXT", "GROUP_STRUCTURE", "USER", "CONTACT", "TAG"];
    return (
        <DialogContent>
            <Stack gap={1}>
                <TextField
                    onChange={(e) => {
                        setName(e.target.value);
                    }}
                    variant="outlined"
                    label={fm(messages.dataColumnNameInputLabel)}
                    placeholder={fm(messages.dataColumnNameInputPlaceholder).toString()}
                    type="text"
                    sx={{ mt: 2 }}
                    autoFocus
                />
                <Grid container spacing={1}>
                    <Grid item xs={selectedDataType && listColumns.includes(selectedDataType) ? 9 : 12}>
                        <Autocomplete
                            options={dataTypeOptions}
                            onChange={(_, value) => {
                                if (value) {
                                    const dataType = value[0] as DataColumnType;
                                    setDataType(dataType);
                                }
                            }}
                            getOptionLabel={([_, { labelMessage }]) => fm(labelMessage).toString()}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={fm(messages.dataColumnDataTypeSelectionLabel)}
                                    inputProps={{ ...params.inputProps }}
                                />
                            )}
                        />
                    </Grid>
                    {selectedDataType && listColumns.includes(selectedDataType) && (
                        <Grid xs={3} alignSelf="center">
                            <Stack direction="row" alignItems="center" justifyContent="center" textAlign="center">
                                <Typography noWrap> {fm(messages.listType)} </Typography>
                                <Checkbox name="checkbox" checked={isList} onChange={(_, val) => setIsList(val)} />
                            </Stack>
                        </Grid>
                    )}
                </Grid>
                {selectedDataType && (
                    <CreateDataColumn
                        name={name}
                        dataTableId={dataTableId}
                        onDataColumnCreated={onDataColumnCreated}
                        isList={isList ?? false}
                        dataType={selectedDataType}
                    />
                )}
            </Stack>
        </DialogContent>
    );
};

export default CreateDataColumnForm;
