import {
    FilterContextProvider,
    useCompanyFavouriteFilters,
    usePersonalFavouriteFilters,
    useUpsertPersonalFavouriteFilters,
} from "@ignite-analytics/filters";
import { GraphqlRequestContainer } from "@ignite-analytics/graphql-utilities";
import { Sync } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Button, Dialog, DialogContent, Stack } from "@mui/material";
import { uniqueId } from "lodash-es";
import React, { useState } from "react";
import { NotificationModal } from "@/components/NotificationModal";
import { cubeBuilderPageTestIdPrefix } from "@/containers/CubeBuilderPage";
import { useChangedConnectionsContext } from "@/containers/CubeBuilderPage/context";
import { useEntityEventListener } from "@/contexts/EventContexts/EntityEventChangeContext";
import { fm } from "@/contexts/IntlContext";
import {
    DataTable,
    DataTableConnection,
    useAddDataTableConnectionCriterionMutation,
    useGetDataTableElasticFieldsQuery,
    useGetDependantColumnsQuery,
    useReapplyDataTableConnectionsMutation,
    useRemoveDataTableConnectionCriterionMutation,
} from "@/generated/client";
import { mapDataTableToFilterDataSource, useElasticFieldsForFilterContextProvider } from "@/helpers";
import { CreateConnectionModalHeader } from "../ConnectionModalHeader";
import { CriterionBuilder } from "../CriterionBuilder";
import TestConnectionResults from "../TestConnectionResults";
import { toDataTableConnectionInput } from "../helpers";
import messages from "../messages";
import { Criterion } from "../types";
import { DeleteConnectionButton } from "./DeleteConnectionButton";
import { useSessionContext } from "@/contexts/SessionContext";

interface Props {
    open: boolean;
    onClose: () => void;
    baseTable: DataTable;
    targetTable: DataTable;
    connection: DataTableConnection;
}
const criteriaEquals = (a: Omit<Criterion, "frontEndKey">, b: Omit<Criterion, "frontEndKey">) =>
    a.sourceField === b.sourceField &&
    a.targetField === b.targetField &&
    a.type === b.type &&
    a.ignoreOnBlankTarget === b.ignoreOnBlankTarget;

export const ViewConnectionModal: React.FC<Props> = ({ open, onClose, baseTable, targetTable, connection }: Props) => {
    const [notification, setNotification] = useState<string>();
    const { id: userId } = useSessionContext();
    const [edited, setEdited] = useState<boolean>(false);
    const [criteria, setCriteria] = useState<Criterion[]>(
        connection.criteria.map((criterion) => ({ ...criterion, frontEndKey: uniqueId() }))
    );
    const filterDataSource = mapDataTableToFilterDataSource(targetTable);
    const usePersonalFavouriteFiltersForContext = () =>
        usePersonalFavouriteFilters(userId, process.env.REACT_APP_GRAPHQL_ROUTER_URL as string);
    const useCompanyFavouriteFiltersForContext = () =>
        useCompanyFavouriteFilters(process.env.REACT_APP_GRAPHQL_ROUTER_URL as string);
    const upsertPersonalFavouriteFilters = useUpsertPersonalFavouriteFilters(
        userId,
        process.env.REACT_APP_GRAPHQL_ROUTER_URL as string
    );

    const { result: getDependantColumnsResult } = useGetDependantColumnsQuery({
        input: { dataColumnId: connection.dataColumnId },
    });

    const [reapplyDataTableConnection] = useReapplyDataTableConnectionsMutation();

    const handleApplyConnections = async () => {
        const applyNotification = await reapplyDataTableConnection({
            input: {
                dataTableId: baseTable.id,
                dataColumns: [connection.dataColumnId],
            },
        });

        setNotification(applyNotification.data?.reapplyDataTableConnections.notificationId);
    };

    const [, dispatch] = useChangedConnectionsContext();
    const { result: targetbaseFieldsResult, refetch } = useGetDataTableElasticFieldsQuery({
        input: { dataTableId: targetTable.id, withEnrichments: false },
    });
    useEntityEventListener("DataColumn", () => refetch());

    const { result: baseFieldsResult } = useGetDataTableElasticFieldsQuery({
        input: { dataTableId: baseTable.id, withEnrichments: false },
    });
    const [addCriterion, addMutation] = useAddDataTableConnectionCriterionMutation({
        refetchQueries: ["getAllDataTableConnections"],
    });
    const [removeCriterion, removeMutation] = useRemoveDataTableConnectionCriterionMutation({
        refetchQueries: ["getAllDataTableConnections"],
    });

    const handleSave = async () => {
        const newCriteria = criteria.filter(
            (newCriterion) =>
                !connection.criteria.some(({ __typename, ...existingCriterion }) =>
                    criteriaEquals(newCriterion, existingCriterion)
                )
        );
        const criteriaToRemove = connection.criteria.filter(
            ({ __typename, ...existingCriterion }) =>
                !criteria.some((newCriterion) => criteriaEquals(newCriterion, existingCriterion))
        );

        await Promise.all(
            newCriteria.map(({ sourceField, targetField, type, ignoreOnBlankTarget }) =>
                sourceField && targetField && type
                    ? addCriterion({
                          input: {
                              dataTableConnectionId: connection.id,
                              sourceField,
                              targetField,
                              type,
                              ignoreOnBlankTarget,
                          },
                      })
                    : undefined
            )
        );
        await Promise.all(
            criteriaToRemove.map((criterion) =>
                removeCriterion({
                    input: { dataTableConnectionId: connection.id, dataTableConnectionCriterionId: criterion.id },
                })
            )
        );
        dispatch({ type: "ADD_CHANGED", changedConnectionColumnId: connection.dataColumnId });
        setEdited(false);
        onClose();
    };

    const handleCriteriaStateChange = (newState: Criterion[]) => {
        setCriteria(newState);
        setEdited(true);
        const workingStateEqualsConnection =
            connection.criteria.length === newState.length &&
            connection.criteria.every((criterion) => newState.find((c) => criteriaEquals(criterion, c)));
        if (workingStateEqualsConnection) setEdited(false);
    };

    const dependantDataColumns =
        getDependantColumnsResult.type === "success" ? getDependantColumnsResult.data.dataColumns : undefined;
    return (
        <Dialog disablePortal open={open} fullWidth maxWidth="xl" onClose={onClose}>
            <CreateConnectionModalHeader baseTable={baseTable} dataTables={[]} targetTable={targetTable} />
            <DialogContent>
                {notification && (
                    <NotificationModal
                        open
                        onClose={() => setNotification(undefined)}
                        notificationIds={[notification]}
                        title={fm(messages.processing)}
                    />
                )}

                {getDependantColumnsResult.type === "loading" ||
                    (getDependantColumnsResult.type === "error" && (
                        <GraphqlRequestContainer asyncData={getDependantColumnsResult} />
                    ))}
                <GraphqlRequestContainer asyncData={baseFieldsResult}>
                    {(baseFieldsResponse) => (
                        <GraphqlRequestContainer asyncData={targetbaseFieldsResult}>
                            {(targetFieldsResponse) => (
                                <Stack gap={2}>
                                    <CriterionBuilder
                                        baseTable={baseTable}
                                        targetTable={targetTable}
                                        relationDataColumnId={connection.dataColumnId}
                                        dependingDataColumnIds={
                                            dependantDataColumns?.map((dataColumn) => dataColumn.id) ?? []
                                        }
                                        baseFieldsResponse={baseFieldsResponse}
                                        targetFieldsResponse={targetFieldsResponse}
                                        setCriteria={handleCriteriaStateChange}
                                        criteria={criteria}
                                    />

                                    <Stack direction="row" justifyContent="space-between">
                                        <DeleteConnectionButton
                                            dataTableId={connection.dataTableId}
                                            dataColumnId={connection.dataColumnId}
                                            onDelete={onClose}
                                        />
                                        {edited ? (
                                            <LoadingButton
                                                size="small"
                                                color="success"
                                                variant="contained"
                                                loading={
                                                    addMutation.result.type === "loading" ||
                                                    removeMutation.result.type === "loading"
                                                }
                                                onClick={handleSave}
                                                data-testid={`${cubeBuilderPageTestIdPrefix}-update-connection-button`}
                                            >
                                                {fm(messages.save)}
                                            </LoadingButton>
                                        ) : (
                                            <Button
                                                size="small"
                                                color="secondary"
                                                onClick={handleApplyConnections}
                                                startIcon={<Sync />}
                                                data-testid={`${cubeBuilderPageTestIdPrefix}-apply-connection-changes-button`}
                                            >
                                                {fm(messages.reapply)}
                                            </Button>
                                        )}
                                    </Stack>
                                    <Stack>
                                        <GraphqlRequestContainer loading={null} asyncData={addMutation.result} />
                                        <GraphqlRequestContainer loading={null} asyncData={removeMutation.result} />
                                    </Stack>
                                    <FilterContextProvider
                                        dataSource={filterDataSource}
                                        useDataFields={useElasticFieldsForFilterContextProvider}
                                        inherit={false}
                                        initialFilters={[]}
                                        disableDefaultCombination
                                        // hooks for favourite filters
                                        usePersonalFavouriteFilters={usePersonalFavouriteFiltersForContext}
                                        upsertPersonalFavouriteFilters={upsertPersonalFavouriteFilters}
                                        useCompanyFavouriteFilters={useCompanyFavouriteFiltersForContext}
                                    >
                                        <TestConnectionResults
                                            dataTableConnection={toDataTableConnectionInput(connection, criteria)}
                                            baseTable={baseTable}
                                            targetTable={targetTable}
                                        />
                                    </FilterContextProvider>
                                </Stack>
                            )}
                        </GraphqlRequestContainer>
                    )}
                </GraphqlRequestContainer>
            </DialogContent>
        </Dialog>
    );
};
