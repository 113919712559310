import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { Alert, Autocomplete, Stack, TextField, Tooltip } from "@mui/material";
import React from "react";
import { fm } from "@/contexts/IntlContext";
import { useAllGlobalTableTypes } from "@/entities/globalTableType";
import { DataTable } from "@/generated/client";
import { useUpdateDataTableAction } from "@/generated/DataTableEntity";
import { formatGrpcErrorMessage } from "@/helpers";
import messages from "./messages";

interface Props {
    dataTable: DataTable;
}
const ManageGlobalTableType: React.FC<Props> = ({ dataTable }) => {
    const globalTableTypes = useAllGlobalTableTypes(undefined, { service: "dashboards" });
    const [updateDataTable, mutation] = useUpdateDataTableAction();

    const handleGlobalTypeChange = (globalType: string | null) => {
        if (dataTable && globalType !== dataTable.globalTypeKey) {
            updateDataTable(dataTable.id, { globalTypeKey: globalType });
        }
    };
    return (
        <Stack>
            <Autocomplete
                size="small"
                sx={{ minWidth: 220, maxWidth: 220 }}
                value={dataTable.globalTypeKey ?? null}
                options={globalTableTypes.map((globalType) => globalType.key).filter((key) => key !== "tasks")}
                getOptionLabel={(option) =>
                    globalTableTypes.find((globalType) => globalType.key === option)?.name ?? ""
                }
                renderInput={(params) => (
                    <TextField
                        {...params}
                        InputProps={{
                            ...params.InputProps,
                            startAdornment: (
                                <Tooltip title={fm(messages.helpTextTableType)}>
                                    <HelpOutlineIcon fontSize="small" />
                                </Tooltip>
                            ),
                        }}
                        label={fm(messages.setGlobalTableType).toString()}
                        placeholder={fm(messages.setGlobalTableTypePlaceHolder).toString()}
                    />
                )}
                onChange={(_, value) => {
                    handleGlobalTypeChange(value);
                }}
            />
            {mutation.error && <Alert severity="error">{formatGrpcErrorMessage(mutation.error.message)}</Alert>}
        </Stack>
    );
};

export default ManageGlobalTableType;
