import { defineMessages } from "react-intl";

const messages = defineMessages({
    fieldToAggregateSelectLabel: {
        id: "components.dataColumns.aggregation.fieldToAggregateSelectLabel",
        defaultMessage: "Field to aggregate",
    },
});

export default messages;
