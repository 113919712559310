import { TableType } from ".";

export const getLink = (rowId: string, prefix: string, tableType: TableType) => {
    if (tableType === "SUPPLIER") {
        return `${prefix}/supplier-page/${rowId}/overview/`;
    }
    if (tableType === "CONTRACT") {
        return `/contracts/detail/${rowId}`;
    }
    return undefined;
};
