import React, { useState } from "react";
import { QuestionMark } from "@mui/icons-material";

import globalMessages from "@/messages";
import { fm } from "@/contexts/IntlContext";
import { DataPipeline, DataPipelineOperation } from "@/generated/client";

import OperationModal from "../../Modals/OperationModal";
import { OPERATION_NODE_IMPLEMENTATION, PIPELINE_COLORS } from "../constants";
import { getOperationNodeWidth } from "../nodeHelpers";
import { BasicNode } from "./basicNode";

interface Props {
    data: {
        operation: DataPipelineOperation;
        allOperations: DataPipelineOperation[];
        dataPipeline: DataPipeline;
    };
}

const getName = (operation: DataPipelineOperation) =>
    operation.operationType in OPERATION_NODE_IMPLEMENTATION
        ? OPERATION_NODE_IMPLEMENTATION[operation.operationType].name
        : fm(globalMessages.error);
const getColor = (operation: DataPipelineOperation) => {
    if (operation.operationType === "LOOKUP") return PIPELINE_COLORS.join;
    return PIPELINE_COLORS.operation;
};
export const OperationNode = ({ data }: Props) => {
    const [open, setOpen] = useState(false);
    return (
        <>
            {open && (
                <OperationModal
                    onClose={() => setOpen(false)}
                    operationId={data.operation.id}
                    allOperations={data.allOperations}
                    dataPipeline={data.dataPipeline}
                />
            )}
            <BasicNode
                color={getColor(data.operation)}
                text={getName(data.operation)}
                name={data.operation.name}
                icon={OPERATION_NODE_IMPLEMENTATION[data.operation.operationType].iconComponent || <QuestionMark />}
                onIconClick={() => setOpen(true)}
                maxWidth={`${getOperationNodeWidth(data.operation.operationType)}px`}
                minWidth={`${getOperationNodeWidth(data.operation.operationType)}px`}
                handleLeft={data.operation.id !== data.allOperations.at(0)?.id}
                handleRight={data.operation.id !== data.allOperations.at(-1)?.id}
                handleBottom={data.operation.operationType === "LOOKUP"}
            />
        </>
    );
};
