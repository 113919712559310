import { defineMessages } from "react-intl";

const messages = defineMessages({
    missingDataColumnName: {
        id: "rowReference.nameMissing",
        description: "Column name undefined",
        defaultMessage: "Missing name",
    },
});

export default messages;
