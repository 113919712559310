import React from "react";
import { Tooltip, Typography, Stack } from "@mui/material";
import { createDragElement } from "./helpers";

import { TinyIcon } from "@/components/TinyIcon";

import { NodeInformation } from "../Nodes/constants";

type Props = {
  dragKey: string;
  content: NodeInformation;
  handleDrop: (e: React.DragEvent<HTMLDivElement>, menuDropItemKey: string) => void;
};

export const SideBarItem: React.FC<Props> = ({ dragKey, content, handleDrop }) => (
      <div
          draggable
          key={dragKey}
          id={dragKey}
          onDragStart={(e) => createDragElement(e, content, dragKey)}
          onDragEnd={(e) => {
              handleDrop(e, dragKey);
          }}
      >
          <Tooltip title={content.explanation} placement="left-end">
              <Stack
                  alignItems="center"
                  direction="row"
                  height="30px"
                  sx={{
                      cursor: "grab",
                      paddingLeft: 1,
                      ":hover": {
                          backgroundColor: (theme) => theme.palette.action.hover,
                      },
                  }}
                  gap={1}
              >
                  <TinyIcon
                      color={content.color}
                      iconComponent={content.iconComponent}
                  />
                  <Typography
                      variant="textSm"
                      noWrap
                      padding={{ left: 1 }}
                      textOverflow="ellipsis"
                  >
                      {content.name}
                  </Typography>
              </Stack>
          </Tooltip>
      </div>
  );
