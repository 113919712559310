import { defineMessages } from "react-intl";

const messages = defineMessages({
    header: {
        id: "uploadPage.pipelineConfiguration.inspectOperationExecution.header",
        defaultMessage: "Data preview",
    },
    testOperation: {
        id: "uploadPage.pipelineConfiguration.inspectOperationExecution.testOperation",
        defaultMessage: "Test operation",
    },
    select: {
        id: "uploadPage.pipelineConfiguration.inspectOperationExecution.select",
        defaultMessage: "Select test run",
    },
    tooltip: {
        id: "uploadPage.pipelineConfiguration.inspectOperationExecution.tooltip",
        defaultMessage: "Test your operation on a small sample of rows from your repositories.",
    },
    operationName: {
        id: "uploadPage.pipelineConfiguration.inspectOperationExecution.operationName",
        defaultMessage: "Operation name",
    },
    outputLabel: {
        id: "uploadPage.pipelineConfiguration.inspectOperationExecution.outputLabel",
        defaultMessage: "Output label",
    },
    input: {
        id: "uploadPage.pipelineConfiguration.inspectOperationExecution.input",
        defaultMessage: "Input",
    },
    output: {
        id: "uploadPage.pipelineConfiguration.inspectOperationExecution.output",
        defaultMessage: "Output",
    },
    formattedOutputLabel: {
        id: "uploadPage.pipelineConfiguration.inspectOperationExecution.formattedOutputLabel",
        defaultMessage: "Output label: {label} ",
    },
    noRowsMessage: {
        id: "uploadPage.pipelineConfiguration.inspectOperationExecution.noRowsMessage",
        defaultMessage:
            "Did not find any rows processed by this operation during the selected test run. Maybe it was created after the test run?",
    },
    missing: {
        id: "uploadPage.pipelineConfiguration.inspectOperationExecution.missing",
        defaultMessage: "Missing",
    },
    noExecutionInstructions: {
        id: "uploadPage.pipelineConfiguration.inspectOperationExecution.noExecutionInstructions",
        defaultMessage: "Click the button to validate your operation on a small sample from your data",
    },
});

export default messages;
