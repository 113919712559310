import { Autocomplete, TextField } from "@mui/material";
import React from "react";
import { fm } from "@/contexts/IntlContext";
import { DataRepositoryField } from "@/generated/client";
import messages from "../messages";

interface LookupCriteriaSelectProps {
    dataRepositoryFields: DataRepositoryField[];
    selectedFields: DataRepositoryField[];
    onChange: (value: DataRepositoryField[]) => void;
}

export const LookupCriteriaSelect: React.FC<LookupCriteriaSelectProps> = ({
    dataRepositoryFields,
    selectedFields,
    onChange,
}) => (
        <Autocomplete
            fullWidth
            multiple
            size="small"
            value={selectedFields}
            options={dataRepositoryFields}
            onChange={(_, v) => {
                onChange(v);
            }}
            getOptionLabel={(option) => option.name}
            renderInput={(params) => (
                <TextField {...params} label={fm(messages.addLookupFieldSelectLabel).toString()} />
            )}
        />
    );
