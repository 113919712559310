import { Storage } from "@mui/icons-material";
import React, { useState } from "react";
import { fm } from "@/contexts/IntlContext";
import messages from "../../messages";
import { RepositoryModal } from "../../Modals/RepositoryModal";
import { PIPELINE_COLORS } from "../constants";
import { RepositoryNodeProps } from "../nodeTypes";
import { BasicNode } from "./basicNode";

interface Props {
    data: RepositoryNodeProps;
}

export const RepositoryNode = ({ data }: Props) => {
    const [open, setOpen] = useState(false);
    return (
        <>
            {open && <RepositoryModal onClose={() => setOpen(false)} dataRepository={data.dataRepository} />}
            <BasicNode
                text={fm(messages.dataRepository)}
                icon={<Storage />}
                name={data.dataRepository.name}
                onIconClick={() => setOpen(true)}
                color={PIPELINE_COLORS.source}
                maxWidth="100px"
                minWidth="150px"
                handleRight
            />
        </>
    );
};
