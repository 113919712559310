import { Typography, TypographyProps } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import { fm } from "@/contexts/IntlContext";
import { getLink } from "./helpers";
import messages from "./messages";

export type TableType = "SUPPLIER" | "CONTRACT" | "NONE";

interface Props {
    label?: string;
    id: string;
    type: TableType;
    prefix?: string;
    style?: TypographyProps;
}

export const RowReference: React.FC<Props> = ({ label, id, type, prefix, style }) => {
    const styling: TypographyProps = style ?? { overflow: "unset", noWrap: true, variant: "body2" };

    const link = getLink(id, prefix || "", type);
    if (!link) {
        return (
            <Typography noWrap {...styling}>
                {label ?? fm(messages.missingDataColumnName)}
            </Typography>
        );
    }
    return (
        <Typography noWrap {...styling}>
            <Link to={link}>{label ?? fm(messages.missingDataColumnName)}</Link>
        </Typography>
    );
};
