import { CONTRACT_TABLE_GT, GlobalColumnType, SUPPLIER_TABLE_GT } from "@ignite-analytics/global-types";
import { GraphqlRequestContainer } from "@ignite-analytics/graphql-utilities";
import { track } from "@ignite-analytics/track";
import {
    Checkbox,
    CircularProgress,
    Grid,
    Stack,
    Switch,
    TableCell,
    TableRow,
    TextField,
    Tooltip,
    Typography,
    tableRowClasses,
} from "@mui/material";
import React, { useState } from "react";
import { useFeatureToggle } from "@ignite-analytics/feature-toggle";
import { DataColumn } from "@/Types/DataColumn";
import { EditDataType } from "@/components/EditDataType";
import { useIsColumnDisabled } from "@/contexts/EventContexts/DataColumnEventContext";
import { fm } from "@/contexts/IntlContext";
import { useHiddenColumnsContext } from "@/contexts/hiddenColumnsContext";
import { useManyDataTables } from "@/generated/DataTableEntity";
import { DataTable, useUpdateDataColumnMutation } from "@/generated/client";
import { formatDataColumnType } from "@/helpers";
import { EditButtons } from "./EditButtons";
import { GlobalColumnSelector } from "./GlobalColumnSelector";
import messages from "./messages";

interface Props {
    dataColumn: DataColumn;
    relevantGlobalTypes: GlobalColumnType[];
    checked: boolean;
    onCheck: (columnId: string) => void;
    dataTable: DataTable;
}

const CONTRACT_AND_SUPPLIER = [CONTRACT_TABLE_GT, SUPPLIER_TABLE_GT];

const DataColumnTableRow: React.FC<Props> = ({ dataColumn, relevantGlobalTypes, checked, onCheck, dataTable }) => {
    const dmsOverrideProtections = useFeatureToggle("dms-override-protections");
    const allDataTablesResult = useManyDataTables();
    const [editState, setEditState] = useState<boolean>(false);
    const [updatedName, setUpdatedName] = useState<string>(dataColumn.name);
    const { columnsToHide, hideColumn, unHideColumn } = useHiddenColumnsContext();
    const allowedToHide = !(dataTable.globalTypeKey && CONTRACT_AND_SUPPLIER.includes(dataTable.globalTypeKey));

    const [updateDataColumn, { result: updateResult }] = useUpdateDataColumnMutation();
    const handleGlobalTypeSelection = async (globalTypeKey: string | null) => {
        const { dataTableId, dataType, id, referencedId, name } = dataColumn;
        await updateDataColumn({
            input: {
                dataTableId,
                dataType,
                globalTypeKey: globalTypeKey || null,
                id,
                name,
                referencedId,
            },
        });
    };

    const handleSaveColumn = async () => {
        if (!updatedName.length || !dataColumn || updatedName === dataColumn.name) {
            setEditState(false);
            return;
        }
        const { dataTableId, dataType, id, referencedId, globalTypeKey } = dataColumn;
        await updateDataColumn({
            input: {
                dataTableId,
                dataType,
                globalTypeKey,
                id,
                name: updatedName,
                referencedId,
            },
        });
        track("Pipeline: Updated Data Column", { type: dataColumn.dataType });
        setEditState(false);
    };
    const isColumnDisabled = useIsColumnDisabled(dataColumn.id);

    return (
        <TableRow
            key={dataColumn.id}
            sx={{
                [`&.${tableRowClasses.hover}`]: {
                    whiteSpace: "nowrap",
                },
            }}
        >
            <TableCell>
                <Stack direction="row">
                    <Checkbox
                        size="small"
                        style={{ padding: 0 }}
                        name="delete"
                        onChange={() => onCheck(dataColumn.id)}
                        checked={checked}
                        disabled={isColumnDisabled || dataColumn.isProtected}
                    />
                </Stack>
            </TableCell>
            <TableCell style={{ paddingTop: 0, paddingBottom: 0 }}>
                {editState ? (
                    <TextField
                        style={{ paddingTop: 0, paddingBottom: 0 }}
                        fullWidth
                        size="small"
                        label=""
                        disabled={isColumnDisabled}
                        name="name"
                        defaultValue={dataColumn.name}
                        onChange={(event) => setUpdatedName(event.target.value)}
                    />
                ) : (
                    <Typography variant="body2">{dataColumn.name}</Typography>
                )}
            </TableCell>
            <TableCell>
                {dataColumn.dataType === "TABLE_RELATION" ? (
                    <GraphqlRequestContainer asyncData={allDataTablesResult}>
                        {(dataTables) => (
                            <Typography variant="body2">{`${formatDataColumnType(dataColumn)} (${
                                dataTables.find((dt) => dt.id === dataColumn.referencedId)?.name
                            })`}</Typography>
                        )}
                    </GraphqlRequestContainer>
                ) : (
                    <EditDataType
                        dataColumn={dataColumn}
                        setEditState={setEditState}
                        hide={!editState || dataColumn.isProtected}
                        dataTable={dataTable}
                    />
                )}
            </TableCell>
            <TableCell style={{ paddingTop: 0, paddingBottom: 0, minWidth: "30em" }}>
                <GlobalColumnSelector
                    isDisabled={isColumnDisabled || relevantGlobalTypes.length === 0 || (dataColumn.isProtected && !dmsOverrideProtections)}
                    relevantGlobalColumnTypes={relevantGlobalTypes}
                    selectedGlobalType={dataColumn.globalTypeKey ?? undefined}
                    onValueChange={handleGlobalTypeSelection}
                />
                <GraphqlRequestContainer asyncData={updateResult} loading={null} />
            </TableCell>
            <TableCell>
                {isColumnDisabled ? (
                    <Grid justifyContent="center" alignItems="center" container>
                        <Tooltip title={fm(messages.columnDisabled)} arrow={false}>
                            <CircularProgress size="1.9rem" />
                        </Tooltip>
                    </Grid>
                ) : (
                    <EditButtons
                        editState={editState}
                        setEditState={setEditState}
                        handleSaveColumn={handleSaveColumn}
                    />
                )}
            </TableCell>
            {allowedToHide && (
                <TableCell>
                    <Tooltip title={fm(messages.hideTooltip)}>
                        <Switch
                            disabled={!allowedToHide}
                            checked={!columnsToHide.includes(dataColumn.id)}
                            onChange={(e, v) => (v ? unHideColumn(dataColumn.id) : hideColumn(dataColumn.id))}
                        />
                    </Tooltip>
                </TableCell>
            )}
        </TableRow>
    );
};

export default DataColumnTableRow;
