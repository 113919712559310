import { useDebounce } from "@ignite-analytics/general-tools";
import { GraphqlRequestContainer } from "@ignite-analytics/graphql-utilities";
import { Skeleton, Stack } from "@mui/material";
import React, { useCallback, useEffect, useRef, useState } from "react";
import ReactFlow, {
    applyEdgeChanges,
    applyNodeChanges,
    Background,
    Controls,
    Edge,
    EdgeChange,
    Node,
    NodeChange,
} from "reactflow";
import {
    DataPipeline,
    DataPipelineLayout,
    DataPipelineOperation,
    DataRepository,
    DataTable,
    useGetAllDataRepositoriesQuery,
    useGetDataPipelineLayoutQuery,
    useGetDataPipelineOperationsQuery,
    useGetInputOutputConnectionsQuery,
    useUpdateOrCreateDataPipelineLayoutMutation,
} from "@/generated/client";
import { createEdges } from "../Nodes/edgeHelpers";
import { createNodes } from "../Nodes/nodeHelpers";
import { EDGE_TYPES, NODE_TYPES } from "../Nodes/nodeTypes";
import { CanvasToolbar } from "../Toolbar";
import PipelineCanvasContextProvider, { usePipelineCanvasContext } from "./context";
import { orderOperations } from "./helpers";

interface Props {
    dataPipelines: DataPipeline[];
    dataTable: DataTable;
}

interface WrappedProps extends Props {
    operations: DataPipelineOperation[];
    dataRepositories: DataRepository[];
    pipelineLayout?: DataPipelineLayout;
}

export const WrappedPipelineCanvas = ({
    dataPipelines,
    operations,
    dataRepositories,
    dataTable,
    pipelineLayout,
}: WrappedProps) => {
    const [{ bundled }] = usePipelineCanvasContext();
    const [bundledPipelineIds, setbundledPipelineIds] = useState<string[]>([]);
    const [saveLayout] = useUpdateOrCreateDataPipelineLayoutMutation({ refetchQueries: ["getDataPipelineLayout"] });
    const [nodes, setNodes] = useState<Node[]>(
        createNodes(
            operations,
            dataPipelines,
            dataRepositories,
            bundledPipelineIds,
            setbundledPipelineIds,
            dataTable,
            pipelineLayout
        )
    );
    const [edges, setEdges] = useState<Edge[]>(createEdges(operations, dataPipelines, bundledPipelineIds, dataTable));

    const handleSaveLayout = useCallback(
        (nds: Node[]) => {
            const parsed: { [key: string]: { x: number; y: number } } = pipelineLayout
                ? JSON.parse(pipelineLayout?.layoutJson)
                : {};
            nds.forEach((n) => {
                parsed[n.id] = n.position;
            });

            const newLayout: { [key: string]: { x: number; y: number } } = nds.reduce(
                (acc, curr) => ({
                    ...acc,
                    [curr.id]: curr.position,
                }),
                parsed
            );
            saveLayout({ input: { dataTableId: dataTable.id, layoutJson: JSON.stringify(newLayout) } });
        },
        [dataTable, pipelineLayout, saveLayout]
    );
    const debouncedSave = useDebounce(handleSaveLayout, 500);

    useEffect(() => {
        bundled ? setbundledPipelineIds(dataPipelines.map((pipeline) => pipeline.id)) : setbundledPipelineIds([]);
    }, [bundled, dataPipelines]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => {
        setNodes(
            createNodes(
                operations,
                dataPipelines,
                dataRepositories,
                bundledPipelineIds,
                setbundledPipelineIds,
                dataTable,
                pipelineLayout
            )
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [operations, dataPipelines, dataRepositories, bundledPipelineIds, dataTable]);

    useEffect(() => {
        setEdges(createEdges(operations, dataPipelines, bundledPipelineIds, dataTable));
    }, [operations, dataPipelines, dataTable, bundledPipelineIds, pipelineLayout]);

    const onNodesChange = useCallback(
        (changes: NodeChange[]) => {
            setNodes((nds) => {
                debouncedSave(nds);
                return applyNodeChanges(changes, nds);
            });
        },
        [setNodes, debouncedSave]
    );
    const onEdgesChange = useCallback(
        (changes: EdgeChange[]) => setEdges((eds) => applyEdgeChanges(changes, eds)),
        [setEdges]
    );

    const zoomFunctionRef = useRef<(e: WheelEvent) => void>();
    const handleMouseEnter = () => {
        const handleWheel = (e: WheelEvent) => {
            if (e.ctrlKey || e.metaKey) {
                e.preventDefault();
            }
        };
        zoomFunctionRef.current = handleWheel;
        window.addEventListener("wheel", handleWheel, { passive: false });
    };
    const handleMouseLeave = () => {
        if (zoomFunctionRef.current) {
            window.removeEventListener("wheel", zoomFunctionRef.current);
        }
    };

    return (
        <Stack>
            <CanvasToolbar dataTableId={dataTable.id} />
            <Stack direction="row" minHeight="80vh">
                <ReactFlow
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                    nodeTypes={NODE_TYPES}
                    edgeTypes={EDGE_TYPES}
                    nodes={nodes}
                    edges={edges}
                    onNodesChange={onNodesChange}
                    onEdgesChange={onEdgesChange}
                    fitView
                    fitViewOptions={{ minZoom: 0.2 }}
                    minZoom={0.2}
                >
                    <Background />
                    <Controls />
                </ReactFlow>
            </Stack>
        </Stack>
    );
};

export const PipelineCanvas = ({ dataPipelines, dataTable }: Props) => {
    const { result: operationsResult } = useGetDataPipelineOperationsQuery({
        input: { dataPipelineIds: dataPipelines.map((p) => p.id) },
    });
    const { result: repostioryResult } = useGetAllDataRepositoriesQuery({});
    const { result: pipelineLayoutResult } = useGetDataPipelineLayoutQuery({
        input: { dataTableId: dataTable.id },
    });
    const { result: IOConnectionsResult } = useGetInputOutputConnectionsQuery({
        input: { dataPipelineIds: dataPipelines.map((p) => p.id) },
    });

    return (
        <GraphqlRequestContainer asyncData={pipelineLayoutResult} loading={<Skeleton height="100%" width="100%" />}>
            {(pipelineLayoutResponse) => (
                <GraphqlRequestContainer asyncData={repostioryResult} loading={<Skeleton height="100%" width="100%" />}>
                    {(respoitoryResponse) => (
                        <GraphqlRequestContainer
                            asyncData={operationsResult}
                            loading={<Skeleton height="100%" width="100%" />}
                        >
                            {(response) => (
                                <GraphqlRequestContainer
                                    asyncData={IOConnectionsResult}
                                    loading={<Skeleton height="100%" width="100%" />}
                                >
                                    {(IOConnectionsResponse) => {
                                        const orderedOperations = orderOperations(
                                            response.dataPipelineOperations,
                                            IOConnectionsResponse.inputOutputConnections
                                        );
                                        return (
                                            <PipelineCanvasContextProvider>
                                                <WrappedPipelineCanvas
                                                    dataPipelines={dataPipelines.filter(
                                                        (dp) => dp.targetDataTableId === dataTable?.id
                                                    )}
                                                    operations={orderedOperations}
                                                    dataRepositories={respoitoryResponse.dataRepositories}
                                                    dataTable={dataTable}
                                                    pipelineLayout={pipelineLayoutResponse.dataPipelineLayout}
                                                />
                                            </PipelineCanvasContextProvider>
                                        );
                                    }}
                                </GraphqlRequestContainer>
                            )}
                        </GraphqlRequestContainer>
                    )}
                </GraphqlRequestContainer>
            )}
        </GraphqlRequestContainer>
    );
};
