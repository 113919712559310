import { defineMessages } from "react-intl";

const messages = defineMessages({
    name: {
        id: "PipelinePageV2.Modals.OperationModal.OperationDetails.Default.ChangeView.name",
        defaultMessage: "Name",
    },
    withColumn: {
        id: "PipelinePageV2.Modals.OperationModal.OperationDetails.Default.ChangeView.withColumn",
        defaultMessage: "With other column",
    },
    operationSpecific: {
        id: "PipelinePageV2.Modals.OperationModal.OperationDetails.Default.ChangeView.operationSpecific",
        defaultMessage: "Change {type}",
    },
});

export default messages;
