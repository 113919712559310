import { SelectableCubeConfigurationField } from "@/containers/CubeBuilderPage/types";

export const isFieldDirectlyOnTable = (elasticField: { field: string }) => 
    // If data_column occur more than once, the field is a related one
     !((elasticField.field.match(/data_column/g) || []).length > 1)
;

export const createIsFieldOnCurrentTable = (relatedDataColumnId?: string) => (field: SelectableCubeConfigurationField) => {
        if (!relatedDataColumnId) {
            return isFieldDirectlyOnTable(field);
        }
        return field.field.startsWith(`data_column_${relatedDataColumnId}-`);
    };
