import { defineMessages } from "react-intl";

const messages = defineMessages({
    maxFileSizeError: {
        id: "fileManager.maxFileSizeError",
        defaultMessage:
            "Maximum file size exceeded. Recieved file of size {fileSize} Mb, maximum allowed is {maxSize} Mb",
    },
});

export default messages;
