import { GraphqlRequestContainer } from "@ignite-analytics/graphql-utilities";
import { Button, Dialog, DialogContent, DialogTitle, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import { OperationExecutionTable } from "@/components/OperationExecutionTable";
import DeletePrompt from "@/components/Prompt/DeletePrompt";
import { testIdPrefix } from "@/components/testIdprefix";
import { hasValue } from "@/containers/CubeBuilderPage/components/ConnectionModal/helpers";
import { OPERATION_NODE_IMPLEMENTATION } from "@/containers/PipelinePageV2/Nodes/constants";
import { useDataColumnsByDataTableId } from "@/contexts/EventContexts/DataColumnEventContext";
import { fm } from "@/contexts/IntlContext";
import {
    DataPipeline,
    DataPipelineOperation,
    useDeleteDataPipelineOperationMutation,
    useGetAllDataRepositoriesQuery,
    useGetDataPipelineOperationQuery,
    useGetInputOutputConnectionsQuery,
    useGetOperationOutputUsageLazyQuery,
} from "@/generated/client";
import globalMessages from "@/messages";
import { createInputSourceItems } from "../../PipelineUtils/index";
import { createUsageMessage, isLookupOperation } from "./helpers";
import { InputMappingSection } from "./InputMappingSection";
import { LookupOperationView } from "./LookupOperationView";
import messages from "./messages";
import { operationComponents } from "./OperationDetails/constants";
import { OutputMappingSection } from "./OutputMappingSection";

interface Props {
    onClose: () => void;
    operation: DataPipelineOperation;
    allOperations: DataPipelineOperation[];
    dataPipeline: DataPipeline;
}

const OperationModal: React.FC<Props> = ({ operation, onClose, allOperations, dataPipeline }) => {
    const { result: dataRepositoryResult } = useGetAllDataRepositoriesQuery({});
    const [showDeletePrompt, setShowDeletePrompt] = useState(true);
    const [getOperationUsage, { result: usageResults }] = useGetOperationOutputUsageLazyQuery();
    const [deleteOperation] = useDeleteDataPipelineOperationMutation({
        refetchQueries: ["getDataPipelineOperations", "getAllPendingChanges"],
    });
    const { result: inputOutputsResult } = useGetInputOutputConnectionsQuery({
        input: { dataPipelineIds: [operation.dataPipelineId] },
    });

    const handleOpenDelete = async () => {
        setShowDeletePrompt(true);
        await getOperationUsage({ input: { id: operation.id } });
    };
    const handleDelete = async () => {
        await deleteOperation({ input: { id: operation.id } });
        setShowDeletePrompt(false);
        onClose();
    };

    const columns = useDataColumnsByDataTableId(dataPipeline.targetDataTableId);

    const OperationComponent = operationComponents[operation.operationType];

    return (
        <Dialog open onClose={onClose} fullWidth maxWidth="lg">
            <DialogTitle>
                <Stack direction="row" gap={1} alignItems="center">
                    {OPERATION_NODE_IMPLEMENTATION[operation.operationType].name}
                    {OPERATION_NODE_IMPLEMENTATION[operation.operationType].iconComponent}
                </Stack>
            </DialogTitle>
            <DialogContent>
                <GraphqlRequestContainer asyncData={dataRepositoryResult}>
                    {(dataRepositoryResponse) => (
                            <GraphqlRequestContainer asyncData={inputOutputsResult}>
                                {(inputOutputConnectionResponse) => {
                                    const relevantRepositories = dataRepositoryResponse.dataRepositories.filter(
                                        (repository) => dataPipeline.sourceDataRepositoryIds.includes(repository.id)
                                    );
                                    const sources = createInputSourceItems(allOperations, relevantRepositories);
                                    const { inputOutputConnections } = inputOutputConnectionResponse;
                                    if (isLookupOperation(operation)) {
                                        const targetDataRepository = dataRepositoryResponse.dataRepositories.find(
                                            (repository) =>
                                                repository.id === operation.lookupOperationAdapter.dataRepositoryId
                                        );
                                        if (targetDataRepository) {
                                            return (
                                                <Stack gap={3}>
                                                    <LookupOperationView
                                                        inputOutputConnections={inputOutputConnections}
                                                        inputSources={sources}
                                                        operation={operation}
                                                        targetDataRepository={targetDataRepository}
                                                        outputDataTableColumns={columns}
                                                        dataPipeline={dataPipeline}
                                                    />
                                                    {relevantRepositories.length && (
                                                        <OperationExecutionTable
                                                            operation={operation}
                                                            sources={sources}
                                                            dataPipeline={dataPipeline}
                                                        />
                                                    )}
                                                    <Button
                                                        size="small"
                                                        color="error"
                                                        onClick={handleOpenDelete}
                                                        data-testid={`${testIdPrefix}-delete-operation-button`}
                                                    >
                                                        {fm(globalMessages.delete)}
                                                    </Button>
                                                </Stack>
                                            );
                                        }
                                    }
                                    return (
                                        <Stack gap={2}>
                                            <Typography variant="subtitle1">
                                                {fm(messages.name, { name: operation.name })}
                                            </Typography>
                                            <InputMappingSection
                                                dataRepositories={dataRepositoryResponse.dataRepositories}
                                                inputOutputConnections={inputOutputConnections}
                                                operation={operation}
                                                allOperations={allOperations}
                                                dataPipeline={dataPipeline}
                                            />
                                            <OperationComponent operation={operation} />
                                            <OutputMappingSection
                                                operation={operation}
                                                dataPipeline={dataPipeline}
                                                inputOutputConnections={inputOutputConnections}
                                                outputDataTableColumns={columns}
                                            />
                                            {relevantRepositories.length && (
                                                <OperationExecutionTable
                                                    operation={operation}
                                                    sources={sources}
                                                    dataPipeline={dataPipeline}
                                                />
                                            )}
                                            <Button
                                                size="small"
                                                color="error"
                                                onClick={handleOpenDelete}
                                                data-testid={`${testIdPrefix}-delete-operation-button`}
                                            >
                                                {fm(globalMessages.delete)}
                                            </Button>
                                        </Stack>
                                    );
                                }}
                            </GraphqlRequestContainer>
                        )}
                </GraphqlRequestContainer>
            </DialogContent>
            <GraphqlRequestContainer asyncData={usageResults} loading={null}>
                {(usage) => (
                    <DeletePrompt
                        title={fm(globalMessages.delete)}
                        description={createUsageMessage({
                            usedInFilters: usage.usedInFilters,
                            columns: usage.dataColumnIds
                                .map((colId) => columns.find((col) => col.id === colId))
                                .filter(hasValue),
                            operations: usage.dataPipelineOperationIds
                                .map((opId) => allOperations.find((op) => op.id === opId))
                                .filter(hasValue),
                        })}
                        onCancel={() => setShowDeletePrompt(false)}
                        onDelete={handleDelete}
                        open={showDeletePrompt}
                    />
                )}
            </GraphqlRequestContainer>
        </Dialog>
    );
};

interface RequestContainerProps {
    onClose: () => void;
    operationId: string;
    allOperations: DataPipelineOperation[];
    dataPipeline: DataPipeline;
}

export const OperationRequestContainer = ({
    operationId,
    onClose,
    allOperations,
    dataPipeline,
}: RequestContainerProps) => {
    const { result: operationResult } = useGetDataPipelineOperationQuery({ input: { id: operationId } });
    return (
        <GraphqlRequestContainer asyncData={operationResult} loading={null}>
            {({ operation }) => (
                <OperationModal
                    operation={operation}
                    onClose={onClose}
                    allOperations={allOperations}
                    dataPipeline={dataPipeline}
                />
            )}
        </GraphqlRequestContainer>
    );
};

export default OperationRequestContainer;
