import { SUPPLIER_GT } from "@ignite-analytics/global-types";
import { GraphqlRequestContainer } from "@ignite-analytics/graphql-utilities";
import { Autocomplete, TextField } from "@mui/material";
import React, { useEffect } from "react";
import { DataColumn } from "@/Types/DataColumn";
import { fm } from "@/contexts/IntlContext";
import { useManyDataTables } from "@/generated/DataTableEntity";
import { DataTable, useGetRelatedDataColumnsByDataTableQuery } from "@/generated/client";
import { SelectableField } from "../types";
import messages from "./messages";

interface Props {
    targetDataTableId: string;
    selectedValue?: SelectableField;
    onChange: (groupByFields: SelectableField) => void;
}

const PREFERRED_GLOBAL_TYPES = [SUPPLIER_GT];

function getSelectableFields(relatedDataColumns: DataColumn[], allDataTables: DataTable[]): SelectableField[] {
    // Sort is on copied array to avoid mutating the original array
    // eslint-disable-next-line fp/no-mutating-methods
    return relatedDataColumns
        .slice()
        .sort((a) => (PREFERRED_GLOBAL_TYPES.includes(a.globalTypeKey ?? "") ? -1 : 1))
        .map((relatedDataColumn) => {
            const dataTable = allDataTables.find((dt) => dt.id === relatedDataColumn.dataTableId);
            if (!dataTable) {
                return undefined;
            }
            return {
                label: `${dataTable.name} (${relatedDataColumn.name})`,
                field: `data_column_${relatedDataColumn.id}`,
                type: "keyword",
                dataTableId: relatedDataColumn.dataTableId,
            };
        })
        .filter((field): field is SelectableField => !!field);
}

export const GroupByRelatedColumnSelect: React.FC<Props> = ({ targetDataTableId, selectedValue, onChange }) => {
    const { data: dataTables } = useManyDataTables();
    const { result: relatedDataColumnsResponse } = useGetRelatedDataColumnsByDataTableQuery({
        input: { dataTableId: targetDataTableId },
    });

    useEffect(() => {
        if (!selectedValue && relatedDataColumnsResponse.type === "success" && dataTables) {
            const firstSelectedValue = getSelectableFields(
                relatedDataColumnsResponse.data.dataColumns,
                dataTables ?? []
            )[0];
            if (firstSelectedValue) {
                onChange(firstSelectedValue);
            }
        }
    }, [selectedValue, dataTables, relatedDataColumnsResponse, onChange]);

    return (
        <GraphqlRequestContainer asyncData={relatedDataColumnsResponse}>
            {({ dataColumns }) => (
                    <Autocomplete
                        fullWidth
                        value={selectedValue ?? null}
                        onChange={(_, value) => value && onChange(value)}
                        renderInput={(params) => (
                            <TextField
                                label={fm(messages.groupByFieldSelectLabel)}
                                {...params}
                                inputProps={{ ...params.inputProps }}
                            />
                        )}
                        options={getSelectableFields(dataColumns, dataTables ?? [])}
                    />
                )}
        </GraphqlRequestContainer>
    );
};
