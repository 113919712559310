import { defineMessages } from "react-intl";

const messages = defineMessages({
    tooLarge: {
        id: "dataTables.exportOptions.tooLarge",
        defaultMessage:
            "This table is too large to export (limit {limit} rows). Add filters to limit the number of rows.",
    },
    exportConfirmation: {
        id: "dataTables.exportOptions.exportConfirmation",
        defaultMessage:
            "Creating export. When the process is finished, click on the notification or head to exports on 'Data tables' page to download the file.",
    },
    tooltip: {
        id: "dataTables.exportOptions.tooltip",
        defaultMessage: "Download data table as file(s)",
    },
    title: {
        id: "dataTables.exportOptions.title",
        defaultMessage: "Export {table}",
    },
    new: {
        id: "dataTables.exportOptions.new",
        defaultMessage: "New export",
    },
});

export default messages;
