import { defineMessages } from "react-intl";

const messages = defineMessages({
    analytics: {
        id: "setupCard.title",
        defaultMessage: "Upload spend data",
    },
    suppliers: {
        id: "setupCard.suppliers",
        defaultMessage: "Upload suppliers",
    },
    contracts: {
        id: "setupCard.contracts",
        defaultMessage: "Upload contracts",
    },
    carbon: {
        id: "setupCard.carbon",
        defaultMessage: "Carbon emission data",
    },
    analyticsDescription: {
        id: "setupCard.analyticsDescription",
        defaultMessage: "Load your spend data in Ignite to understand and analyze your spend",
    },
    suppliersDescription: {
        id: "setupCard.suppliersDescription",
        defaultMessage: "Start working with suppliers, assessments and campaigns in Ignite!",
    },
    contractsDescription: {
        id: "setupCard.contractsDescription",
        defaultMessage: "Get an overview of your contracts. Add notes, contacts and alerts!",
    },
    carbonDescription: {
        id: "setupCard.carbonDescription",
        defaultMessage: "Upload or fill in your carbon emission data to get an overview of your carbon footprint!",
    },
    goToModule: {
        id: "setupCard.goToModule",
        defaultMessage: "Go to module",
    },
    getStarted: {
        id: "setupCard.getStarted",
        defaultMessage: "Get started",
    },
    integration: {
        id: "setupCard.integration",
        defaultMessage: "Integrate with ERP system (Recommended)",
    },
    integrationDescription: {
        id: "setupCard.integrationDescription",
        defaultMessage: "We support automatic data pull and setup for customers using xLedger",
    },
});

export default messages;
