import { defineMessages } from "react-intl";

const messages = defineMessages({
    nameLabel: {
        id: "aggregatedView.create.nameLabel",
        defaultMessage: "Aggregated view",
    },
    addAggregationConfigurationButtonText: {
        id: "aggregatedView.create.addAggregationConfigurationButtonText",
        defaultMessage: "Add column",
    },
    cancelButtonText: {
        id: "aggregatedView.create.cancelButtonText",
        defaultMessage: "Cancel",
    },
    createAggregatedViewButtonText: {
        id: "aggregatedView.create.createAggregatedViewButtonText",
        defaultMessage: "Create view",
    },
    groupingFieldsSelectLabel: {
        id: "aggregatedView.create.groupingFieldsSelectLabel",
        defaultMessage: "Columns to group by",
    },
    groupingFieldsHeaderText: {
        id: "aggregatedView.create.groupingFieldsHeaderText",
        defaultMessage: "Select columns to group by",
    },
    aggregatedColumnsHeaderText: {
        id: "aggregatedView.create.aggregatedColumnsHeaderText",
        defaultMessage: "Create new columns to represent aggregated values",
    },
    missingNameValidationErrorText: {
        id: "aggregatedView.create.missingNameValidationErrorText",
        defaultMessage: "Missing name",
    },
    noGroupFieldsValdiationError: {
        id: "aggregatedView.create.noGroupFieldsValdiationError",
        defaultMessage: "You must select at least one field",
    },
    saveButtonText: {
        id: "aggregatedView.create.saveButtontext",
        defaultMessage: "Save",
    },
});

export default messages;
