import { FeatureToggleContextProvider } from "@ignite-analytics/feature-toggle";
import { track } from "@ignite-analytics/track";
import React, { Suspense, useEffect } from "react";
import { Route, Switch } from "react-router-dom";
import { useBasePath } from "@/contexts/BasePathContext";
import { DataColumnContextProvider } from "@/contexts/EventContexts/DataColumnEventContext";
import { DataManagementEventContextProvider } from "@/contexts/EventContexts/DataManagementEventContext";
import {
    EntityChangeEventContextProvider,
    useEntityEventListener,
} from "@/contexts/EventContexts/EntityEventChangeContext";
import { IndexMappingChangeEventContextProvider } from "@/contexts/EventContexts/IndexMappingChangeEventContext";
import { PipelineContextProvider } from "@/contexts/EventContexts/PipelineContext";
import NotificationsContextProvider from "@/contexts/NotificationsContext";
import { AggregatedViewContext } from "@/generated/AggregatedViewEntity";
import { DataTableContext } from "@/generated/DataTableEntity";
import { UniqueIdentifierConfigurationContext } from "@/generated/UniqueIdentifierConfigurationEntity";
import DataTableOverview from "./DataTableOverview";
import { useInjectedReactFlowCss } from "./PipelinePageV2/PipelineCanvas/hooks";
import { EnsureUpdatedElasticFieldsWrapper } from "./UpdateElasticFieldsMappingContainer";
import { useSessionContext } from "@/contexts/SessionContext";
import { PageLayout } from "@/components/Layout/PageLayout";
import { AppLayout } from "@/components/Layout/AppLayout";

const SetupPage = React.lazy(() => import("./SetUpPage"));

/**
 *  CONTAINER - DATA TABLES PAGE
 * ================================================
 *
 * Acts as a parent container with the purpose of routing users to the child containers
 *
 */

const DataTablesPage: React.FC = () => {
    const basePath = useBasePath();
    const { id: userId, email, tenant } = useSessionContext();

    useEffect(() => {
        track("DataTable: App loaded");
    }, []);

    useInjectedReactFlowCss();

    return (
        <FeatureToggleContextProvider tenant={tenant} userEmail={email}>
            <EntityChangeEventContextProvider userId={userId} channel={0} topic="entity-change-event">
                <DataManagementEventContextProvider userId={userId} channel={0} topic="datamanagement-events">
                    <IndexMappingChangeEventContextProvider
                        userId={userId}
                        channel={0}
                        topic="index-mapping-change-events"
                    >
                        <PipelineContextProvider>
                            <DataColumnContextProvider>
                                <EnsureUpdatedElasticFieldsWrapper>
                                    <NotificationsContextProvider
                                        userId={userId}
                                        channel={0}
                                        topic="notifier-broadcast"
                                    >
                                        <DataTableContext useChangeEvent={useEntityEventListener}>
                                            <AggregatedViewContext useChangeEvent={useEntityEventListener}>
                                                <UniqueIdentifierConfigurationContext
                                                    useChangeEvent={useEntityEventListener}
                                                >
                                                    <Switch>
                                                        <Route path={[`${basePath}setup/`]}>
                                                            <Suspense>
                                                                <PageLayout>
                                                                    <SetupPage />
                                                                </PageLayout>
                                                            </Suspense>
                                                        </Route>
                                                        <Route path={[`${basePath}:dataTableId/`, "*/"]}>
                                                            <AppLayout>
                                                                <DataTableOverview />
                                                            </AppLayout>
                                                        </Route>
                                                    </Switch>
                                                </UniqueIdentifierConfigurationContext>
                                            </AggregatedViewContext>
                                        </DataTableContext>
                                    </NotificationsContextProvider>
                                </EnsureUpdatedElasticFieldsWrapper>
                            </DataColumnContextProvider>
                        </PipelineContextProvider>
                    </IndexMappingChangeEventContextProvider>
                </DataManagementEventContextProvider>
            </EntityChangeEventContextProvider>
        </FeatureToggleContextProvider>
    );
};

export default DataTablesPage;
