import { LoadingButton } from "@mui/lab";
import { Stack, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { DataColumn } from "@/Types/DataColumn";
import { fm } from "@/contexts/IntlContext";
import { useUpdateDataColumnMutation } from "@/generated/client";
import messages from "@/messages";

interface Props {
    dataColumn: DataColumn;
    onAction: () => void;
}

export const RenameDataColumn: React.FC<Props> = ({ dataColumn, onAction }) => {
    const [updateDataColumn, { result: updateResult }] = useUpdateDataColumnMutation();
    const [updatedName, setUpdatedName] = useState<string>(dataColumn.name);
    const [editNameState, setEditNameState] = useState(false);

    useEffect(() => {
        setUpdatedName(dataColumn.name);
    }, [setUpdatedName, dataColumn]);

    const handleNameChange = async () => {
        setEditNameState(false);
        if (!updatedName.length || updatedName === dataColumn.name) return;
        await updateDataColumn({
            input: {
                id: dataColumn.id,
                name: updatedName,
                dataTableId: dataColumn.dataTableId,
                globalTypeKey: dataColumn.globalTypeKey,
                referencedId: dataColumn.referencedId,
            },
        });
        onAction();
    };

    return (
        <Stack direction="row" alignItems="center" spacing={2}>
                <TextField
                    label="Name"
                    name="name"
                    value={updatedName}
                    size="small"
                    onChange={(e) => setUpdatedName(e.target.value)}
                />
                {updatedName !== dataColumn.name && (
                    <LoadingButton
                        variant="contained"
                        color={editNameState ? "primary" : "secondary"}
                        size="small"
                        loading={updateResult.type === "loading"}
                        disabled={updatedName === dataColumn.name}
                        onClick={() => {
                            if (updatedName !== dataColumn.name) handleNameChange();
                        }}
                    >
                        {fm(messages.save)}
                    </LoadingButton>
                )}
            </Stack>
    );
};
