import { ManageAccounts } from "@mui/icons-material";
import { Button, Dialog, DialogContent, DialogTitle } from "@mui/material";
import React, { useState } from "react";
import { fm } from "@/contexts/IntlContext";
import { DataTable } from "@/generated/client";
import { PermissionContainer } from "@/lib/permissions";
import { AdminPage } from "./index";
import messages from "./messages";

interface Props {
    dataTable: DataTable;
}

export const InModalAdminPanel: React.FC<Props> = ({ dataTable }) => {
    const [open, setOpen] = useState(false);
    const toggleOpen = () => setOpen((prev) => !prev);

    return (
        <>
            <PermissionContainer
                namespace="data-tables"
                relation={{ object: "privileged", relation: "write" }}
                unauthorizedComponent={
                    <Button size="small" variant="outlined" color="secondary" startIcon={<ManageAccounts />} disabled>
                        {fm(messages.adminPanel)}
                    </Button>
                }
                tooltipMsg={fm(messages.unauthorizedAdminPanelTooltip).toString()}
            >
                <Button
                    size="small"
                    variant="outlined"
                    color="secondary"
                    onClick={toggleOpen}
                    startIcon={<ManageAccounts />}
                >
                    {fm(messages.adminPanel)}
                </Button>
            </PermissionContainer>
            <Dialog fullWidth maxWidth="xl" open={open} onClose={toggleOpen}>
                <DialogTitle> {fm(messages.adminPanel)}</DialogTitle>
                <DialogContent>
                    <AdminPage dataTable={dataTable} />
                </DialogContent>
            </Dialog>
        </>
    );
};
