import React from "react";
import { fm } from "@/contexts/IntlContext";
import Prompt from "@/components/Prompt";
import { TranslatedString } from "@/Types/translation";
import messages from "./messages";

interface Props {
    open: boolean;
    title?: TranslatedString;
    description?: TranslatedString;
    isProcessing?: boolean;
    onCancel: () => void;
    onDelete: () => void;
    acceptTestId?: string;
    declineTestId?: string;
    writeConfirmation?: boolean;

    children?: React.ReactNode;
}

/**
 * Simple prompt that can be used to present the user with a
 * chance to abort a delete
 */

const DeletePrompt: React.FC<Props> = ({
    open,
    title,
    description,
    isProcessing,
    onDelete,
    onCancel,
    children,
    acceptTestId,
    declineTestId,
    writeConfirmation,
}) => (
        <Prompt
            open={open}
            header={title}
            description={description}
            acceptText={fm(messages.delete).toString()}
            declineText={fm(messages.cancel).toString()}
            isProcessing={isProcessing}
            onAccept={onDelete}
            onClose={onCancel}
            acceptTestId={acceptTestId}
            declineTestId={declineTestId}
            writeConfirmation={writeConfirmation}
        >
            {children}
        </Prompt>
    );

export default DeletePrompt;
