import { Stack, Typography } from "@mui/material";
import React from "react";
import { createInputSourceItems } from "@/containers/PipelinePageV2/PipelineUtils";
import { fm } from "@/contexts/IntlContext";
import { DataPipeline, DataPipelineOperation, DataRepository, InputOutputConnection } from "@/generated/client";
import messages from "../messages";
import { InputSelect } from "./InputSelect";

interface Props {
    operation: DataPipelineOperation;
    allOperations: DataPipelineOperation[];
    dataPipeline: DataPipeline;
    dataRepositories: DataRepository[];
    inputOutputConnections: InputOutputConnection[];
}

export const InputMappingSection: React.FC<Props> = ({
    operation,
    allOperations,
    dataPipeline,
    dataRepositories,
    inputOutputConnections,
}) => {
    const relevantRepositories = dataRepositories.filter((repository) =>
        dataPipeline.sourceDataRepositoryIds.includes(repository.id)
    );
    const sources = createInputSourceItems(allOperations, relevantRepositories);

    return (
        <Stack gap={1}>
            <Typography variant="subtitle2">{fm(messages.input)}</Typography>
            <Stack gap={1}>
                {operation.input.map((input) => (
                    <InputSelect
                        inputOutputConnections={inputOutputConnections}
                        input={input}
                        sources={sources}
                        key={input.id}
                        operation={operation}
                    />
                ))}
            </Stack>
        </Stack>
    );
};
