import { defineMessages } from "react-intl";

const messages = defineMessages({
    description: {
        id: "GlobalTagConfirmation.description",
        defaultMessage: "Connect your transaction value, date and currency by linking the following fields",
    },
    supplierDescription: {
        id: "GlobalTagConfirmation.supplierDescription",
        defaultMessage: "Tell us about your suppliers by linking the following fields or leave them blank.",
    },
    spendAmount: {
        id: "GlobalTagConfirmation.spendAmount",
        defaultMessage: "Transaction amount",
    },
    spendDate: {
        id: "GlobalTagConfirmation.spendDate",
        defaultMessage: "Date of transaction",
    },
    currency: {
        id: "GlobalTagConfirmation.currency",
        defaultMessage: "Currency of transaction",
    },
    currencyExplanation: {
        id: "GlobalTagConfirmation.currencyExplanation",
        defaultMessage:
            "If you have a column containing currency of transaction, we can use this column to convert all transactions into the same currency",
    },
    supplierExplanations: {
        id: "GlobalTagConfirmation.supplierExplanations",
        defaultMessage:
            "If you have supplier country and organization number we can enrich your data with financial & public data from Enin.",
    },
    supplierName: {
        id: "GlobalTagConfirmation.supplierName",
        defaultMessage: "Supplier name",
    },
    supplierCountry: {
        id: "GlobalTagConfirmation.supplierCountry",
        defaultMessage: "Country of supplier",
    },
    OrgNr: {
        id: "GlobalTagConfirmation.OrgNr",
        defaultMessage: "Organization/VAT number of supplier",
    },
    contractTitle: {
        id: "GlobalTagConfirmation.contractTitle",
        defaultMessage: "Contract title",
    },
    contractDescription: {
        id: "GlobalTagConfirmation.contractDescription",
        defaultMessage: "Contract description",
    },
    endDate: {
        id: "GlobalTagConfirmation.contractEndDate",
        defaultMessage: "Contract end date",
    },
    startDate: {
        id: "GlobalTagConfirmation.startDate",
        defaultMessage: "Contract start date",
    },
    selectPrompt: {
        id: "GlobalTagConfirmation.selectPrompt",
        defaultMessage: "We need you to tell us what columns from your file represents the following",
    },
    transaction: {
        id: "GlobalTagConfirmation.transaction",
        defaultMessage: "transaction related columns",
    },
    supplier: {
        id: "GlobalTagConfirmation.supplier",
        defaultMessage: "supplier  related columns",
    },
    contract: {
        id: "GlobalTagConfirmation.contract",
        defaultMessage: "contract  related columns",
    },
    notPresent: {
        id: "GlobalTagConfirmation.notPresent",
        defaultMessage: "Not present in my data",
    },
    selectColumn: {
        id: "GlobalTagConfirmation.selectColumn",
        defaultMessage: "Select column",
    },
});

export default messages;
