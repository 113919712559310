import { GraphqlRequestContainer } from "@ignite-analytics/graphql-utilities";
import { Cancel, Check, Delete, Edit } from "@mui/icons-material";
import { IconButton, Stack, TableCell, TableRow, Typography, TextField } from "@mui/material";
import React, { useState } from "react";
import { useUpdateMappingOperationElementMutation } from "@/generated/client";

interface Props {
    mapping: { elementId: number; from: string; to: string } | { from: null; to: null };
    operationId: string;
    onAdd?: () => void;
    onDelete: () => void;
}
const MappingElement: React.FC<Props> = ({ mapping, operationId, onAdd, onDelete }) => {
    const [editState, setEditState] = useState(mapping.from === null);
    const [from, setFrom] = useState(mapping.from);
    const [to, setTo] = useState(mapping.to);

    const [updateMappingOperationElement, { result: updateResult }] = useUpdateMappingOperationElementMutation({
        refetchQueries: ["getDataPipelineOperations", "getDataPipelineOperation"],
    });

    const handleSave = async () => {
        if (onAdd) {
            await updateMappingOperationElement({
                input: { updateType: "ADD", operationId, from: from?.toUpperCase() ?? "", to: to ?? "" },
            });
            onAdd();
        } else if ("elementId" in mapping && from !== null && to !== null) {
            await updateMappingOperationElement({
                input: { updateType: "EDIT", operationId, elementId: mapping.elementId, from: from.toUpperCase(), to },
            });
        }
        setEditState((current) => !current);
    };

    const handleCancel = () => {
        setFrom(mapping.from);
        setTo(mapping.to);
        setEditState(false);
    };

    const handleDelete = async () => {
        if ("elementId" in mapping)
            await updateMappingOperationElement({
                input: { updateType: "REMOVE", operationId, elementId: mapping.elementId, from: "", to: "" },
            });
        onDelete();
        setEditState(false);
    };
    return (
        <TableRow>
            {editState ? (
                <>
                    <TableCell>
                        <TextField
                            onChange={(e) => setFrom(e.target.value)}
                            value={from ?? ""}
                            label="From"
                            name="from"
                        />
                    </TableCell>
                    <TableCell>
                        <TextField onChange={(e) => setTo(e.target.value)} value={to ?? ""} label="To" name="to" />
                    </TableCell>
                </>
            ) : (
                <>
                    <TableCell>
                        <Typography>{mapping.from}</Typography>
                    </TableCell>
                    <TableCell>
                        <Typography>{mapping.to}</Typography>
                    </TableCell>
                </>
            )}
            <TableCell>
                <Stack direction="row" gap={1} alignItems="center">
                    <IconButton
                        onClick={() => {
                            if (editState) {
                                handleSave();
                            } else {
                                setEditState((current) => !current);
                            }
                        }}
                    >
                        {editState ? <Check /> : <Edit />}
                    </IconButton>
                    <IconButton
                        onClick={() => {
                            editState ? handleCancel() : handleDelete();
                        }}
                    >
                        {editState ? <Cancel /> : <Delete />}
                    </IconButton>
                    <GraphqlRequestContainer asyncData={updateResult} loading={null} />
                </Stack>
            </TableCell>
        </TableRow>
    );
};

export default MappingElement;
