import { GraphqlRequestContainer } from "@ignite-analytics/graphql-utilities";
import { track } from "@ignite-analytics/track";
import { Info } from "@mui/icons-material";
import {
    Button,
    Checkbox,
    MenuItem,
    Select,
    SelectChangeEvent,
    Stack,
    TextField,
    Tooltip,
    Typography,
} from "@mui/material";
import React, { useState } from "react";
import { testIdPrefix } from "@/components/testIdprefix";
import { useEntityEventListener } from "@/contexts/EventContexts/EntityEventChangeContext";
import { fm } from "@/contexts/IntlContext";
import {
    CriterionType,
    DataRepository,
    DataRepositoryField,
    useCreateGetOrCreateDataPipelineOperationMutation,
    useGetAllDataRepositoriesQuery,
} from "@/generated/client";
import { useManyUniqueIdentifierConfigurations } from "@/generated/UniqueIdentifierConfigurationEntity";
import globalMessages from "@/messages";
import { OperationComponentProps } from "..";
import AdditionalFields from "./AdditionalFields";
import { NewGetOrCreateRepository } from "./CreateRepositoryForm";
import MatchCriteria from "./MatchCriteria";
import messages from "./messages";

export type Criterion = { dataRepositoryField: DataRepositoryField; criterionType: CriterionType };

const CreateGetOrCreateOperationForm: React.FC<OperationComponentProps> = ({ dataPipeline, onFormSubmit }) => {
    const { result, refetch } = useGetAllDataRepositoriesQuery({});
    const [createGetOrCreateDataPipelineOperation, mutation] = useCreateGetOrCreateDataPipelineOperationMutation({
        refetchQueries: ["getDataPipelineOperations"],
    });
    const uniqueConfigurations = useManyUniqueIdentifierConfigurations();

    const [newRepositoryDialogOpen, setNewRepositoryDialogOpen] = useState(false);
    const [name, setName] = useState<string>();
    const [upsert, setUpsert] = useState<boolean>(true);
    const [selectedDataRepository, setSelectedDataRepository] = useState<DataRepository>();
    const [getOrCreateCriteria, setGetOrCreateCriteria] = useState<Criterion[]>([]);

    const handleSubmit = async () => {
        if (!selectedDataRepository || !name || mutation.result.type !== "not-asked") {
            return;
        }

        const createResult = await createGetOrCreateDataPipelineOperation({
            input: {
                targetDataRepositoryId: selectedDataRepository.id,
                dataPipelineId: dataPipeline.id,
                name,
                getOrCreateCriteria: getOrCreateCriteria.map((getOrCreateCriterion) => ({
                    dataRepositoryFieldId: getOrCreateCriterion.dataRepositoryField.id,
                    criterionType: getOrCreateCriterion.criterionType,
                })),
                upsert,
            },
        });

        track("Pipeline: Created Pipeline Operation", {
            operationType: "GET_OR_CREATE",
        });
        onFormSubmit(createResult.data?.createGetOrCreateDataPipelineOperation.dataPipelineOperation);
    };
    useEntityEventListener("UniqueIdentifierConfiguration", () => {
        refetch();
    });
    return (
        <Stack>
            {newRepositoryDialogOpen && (
                <NewGetOrCreateRepository
                    onCreate={(newRepository: DataRepository) => {
                        setNewRepositoryDialogOpen(false);
                        setSelectedDataRepository(newRepository);
                    }}
                    onClose={() => setNewRepositoryDialogOpen(false)}
                />
            )}

            <Stack gap={1}>
                <Stack direction="row" gap={1}>
                    <Typography variant="subtitle1">{fm(messages.operationHeader)}</Typography>
                    <Tooltip title={fm(messages.operationTooltip)}>
                        <Info />
                    </Tooltip>
                </Stack>
                <Stack direction="row" alignItems="center" gap={1}>
                    <TextField
                        label={fm(messages.nameOperation)}
                        name="nameOperation"
                        onChange={(e) => setName(e.target.value)}
                    />
                    <GraphqlRequestContainer asyncData={result}>
                        {({ dataRepositories }) => (
                            <Select
                                fullWidth
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                label=""
                                sx={{ backgroundColor: (theme) => theme.palette.background.default }}
                                onChange={(e: SelectChangeEvent<string>) =>
                                    e.target.value !== null &&
                                    e.target.value !== "NEW" &&
                                    setSelectedDataRepository(dataRepositories.find((r) => r.id === e.target.value))
                                }
                            >
                                <MenuItem key="NEW" value="NEW">
                                    <Button
                                        size="small"
                                        onClick={() => {
                                            setNewRepositoryDialogOpen(true);
                                        }}
                                    >
                                        {fm(messages.newRepository)}
                                    </Button>
                                </MenuItem>
                                {dataRepositories
                                    .filter((repository) =>
                                        uniqueConfigurations.data?.some(
                                            (config) => config.repositoryId === repository.id
                                        )
                                    )
                                    .map((dataRepository) => (
                                            <MenuItem key={dataRepository.id} value={dataRepository.id}>
                                                {dataRepository.name}
                                            </MenuItem>
                                        ))}
                            </Select>
                        )}
                    </GraphqlRequestContainer>
                    <Checkbox name="upsert" checked={upsert} onChange={(e) => setUpsert(e.currentTarget.checked)} />
                    <Typography>{fm(messages.upsert)}</Typography>
                    <Tooltip title={fm(messages.upsertTooltip)}>
                        <Info />
                    </Tooltip>
                </Stack>
                {selectedDataRepository && (
                    <>
                        <MatchCriteria dataRepository={selectedDataRepository} />
                        <AdditionalFields
                            dataRepository={selectedDataRepository}
                            criteria={getOrCreateCriteria}
                            onChange={(criteria) => setGetOrCreateCriteria(criteria)}
                        />
                        <Button
                            size="small"
                            color="success"
                            onClick={handleSubmit}
                            data-testid={`${testIdPrefix}-create-get-or-create-operation`}
                        >
                            {fm(globalMessages.submitButton)}
                        </Button>
                    </>
                )}
            </Stack>
        </Stack>
    );
};

export default CreateGetOrCreateOperationForm;
