import { createDetailResource, createEntityContext, createListResource } from "@ignite-analytics/entities";
import { GlobalColumnType } from "@ignite-analytics/global-types";

const listCreateResource = createListResource<GlobalColumnType>`/global-types/columns/`;
const detailResource = createDetailResource<GlobalColumnType>`/global-types/columns/${(params) => params.id}/`;

export const { Provider: GlobalColumnTypeContextProvider, useAll: useAllGlobalColumnTypes } = createEntityContext(
    listCreateResource,
    detailResource,
    {
        interchangableTypes: true,
        name: "GlobalColumnType fields",
    }
);
