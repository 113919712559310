import { defineMessages } from "react-intl";

const messages = defineMessages({
    searchOperations: {
        id: "dataTables.operationMenu.searchOperations",
        defaultMessage: "Search operations",
    },
    addDataSource: {
        id: "dataTables.operationMenu.addDataSource",
        defaultMessage: "Upload data to table or use existing data source",
    },
    noPermissions: {
        id: "dataTables.operationMenu.noPermissions",
        defaultMessage: "You don't have permission to add this component",
    },
});

export default messages;
