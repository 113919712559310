import { defineMessages } from "react-intl";

const messages = defineMessages({
    test: {
        id: "components.testConnectionResults.test",
        defaultMessage: "Test connection",
    },
    open: {
        id: "components.testConnectionResults.open",
        defaultMessage: "Open test mode",
    },
    title: {
        id: "components.testConnectionResults.title",
        defaultMessage: "Test connection on a subset of the target table",
    },
    testTooltip: {
        id: "components.testConnectionResults.testTooltip",
        defaultMessage: "Tries to find 100 matches for 100 unique rows of your target table",
    },
    targetRows: {
        id: "components.testConnectionResults.targetRows",
        defaultMessage: "Unique {target} rows tested:   {amount}",
    },
    targetRowsWithMatch: {
        id: "components.testConnectionResults.targetRowsWithMatch",
        defaultMessage: "{target} rows with matches:    {amount}",
    },
    targetRowsWithNoMatch: {
        id: "components.testConnectionResults.targetRowsWithNoMatch",
        defaultMessage: "{target} rows with no matches: {amount}",
    },
    baseTableMatches: {
        id: "components.testConnectionResults.baseTableMatches",
        defaultMessage: "{base} rows that matched:      {amount}",
    },
    filterTooltip: {
        id: "components.testConnectionResults.filterTooltip",
        defaultMessage: "Use the filter button to the right to pick specific {target} rows to test connection on",
    },
});

export default messages;
