import { useTheme } from "@mui/material";
import { Edit } from "@mui/icons-material";
import React from "react";
import { getSmoothStepPath, EdgeProps } from "reactflow";

export const CustomEdge: React.FC<EdgeProps> = ({
    id,
    sourceX,
    sourceY,
    targetX,
    targetY,
    sourcePosition,
    targetPosition,
    style = {},
    data,
    markerEnd,
}) => {
    const foreignObjectSize = 30;
    const [edgePath, edgeCenterX, edgeCenterY] = getSmoothStepPath({
        sourceX,
        sourceY,
        sourcePosition,
        targetX,
        targetY,
        targetPosition,
    });
    const theme = useTheme();

    return (
        <>
            <path id={id} style={style} className="react-flow__edge-path" d={edgePath} markerEnd={markerEnd} />
            <foreignObject
                width={foreignObjectSize}
                height={foreignObjectSize}
                x={edgeCenterX - foreignObjectSize / 2}
                y={edgeCenterY - foreignObjectSize / 2}
            >
                <body>
                    <div
                        style={{
                            cursor: "pointer",
                            width: foreignObjectSize,
                            height: foreignObjectSize,
                            borderRadius: "50%",
                            borderWidth: "1px",
                            borderStyle: "solid",
                            borderColor: theme.palette.secondary.main,
                            backgroundColor: theme.palette.success.main,
                            display: "flex",
                            justifyContent: "center",
                            padding: "5px 0 0 0 ",
                        }}
                    >
                        <Edit sx={{ height: "18px" }} onClick={() => data.onEdgeClick()} />
                    </div>
                </body>
            </foreignObject>
        </>
    );
};
