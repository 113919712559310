import { defineMessages } from "react-intl";

const messages = defineMessages({
    advancedSettings: {
        id: "filePreview.advancedSettings",
        defaultMessage: "Open file settings",
    },
    confirm: {
        id: "filePreview.confirm",
        defaultMessage: "Confirm file parsing",
    },
    fileConfirmText: {
        id: "filePreview.fileConfirmText",
        defaultMessage: "Does the file preview look as expected? If not, try changing the file settings on the bottom",
    },
    showFileSettings: {
        id: "filePreview.showFileSettings",
        defaultMessage: "Show file settings",
    },
});

export default messages;
