import React, { useState } from "react";
import {
    Button,
    Select,
    Stack,
    Typography,
    TableContainer,
    Table,
    TableBody,
    TableHead,
    TableRow,
    TableCell,
    MenuItem,
} from "@mui/material";
import { Warning } from "@mui/icons-material";
import globalMessages from "@/messages";
import { fm } from "@/contexts/IntlContext";
import { useAllGlobalColumnTypes } from "@/entities/globalColumnType";
import { DataColumnTypeInput, useCreateDataColumnsMutation } from "@/generated/client";
import { useCreateDataTableAction } from "@/generated/DataTableEntity";
import { FileDropZone } from "@/components/FileDropZone";
import messages from "./messages";

export interface TableSpec {
    type: "DataTable";
    name: string;
    columns: { name: string; globalTypeKey: string; dataType: DataColumnTypeInput }[];
}
interface Props {
    onCreate: (dataTableId: string) => void;
}

const VALID_TYPES: DataColumnTypeInput[] = ["BOOLEAN", "DATE", "MONETARY_AMOUNT", "NUMBER", "TEXT"];

export const CreateTableFromSpec = ({ onCreate }: Props) => {
    const [createDataTable] = useCreateDataTableAction();
    const [createDataColumns] = useCreateDataColumnsMutation();
    const [selectedSpec, setSpec] = useState<TableSpec | undefined>();
    const [fileError, setFileError] = useState<boolean>(false);
    const globalColumnTypes = useAllGlobalColumnTypes(undefined, { service: "dashboards" });
    const [gtWarning, setGTWarning] = useState(false);
    const existingGlobalTypeKeys = globalColumnTypes.map((type) => type.key);
    const [overRides, setOverrides] = useState<{ name: string; type: DataColumnTypeInput }[]>([]);

    const handleSave = () => {
        selectedSpec &&
            createDataTable({ name: selectedSpec.name }).then(async (res) => {
                if (res && res.id) {
                    const dataTableId = res.id;
                    await createDataColumns({
                        input: {
                            dataTableId,
                            dataColumns: selectedSpec.columns.map((column) => ({
                                ...column,
                                globalTypeKey: existingGlobalTypeKeys.includes(column.globalTypeKey)
                                    ? column.globalTypeKey
                                    : undefined,
                            })),
                        },
                    });
                    onCreate(dataTableId);
                }
            });
    };

    const handleFile = async (file: File[]) => {
        try {
            const spec = JSON.parse(await file[0].text());
            setSpec(spec);
            spec.columns.forEach((column: { name: string; globalTypeKey: string; dataType: DataColumnTypeInput }) => {
                if (!existingGlobalTypeKeys.includes(column.globalTypeKey)) {
                    setGTWarning(true);
                }
            });
        } catch (error) {
            setFileError(true);
        }
    };

    return (
        <Stack maxHeight="40vh" width="70vw" gap={1} alignContent="center">
            {!selectedSpec && (
                <Stack direction="row" justifyContent="center">
                    <FileDropZone onFile={(file) => handleFile([file])} fileTypes={["json"]} />
                </Stack>
            )}
            {fileError && <Typography>{fm(messages.parseError)}</Typography>}
            {selectedSpec && !fileError && (
                <>
                    <Typography variant="subtitle1">{fm(messages.name, { name: selectedSpec.name })}</Typography>
                    <TableContainer>
                        <Table stickyHeader>
                            <TableHead>
                                <TableCell>{fm(messages.columnName)}</TableCell>
                                <TableCell>{fm(messages.dataType)}</TableCell>
                                <TableCell>{fm(messages.globalType)}</TableCell>
                            </TableHead>
                            <TableBody>
                                {selectedSpec.columns?.map((column) => (
                                    <TableRow
                                        key={column.name}
                                        sx={{
                                            backgroundColor: (theme) =>
                                                VALID_TYPES.includes(column.dataType) ||
                                                overRides.find((o) => o.name === column.name)
                                                    ? undefined
                                                    : theme.palette.error.light,
                                        }}
                                    >
                                        <TableCell>{column.name}</TableCell>
                                        <TableCell>
                                            {VALID_TYPES.includes(column.dataType) ? (
                                                column.dataType
                                            ) : (
                                                <Select
                                                    size="small"
                                                    label={null}
                                                    name="select type"
                                                    value={overRides.find((o) => o.name === column.name)?.type}
                                                    onChange={(e) => {
                                                        const newtype = VALID_TYPES.find((t) => t === e.target.value);
                                                        newtype &&
                                                            setOverrides((prev) => [
                                                                ...prev.filter((o) => o.name === column.name),
                                                                {
                                                                    name: column.name,
                                                                    type: newtype,
                                                                },
                                                            ]);
                                                    }}
                                                >
                                                    {VALID_TYPES.map((type) => (
                                                        <MenuItem value={type} key={type}>
                                                            {type}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            )}
                                        </TableCell>
                                        <TableCell>{column.globalTypeKey}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </>
            )}
            {gtWarning && (
                <Typography sx={{ color: (theme) => theme.palette.warning.dark }}>
                    {fm(messages.nonExistingGlobalTypes)}
                </Typography>
            )}
            {selectedSpec &&
                selectedSpec.columns.filter((c) => !VALID_TYPES.includes(c.dataType)).length - overRides.length > 0 && (
                    <Stack direction="row" alignContent="center">
                        <Warning sx={{ color: (theme) => theme.palette.error.dark }} />
                        <Typography sx={{ color: (theme) => theme.palette.error.dark }}>
                            {fm(messages.missingColumnTypes, {
                                numMissing:
                                    selectedSpec.columns.filter((c) => !VALID_TYPES.includes(c.dataType)).length -
                                    overRides.length,
                            })}
                        </Typography>
                    </Stack>
                )}
            <Button disabled={selectedSpec === undefined} onClick={handleSave}>
                {fm(globalMessages.save)}
            </Button>
        </Stack>
    );
};
