import { defineMessages } from "react-intl";

const messages = defineMessages({
    targetRowsTable: {
        id: "components.ResultsTable.targetRowsTable",
        defaultMessage: " Unique {target} rows",
    },
    baseTableMatchesTable: {
        id: "components.ResultsTable.baseTableMatchesTable",
        defaultMessage: "{base} row matches",
    },
    numMatches: {
        id: "components.ResultsTable.numMatches",
        defaultMessage: "Number of matches",
    },
    showFullRow: {
        id: "components.ResultsTable.showFullRow",
        defaultMessage: "Show all columns",
    },
});

export default messages;
