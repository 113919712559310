import { NotificationsContextProvider as GeneralNotificationsContextProvider } from "@ignite-analytics/notifications";
import React from "react";
import { useIntl } from "react-intl";

interface Props {
    userId: string;
    channel: number;
    topic: string;
    children: React.ReactNode;
}

const NotificationsContextProvider: React.FC<Props> = ({ children, topic, channel, userId }) => {
    const intl = useIntl();

    return (
        <GeneralNotificationsContextProvider
            socketUrl={`${process.env.REACT_APP_WS_PROTOCOL || ""}://${
                process.env.REACT_APP_NOTIFICATIONS_URL || ""
            }/ws/v2/${topic}/${localStorage.tenant}/${channel}/${userId}`}
            intl={intl}
        >
            {children}
        </GeneralNotificationsContextProvider>
    );
};

export default NotificationsContextProvider;
