import { GraphqlRequestContainer } from "@ignite-analytics/graphql-utilities";
import LoadingButton from "@mui/lab/LoadingButton";
import React from "react";
import { DataColumn } from "@/Types/DataColumn";
import { fm } from "@/contexts/IntlContext";
import { DataColumnType, useCreateDataColumnsMutation } from "@/generated/client";
import globalMessages from "@/messages";

interface Props {
    dataTableId: string;
    onDataColumnCreated?: (newColumn?: DataColumn) => void;
    dataType: DataColumnType;
    name?: string;
    isList: boolean;
}

export const CreateDefaultColumn: React.FC<Props> = ({ dataTableId, onDataColumnCreated, name, isList, dataType }) => {
    const [createDataColumns, mutation] = useCreateDataColumnsMutation({ refetchQueries: ["getAllDataColumns"] });

    const onSubmit = () => {
        if (mutation.result.type === "loading" || !name) {
            return;
        }
        createDataColumns({
            input: {
                dataTableId,
                dataColumns: [
                    {
                        dataType,
                        name,
                        isList,
                    },
                ],
            },
        }).then((res) => onDataColumnCreated && onDataColumnCreated(res.data?.createDataColumns.dataColumns.at(0)));
    };
    return (
        <>
            <LoadingButton
                disabled={!name}
                loading={mutation.result.type === "loading"}
                variant="outlined"
                onClick={onSubmit}
            >
                {fm(globalMessages.submitButton)}
            </LoadingButton>
            <GraphqlRequestContainer asyncData={mutation.result} loading={null} />
        </>
    );
};
