import React, { useState } from "react";

import { Delete } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";

import DeletePrompt from "@/components/Prompt/DeletePrompt";
import { useDeleteDataPipelineMutation } from "@/generated/client";

export const DeletePipelineButton: React.FC<{ id: string }> = ({ id }) => {
    const [deletePipeline, { result }] = useDeleteDataPipelineMutation({ refetchQueries: ["getAllDataPipelines"] });
    const [openPrompt, setOpenPrompt] = useState(false);

    const togglePrompt = () => setOpenPrompt((prev) => !prev);
    const handleDelete = async () => {
        await deletePipeline({ input: { dataPipelineId: id } });
        togglePrompt();
    };

    // Loading the delete or the refetch
    const loading = result.type === "loading" || result.type === "success";

    return (
        <>
            <LoadingButton onClick={togglePrompt} loading={loading}>
                {!loading && <Delete color="error" />}
            </LoadingButton>

            {openPrompt && (
                <DeletePrompt open={openPrompt} onCancel={togglePrompt} onDelete={handleDelete} writeConfirmation />
            )}
        </>
    );
};
