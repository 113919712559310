import {
    Autocomplete,
    Button,
    CircularProgress,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField,
    Typography,
} from "@mui/material";
import React, { useState } from "react";
import { DataColumn } from "@/Types/DataColumn";
import { fm } from "@/contexts/IntlContext";
import {
    DataPipeline,
    DataRepository,
    DataRepositoryField,
    DataTable,
    useCreateInputOutputConnectionMutation,
} from "@/generated/client";
import messages from "../messages";
import { createRepositoryToTableConnectionInput, getInputOutputMatches } from "./helpers";

interface Props {
    repository: DataRepository;
    dataTable: DataTable;
    dataPipeline: DataPipeline;
    onCreate: () => void;
    columns: DataColumn[];
}

export const MapColumnsStep: React.FC<Props> = ({ repository, dataPipeline, onCreate, columns }) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [createInputOutputConnection] = useCreateInputOutputConnectionMutation({
        refetchQueries: ["getInputOutputConnections"],
    });
    const [matches, setMatches] = useState<
        { dataColumn: DataColumn; dataRepositoryField: DataRepositoryField | null }[]
    >(getInputOutputMatches(columns, repository));

    if (!matches.length) {
        onCreate();
    }
    const connectSources = async () => {
        const promises = matches.map(
            (match) =>
                match.dataRepositoryField &&
                createInputOutputConnection(
                    createRepositoryToTableConnectionInput(
                        dataPipeline.id,
                        match.dataRepositoryField.id,
                        match.dataColumn.id,
                        "DATA_REPOSITORY_FIELD",
                        "DATA_TABLE_COLUMN"
                    )
                )
        );
        await Promise.all(promises)
            .then()
            .finally(() => {
                setLoading && setLoading(false);
                onCreate();
            });
    };

    return (
        <Stack flexGrow={1}>
            <Typography variant="h5">{fm(messages.connectHeader)}</Typography>
            <Typography>{fm(messages.connectColumnsPrompt)}</Typography>
            <Stack maxHeight="50vh" minHeight="50vh" overflow="auto" padding={{ top: "xs" }}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>{fm(messages.name)}</TableCell>
                            <TableCell>{fm(messages.repositoryField)}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {matches.map((match) => (
                                <TableRow key={match.dataColumn.id}>
                                    <TableCell>
                                        <Typography variant="subtitle2">{match.dataColumn.name}</Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Autocomplete
                                            options={repository.fields}
                                            onChange={(_, v) =>
                                                setMatches((prev) =>
                                                    prev.map((m) =>
                                                        m.dataColumn.id === match.dataColumn.id
                                                            ? { ...m, dataRepositoryField: v }
                                                            : m
                                                    )
                                                )
                                            }
                                            getOptionLabel={(option) => option.name}
                                            defaultValue={match.dataRepositoryField}
                                            renderInput={(params) => <TextField {...params} />}
                                        />
                                    </TableCell>
                                </TableRow>
                            ))}
                    </TableBody>
                </Table>
            </Stack>
            <Stack direction="row" justifyContent="flex-end" gap={1}>
                <Button size="small" onClick={onCreate}>
                    {fm(messages.notNow)}
                </Button>
                <Button size="small" color="success" onClick={connectSources}>
                    {loading && <CircularProgress />}
                    {fm(messages.confirm)}
                </Button>
            </Stack>
        </Stack>
    );
};
