import { openSocketConnection } from "@ignite-analytics/notifications";
import React, { useCallback, useContext, useEffect, useRef } from "react";

type Entity =
    | "DataRepository"
    | "DataColumn"
    | "DataTable"
    | "AggregatedView"
    | "UniqueIdentifierConfiguration"
    | "DataPipeline"
    | "DataPipelineOperation"
    | "PendingChange";
type Event = {
    id: string;
    model: Entity;
    type: "CREATED" | "UPDATED" | "DELETED";
};
type Handler = (event: Event) => void;
type ContextValue = {
    addListener: (entity: Entity, handler: Handler) => void;
};
const Context = React.createContext<ContextValue | null>(null);

type Props = {
    userId: string;
    channel: number;
    topic: string;

    children: React.ReactNode;
};
export const EntityChangeEventContextProvider: React.FC<Props> = ({ userId, channel, topic, children }) => {
    const listeners = useRef<Record<Entity, Handler[]>>({
        DataRepository: [],
        DataColumn: [],
        DataTable: [],
        AggregatedView: [],
        UniqueIdentifierConfiguration: [],
        DataPipeline: [],
        DataPipelineOperation: [],
        PendingChange: [],
    });
    useEffect(() => openSocketConnection(
            `${process.env.REACT_APP_WS_PROTOCOL || ""}://${
                process.env.REACT_APP_NOTIFICATIONS_URL || ""
            }/ws/v2/${topic}/${localStorage.tenant}/${channel}/${userId}`,
            (message) => {
                const event: Event = JSON.parse(message);
                listeners.current[event.model]?.forEach((fn) => fn(event));
            }
        ), [userId, channel, topic]);

    const addListener = useCallback((entity: Entity, handler: Handler) => {
        listeners.current[entity] = listeners.current[entity].concat(handler);
        return () => {
            listeners.current[entity] = listeners.current[entity].filter((fn) => fn !== handler);
        };
    }, []);

    return <Context.Provider value={{ addListener }}>{children}</Context.Provider>;
};

export const useEntityEventListener = (entity: Entity, handler: Handler) => {
    const context = useContext(Context);
    if (!context) {
        throw new Error("Must use useEntityEventListener within an EntityChangeEventContextProvider");
    }
    const { addListener } = context;
    useEffect(() => addListener(entity, handler), [entity, addListener, handler]);
};
