import { isNotNullish } from "@ignite-analytics/general-tools";
import { AutoFixHigh } from "@mui/icons-material";
import { IconButton, Tooltip } from "@mui/material";
import React, { useMemo, useState } from "react";
import { DataColumn } from "@/Types/DataColumn";
import Prompt from "@/components/Prompt";
import { fm } from "@/contexts/IntlContext";
import { DataRepository, InputOutputConnection, useCreateInputOutputConnectionMutation } from "@/generated/client";
import { cleanDataColumnName } from "@/helpers";
import messages from "./messages";

interface Props {
    dataPipelineId: string;
    dataRepository: DataRepository;
    dataColumns: DataColumn[];
    inputOutputConnections: InputOutputConnection[];
    onInputOutputConnectionsCreated: () => void;
}
const AutoFillConnectionsModalButton: React.FC<Props> = ({
    dataPipelineId,
    dataRepository,
    dataColumns,
    inputOutputConnections,
    onInputOutputConnectionsCreated,
}) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [createInputOutputConnection] = useCreateInputOutputConnectionMutation();
    const [isLoading, setIsLoading] = useState(false);

    const suggestions = useMemo(() => {
        const dataColumnsWithMissingConnection = dataColumns.filter(
            (dataColumn) =>
                !inputOutputConnections.some(
                    (inputOutputConnection) =>
                        dataColumn.id === inputOutputConnection.to.referencedId &&
                        inputOutputConnection.to.type === "DATA_TABLE_COLUMN"
                )
        );

        return dataColumnsWithMissingConnection
            .filter((dc) => ["TEXT", "NUMBER", "DATE", "BOOLEAN"].includes(dc.dataType))
            .map((dataColumn) => {
                const matchingRepositoryField = dataRepository.fields.find(
                    (dataRepositoryField) =>
                        cleanDataColumnName(dataRepositoryField.name).toLowerCase() ===
                        cleanDataColumnName(dataColumn.name).toLowerCase()
                );
                if (!matchingRepositoryField) {
                    return undefined;
                }
                return {
                    dataColumnId: dataColumn.id,
                    dataRepositoryFieldId: matchingRepositoryField.id,
                };
            })
            .filter(isNotNullish);
    }, [dataColumns, dataRepository.fields, inputOutputConnections]);

    const handleAutoConnectConfirm = () => {
        setIsLoading(true);
        const promises = suggestions.map((suggestion) =>
            createInputOutputConnection({
                input: {
                    dataPipelineId,
                    from: {
                        type: "DATA_REPOSITORY_FIELD",
                        referencedId: suggestion.dataRepositoryFieldId,
                    },
                    to: {
                        type: "DATA_TABLE_COLUMN",
                        referencedId: suggestion.dataColumnId,
                    },
                },
            })
        );
        Promise.all(promises)
            .then(onInputOutputConnectionsCreated)
            .finally(() => {
                setIsLoading(false);
                setIsModalOpen(false);
            });
    };

    if (suggestions.length === 0) {
        return null;
    }

    return (
        <>
            <Tooltip title={fm(messages.autoConnectButtonText)}>
                <IconButton onClick={() => setIsModalOpen(true)}>
                    <AutoFixHigh />
                </IconButton>
            </Tooltip>
            <Prompt
                isProcessing={isLoading}
                open={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                onAccept={handleAutoConnectConfirm}
            >
                {fm(messages.autoConnectDescription, { numberOfConnections: suggestions.length })}
            </Prompt>
        </>
    );
};

export default AutoFillConnectionsModalButton;
