import { DataColumn } from "@/Types/DataColumn";
import { fm } from "@/contexts/IntlContext";
import { DataPipelineOperation, LookupOperationAdapter } from "@/generated/client";
import messages from "./messages";

export function isLookupOperation(
    operation: DataPipelineOperation
): operation is DataPipelineOperation & { lookupOperationAdapter: LookupOperationAdapter } {
    return (
        operation.lookupOperationAdapter !== undefined &&
        operation.lookupOperationAdapter !== null &&
        operation.operationType === "LOOKUP"
    );
}

export function createUsageMessage(usage: {
    usedInFilters: boolean;
    columns: DataColumn[];
    operations: DataPipelineOperation[];
}) {
    if (!usage.usedInFilters && !usage.columns.length && !usage.operations.length)
        return fm(messages.unused).toString();
    return fm(messages.usage, {
        filterMessage: usage.usedInFilters ? fm(messages.usedInFilter).toString() : "",
        columnMessage: usage.columns.length
            ? fm(messages.asInputToColumns, {
                  columnNames: usage.columns.map((col) => col.name).join(", "),
              }).toString()
            : "",
        operationMessage: usage.operations.length
            ? fm(messages.inTheseOperations, {
                  operationNames: usage.operations.map((op) => op.name).join(", "),
              }).toString()
            : "",
    });
}
