import { CONTRACT_TABLE_GT, SUPPLIER_TABLE_GT, TRANSACTIONS_GT } from "@ignite-analytics/global-types";
import { track } from "@ignite-analytics/track";
import { Card, CardContent, Slide, Stack, Typography } from "@mui/material";
import React, { useRef, useState } from "react";
import { Redirect, useHistory } from "react-router-dom";
import { SuccessStep } from "@/components/SuccessStep";
import { BlueBall } from "@/components/svgs";
import { OrangeBall } from "@/components/svgs/orangeBall";
import { useBasePath } from "@/contexts/BasePathContext";
import { fm } from "@/contexts/IntlContext";
import { useManyDataTables } from "@/generated/DataTableEntity";
import {
    ParseFileInput,
    PrimitiveDataColumnTypeInput,
    useCompleteSetupMutation,
    useGetDataTableRowCountQuery,
} from "@/generated/client";
import { FilePreview, FilePreviewData } from "../../FilePreview";
import { GlobalTagConfiguration } from "../GlobalTagConfiguration";
import messages from "./messages";

type FlowTypes = typeof CONTRACT_TABLE_GT | typeof SUPPLIER_TABLE_GT | typeof TRANSACTIONS_GT;

const messagesMap = {
    [TRANSACTIONS_GT]: "transaction",
    [SUPPLIER_TABLE_GT]: "supplier",
    [CONTRACT_TABLE_GT]: "contract",
    custom: "custom",
} as const;

export const FileToTable: React.FC<{ type?: FlowTypes }> = ({ type }) => {
    const [file, setFile] = useState<{ id: string; name: string } | undefined>(undefined);
    const [filePreview, setFilePreview] = useState<FilePreviewData>();
    const [importConfig, setImportConfig] = useState<Omit<ParseFileInput, "fileId">>();
    const [completeSetup, mutation] = useCompleteSetupMutation();
    const [result, setResult] = useState<{ notificationId: string; dataTableId: string }>();
    const tables = useManyDataTables();
    const hasTableTypeAlready = tables.data?.find((table) => table.globalTypeKey === type);
    const basePath = useBasePath();
    const browserHistory = useHistory();
    const { result: rowCountResult } = useGetDataTableRowCountQuery(
        {
            input: { dataTableId: hasTableTypeAlready?.id || "" },
        },
        { skip: !hasTableTypeAlready }
    );

    // Track initial load of page
    const pageLoadedRef = useRef(false);
    if (!pageLoadedRef.current) {
        pageLoadedRef.current = true;
        if (type === SUPPLIER_TABLE_GT) track("Onboarding: Start - Suppliers");
    }

    const backToStart = () => {
        browserHistory.push(`${basePath}setup/`);
    };

    const handleComplete = async (
        mappings: Record<string, string | null>,
        fileId: string,
        fileName: string,
        filePreviewData: FilePreviewData,
        importConfiguration: Omit<ParseFileInput, "fileId">,
        typeMappings: Record<string, PrimitiveDataColumnTypeInput>
    ) => {
        if (type === SUPPLIER_TABLE_GT) track("Onboarding: Completed - Suppliers");
        const response = await completeSetup({
            input: {
                fields: filePreviewData?.map((f) => ({
                    fieldName: f.fieldKey,
                    tag: mappings[f.fieldKey] ?? null,
                    dataType: typeMappings[f.fieldKey] || "TEXT",
                })),
                tableType: type,
                fileSettings: {
                    fileId,
                    fileName,
                    contentType: importConfiguration.contentType,
                    csvConfiguration: importConfiguration.csvConfiguration,
                    xlsxConfiguration: importConfiguration.xlsxConfiguration,
                },
            },
        });
        setResult(response.data?.completeSetup);
    };

    const handleCompleteWithState = async (
        mappings: Record<string, string | null>,
        typeMappings: Record<string, PrimitiveDataColumnTypeInput>
    ) => {
        if (!(file && filePreview && importConfig)) return;
        await handleComplete(mappings, file.id, file.name, filePreview, importConfig, typeMappings);
    };

    // Uploads file to file management and gets the parsed fiel results for preview
    const handleFileConfirmation = async (
        fields: FilePreviewData,
        importSettings: Omit<ParseFileInput, "fileId">,
        uploadedFileId: string,
        uploadedFile: File
    ) => {
        setFile({ id: uploadedFileId, name: uploadedFile.name });
        setFilePreview(fields);
        setImportConfig(importSettings);
        if (!type) await handleComplete({}, uploadedFileId, uploadedFile.name, fields, importSettings, {});
    };

    const handleBack = () => {
        setFilePreview(undefined);
    };

    if (hasTableTypeAlready && rowCountResult.type === "success" && rowCountResult.data.count) {
        return <Redirect to={`${basePath}${hasTableTypeAlready.id}/table-view/`} />;
    }

    return (
        <>
            {!result && (
                <Stack>
                    <Card>
                        <CardContent>
                            {!filePreview && (
                                <Slide in={!filePreview} direction="left">
                                    <Stack justifyContent="center" gap={2}>
                                        <Typography variant="h6">
                                            {fm(messages.prompt, { dataType: messagesMap[type || "custom"] })}
                                        </Typography>

                                        <FilePreview
                                            onBack={backToStart}
                                            height="50vh"
                                            onFileConfirmation={handleFileConfirmation}
                                        />
                                    </Stack>
                                </Slide>
                            )}
                            {filePreview && type && (
                                <GlobalTagConfiguration
                                    fields={filePreview.map((f) => f.fieldKey)}
                                    filePreviewData={filePreview}
                                    tableType={type}
                                    onComplete={handleCompleteWithState}
                                    onBack={handleBack}
                                    loading={mutation.result.type === "loading"}
                                />
                            )}
                        </CardContent>
                    </Card>
                </Stack>
            )}
            {result && (
                <Stack direction="row" height="90vh" justifyContent="center" alignItems="center">
                    <SuccessStep
                        notificationId={result.notificationId}
                        dataTableId={result.dataTableId}
                        title={fm(messages.successTitle)}
                    />
                </Stack>
            )}
        </>
    );
};

export const FileToTablePageContainer: React.FC<{ children?: React.ReactNode }> = ({ children }) => (
    <Stack sx={{ minHeight: `calc(100vh - 65px)` }}>
        <Stack position="absolute" sx={{ right: "5px", zIndex: 0 }}>
            <OrangeBall />
        </Stack>
        <Stack position="absolute" sx={{ bottom: "5px", left: "5%", zIndex: 0 }}>
            <OrangeBall />
        </Stack>
        <Stack position="absolute" sx={{ bottom: "5px", right: "30%", zIndex: 0 }}>
            <BlueBall />
        </Stack>
        <CardContent sx={{ zIndex: 1 }}>{children}</CardContent>
    </Stack>
);
