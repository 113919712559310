import { ApolloProvider, createGraphqlClient } from "@ignite-analytics/graphql-utilities";
import { getLocale } from "@ignite-analytics/locale2";
import { getDesignTokens, responsiveFontSizes } from "@ignite-analytics/theme";
import { CssBaseline, ThemeProvider as MuiThemeProvider, createTheme } from "@mui/material";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { createBrowserHistory } from "history";
import React from "react";
import { Route, Router } from "react-router-dom";
import { CompatRouter } from "react-router-dom-v5-compat";
import FatalErrorBoundary from "./components/FatalErrorBoundary";
import Containers from "./containers";
import { BasePathProvider } from "./contexts/BasePathContext";
import { IntlContext } from "./contexts/IntlContext";
import SessionContextProvider from "./contexts/SessionContext";
import { EntitiesProvider } from "./entities";
import { errorLink } from "./errorLink";
import { useOnUnhandledRejectionReporter } from "./hooks";
import { MicroAppMountValues } from "./types";
import { CurrencyProvider } from "./contexts/useCurrency/provider";

const uri = process.env.REACT_APP_GRAPHQL_ROUTER_URL as string;
const graphqlClient = createGraphqlClient(uri, errorLink);

/* Data tables MicroApp */
const App: React.FC<MicroAppMountValues> = ({
    history = createBrowserHistory(),
    locale = getLocale(),
    theme = "ignite-riddle",
    path,
    sessionContext,
}) => {
    const queryClient = new QueryClient();
    useOnUnhandledRejectionReporter();
    return (
        <IntlContext locale={locale}>
            <FatalErrorBoundary>
                <QueryClientProvider client={queryClient}>
                    <Router history={history}>
                        <CompatRouter>
                            <CssBaseline />
                            <ApolloProvider client={graphqlClient}>
                                <BasePathProvider path={path ?? "/"}>
                                    <MuiThemeProvider
                                        theme={responsiveFontSizes(createTheme(getDesignTokens(theme, "light")))}
                                    >
                                        <Route path={`${path ?? "/"}`}>
                                            <SessionContextProvider sessionContext={sessionContext}>
                                                <EntitiesProvider>
                                                    <CurrencyProvider>
                                                        <Containers />
                                                    </CurrencyProvider>
                                                </EntitiesProvider>
                                            </SessionContextProvider>
                                        </Route>
                                    </MuiThemeProvider>
                                </BasePathProvider>
                            </ApolloProvider>
                        </CompatRouter>
                    </Router>
                </QueryClientProvider>
            </FatalErrorBoundary>
        </IntlContext>
    );
};

export default App;
