import { defineMessages } from "react-intl";

const messages = defineMessages({
    name: {
        id: "pipelinePage.OperationConfiguration.OperationDetail.MappingOperation.name",
        defaultMessage: "Name",
    },
    from: {
        id: "pipelinePage.OperationConfiguration.OperationDetail.MappingOperation.from",
        defaultMessage: "From",
    },
    fromTooltip: {
        id: "pipelinePage.OperationConfiguration.OperationDetail.MappingOperation.fromTooltip",
        defaultMessage: "From values must be unique. All keys are case insensitive.",
    },
    to: {
        id: "pipelinePage.OperationConfiguration.OperationDetail.MappingOperation.to",
        defaultMessage: "To",
    },
    action: {
        id: "pipelinePage.OperationConfiguration.OperationDetail.MappingOperation.action",
        defaultMessage: "Action",
    },
    addMapping: {
        id: "pipelinePage.OperationConfiguration.OperationDetail.MappingOperation.addMapping",
        defaultMessage: "Add mapping",
    },
    inputSelect: {
        id: "pipelinePage.OperationConfiguration.OperationDetail.MappingOperation.inputSelect",
        defaultMessage: "Input: ",
    },
});

export default messages;
