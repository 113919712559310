import { defineMessages } from "react-intl";

const messages = defineMessages({
    tableMenuHeader: {
        id: "dataTablesOverviewPage.tableMenuHeader",
        defaultMessage: "Data tables",
    },
    rename: {
        id: "dataTablesOverviewPage.reanme",
        defaultMessage: "Rename",
    },
    dataTables: {
        id: "dataTablesOverviewPage.dataTables",
        defaultMessage: "Data tables",
    },
    addTables: {
        id: "dataTablesOverviewPage.addTables",
        defaultMessage: "Add tables",
    },
});

export default messages;
