import { GraphqlRequestContainer } from "@ignite-analytics/graphql-utilities";
import { Add, Delete } from "@mui/icons-material";
import { Button, Card, CardContent, IconButton, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import DeletePrompt from "@/components/Prompt/DeletePrompt";
import { fm } from "@/contexts/IntlContext";
import {
    DataPipelineFilterCombination,
    FilterDataType,
    useDeletePipelineFilterCombinationMutation,
} from "@/generated/client";
import { InputSourceItem } from "@/Types/InputSourceItem";
import messages from "./messages";
import PipelineFilterRow from "./PipelineFilterRow";

interface Props {
    filterCombination: DataPipelineFilterCombination;
    sources: InputSourceItem[];
    fcIndex: number;
}
const PipelineFilterCombination: React.FC<Props> = ({ filterCombination, sources, fcIndex }) => {
    const [showEmptyFilter, setShowEmptyFilter] = useState(false);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [dataType, setDataType] = useState<FilterDataType>("TEXT");
    const [deleteFilterCombination, { result: deleteResult }] = useDeletePipelineFilterCombinationMutation();
    return (
        <Card variant="outlined">
            <CardContent>
                <Stack direction="row" alignItems="center" justifyContent="space-between">
                    <Typography variant="subtitle2">
                        {fm(messages.filterCombinationHeader, { index: fcIndex + 1 })}
                    </Typography>
                    <GraphqlRequestContainer
                        asyncData={deleteResult}
                        notAsked={
                            <IconButton onClick={() => setOpenDeleteModal(true)}>
                                <Delete color="secondary" />
                            </IconButton>
                        }
                    />
                </Stack>
                <DeletePrompt
                    title={fm(messages.deleteFilterCombination)}
                    description={fm(messages.deleteCombinationDescription)}
                    onCancel={() => setOpenDeleteModal(false)}
                    onDelete={() => deleteFilterCombination({ input: { id: filterCombination.id } })}
                    isProcessing={false}
                    open={openDeleteModal}
                />
                <Stack>
                    {!filterCombination.filters.length && !showEmptyFilter && (
                        <Typography variant="subtitle1">{fm(messages.noFilters)}</Typography>
                    )}
                    {filterCombination.filters.map((filter, i) => (
                        <>
                            <PipelineFilterRow
                                key={filter.id}
                                filterCombinationId={filterCombination.id}
                                filter={filter}
                                availableSources={sources}
                                editable
                                onComplete={() => null}
                            />
                            {i !== filterCombination.filters.length - 1 && (
                                <Typography variant="subtitle2">{fm(messages.and)}</Typography>
                            )}
                        </>
                    ))}
                    {showEmptyFilter && (
                        <PipelineFilterRow
                            filterCombinationId={filterCombination.id}
                            availableSources={sources}
                            editable={false}
                            newFilterDataType={dataType}
                            onComplete={() => setShowEmptyFilter(false)}
                        />
                    )}
                    <Stack direction="row" gap={1}>
                        <Button
                            startIcon={<Add />}
                            color="secondary"
                            variant="outlined"
                            size="small"
                            onClick={() => {
                                setDataType("TEXT");
                                setShowEmptyFilter(true);
                            }}
                        >
                            {fm(messages.addTextFilter)}
                        </Button>
                        <Button
                            startIcon={<Add />}
                            color="secondary"
                            variant="outlined"
                            size="small"
                            onClick={() => {
                                setDataType("NUMBER");
                                setShowEmptyFilter(true);
                            }}
                        >
                            {fm(messages.addNumberFilter)}
                        </Button>
                        <Button
                            startIcon={<Add />}
                            color="secondary"
                            variant="outlined"
                            size="small"
                            onClick={() => {
                                setDataType("DATE");
                                setShowEmptyFilter(true);
                            }}
                        >
                            {fm(messages.addDateFilter)}
                        </Button>
                    </Stack>
                </Stack>
            </CardContent>
        </Card>
    );
};

export default PipelineFilterCombination;
