import React from "react";
import { Stack } from "@mui/material";
import { useGetPipelinesByTableId } from "@/contexts/EventContexts/PipelineContext";
import { DataTable } from "@/generated/client";
import { PipelineCanvas } from "./PipelineCanvas";
import { SidebarMenu } from "./SidebarMenu";

type Props = {
    dataTable: DataTable;
};

const PipelinePageV2: React.FC<Props> = ({ dataTable }) => {
    const dataPipelines = useGetPipelinesByTableId(dataTable.id);
    return (
        <Stack>
            <Stack direction="row" width="100%" gap={3}>
                <SidebarMenu dataTable={dataTable} />
                <Stack flexGrow={1}>
                    <PipelineCanvas dataPipelines={dataPipelines} dataTable={dataTable} />
                </Stack>
            </Stack>
        </Stack>
    );
};

export default PipelinePageV2;
