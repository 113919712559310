import { defineMessages } from "react-intl";

const messages = defineMessages({
    tooltip: {
        id: "RegexHelper.tooltip",
        defaultMessage: "Ask AI for a regex suggestion",
    },

    placeholder: {
        id: "RegexHelper.placeholder",
        defaultMessage: "A regex that extracts first two digits of a string",
    },
    getSuggestion: {
        id: "RegexHelper.getSuggestion",
        defaultMessage: "Get suggestion",
    },
});

export default messages;
