import Search from "@mui/icons-material/Search";
import { Stack, Switch, TextField, Typography } from "@mui/material";
import React from "react";
import { fm } from "@/contexts/IntlContext";
import { usePipelineCanvasContext } from "../PipelineCanvas/context";
import messages from "../messages";
import ApplyChangesButtons from "./ApplyChanges/ApplyChangesButtons";

interface Props {
    dataTableId: string;
}
export const CanvasToolbar: React.FC<Props> = ({ dataTableId }) => {
    const [{ searchString, bundled }, dispatch] = usePipelineCanvasContext();
    return (
        <Stack direction="row" justifyContent="space-between" alignItems="center">
            <TextField
                key="canvasSearch"
                size="small"
                placeholder={fm(messages.searchPlaceholder).toString()}
                value={searchString}
                InputProps={{
                    startAdornment: <Search fontSize="small" />,
                }}
                onChange={({ target }) => dispatch({ type: "setSearchString", searchString: target.value })}
                name="searchCanvas"
            />
            <Stack direction="row" alignItems="center" gap={1}>
                <ApplyChangesButtons dataTableId={dataTableId} />
                <Typography variant="subtitle2" color="InfoText" noWrap sx={{ paddingLeft: 2 }}>
                    {fm(messages.bundleMode)}
                </Typography>
                <Switch
                    name="Bundle nodes"
                    checked={bundled}
                    onChange={({ target }) => {
                        dispatch({ type: "setBundled", bundled: target.checked });
                    }}
                />
            </Stack>
        </Stack>
    );
};
