import { ContentCut, DataArray, JoinLeft, Keyboard, Signpost, Star, Sync, Translate } from "@mui/icons-material";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import CalculateIcon from "@mui/icons-material/Calculate";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import CompareArrowsIcon from "@mui/icons-material/CompareArrows";
import FormatColorFillIcon from "@mui/icons-material/FormatColorFill";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import SortByAlphaIcon from "@mui/icons-material/SortByAlpha";
import TourIcon from "@mui/icons-material/Tour";
import React from "react";
import { fm } from "@/contexts/IntlContext";
import { OperationType } from "@/generated/client";
import { TranslatedString } from "@/Types/translation";
import { Relation } from "@/lib/permissions";
import messages from "./messages";

export const PIPELINE_COLORS = {
    operation: "rgb(255, 194, 194)",
    source: "rgb(253, 245, 207)",
    table: "rgb(212, 252, 245)",
    union: "rgb(230, 240, 254)",
    join: "rgb(206, 227, 255)",
};
export interface NodeInformation {
    name: TranslatedString;
    nodeCollection:
        | "String operations"
        | "Input/Output"
        | "Combine sources"
        | "ML operations"
        | "Numeric operations"
        | "Ignite operations"
        | "Other";
    color: string;
    iconComponent?: React.ReactNode;
    explanation: TranslatedString;
    permissionTuple?: { namespace: string; relation: Relation };
}

export const OPERATION_NODE_IMPLEMENTATION: Record<OperationType, NodeInformation> = {
    LOOKUP: {
        name: fm(messages.leftJoin),
        nodeCollection: "Combine sources",
        color: PIPELINE_COLORS.join,
        iconComponent: <JoinLeft fontSize="small" />,
        explanation: fm(messages.leftJoinExplanation),
    },
    FILL_BLANKS: {
        name: fm(messages.fillBlanks),
        nodeCollection: "Ignite operations",
        color: PIPELINE_COLORS.operation,
        iconComponent: <FormatColorFillIcon fontSize="small" />,
        explanation: fm(messages.fillBlanksExplanation),
    },
    LETTER_CASING: {
        name: fm(messages.convertCasing),
        nodeCollection: "String operations",
        color: PIPELINE_COLORS.operation,
        iconComponent: <SortByAlphaIcon fontSize="small" />,
        explanation: fm(messages.convertCasingExplanation),
    },
    MATHEMATICAL_EXPRESSION: {
        name: fm(messages.arithmeticOperation),
        nodeCollection: "Numeric operations",
        color: PIPELINE_COLORS.operation,
        iconComponent: <CalculateIcon fontSize="small" />,
        explanation: fm(messages.arithmeticOperationExplanation),
    },
    CONVERT_COUNTRY: {
        name: fm(messages.convertCountryCode),
        nodeCollection: "Other",
        color: PIPELINE_COLORS.operation,
        iconComponent: <TourIcon fontSize="small" />,
        explanation: fm(messages.convertCountryCodeExplanation),
    },
    DATE_PARSER: {
        name: fm(messages.dateParser),
        nodeCollection: "Other",
        color: PIPELINE_COLORS.operation,
        iconComponent: <CalendarMonthIcon fontSize="small" />,
        explanation: fm(messages.dateParserExplanation),
    },
    LIST_PARSER: {
        name: fm(messages.listParser),
        nodeCollection: "Other",
        color: PIPELINE_COLORS.operation,
        iconComponent: <DataArray fontSize="small" />,
        explanation: fm(messages.listParserExplanation),
    },
    GET_OR_CREATE: {
        name: fm(messages.createTableRelation),
        nodeCollection: "Ignite operations",
        color: PIPELINE_COLORS.operation,
        iconComponent: <CompareArrowsIcon fontSize="small" />,
        explanation: fm(messages.createTableRelationExplanation),
        permissionTuple: {namespace: "data-sources", relation: { object: "general", relation: "write" } },
    },
    KEYWORD_TAGGER: {
        name: fm(messages.keywordTagger),
        nodeCollection: "Ignite operations",
        color: PIPELINE_COLORS.operation,
        iconComponent: <LocalOfferIcon fontSize="small" />,
        explanation: fm(messages.keywordTaggerExplanation),
    },
    MATCH_COMPANY: {
        name: fm(messages.supplierEnrichment),
        nodeCollection: "Ignite operations",
        color: PIPELINE_COLORS.operation,
        iconComponent: <LocalShippingIcon fontSize="small" />,
        explanation: fm(messages.supplierEnrichmentExplanation),
    },
    MONETARY_AMOUNT: {
        name: fm(messages.convertCurrency),
        nodeCollection: "Ignite operations",
        color: PIPELINE_COLORS.operation,
        iconComponent: <AttachMoneyIcon fontSize="small" />,
        explanation: fm(messages.convertCurrencyExplanation),
    },
    REGEX: {
        name: fm(messages.regex),
        nodeCollection: "Ignite operations",
        color: PIPELINE_COLORS.operation,
        iconComponent: <Star fontSize="small" />,
        explanation: fm(messages.regexExplanation),
    },
    REPLACE: {
        name: fm(messages.replace),
        nodeCollection: "String operations",
        color: PIPELINE_COLORS.operation,
        iconComponent: <Sync fontSize="small" />,
        explanation: fm(messages.replaceExplanation),
    },
    SPLIT: {
        name: fm(messages.splitString),
        nodeCollection: "String operations",
        color: PIPELINE_COLORS.operation,
        iconComponent: <ContentCut fontSize="small" />,
        explanation: fm(messages.splitStringExplanation),
    },
    TEMPLATE_STRING: {
        name: fm(messages.templateString),
        nodeCollection: "String operations",
        color: PIPELINE_COLORS.operation,
        iconComponent: <Keyboard fontSize="small" />,
        explanation: fm(messages.templateStringExplanation),
    },
    TRANSLATION: {
        name: fm(messages.translate),
        nodeCollection: "Other",
        color: PIPELINE_COLORS.operation,
        iconComponent: <Translate fontSize="small" />,
        explanation: fm(messages.translateExplanation),
    },
    MAPPING: {
        name: fm(messages.mapper),
        nodeCollection: "Ignite operations",
        color: PIPELINE_COLORS.operation,
        iconComponent: <Signpost fontSize="small" />,
        explanation: fm(messages.mapperExplanation),
    },
};

export const OPERATION_NODE_HEIGHT = 70;

export const BUNDLE_HEIGHT = 150;
export const X_PADDING = 60;
export const Y_PADDING = 10;
export const INPUT_NODE_SPACING = 160;

export const handleStyle = { opacity: 0 };
export const SHOW_CONTENT_ZOOM = 0.4;
export const X_PADDING_JOIN_NODES = 60;
