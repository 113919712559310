import { defineMessages } from "react-intl";

const messages = defineMessages({
    formNameInputLabel: {
        id: "uploadPage.createImportConfigurationForm.formNameInputLabel",
        defaultMessage: "Name",
    },
    formImportTypeInputLabel: {
        id: "uploadPage.createImportConfigurationForm.formImportTypeInputLabel",
        defaultMessage: "CSV or XLSX",
    },
    delimiter: {
        id: "uploadPage.createImportConfigurationForm.delimiter",
        defaultMessage: "Delimiter",
    },
    quotechar: {
        id: "uploadPage.createImportConfigurationForm.quotechar",
        defaultMessage: "Quote character",
    },
    escapechar: {
        id: "uploadPage.createImportConfigurationForm.escapechar",
        defaultMessage: "Escape character",
    },
    encoding: {
        id: "uploadPage.createImportConfigurationForm.encoding",
        defaultMessage: "Encoding",
    },
    headerRowIndex: {
        id: "uploadPage.createImportConfigurationForm.headerRowIndex",
        defaultMessage: "Header line",
    },
    compression: {
        id: "uploadPage.createImportConfigurationForm.compression",
        defaultMessage: "Compression",
    },
    none: {
        id: "uploadPage.createImportConfigurationForm.none",
        defaultMessage: "None",
    },
    dataStartIndex: {
        id: "uploadPage.createImportConfigurationForm.dataStartIndex",
        defaultMessage: "Include lines from row",
    },
    numberOfEndLinesToDrop: {
        id: "uploadPage.createImportConfigurationForm.numberOfEndLinesToDrop",
        defaultMessage: "Drop rows from bottom",
    },
    sheetMessage: {
        id: "uploadPage.createImportConfigurationForm.sheetMessage",
        defaultMessage: "Note: Ignite will only read the first sheet of your excel file.",
    },
    positiveNumbersOnlyValidation: {
        id: "uploadPage.createImportConfigurationForm.positiveNumbersOnlyValidation",
        defaultMessage: "Only positive numbers are valid for header and include",
    },
    headerBelowDataStartValidation: {
        id: "uploadPage.createImportConfigurationForm.headerBelowDataStartValidation",
        defaultMessage: "Include row must be after header",
    },
    requiredFieldsValidation: {
        id: "uploadPage.createImportConfigurationForm.requiredFieldsValidation",
        defaultMessage: "All fields are required",
    },

    headerRowIndexExplanation: {
        id: "uploadPage.createImportConfigurationForm.headerRowIndexExplanation",
        defaultMessage: "This indicates which line number in excel is the header",
    },

    dataStartIndexExplanation: {
        id: "uploadPage.createImportConfigurationForm.dataStartIndexExplanation",
        defaultMessage: "This indicates which line in excel your data rows begin",
    },
    numberOfEndLinesToDropExplanation: {
        id: "uploadPage.createImportConfigurationForm.numberOfEndLinesToDropExplanation",
        defaultMessage:
            "If you have any rows at the end of the file that you want to remove while uploading, you can select number of lines here",
    },
});

export default messages;
