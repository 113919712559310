import { defineMessages } from "react-intl";

const messages = defineMessages({
    autoConnectButtonText: {
        id: "uploadPage.pipelineConfiguration.autoConnectButtonText",
        defaultMessage: "Auto connect sources",
    },
    autoConnectDescription: {
        id: "uploadPage.pipelineConfiguration.autoConnectDescription",
        defaultMessage: "This will connect the input of {numberOfConnections} data column(s).",
    },
});

export default messages;
