import { toSnakeCase } from "@ignite-analytics/api-client";
import { ElasticField } from "@ignite-analytics/elastic-fields";
import { Filter, getTermsFilter, useFilters } from "@ignite-analytics/filters";

export const postFixForFiltering = [
    "bankrupt",
    "country",
    "domestic_company_country",
    "domestic_company_org_number",
    "domestic_company_country",
    "country",
    "org_number",
    "local_category_code",
    "id",
    "local_category_name",
    "local_category_short_name",
    "local_category_description",
    "international_category_name",
    "international_category_code",
    "international_category_short_name",
    "international_category_description",
    "parent_company_name",
    "parent_company_country",
    "parent_company_org_number",
    "global_company_name",
    "global_company_country",
    "global_company_org_number",
    "domestic_company_name",
    "domestic_company_country",
    "domestic_company_org_number",
    "domestic_company_country",
    "number_of_employees",
    "zip_code",
    "post_location",
    "post_location",
    "bankrupt",
    "larger_revenue",
    "latest_ebit",
    "latest_ebit_margin",
    "latest_profit_margin",
    "latest_year",
    "latest_current_ratio",
    "latest_quicks_ratio",
    "latest_equity_profitability",
    "latest_return_on_assets",
    "latest_equity_ratio",
    "latest_debt_ratio",
    "latest_current_liabilities",
    "latest_total_assets",
];

const defaultFilters: Filter[] = [];

export const useGetFilters = (searchTerm: string, elasticFields: ElasticField[]) => {
    const initialFilters = useFilters() ?? defaultFilters;
    const filteredElasticFields = elasticFields.filter((field) => {
        const postFix = field.fieldId.split("_").slice(3).join("_");
        return !postFixForFiltering.includes(postFix);
    });
    const termsFilter = getTermsFilter(searchTerm, filteredElasticFields);
    const filterCopy = termsFilter ? [...initialFilters, termsFilter] : initialFilters;
    return filterCopy.length ? JSON.stringify(toSnakeCase(filterCopy, false)) : undefined;
};
