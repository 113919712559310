import { Search } from "@mui/icons-material";
import { Card, CardContent, Stack, TextField } from "@mui/material";
import React, { useState } from "react";
import { Handle, Position } from "reactflow";
import {
    DataCubeConfiguration,
    DataTable,
    DataTableConnection,
    useDeleteDataColumnsMutation,
} from "@/generated/client";
import { SelectableCubeConfigurationField } from "@/containers/CubeBuilderPage/types";
import { DataColumn } from "@/Types/DataColumn";
import { NodeHeader } from "./nodeHeader";
import { NodeTable } from "./nodeTable";

export type DataTableNodeData = {
    relatedDataColumnId?: string;
    position: { x: number; y: number };
    dataTable: DataTable | undefined;
    connection?: DataTableConnection;
    base?: boolean;
    pipelineOperationOutputColumn?: DataColumn;
    baseDataCubeConfiguration: DataCubeConfiguration;
    selectableCubeConfigurationFields: SelectableCubeConfigurationField[];
};
interface DataTableNodeProps {
    data: DataTableNodeData;
}

export const DataTableNode: React.FC<DataTableNodeProps> = ({
    data: {
        relatedDataColumnId,
        dataTable,
        base,
        connection,
        position,
        pipelineOperationOutputColumn,
        baseDataCubeConfiguration,
        selectableCubeConfigurationFields,
    },
}: DataTableNodeProps) => {
    const [searchString, setSearchString] = useState("");

    const [deleteConnectionByDeletingDataColumn, mutation] = useDeleteDataColumnsMutation({
        refetchQueries: ["getAllDataTableConnections", "getDataColumns", "getDataTableElasticFields"],
    });
    const handleDelete = async () => {
        connection &&
            (await deleteConnectionByDeletingDataColumn({
                input: { dataColumnIds: [connection.dataColumnId], dataTableId: connection.dataTableId },
            }));
    };

    return (
        <>
            {base ? (
                <>
                    <Handle id="right" type="target" position={Position.Right} />
                    <Handle id="left" type="target" position={Position.Left} />
                </>
            ) : (
                <Handle type="source" position={position?.x < 0 ? Position.Right : Position.Left} />
            )}

            <Handle type={base ? "target" : "source"} position={position?.x < 0 ? Position.Right : Position.Left} />
            <Card
                sx={{ maxWidth: "300px", border: "0.2px solid", borderColor: (theme) => theme.palette.primary.light }}
            >
                <CardContent>
                    <Stack>
                        <NodeHeader
                            dataTableName={dataTable?.name}
                            handleDelete={handleDelete}
                            base={base}
                            pipelineOperationOutputColumn={pipelineOperationOutputColumn}
                            loading={mutation.result.type === "loading"}
                        />

                        <TextField
                            onChange={(e) => {
                                setSearchString(e.target.value);
                            }}
                            InputProps={{
                                endAdornment: <Search fontSize="small" />,
                            }}
                        />

                        <Stack overflow="scroll" direction="row" sx={{ maxHeight: "30vh", minHeight: "30vh" }}>
                            <NodeTable
                                relatedDataColumnId={relatedDataColumnId}
                                base={base}
                                connection={connection}
                                searchString={searchString}
                                pipelineOperationOutputColumn={pipelineOperationOutputColumn}
                                baseDataCubeConfiguration={baseDataCubeConfiguration}
                                selectableCubeConfigurationFields={selectableCubeConfigurationFields}
                            />
                        </Stack>
                    </Stack>
                </CardContent>
            </Card>
        </>
    );
};
