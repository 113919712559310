import {
    FilterContextProvider,
    FilterModal,
    useCompanyFavouriteFilters,
    useFilters,
    useFilterStateAction,
    usePersonalFavouriteFilters,
    useUpsertPersonalFavouriteFilters,
} from "@ignite-analytics/filters";
import { GraphqlRequestContainer } from "@ignite-analytics/graphql-utilities";
import { Filter as FilterIcon } from "@ignite-analytics/icons";
import { LoadingButton } from "@mui/lab";
import { Alert, Autocomplete, Button, Stack, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { aggregationTypeOptions } from "@/components/AggregatedView/CreateAggregatedView/AggregationConfigurationView/types";
import { hasValue } from "@/containers/CubeBuilderPage/components/ConnectionModal/helpers";
import { fm } from "@/contexts/IntlContext";
import { useSessionContext } from "@/contexts/SessionContext";
import { useCreateAggregatedDataColumnMutation } from "@/generated/client";
import { useDataTable } from "@/generated/DataTableEntity";
import {
    mapDataTableToFilterDataSource,
    mapFromUnpopulatedFrontendFiltersToAnalysisFilter,
    useElasticFieldsForFilterContextProvider,
} from "@/helpers";
import globalMessages from "@/messages";
import { DataColumn } from "@/Types/DataColumn";
import { AggregationFieldSelect } from "./AggregationFieldSelect";
import { GroupByRelatedColumnSelect } from "./GroupByRelatedColumnSelect";
import messages from "./messages";
import { SelectableField } from "./types";

interface Props {
    dataTableId: string;
    onDataColumnCreated?: (newColumn?: DataColumn) => void;
    name?: string;
    isList: boolean;
    onDataTableChange: (id: string) => void;
}
const InnerCreateAggregatedColumn: React.FC<Props> = ({
    dataTableId,
    name,
    onDataTableChange,
    onDataColumnCreated,
}) => {
    const [groupingField, setGroupingField] = useState<SelectableField | undefined>();
    const [selectedAggregationField, setSelectedAggregationField] = useState<SelectableField | null>(null);

    const [aggregationType, setSelectedAggregationType] = useState(aggregationTypeOptions[0]);

    const filters = useFilters();

    const [createAggregatedColumn, mutation] = useCreateAggregatedDataColumnMutation({
        refetchQueries: ["getAllDataColumns"],
    });
    const handleSubmit = () => {
        if (!selectedAggregationField || !groupingField) {
            return;
        }
        createAggregatedColumn({
            input: {
                name: name ?? "",
                sourceDataTable: selectedAggregationField.dataTableId,
                dataTableId,
                aggregationType: aggregationType.type,
                aggregationField: selectedAggregationField.field,
                groupByFields: [groupingField.field],
                filters: filters?.map(mapFromUnpopulatedFrontendFiltersToAnalysisFilter).filter(hasValue) ?? [],
            },
        }).then(
            (result) =>
                result.data?.createAggregatedDataColumn.dataColumn &&
                onDataColumnCreated?.(result.data.createAggregatedDataColumn.dataColumn)
        );
    };

    useEffect(() => {
        setSelectedAggregationField(null);
    }, [groupingField]);

    const dispatchFilterAction = useFilterStateAction();
    const handleGroupByFieldChange = (value: SelectableField) => {
        setGroupingField(value);
        onDataTableChange(value.dataTableId);
        dispatchFilterAction?.({ type: "RESET_FILTERS" });
    };

    return (
        <Stack gap={2}>
            <GroupByRelatedColumnSelect
                targetDataTableId={dataTableId}
                selectedValue={groupingField}
                onChange={handleGroupByFieldChange}
            />
            <Autocomplete
                value={aggregationType}
                onChange={(_, value) => value && setSelectedAggregationType(value)}
                options={aggregationTypeOptions}
                renderInput={(params) => (
                    <TextField
                        label={fm(messages.aggregationTypeSelectLabel)}
                        {...params}
                        inputProps={{ ...params.inputProps }}
                    />
                )}
            />
            <AggregationFieldSelect
                dataTableId={groupingField?.dataTableId ?? dataTableId}
                selectedField={selectedAggregationField}
                selectedAggregationType={aggregationType.type}
                onChange={setSelectedAggregationField}
            />
            <FilterModal
                title={`${fm(messages.fieldToAggregateSelectLabel).toString()}: ${
                    selectedAggregationField && selectedAggregationField.label
                }`}
                button={
                    <Button variant="text" color="inherit" startIcon={<FilterIcon fontSize="small" />}>
                        {fm(messages.filter)}
                    </Button>
                }
                placement="CreateAggregatedColumn"
            />
            <LoadingButton onClick={handleSubmit} loading={mutation.result.type === "loading"} variant="contained">
                {fm(globalMessages.submitButton)}
            </LoadingButton>
            {aggregationType.type === "AGGREGATION_COUNT_UNIQUE" && (
                <Alert severity="warning">{fm(messages.uniqueCountEstimationWarning)}</Alert>
            )}
            <GraphqlRequestContainer asyncData={mutation.result} loading={null} />
        </Stack>
    );
};

export const CreateAggregatedColumn: React.FC<Omit<Props, "onDataTableChange">> = (props) => {
    const { dataTableId } = props;
    const [currentDataTableId, setCurrentDataTableId] = useState<string>(dataTableId);
    const dataTable = useDataTable(currentDataTableId);
    const filterDataSource = mapDataTableToFilterDataSource(dataTable?.data);
    const { id: userId } = useSessionContext();
    const usePersonalFavouriteFiltersForContext = () =>
        usePersonalFavouriteFilters(userId, process.env.REACT_APP_GRAPHQL_ROUTER_URL as string);
    const useCompanyFavouriteFiltersForContext = () =>
        useCompanyFavouriteFilters(process.env.REACT_APP_GRAPHQL_ROUTER_URL as string);

    const upsertPersonalFavouriteFilters = useUpsertPersonalFavouriteFilters(
        userId,
        process.env.REACT_APP_GRAPHQL_ROUTER_URL as string
    );
    return (
        <FilterContextProvider
            inherit={false}
            dataSource={filterDataSource}
            useDataFields={useElasticFieldsForFilterContextProvider}
            // hooks for personal and company filters
            usePersonalFavouriteFilters={usePersonalFavouriteFiltersForContext}
            useCompanyFavouriteFilters={useCompanyFavouriteFiltersForContext}
            upsertPersonalFavouriteFilters={upsertPersonalFavouriteFilters}
        >
            <InnerCreateAggregatedColumn onDataTableChange={setCurrentDataTableId} {...props} />
        </FilterContextProvider>
    );
};
