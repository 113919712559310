import { useMemo } from "react";
import { useManyAggregatedViews } from "@/generated/AggregatedViewEntity";
import { AggregatedView, DataTable } from "@/generated/client";
import { useManyDataTables } from "@/generated/DataTableEntity";

const emptyList: (AggregatedView & DataTable)[] = [];

export function useAggregatedTablesOfDataTable(dataTableId: string) {
    const aggregatedViews =
        useManyAggregatedViews().data?.filter((aggregatedView) => aggregatedView.sourceDataTableId === dataTableId) ??
        emptyList;
    const dataTables = useManyDataTables()?.data ?? emptyList;
    return useMemo(
        () =>
            dataTables
                .filter((dataTable) =>
                    aggregatedViews.some((aggregatedView) => aggregatedView.resultDataTableId === dataTable.id)
                )
                .filter((dataTable) => dataTable.id !== dataTableId),
        [aggregatedViews, dataTables, dataTableId]
    );
}
