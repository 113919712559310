import {
    Box,
    Checkbox,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
    tableCellClasses,
} from "@mui/material";
import React from "react";
import { fm } from "@/contexts/IntlContext";
import { Change } from "../../ApplyChangesButtons/helpers";
import messages from "../../ApplyChangesButtons/messages";
import { ChangeRow } from "./ChangeRow";

interface Props {
    changesByPipelineId: Record<string, Change[]>;
    selectedPipelineIds: string[];
    select: (ids: string[], add: boolean) => void;
}
const ChangesTable: React.FC<Props> = ({ changesByPipelineId, selectedPipelineIds, select }) => {
    const isAllChecked = Object.keys(changesByPipelineId).every((id) => selectedPipelineIds.includes(id));
    const handleSelectAll = () => {
        select(Object.keys(changesByPipelineId), !isAllChecked);
    };
    return (
        <Box>
            <Paper>
                <Table
                    size="small"
                    aria-labelledby="tableTitle"
                    sx={{
                        [`& .${tableCellClasses.root}`]: {
                            borderBottom: "unset",
                            borderRight: "none",
                            borderLeft: "none",
                        },
                    }}
                >
                    <TableHead>
                        <TableRow selected={isAllChecked}>
                            <TableCell padding="checkbox">
                                <Checkbox onChange={handleSelectAll} checked={isAllChecked} />
                            </TableCell>
                            <TableCell>
                                <Typography variant="h6">{fm(messages.source)}</Typography>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {Object.entries(changesByPipelineId).map(([pipelineId, changes]) => (
                            <ChangeRow
                                key={pipelineId}
                                pipelineId={pipelineId}
                                selectedPipelineIds={selectedPipelineIds}
                                onSelect={() => select([pipelineId], !selectedPipelineIds.includes(pipelineId))}
                                changes={changes}
                            />
                        ))}
                    </TableBody>
                </Table>
            </Paper>
        </Box>
    );
};

export default ChangesTable;
