import { defineMessages } from "react-intl";

const messages = defineMessages({
    title: {
        id: "FileToTable.title",
        defaultMessage: "Create {dataType} table",
    },
    prompt: {
        id: "FileToTable.prompt",
        defaultMessage: "Upload or drop your {dataType} data",
    },
    successTitle: {
        id: "SuccessStep.successTitle",
        defaultMessage: "Your spend and suppliers are now being created in Ignite",
    },
});

export default messages;
