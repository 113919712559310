import { defineMessages } from "react-intl";

const messages = defineMessages({
    download: {
        id: "exportTable.download",
        defaultMessage: "Download",
    },
    history: {
        id: "exportTable.history",
        defaultMessage: "Export history",
    },
    downloadAll: {
        id: "exportTable.downloadAll",
        defaultMessage: "Download all",
    },
    chunk: {
        id: "exportTable.chunk",
        defaultMessage: "Click here to download chunk {i}",
    },
});

export default messages;
