import { FilterModal, useFilters } from "@ignite-analytics/filters";
import { GraphqlRequestContainer } from "@ignite-analytics/graphql-utilities";
import { track } from "@ignite-analytics/track";
import { Info, Search } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Card, CardContent, Stack, Typography } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import React, { useState } from "react";
import { cubeBuilderPageTestIdPrefix } from "@/containers/CubeBuilderPage";
import { fm } from "@/contexts/IntlContext";
import { DataTable, DataTableConnectionInput, useTestDataTableConnectionMutation } from "@/generated/client";
import { TestResultsTable } from "./TestResultsTable";
import messages from "./messages";

interface Props {
    dataTableConnection: DataTableConnectionInput;
    baseTable: DataTable;
    targetTable: DataTable;
}

const TestConnectionResults: React.FC<Props> = ({ dataTableConnection, baseTable, targetTable }) => {
    const [testConnection, testConnectionMutation] = useTestDataTableConnectionMutation();
    const [open, setOpen] = useState(false);

    const filters = useFilters();

    if (!open)
        return (
            <LoadingButton
                loading={testConnectionMutation.result.type === "loading"}
                size="small"
                variant="text"
                endIcon={<Search />}
                data-testid={`${cubeBuilderPageTestIdPrefix}-open-test-connection-mode-Button`}
                onClick={() => setOpen(true)}
            >
                {fm(messages.open)}
            </LoadingButton>
        );

    return (
        <Card variant="outlined" sx={{ backgroundColor: (theme) => theme.palette.grey[200] }}>
            <CardContent>
                <Stack direction="row" gap={1}>
                    <Typography variant="subtitle2">{fm(messages.title)}</Typography>
                    <Tooltip title={fm(messages.testTooltip)}>
                        <Info />
                    </Tooltip>
                </Stack>
                <Stack direction="row">
                    <Tooltip title={fm(messages.filterTooltip, { target: targetTable.name }).toString()}>
                        <LoadingButton
                            loading={testConnectionMutation.result.type === "loading"}
                            size="small"
                            color="secondary"
                            endIcon={<Search />}
                            data-testid={`${cubeBuilderPageTestIdPrefix}-test-connection-button`}
                            onClick={() => {
                                testConnection({
                                    input: {
                                        dataTableConnection,
                                        filtersJSON: JSON.stringify(filters),
                                    },
                                });
                                track(`Table Connections: Test Table Connection`);
                            }}
                        >
                            {fm(messages.test)}
                        </LoadingButton>
                    </Tooltip>
                    <FilterModal title={fm(messages.test).toString()} placement="TestConnectionResults" />
                </Stack>
                <GraphqlRequestContainer asyncData={testConnectionMutation.result}>
                    {(response) => (
                        <>
                            <Stack direction="row" gap={3}>
                                <Typography>
                                    {fm(messages.targetRows, {
                                        target: targetTable.name,
                                        amount: response.numberOfTargetRowsTested,
                                    })}
                                </Typography>
                                <Typography>
                                    {fm(messages.targetRowsWithMatch, {
                                        target: targetTable.name,
                                        amount: response.numberOfTargetRowsTested - response.targetRowsWithNoMatch,
                                    })}
                                </Typography>

                                <Typography>
                                    {fm(messages.targetRowsWithNoMatch, {
                                        target: targetTable.name,
                                        amount: response.targetRowsWithNoMatch,
                                    })}
                                </Typography>
                                <Typography>
                                    {fm(messages.baseTableMatches, {
                                        base: baseTable.name,
                                        amount: response.baseTableRowsWithMatch,
                                    })}
                                </Typography>
                            </Stack>
                            <TestResultsTable
                                dataTableConnection={dataTableConnection}
                                matchesJSON={response.matchesJSON}
                                target={targetTable}
                            />
                        </>
                    )}
                </GraphqlRequestContainer>
            </CardContent>
        </Card>
    );
};

export default TestConnectionResults;
