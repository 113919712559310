import { defineMessages } from "react-intl";

const messages = defineMessages({
    or: {
        id: "uploadPage.dataRepositoryPage.createTableFromSpec.or",
        defaultMessage: "Or upload table definition/specification",
    },
    select: {
        id: "uploadPage.dataRepositoryPage.createTableFromSpec.select",
        defaultMessage: "Select table definition/specification",
    },
    columnName: {
        id: "uploadPage.dataRepositoryPage.createTableFromSpec.columnName",
        defaultMessage: "Name",
    },
    name: {
        id: "uploadPage.dataRepositoryPage.createTableFromSpec.name",
        defaultMessage: "Name: {name}",
    },
    dataType: {
        id: "uploadPage.dataRepositoryPage.createTableFromSpec.dataType",
        defaultMessage: "Data Type",
    },
    globalType: {
        id: "uploadPage.dataRepositoryPage.createTableFromSpec.globalType",
        defaultMessage: "Global Type",
    },
    header: {
        id: "uploadPage.dataRepositoryPage.createTableFromSpec.header",
        defaultMessage: "Create Data Table from Specification",
    },
    parseError: {
        id: "uploadPage.dataRepositoryPage.createTableFromSpec.error",
        defaultMessage: "Something went wrong when reading file",
    },
    nonExistingGlobalTypes: {
        id: "uploadPage.dataRepositoryPage.createTableFromSpec.nonExistingGlobalTypes",
        defaultMessage: "Some global types does not exist",
    },
    missingColumnTypes: {
        id: "uploadPage.dataRepositoryPage.createTableFromSpec.missingColumnTypes",
        defaultMessage: "{numMissing} column(s) with missing types",
    },
});

export default messages;
