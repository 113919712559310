import { CustomEdge } from "../CustomEdge";
import { DataTableNode } from "../DataTableNode";

export const NODE_TYPES = {
    tableNode: DataTableNode,
};

export const EDGE_TYPES = {
    stepWithButton: CustomEdge,
};
