import { isNotNullish } from "@ignite-analytics/general-tools";
import { DataColumn } from "@/Types/DataColumn";
import {
    CreateInputOutputConnectionInput,
    DataColumnType,
    DataRepository,
    InputOutputNodeTypeInput,
} from "@/generated/client";

export const cleanDataColumnName = (name: string) => name.replace(/_/g, " ");

const allowedInputTypes: DataColumnType[] = ["BOOLEAN", "DATE", "NUMBER", "TEXT"];

export const getInputOutputMatches = (dataColumns: DataColumn[], repository: DataRepository) => dataColumns
        .map((dataColumn) => {
            const matchingRepositoryField = repository.fields.find(
                (dataRepositoryField) =>
                    cleanDataColumnName(dataRepositoryField.name) === cleanDataColumnName(dataColumn.name) &&
                    allowedInputTypes.includes(dataColumn.dataType)
            );
            if (!matchingRepositoryField) {
                return undefined;
            }
            return {
                dataColumn,
                dataRepositoryField: matchingRepositoryField,
            };
        })
        .filter(isNotNullish);

export const createRepositoryToTableConnectionInput: (
    pipelineId: string,
    from: string,
    to: string,
    fromType: InputOutputNodeTypeInput,
    toType: InputOutputNodeTypeInput
) => { input: CreateInputOutputConnectionInput } = (pipelineId, from, to, fromType, toType) => ({
    input: {
        dataPipelineId: pipelineId,
        from: {
            type: fromType,
            referencedId: from,
        },
        to: {
            type: toType,
            referencedId: to,
        },
    },
});
