import { useNotificationsContext } from "@ignite-analytics/notifications";
import { AnalyticsSharp, Category, Dashboard, Storage } from "@mui/icons-material";
import { CircularProgress, LinearProgress, Slide, Stack, Typography } from "@mui/material";
import React from "react";
import { TranslatedString } from "@/Types/translation";
import { WelcomeSvg } from "@/components/svgs/welcome";

import { useBasePath } from "@/contexts/BasePathContext";
import { fm } from "@/contexts/IntlContext";
import { LinkButton } from "./LinkButton";
import messages from "./messages";

interface Props {
    notificationId: string;
    dataTableId: string;
    title: TranslatedString;
    onClose?: () => void;
}

export const SuccessStep: React.FC<Props> = ({ notificationId, dataTableId, title, onClose }) => {
    const { notifications } = useNotificationsContext();
    const notification = notifications.find((n) => `${n.id}` === notificationId);
    const basePath = useBasePath();

    return (
        <Slide in direction="left">
            <Stack direction="row" height="100%" justifyContent="center" alignItems="center">
                <Stack gap={1} alignItems="center">
                    <Typography variant="h5">{title}</Typography>
                    <Stack direction="row" justifyContent="center">
                        <WelcomeSvg size="250" />
                    </Stack>
                    {!notificationId && <CircularProgress />}
                    {notification?.status === "IN_PROGRESS" && (
                        <LinearProgress color="primary" value={notification?.progress} />
                    )}
                    <Typography variant="caption">
                        {notification?.status === "IN_PROGRESS" && notification?.message}
                        {notification?.status === "FAILED" && notification?.status}
                    </Typography>
                    {dataTableId && (
                        <Stack gap={3} alignItems="center">
                            <Stack direction="row" justifyContent="center" gap={3}>
                                <LinkButton
                                    href="/dashboard/analyze/custom/"
                                    onClick={onClose}
                                    message={fm(messages.createChart).toString()}
                                    icon={<AnalyticsSharp />}
                                />
                                <LinkButton
                                    href={`${basePath}${dataTableId}/table-view/`}
                                    onClick={onClose}
                                    message={fm(messages.goToTable).toString()}
                                    icon={<Storage />}
                                />
                            </Stack>
                            <Stack direction="row" justifyContent="center" gap={3}>
                                <LinkButton
                                    href="dashboard/library/dashboard/"
                                    onClick={onClose}
                                    message={fm(messages.browseDashboards).toString()}
                                    icon={<Dashboard />}
                                />
                                <LinkButton
                                    href="classification/"
                                    onClick={onClose}
                                    icon={<Category />}
                                    message={fm(messages.classify).toString()}
                                />
                            </Stack>
                        </Stack>
                    )}
                </Stack>
            </Stack>
        </Slide>
    );
};
