import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { getHiddenColumnsPreferences, saveHiddenColumnsPreferences } from "./helpers";

type ContextValue = {
    columnsToHide: string[];
    hideColumn: (id: string) => void;
    unHideColumn: (id: string) => void;
};

const Context = React.createContext<ContextValue | null>(null);

const HiddenColumnsContextProvider: React.FC<{ userId: string; dataTableId: string; children?: React.ReactNode }> = ({
    children,
    userId,
    dataTableId,
}) => {
    const [hiddenColumns, setHiddenColumns] = useState<string[]>(getHiddenColumnsPreferences(userId, dataTableId));

    const resetColumnPreferences = useCallback(() => {
        const idsToHide = userId && getHiddenColumnsPreferences(userId, dataTableId);
        userId && idsToHide && setHiddenColumns(idsToHide);
    }, [userId, dataTableId]);

    const hideColumn = useCallback(
        (id: string) => {
            const idsToHide = getHiddenColumnsPreferences(userId, dataTableId) ?? [];
            const newIds = [...new Set([...idsToHide, id])];
            saveHiddenColumnsPreferences(userId, dataTableId, newIds);
            setHiddenColumns(newIds);
        },
        [dataTableId, userId]
    );

    const unHideColumn = useCallback(
        (id: string) => {
            const idsToHide = getHiddenColumnsPreferences(userId, dataTableId) ?? [];
            const newIds = idsToHide.filter((hiddenId) => id !== hiddenId);
            saveHiddenColumnsPreferences(userId, dataTableId, newIds);
            setHiddenColumns(newIds);
        },
        [dataTableId, userId]
    );

    useEffect(resetColumnPreferences, [resetColumnPreferences]);

    return (
        <Context.Provider
            value={useMemo(
                () => ({
                    columnsToHide: hiddenColumns,
                    hideColumn,
                    unHideColumn,
                }),
                [hiddenColumns, hideColumn, unHideColumn]
            )}
        >
            {children}
        </Context.Provider>
    );
};

const useHiddenColumnsContext = () => {
    const ctx = useContext(Context);
    if (!ctx) {
        throw new Error("DataTableContext must be used within a DataTablesProvider");
    }
    return ctx;
};

export { HiddenColumnsContextProvider, useHiddenColumnsContext };
