import { track } from "@ignite-analytics/track";
import { Alert, Button, LinearProgress, Slide, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { DataTypeMapping } from "@/components/DataTypeMapping";
import { FilePreview, FilePreviewData } from "@/components/FilePreview";
import { useBasePath } from "@/contexts/BasePathContext";
import { fm } from "@/contexts/IntlContext";
import { ParseFileInput, PrimitiveDataColumnTypeInput, useCompleteSetupMutation } from "@/generated/client";
import globalMessage from "@/messages/index";
import messages from "./messages";

export const CreateTableFromFile: React.FC<{
    onClose: () => void;
    onPickExistingDataSource?: () => void;
}> = ({ onClose, onPickExistingDataSource }) => {
    const [file, setFile] = useState<{ id: string; name: string } | undefined>(undefined);
    const [hide, setHide] = useState(false);
    const [filePreview, setFilePreview] = useState<FilePreviewData>();
    const [importConfig, setImportConfig] = useState<Omit<ParseFileInput, "fileId">>();
    const [typeMappings, setTypeMappings] = useState<Record<string, PrimitiveDataColumnTypeInput>>({});
    const [completeSetup, mutation] = useCompleteSetupMutation();

    const browserHistory = useHistory();
    const basePath = useBasePath();

    const handleComplete = async () => {
        if (!(filePreview && importConfig && file)) return;
        const result = await completeSetup({
            input: {
                fields: filePreview?.map((f) => ({
                    fieldName: f.fieldKey,
                    tag: null,
                    dataType: typeMappings[f.fieldKey] ?? "TEXT",
                })),
                tableType: null,
                tableName: file.name,
                fileSettings: {
                    fileId: file.id,
                    fileName: file.name,
                    contentType: importConfig?.contentType,
                    csvConfiguration: importConfig.csvConfiguration,
                    xlsxConfiguration: importConfig.xlsxConfiguration,
                },
            },
        });

        if (result.errors) return;
        track("Data Table: Create", { type: "from-file" });
        onClose();
        browserHistory.push(`${basePath}${result.data?.completeSetup.dataTableId}/table-view/`);
    };

    // Uploads file to file management and gets the parsed fiel results for preview
    const handleFileConfirmation = (
        fields: FilePreviewData,
        importSettings: Omit<ParseFileInput, "fileId">,
        uploadedFileId: string,
        uploadedFile: File
    ) => {
        setFile({ id: uploadedFileId, name: uploadedFile.name });
        setFilePreview(fields);
        setImportConfig(importSettings);
    };

    const handleBack = () => {
        setHide((prev) => !prev);
        setFilePreview(undefined);
    };

    return (
        <>
            {mutation.result.type === "not-asked" && (
                <>
                    <Typography variant="h6">{fm(messages.createNewTable)}</Typography>
                    <Stack gap={1} pt={2}>
                        {!filePreview && (
                            <Slide in={!filePreview} direction="left">
                                <Stack gap={3}>
                                    <FilePreview
                                        height="40vh"
                                        onFileChange={() => setHide((prev) => !prev)}
                                        onFileConfirmation={handleFileConfirmation}
                                    />
                                    {!hide && onPickExistingDataSource && (
                                        <>
                                            <Typography>Or</Typography>
                                            <Stack width="400px">
                                                <Button
                                                    variant="outlined"
                                                    color="secondary"
                                                    onClick={onPickExistingDataSource}
                                                >
                                                    {fm(messages.buildfromExistingSources)}
                                                </Button>
                                            </Stack>
                                        </>
                                    )}
                                </Stack>
                            </Slide>
                        )}
                        {filePreview && (
                            <>
                                <Slide in={!!filePreview} direction="left">
                                    <Stack>
                                        <DataTypeMapping
                                            fileData={filePreview}
                                            typeMappings={typeMappings}
                                            setTypeMappings={setTypeMappings}
                                            searchBar
                                            maxHeight="40vh"
                                            message={fm(messages.dataTypes).toString()}
                                        />
                                    </Stack>
                                </Slide>
                                <Stack direction="row" justifyContent="space-between">
                                    <Button size="small" variant="outlined" color="secondary" onClick={handleBack}>
                                        {fm(globalMessage.back)}
                                    </Button>
                                    <Button size="small" color="success" onClick={handleComplete}>
                                        {fm(globalMessage.finish)}
                                    </Button>
                                </Stack>
                            </>
                        )}
                    </Stack>
                </>
            )}
            {mutation.result.type === "loading" && <LinearProgress />}
            {mutation.result.type === "error" && <Alert>{mutation.result.error.message}</Alert>}
        </>
    );
};
