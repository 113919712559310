import { track } from "@ignite-analytics/track";
import { Close } from "@mui/icons-material";
import { Button, Dialog, IconButton, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import {
    DataPipeline,
    DataRepository,
    DataTable,
    useCreateDataPipelineMutation,
    useGetAllDataRepositoriesQuery,
    useGetAllDataRepositoryCollectionsQuery,
} from "@/generated/client";
import { useDataColumnsByDataTableId } from "@/contexts/EventContexts/DataColumnEventContext";
import { useEntityEventListener } from "@/contexts/EventContexts/EntityEventChangeContext";
import { fm } from "@/contexts/IntlContext";
import { DataRepositoryPreview } from "./ConnectRepositoryPreview/index";
import { ConnectRepositoriesSideMenu } from "./ConnectRepositorySideMenu/index";
import { CreateOrMapColumnsStep } from "./CreateOrMapColumnsStep";
import messages from "./messages";

interface Props {
    onClose: () => void;
    dataTable: DataTable;
}

export const ConnectRepositoryModal: React.FC<Props> = ({ onClose, dataTable }: Props) => {
    const [selectedDataRepository, setSelectedDataRepository] = useState<DataRepository>();
    const [createdPipeline, setCreatedPipeline] = useState<DataPipeline>();
    const columns = useDataColumnsByDataTableId(dataTable.id);

    const { result: dataRepositoriesResult, refetch } = useGetAllDataRepositoriesQuery(
        {},
        { fetchPolicy: "network-only" }
    );
    const { result: dataRepositoryCollectionsResult } = useGetAllDataRepositoryCollectionsQuery({});
    const [createPipelineMutation] = useCreateDataPipelineMutation({
        refetchQueries: ["getAllDataPipelines", "getAllDataRepositories"],
    });
    useEntityEventListener("DataRepository", () => refetch());
    const handleCreate = async (dataRepository: DataRepository | undefined, dataTableId: string) => {
        if (dataRepository) {
            const name = `${dataRepository?.name} to ${dataTable.name} pipeline`;
            const newPipeline = await createPipelineMutation({
                input: { name, sourceDataRepositoryIds: [dataRepository.id], targetDataTableId: dataTableId },
            });
            track("Pipeline: Created Data Pipeline");
            setCreatedPipeline(newPipeline.data?.createDataPipeline.dataPipeline);
        }
    };

    const dataRepositories =
        dataRepositoriesResult.type === "success" ? dataRepositoriesResult.data.dataRepositories : null;

    const dataRepositoryCollections =
        dataRepositoryCollectionsResult.type === "success"
            ? dataRepositoryCollectionsResult.data.dataRepositoryCollections
            : null;

    // Initial selection. Make sure we have at least one repository to prevent an infinite loop.
    if (dataRepositories?.length && selectedDataRepository === undefined) {
        setSelectedDataRepository(dataRepositories[0]);
    }

    const createOrMapColumnsState = createdPipeline && selectedDataRepository;

    return (
        <Dialog maxWidth="lg" fullWidth open onClose={onClose}>
            <Stack sx={{ padding: 2 }}>
                {createOrMapColumnsState ? (
                    <CreateOrMapColumnsStep
                        repository={selectedDataRepository}
                        dataTable={dataTable}
                        dataPipeline={createdPipeline}
                        onCreate={onClose}
                        columns={columns}
                    />
                ) : (
                    <Stack spacing={1}>
                        <Stack direction="row" justifyContent="space-between">
                            <Stack>
                                <Typography variant="h5">{fm(messages.configureInput)}</Typography>
                                <Typography>{fm(messages.preview)}</Typography>
                            </Stack>
                            <IconButton onClick={onClose}>
                                <Close />
                            </IconButton>
                        </Stack>
                        <Stack direction="row" gap={1}>
                            {!!dataRepositories?.length && dataRepositoryCollections !== null && (
                                <ConnectRepositoriesSideMenu
                                    onSelect={(dataRepository: DataRepository) => {
                                        setSelectedDataRepository(dataRepository);
                                    }}
                                    dataRepositories={dataRepositories}
                                    dataRepositoryCollections={dataRepositoryCollections}
                                />
                            )}
                            {selectedDataRepository && (
                                <DataRepositoryPreview dataRepository={selectedDataRepository} />
                            )}
                        </Stack>
                        {selectedDataRepository && (
                            <Stack direction="row" justifyContent="end">
                                <Button onClick={() => handleCreate(selectedDataRepository, dataTable.id)}>
                                    {fm(messages.confirm)}
                                </Button>
                            </Stack>
                        )}
                    </Stack>
                )}
            </Stack>
        </Dialog>
    );
};
