import { defineMessages } from "react-intl";

const messages = defineMessages({
    match: {
        id: "components.CreateConnectionModal.match",
        defaultMessage: "Match",
    },
    selectTarget: {
        id: "components.CreateConnectionModal.selectTarget",
        defaultMessage: "Select target table",
    },
    connectionCriteria: {
        id: "components.CreateConnectionModal.connectionCriteria",
        defaultMessage: "Connection Criteria",
    },
    connectionHeader: {
        id: "components.CreateConnectionModal.connectionHeader",
        defaultMessage: "Manage table connection",
    },
    createConnection: {
        id: "components.CreateConnectionModal.createConnection",
        defaultMessage: "Create connection",
    },
    baseColumn: {
        id: "components.CreateConnectionModal.baseColumn",
        defaultMessage: "Base field",
    },
    targetColumn: {
        id: "components.CreateConnectionModal.targetColumn",
        defaultMessage: "Target field",
    },
    explanation: {
        id: "components.CreateConnectionModal.explanation",
        defaultMessage: "Specify what conditions should be met before joining row from the target table.",
    },
    dependingFieldWarning: {
        id: "components.CreateConnectionModal.dependingFieldWarning",
        defaultMessage: "Selecting this field would result in a circular dependency.",
    },
    addCriterion: {
        id: "components.CreateConnectionModal.addCriterion",
        defaultMessage: "Add condition",
    },
    base: {
        id: "components.CreateConnectionModal.base",
        defaultMessage: "Base",
    },
    delete: {
        id: "components.CreateConnectionModal.delete",
        defaultMessage: "delete",
    },
    cancel: {
        id: "components.CreateConnectionModal.cancel",
        defaultMessage: "cancel",
    },
    createHeader: {
        id: "components.CreateConnectionModal.createHeader",
        defaultMessage: "Create table connection",
    },
    save: {
        id: "components.CreateConnectionModal.save",
        defaultMessage: "save",
    },
    reapply: {
        id: "components.CreateConnectionModal.reapply",
        defaultMessage: "Reapply connection",
    },
    processing: {
        id: "components.CreateConnectionModal.processing",
        defaultMessage: "Processing",
    },
    ignoreOnBlankLabel: {
        id: "components.CreateConnectionModal.criterion.ignoreOnBlankLabel",
        defaultMessage: "Ignore on blank value for {fieldLabel} ",
    },
    ignoreOnBlankDescription: {
        id: "components.CreateConnectionModal.criterion.ignoreOnBlankDescription",
        defaultMessage:
            "Toggle this option to ignore this criterion on blank values for {fieldLabel} in the target data set. When this value is blank, any value in the base table will be matched for this criterion. Rows that has a value in this field is prioritized over rows that has a blank value.",
    },
    removeCriterionLabel: {
        id: "components.CreateConnectionModal.criterion.removeCriterionLabel",
        defaultMessage: "Remove this criterion",
    },
});

export default messages;
