import { Storage } from "@mui/icons-material";
import { Card, Divider, Stack, Typography } from "@mui/material";
import React, { useState } from "react";

import { fm } from "@/contexts/IntlContext";
import { DataTable } from "@/generated/client";

import { ConnectRepositoryModal } from "../Modals/ConnectRepositoryModal";
import { NodeInformation, OPERATION_NODE_IMPLEMENTATION, PIPELINE_COLORS } from "../Nodes/constants";
import messages from "./messages";
import { SideBarItem } from "./SideBarItem";
import { LockedSideBarItem } from "./LockedSideBarItem";
import { OptionalPermissionContainer } from "@/lib/permissions";

const headers: Record<string, NodeInformation> = {
    DATA_SOURCE: {
        name: "Data source",
        nodeCollection: "Input/Output",
        color: PIPELINE_COLORS.source,
        iconComponent: <Storage fontSize="small" />,
        explanation: fm(messages.addDataSource),
    },
    ...OPERATION_NODE_IMPLEMENTATION,
};

const collectionHeaders = [...new Set<string>(Object.values(headers).map((node) => node.nodeCollection))].filter(
    (header) => header !== "ML operations"
);

const GroupHeader = ({ header }: { header: string }) => (
    <Typography variant="subtitle2" color="seconadry" noWrap>
        {header}
    </Typography>
);

interface Props {
    dataTable: DataTable | undefined;
}

export const SidebarMenu = ({ dataTable }: Props) => {
    const [modalComponent, setModalComponent] = useState<JSX.Element>();
    const handleDrop = (event: React.DragEvent<HTMLDivElement>, menuDropItemKey: string) => {
        event.preventDefault();
        const dragEle = document.getElementById("dragElement");
        dragEle?.remove();
        if (menuDropItemKey === "DATA_SOURCE") {
            if (dataTable) {
                setModalComponent(
                    <ConnectRepositoryModal onClose={() => setModalComponent(undefined)} dataTable={dataTable} />
                );
            }
        }
    };
    return (
        <Stack sx={{ minWidth: "250px", maxWidth: "250px" }}>
            {modalComponent}
            <Card sx={{ padding: 1 }}>
                <Stack>
                    {collectionHeaders.map((header) => (
                            <Stack key={header} alignContent="center" justifyContent="center">
                                <Stack paddingY={1}>
                                    <GroupHeader header={header} />
                                    <Divider />
                                </Stack>
                                {Object.entries(headers).map(([key, content]) => {
                                    if (content.nodeCollection === header) {
                                        return (
                                            <OptionalPermissionContainer
                                                key={key}
                                                namespace={content.permissionTuple?.namespace}               
                                                relation={content.permissionTuple?.relation}
                                                unauthorizedComponent={
                                                    <LockedSideBarItem content={content} />
                                                }
                                            >
                                                <SideBarItem dragKey={key} content={content} handleDrop={handleDrop} />
                                            </OptionalPermissionContainer>
                                        );
                                    }
                                    return null;
                                })}
                            </Stack>
                        ))}
                </Stack>
            </Card>
        </Stack>
    );
};
