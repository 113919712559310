import { defineMessages } from "react-intl";

const messages = defineMessages({
    syncTableButton: {
        id: "dataTables.adminPage.syncTableButton",
        defaultMessage: "Update table",
    },
    updateTableModalTitle: {
        id: "dataTables.adminPage.updateTableModalTitle",
        defaultMessage: "Updating data table",
    },
});

export default messages;
