import { NetworkError } from "@apollo/client/errors";
import { onError } from "@apollo/client/link/error";
import { captureException } from "@sentry/react";
import { GraphQLError } from "graphql";
import { isString } from "lodash-es";

const dataTablesApp = "data-tables-app";

const truncateValues = (obj: Record<string, string>) => {
    Object.keys(obj).forEach((key) => {
        if (obj[key].length > 200) {
            // eslint-disable-next-line no-param-reassign
            obj[key] = `${obj[key].slice(197)}...`;
        }
    });
};

function extractTagsFromNetWorkError(error: NetworkError): Record<string, string> {
    const tags: Record<string, string> = { app: dataTablesApp };
    if (!error) {
        return tags;
    }
    if (!("statusCode" in error)) {
        return tags;
    }
    tags.statusCode = error.statusCode.toString();
    if ("bodyText" in error) {
        tags.bodyText = error.bodyText;
    }
    if ("response" in error) {
        tags.response = JSON.stringify(error.response.body);
    }
    return tags;
}

function extractTagsFromGqlError(error: GraphQLError): Record<string, string> {
    const { path, locations, extensions, source } = error;
    const tags: Record<string, string> = {
        app: dataTablesApp,
        code: isString(extensions?.code) ? extensions.code : "UNKNOWN",
        serviceName: isString(extensions?.serviceName) ? extensions.serviceName : "UNKNOWN",
    };
    const pathRecord: Record<string, string> = path ? { path: path.join(" -> ") } : {};
    const locationRecord: Record<string, string> = locations ? { locations: JSON.stringify(locations) } : {};
    const sourceRecord: Record<string, string> = source
        ? {
              sourceName: source.name,
              sourceBody: source.body,
              sourceLocation: JSON.stringify(source.locationOffset),
          }
        : {};
    return {
        ...tags,
        ...pathRecord,
        ...locationRecord,
        ...sourceRecord,
    };
}

export const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
        const tags = extractTagsFromGqlError(graphQLErrors[0]);
        truncateValues(tags);
        graphQLErrors.forEach((gqlError) => {
            captureException(new Error(gqlError.message), {
                tags: extractTagsFromGqlError(gqlError),
            });
        });
    }

    if (networkError) {
        const tags = extractTagsFromNetWorkError(networkError);
        truncateValues(tags);
        captureException(new Error(networkError.message), {
            tags,
        });
    }
});
