import { defineMessages } from "react-intl";

const messages = defineMessages({
    industryCode: {
        id: "companyCell.industryCode",
        defaultMessage: "Industry code:",
    },
    industry: {
        id: "companyCell.industry",
        defaultMessage: "Industry:",
    },
    orgNumber: {
        id: "companyCell.orgNumber",
        defaultMessage: "Organization number:",
    },
    country: {
        id: "companyCell.country",
        defaultMessage: "Country:",
    },
    revenue: {
        id: "companyCell.revenue",
        defaultMessage: "Revenue {year}:",
    },
    ebitMargin: {
        id: "companyCell.ebitMargin",
        defaultMessage: "Ebit margin {year}:",
    },
});

export default messages;
