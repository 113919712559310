import { GraphqlRequestContainer } from "@ignite-analytics/graphql-utilities";
import { track } from "@ignite-analytics/track";
import { LoadingButton } from "@mui/lab";
import { Alert, Autocomplete, Dialog, DialogContent, Stack, TextField } from "@mui/material";
import React, { useState } from "react";
import { InputSourceItem } from "@/Types/InputSourceItem";
import { createPrimitiveColumnTypeMessages } from "@/components/CreateDataColumnForm/helpers";
import { useDataColumnsByDataTableId } from "@/contexts/EventContexts/DataColumnEventContext";
import { fm } from "@/contexts/IntlContext";
import {
    DataColumnType,
    OperationType,
    useCreateDataColumnsMutation,
    useCreateInputOutputConnectionMutation,
} from "@/generated/client";
import globalMessages from "@/messages";
import messages from "../messages";

interface Props {
    dataTableId: string;
    dataPipelineId: string;
    source: InputSourceItem;
    onCreate: () => void;
    onClose: () => void;
}

const getRelevantTypeOrPrimitives = (operationType?: OperationType): DataColumnType[] => {
    switch (operationType) {
        case "MONETARY_AMOUNT": {
            return ["MONETARY_AMOUNT"];
        }
        case "MATCH_COMPANY": {
            return ["COMPANY"];
        }
        case "GET_OR_CREATE": {
            return ["TABLE_RELATION"];
        }
    }
    return createPrimitiveColumnTypeMessages();
};

export const CreateAndConnectUnusedSourceModal: React.FC<Props> = ({
    source,
    dataTableId,
    dataPipelineId,
    onCreate,
    onClose,
}) => {
    const relevantTypes = getRelevantTypeOrPrimitives(source.sourceOperationType);
    const haveToUseFixedType = relevantTypes.length === 1;
    const defaultType = haveToUseFixedType ? relevantTypes[0] : "TEXT";
    const [selectedDataType, setSelectedDataType] = useState<DataColumnType>(defaultType);
    const [createColumn, columnMutation] = useCreateDataColumnsMutation();
    const [connectColumn, connectionMutation] = useCreateInputOutputConnectionMutation();
    const isLoading = columnMutation.result.type === "loading" || connectionMutation.result.type === "loading";
    const columns = useDataColumnsByDataTableId(dataTableId);
    const conflictingName = columns.find((dc) => dc.name === source.label);
    const prefix = conflictingName ? "(2)" : "";

    const handleCreate = async () => {
        if (isLoading) return;
        const column = await createColumn({
            input: {
                dataColumns: [
                    {
                        dataType: selectedDataType,
                        name: source.label + prefix,
                    },
                ],
                dataTableId,
            },
        });
        track("Pipeline union node: Created Data Column", {});

        if (!column.data?.createDataColumns.dataColumns.length) return;
        const result = await connectColumn({
            input: {
                dataPipelineId,
                from: { referencedId: source.id, type: source.type },
                to: {
                    referencedId: column.data?.createDataColumns.dataColumns[0].id,
                    type: "DATA_TABLE_COLUMN",
                },
            },
        });
        if (result.errors) return;
        onCreate();
    };

    return (
        <Dialog open onClose={onClose}>
            <DialogContent sx={{ minWidth: 300 }}>
                <Stack gap={1}>
                    <Autocomplete
                        options={relevantTypes}
                        disabled={haveToUseFixedType}
                        value={selectedDataType}
                        onChange={(_, value) => {
                            if (!haveToUseFixedType && value) {
                                setSelectedDataType(value);
                            }
                        }}
                        renderInput={(params) => (
                            <TextField {...params} label="Data type" inputProps={{ ...params.inputProps }} />
                        )}
                    />

                    {!!conflictingName && (
                        <Alert
                            severity="warning"
                            action={
                                <LoadingButton
                                    variant="contained"
                                    loading={isLoading}
                                    onClick={handleCreate}
                                    color="success"
                                >
                                    {fm(globalMessages.confirm)}
                                </LoadingButton>
                            }
                        >
                            {fm(messages.warning)}
                        </Alert>
                    )}
                    {!conflictingName && (
                        <LoadingButton variant="contained" loading={isLoading} onClick={handleCreate} color="success">
                            {fm(globalMessages.confirm)}
                        </LoadingButton>
                    )}
                    <GraphqlRequestContainer asyncData={columnMutation.result} />
                    <GraphqlRequestContainer asyncData={connectionMutation.result} />
                </Stack>
            </DialogContent>
        </Dialog>
    );
};
