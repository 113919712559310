import { useFeatureToggle } from "@ignite-analytics/feature-toggle";
import axios, { AxiosProgressEvent } from "axios";

import { fm } from "@/contexts/IntlContext";
import {
    useCreateUploadUrlsMutation,
    useCreateUploadUrlsV2Mutation,
    useUpdateFilesUploadStatusMutation,
} from "@/generated/client";
import messages from "./messages";

const MAX_FILE_SIZE = 200_000_000;

export class FileSizeError extends Error {
    constructor(message: string) {
        super();
        this.name = FileSizeError.name;
        this.message = message;
    }
}

const useFileManager = (serviceId: string) => {
    const newImplementation = useFeatureToggle("internal-file-management", true);

    const [createUploadUrls] = useCreateUploadUrlsMutation();
    const [createUploadUrlsV2] = useCreateUploadUrlsV2Mutation();
    const [updateFilesUploadStatusMutation] = useUpdateFilesUploadStatusMutation();

    const createUploadUrl = async (file: File): Promise<{ name: string; url: string; id: string }> => {
        if (newImplementation) {
            const uploadUrlsObjects = await createUploadUrlsV2({
                input: {
                    type: "import",
                    files: [{ fileName: file.name, fileSize: file.size, fileType: file.type }],
                },
            });
            const uploadUrl = uploadUrlsObjects.data?.createUploadUrlsV2.urls[0];
            if (!uploadUrl) throw new Error("Something went wrong");
            return {
                name: uploadUrl.fileName,
                url: uploadUrl.url,
                id: uploadUrl.fileId,
            };
        }

        const uploadUrlsObjects = await createUploadUrls({
            input: {
                serviceId,
                files: [{ fileName: file.name, fileSize: file.size.toString(), fileType: file.type }],
            },
        });
        const uploadUrl = uploadUrlsObjects.data?.createUploadUrls.urls[0];
        if (!uploadUrl) throw new Error("Something went wrong");
        return {
            name: uploadUrl.fileName,
            url: uploadUrl.url,
            id: uploadUrl.fileId,
        };
    };

    const uploadFile = async (file: File, onProgress: (progress: number) => void) => {
        if (file.size > MAX_FILE_SIZE) {
            return {
                error: new FileSizeError(
                    fm(messages.maxFileSizeError, {
                        fileSize: Math.round(file.size / 10 ** 6),
                        maxSize: 200,
                    }).toString()
                ),
            };
        }
        try {
            const fileInformation = await createUploadUrl(file);
            await axios({
                method: "PUT",
                url: fileInformation.url,
                headers: { "Content-Type": file.type },
                data: file,
                onUploadProgress: (progressEvent: AxiosProgressEvent) => {
                    if (progressEvent.total) {
                        onProgress(Math.round((progressEvent.loaded * 100) / progressEvent.total));
                    } else {
                        onProgress(0);
                    }
                },
            });

            await updateFilesUploadStatusMutation({
                input: {
                    files: [{ fileId: fileInformation.id || "", uploadStatus: "SUCCESS" }],
                },
            });
            return { data: fileInformation };
        } catch (error) {
            if (error instanceof Error) {
                return { error };
            }
            return { error: new Error("Something went wrong") };
        }
    };

    return {
        uploadFile,
    };
};

export default useFileManager;
