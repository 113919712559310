import { DataTableCollection } from "@/generated/client";

type DataTableCollectionOrderItem = {
    id: string;
    index: number;
};

export const getUrl = (url: string, split?: string) => {
    if (!split) {
        return "/table-view";
    }
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [_, urlAfterSplit] = url.split(`${split}/`);
    return urlAfterSplit ?? "/table-view";
};

export const sortCollections = (
    collectionOrder: DataTableCollectionOrderItem[],
    collections: DataTableCollection[]
) => {
    if (!collectionOrder.length) return collections;
    const copy = [...collections];

    return copy.sort(
        (a, b) =>
            (collectionOrder.find((item) => item.id === a.id)?.index ?? 100) -
            (collectionOrder.find((item) => item.id === b.id)?.index ?? 100)
    );
};

export const generateNewOrderConfig = (
    targetCollectionId: string,
    dragCollectionId: string,
    borderPosition: "TOP" | "BOTTOM" | undefined,
    collections: DataTableCollection[]
) => {
    let newOrderIds: string[] = [];

    const targetCollection = collections.find((collection) => collection.id === targetCollectionId);
    const dragCollection = collections.find((collection) => collection.id === dragCollectionId);

    if (
        typeof targetCollection === "object" &&
        "id" in targetCollection &&
        typeof dragCollection === "object" &&
        "id" in dragCollection
    ) {
        const collectionIds = collections.map((collection) => collection.id);

        const targetCollectionIndex = collectionIds.findIndex((collectionId) => collectionId === targetCollection.id);

        if (borderPosition === "TOP") {
            if (targetCollectionIndex === 0) {
                newOrderIds = [
                    dragCollectionId,
                    ...collectionIds.slice(targetCollectionIndex).filter((c) => c !== dragCollectionId),
                ];
            } else {
                newOrderIds = [
                    ...collectionIds.slice(0, targetCollectionIndex).filter((c) => c !== dragCollectionId),
                    dragCollectionId,
                    ...collectionIds.slice(targetCollectionIndex).filter((c) => c !== dragCollectionId),
                ];
            }
        } else if (borderPosition === "BOTTOM" || undefined) {
            newOrderIds = [
                ...collectionIds.slice(0, targetCollectionIndex + 1).filter((c) => c !== dragCollectionId),
                dragCollectionId,
                ...collectionIds.slice(targetCollectionIndex + 1).filter((c) => c !== dragCollectionId),
            ];
        }

        const newOrderConfig: DataTableCollectionOrderItem[] = newOrderIds.map((id, index) => ({ id, index }));

        return newOrderConfig;
    }
};
