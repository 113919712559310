import { GraphqlRequestContainer } from "@ignite-analytics/graphql-utilities";
import LoadingButton from "@mui/lab/LoadingButton";
import { TextField } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import React, { useState } from "react";

import { DataColumn } from "@/Types/DataColumn";
import { fm } from "@/contexts/IntlContext";
import { useCreateTagColumnMutation } from "@/generated/client";
import globalMessages from "@/messages";

import messages from "../../messages";

interface Props {
    dataTableId: string;
    onDataColumnCreated?: (newColumn?: DataColumn) => void;
    isList?: boolean;
    name?: string;
}

export const CreateTagColumn: React.FC<Props> = ({ dataTableId, onDataColumnCreated, isList, name }) => {
    const [createTagColumn, mutation] = useCreateTagColumnMutation({ refetchQueries: ["getAllDataColumns"] });
    const [tagValues, setTagValues] = useState<string[]>([]);

    const onSubmit = () => {
        if (!name) return;
        if (mutation.result.type === "loading") {
            return;
        }
        createTagColumn({
            input: {
                dataTableId,
                isList,
                name,
                tagStringValues: tagValues,
            },
        }).then(() => onDataColumnCreated && onDataColumnCreated());
    };

    return (
        <Stack gap={1}>
            <Autocomplete
                multiple
                options={[]}
                value={tagValues}
                freeSolo
                onChange={(_, value: string[]) => {
                    const newValues = Array.from(new Set(value));
                    setTagValues(newValues);
                }}
                renderTags={(value: readonly string[], getTagProps) =>
                    value.map((option: string, index: number) => {
                        const key = `${option}-${index}`;
                        return <Chip variant="status" label={option} {...getTagProps({ index })} key={key} />;
                    })
                }
                renderInput={(params) => (
                    <TextField
                        {...params}
                        placeholder={fm(messages.addTagPlaceholder).toString()}
                        inputProps={{ ...params.inputProps }}
                    />
                )}
            />
            <LoadingButton
                disabled={!name}
                loading={mutation.result.type === "loading"}
                variant="outlined"
                onClick={onSubmit}
            >
                {fm(globalMessages.submitButton)}
            </LoadingButton>
            <GraphqlRequestContainer asyncData={mutation.result} loading={null} />
        </Stack>
    );
};
