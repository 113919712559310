import React from "react";
import { Tooltip, Typography, Stack } from "@mui/material";

import { fm } from "@/contexts/IntlContext";
import { TinyIcon } from "@/components/TinyIcon";

import { NodeInformation } from "../Nodes/constants";
import messages from "./messages";

type Props = {
  content: NodeInformation;
};

export const LockedSideBarItem: React.FC<Props> = ({ content }) => (
      <Tooltip title={fm(messages.noPermissions)} placement="left">
          <Stack
              alignItems="center"
              direction="row"
              height="30px"
              sx={{
                  cursor: "not-allowed",
                  paddingLeft: 1,
              }}
              gap={1}
          >
              <TinyIcon
                  color="text.disabled"
                  iconComponent={content.iconComponent}
              />
              <Typography
                  variant="textSm"
                  color="text.disabled"
                  noWrap
                  padding={{ left: 1 }}
                  textOverflow="ellipsis"
              >
                  {content.name}
              </Typography>
          </Stack>
      </Tooltip>
  );

