import { TRANSACTIONS_GT } from "@ignite-analytics/global-types";
import { GraphqlRequestContainer } from "@ignite-analytics/graphql-utilities";
import React from "react";
import { Redirect, useParams } from "react-router-dom";
import ContentCard from "@/components/ContentCard";
import { useBasePath } from "@/contexts/BasePathContext";
import { DataTable } from "@/generated/client";
import { useManyDataTables } from "@/generated/DataTableEntity";
import DataTableOverview from "./SpecificTableOverview";

const DataTableOverviewWrapper: React.FC = () => {
    const basePath = useBasePath();
    const result = useManyDataTables();
    const { dataTableId } = useParams<{ dataTableId: string | undefined }>();

    if (!dataTableId) {
        return (
            <GraphqlRequestContainer asyncData={result} loading={<ContentCard tabLinks={[]} loading />}>
                {(dataTables) => {
                    const spendTable: DataTable | undefined = dataTables.find(
                        (dt) => dt.globalTypeKey === TRANSACTIONS_GT
                    );
                    if (spendTable) {
                        return <Redirect to={`${basePath}${spendTable.id}/table-view`} />;
                    }
                    const firstTable: DataTable | undefined = dataTables.filter((dt) => !dt.tableType)[0];
                    if (!firstTable) {
                        return <Redirect to={`${basePath}setup`} />;
                    }
                    return <Redirect to={`${basePath}${firstTable.id}/table-view`} />;
                }}
            </GraphqlRequestContainer>
        );
    }
    return <DataTableOverview dataTableId={dataTableId} />;
};

export default DataTableOverviewWrapper;
