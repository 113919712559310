import { defineMessages } from "react-intl";

const messages = defineMessages({
    constantNumber: {
        id: "uploadPage.createOperationButton.mathematicalExpressionOperation.constantNumber",
        defaultMessage: "Constant Number",
    },
    input: {
        id: "uploadPage.createOperationButton.mathematicalExpressionOperation.input",
        defaultMessage: "Data field",
    },
    expresssion: {
        id: "uploadPage.createOperationButton.mathematicalExpressionOperation.expresssion",
        defaultMessage: "Expression",
    },
    selectInput: {
        id: "uploadPage.createOperationButton.mathematicalExpressionOperation.selectInput",
        defaultMessage: "Select input field",
    },
    buildPrompt: {
        id: "uploadPage.createOperationButton.mathematicalExpressionOperation.buildPrompt",
        defaultMessage: "Build Mathematical expression",
    },
    operationName: {
        id: "uploadPage.createOperationButton.mathematicalExpressionOperation.name",
        defaultMessage: "Name",
    },
    missingContext: {
        id: "uploadPage.createOperationButton.mathematicalExpressionOperation.missingContext",
        defaultMessage: "Missing data flow context",
    },
    create: {
        id: "uploadPage.createOperationButton.mathematicalExpressionOperation.create",
        defaultMessage: "create",
    },
});

export default messages;
