export function filterIsFirstOccurrence() {
    const occurrenceMap: Record<string, number> = {};
    return (key: string, index: number) => {
        if (occurrenceMap[key] === index) {
            return true;
        }
        if (occurrenceMap[key] === undefined) {
            occurrenceMap[key] = index;
            return true;
        }
        return false;
    };
}
