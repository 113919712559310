import { DotsVertical, Pen, Trash } from "@ignite-analytics/icons";
import { track } from "@ignite-analytics/track";
import { IconButton, Menu, MenuItem, Stack, Typography } from "@mui/material";
import React from "react";

import DeletePrompt from "@/components/Prompt/DeletePrompt";
import { RenameDataTableModal } from "@/components/RenameDataTableModal";
import { fm } from "@/contexts/IntlContext";
import { useDeleteDataTableAction } from "@/generated/DataTableEntity";
import { DataTable } from "@/generated/client";
import globalMessages from "@/messages";
import messages from "./messages";

interface Props {
    dataTable: DataTable;
}

export const TableActionMenu: React.FC<Props> = ({ dataTable }) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [deleteTable] = useDeleteDataTableAction();
    const [selectedAction, setSelectedAction] = React.useState<"RENAME" | "DELETE">();
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleDelete = async () => {
        await deleteTable(dataTable.id);
        track("Data Table: Delete", { from: "side-menu" });
    };

    return (
        <>
            <RenameDataTableModal
                dataTable={dataTable}
                open={selectedAction === "RENAME"}
                onAction={() => setSelectedAction(undefined)}
            />
            <DeletePrompt
                title={fm(messages.deleteDataTableHeader).toString()}
                description={fm(messages.deleteDataTableDescription, { name: dataTable.name }).toString()}
                writeConfirmation
                onCancel={() => setSelectedAction(undefined)}
                onDelete={handleDelete}
                open={selectedAction === "DELETE"}
            />
            <IconButton size="small" color="secondary" onClick={handleClick}>
                <DotsVertical fontSize="small" />
            </IconButton>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    "aria-labelledby": "basic-button",
                }}
            >
                <MenuItem
                    onClick={() => {
                        setSelectedAction("RENAME");
                    }}
                    sx={{ justifyContent: "space-between" }}
                >
                    <Stack direction="row" width="150px" gap={2}>
                        <Pen fontSize="small" />
                        <Typography>{fm(globalMessages.rename)}</Typography>
                    </Stack>
                </MenuItem>
                <MenuItem
                    onClick={() => {
                        setSelectedAction("DELETE");
                    }}
                    sx={{ justifyContent: "space-between" }}
                >
                    <Stack direction="row" width="150px" gap={2}>
                        <Trash fontSize="small" />
                        <Typography>{fm(globalMessages.delete)}</Typography>
                    </Stack>
                </MenuItem>
            </Menu>
        </>
    );
};
