import { defineMessages } from "react-intl";

const messages = defineMessages({
    delete: {
        id: "dataTables.global.delete",
        defaultMessage: "Delete",
    },
    dataColumn: {
        id: "dataTables.global.dataColumn",
        defaultMessage: "Data column",
    },
    save: {
        id: "dataTables.global.save",
        defaultMessage: "Save",
    },
    settings: {
        id: "dataTables.global.settings",
        defaultMessage: "Settings",
    },
    user: {
        id: "dataTables.global.user",
        defaultMessage: "User",
    },
    cancel: {
        id: "dataTables.global.cancel",
        defaultMessage: "Cancel",
    },
    submitButton: {
        id: "dataTables.global.submitButton",
        defaultMessage: "Submit",
    },
    name: {
        id: "dataTables.global.name",
        defaultMessage: "Name",
    },
    dataType: {
        id: "dataTables.global.dataType",
        defaultMessage: "Data type",
    },
    add: {
        id: "dataTables.global.add",
        defaultMessage: "Add",
    },
    apply: {
        id: "dataTables.global.apply",
        defaultMessage: "Apply",
    },
    hide: {
        id: "dataTables.global.hide",
        defaultMessage: "Hide",
    },
    error: {
        id: "dataTables.global.error",
        defaultMessage: "Error",
    },
    edit: {
        id: "dataTables.global.edit",
        defaultMessage: "Edit",
    },
    confirm: {
        id: "dataTables.global.confirm",
        defaultMessage: "Confirm",
    },
    back: {
        id: "dataTables.global.back",
        defaultMessage: "Back",
    },
    next: {
        id: "dataTables.global.next",
        defaultMessage: "Next",
    },
    finish: {
        id: "dataTables.global.finish",
        defaultMessage: "Finish",
    },
    complete: {
        id: "dataTables.global.complete",
        defaultMessage: "Complete",
    },
    search: {
        id: "dataTables.global.search",
        defaultMessage: "Search",
    },
    rename: {
        id: "dataTables.global.rename",
        defaultMessage: "Rename",
    },
});

export default messages;
