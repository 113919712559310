import { Download } from "@mui/icons-material";
import { Button, Table, TableBody, TableCell, TableContainer, TableRow } from "@mui/material";
import React from "react";
import { fm } from "@/contexts/IntlContext";
import messages from "./messages";

function downloadAllFiles(files: string[]) {
    function downloadNext(i: number) {
        if (i >= files.length) {
            return;
        }
        const iframe = document.createElement("iframe");
        iframe.setAttribute("sandbox", "allow-downloads allow-scripts");
        iframe.src = files[i];
        iframe.setAttribute("style", "display: none");
        document.body.appendChild(iframe);
        setTimeout(() => {
            downloadNext(i + 1);
        }, 500);
    }
    downloadNext(0);
}
export const UrlList: React.FC<{ urls: string[] }> = ({ urls }) => (
        <TableContainer>
            <Table>
                <TableBody>
                    <TableRow>
                        <TableCell>
                            <Button
                                size="small"
                                variant="text"
                                startIcon={<Download />}
                                onClick={() => downloadAllFiles(urls)}
                            >
                                {fm(messages.downloadAll)}
                            </Button>
                        </TableCell>
                    </TableRow>
                    {urls.map((url, i) => (
                        <TableRow key={url}>
                            <TableCell>
                                <a href={url}>{fm(messages.chunk, { i: i + 1 })}</a>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
