import {
    AccountTreeOutlined,
    CalendarMonthOutlined,
    Delete,
    FontDownloadOutlined,
    Info,
    Key,
    MoreHoriz,
    TagOutlined,
    ToggleOnOutlined,
} from "@mui/icons-material";
import {
    Autocomplete,
    Grid,
    IconButton,
    InputAdornment,
    Menu,
    MenuItem,
    Select,
    Stack,
    Switch,
    TextField,
    Tooltip,
    Typography,
} from "@mui/material";
import React, { useState } from "react";
import { fm } from "@/contexts/IntlContext";
import { DataTableConnectionCriterionTypeInput } from "@/generated/client";
import messages from "../../messages";
import { Criterion, connectionCriterionOperators } from "../../types";
import { SelectableField } from "../helpers";
import { getErrorText } from "./helpers";

const IconForType: React.FC<{ type: SelectableField["type"] }> = ({ type }) => {
    switch (type) {
        case "text":
            return <FontDownloadOutlined />;
        case "number":
            return <TagOutlined />;
        case "date":
            return <CalendarMonthOutlined />;
        case "id":
            return <Key />;
        case "boolean":
            return <ToggleOnOutlined />;
        case "group":
            return <AccountTreeOutlined />;
        default:
            return null;
    }
};

type Props = {
    criterion: Criterion;
    baseSelectableFields: SelectableField[];
    targetSelectableFields: SelectableField[];
    onCriterionChange: (criterion: Criterion) => void;
    onDelete: () => void;
};
export const CriterionRow: React.FC<Props> = ({
    onCriterionChange,
    criterion,
    baseSelectableFields,
    targetSelectableFields,
    onDelete,
}) => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const targetSelectedField = targetSelectableFields.find(
        (selectableField) => selectableField.field === criterion.targetField
    );
    const baseSelectedField = baseSelectableFields.find(
        (selectableField) => selectableField.field === criterion.sourceField
    );

    const errorText = getErrorText(baseSelectedField, targetSelectedField);
    return (
        <Grid container direction="row" justifyContent="space-between" alignItems="center">
            <Grid item sm={4}>
                <Autocomplete
                    fullWidth
                    value={baseSelectedField}
                    renderOption={(optionProps, option) => (
                        <li {...optionProps}>
                            <Stack direction="row" gap={1}>
                                <IconForType type={option.type} />
                                <Typography>{option.label}</Typography>
                            </Stack>
                        </li>
                    )}
                    options={baseSelectableFields}
                    getOptionLabel={(option) => `${option.label}`}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            error={!!errorText}
                            helperText={errorText}
                            InputProps={{
                                ...params.InputProps,
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <IconForType type={baseSelectedField?.type} />
                                    </InputAdornment>
                                ),
                            }}
                            fullWidth
                            label={fm(messages.baseColumn)}
                            size="small"
                            placeholder={fm(messages.baseColumn).toString()}
                        />
                    )}
                    onChange={(_, v) => {
                        v?.isSelectable && onCriterionChange({ ...criterion, sourceField: v.field });
                    }}
                />
            </Grid>
            <Grid sm={3} justifyContent="center" alignItems="center">
                <Stack direction="row" justifyContent="center">
                    <Select
                        size="small"
                        value={criterion.type}
                        onChange={(v) =>
                            onCriterionChange({
                                ...criterion,
                                type: v.target.value as DataTableConnectionCriterionTypeInput,
                            })
                        }
                    >
                        {connectionCriterionOperators.map((operator) => (
                                <MenuItem key={operator} value={operator}>
                                    {operator}
                                </MenuItem>
                            ))}
                    </Select>
                </Stack>
            </Grid>
            <Grid sm={4} direction="row">
                <Stack direction="row">
                    <Autocomplete
                        fullWidth
                        value={targetSelectedField}
                        options={targetSelectableFields}
                        getOptionLabel={(option) => option.label}
                        renderOption={(optionProps, option) => (
                            <li {...optionProps}>
                                <Stack direction="row" gap={1}>
                                    <IconForType type={option.type} />
                                    <Typography>{option.label}</Typography>
                                </Stack>
                            </li>
                        )}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                error={!!errorText}
                                helperText={errorText}
                                InputProps={{
                                    ...params.InputProps,
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <IconForType type={targetSelectedField?.type} />
                                        </InputAdornment>
                                    ),
                                }}
                                fullWidth
                                label={fm(messages.baseColumn)}
                                size="small"
                                placeholder={fm(messages.baseColumn).toString()}
                            />
                        )}
                        onChange={(_, v) => {
                            v?.isSelectable && onCriterionChange({ ...criterion, targetField: v.field });
                        }}
                    />
                    <IconButton onClick={() => setIsMenuOpen(true)} ref={setAnchorEl}>
                        <MoreHoriz />
                    </IconButton>
                    <Menu onClose={() => setIsMenuOpen(false)} anchorEl={anchorEl} open={isMenuOpen}>
                        <Stack gap={1} alignItems="center">
                            <MenuItem
                                onClick={() =>
                                    onCriterionChange({
                                        ...criterion,
                                        ignoreOnBlankTarget: !criterion.ignoreOnBlankTarget,
                                    })
                                }
                            >
                                <Stack alignItems="center" direction="row">
                                    <Typography>
                                        {fm(messages.ignoreOnBlankLabel, {
                                            fieldLabel: targetSelectedField?.label ?? criterion.targetField,
                                        })}
                                    </Typography>
                                    <Tooltip
                                        title={fm(messages.ignoreOnBlankDescription, {
                                            fieldLabel: targetSelectedField?.label ?? criterion.targetField,
                                        })}
                                    >
                                        <Info />
                                    </Tooltip>
                                    <Switch
                                        checked={criterion.ignoreOnBlankTarget}
                                        onChange={(_, checked) =>
                                            onCriterionChange({ ...criterion, ignoreOnBlankTarget: checked })
                                        }
                                    />
                                </Stack>
                            </MenuItem>
                            <MenuItem onClick={onDelete} sx={{ width: "100%" }}>
                                <Stack alignItems="center" width="100%" justifyContent="space-between" direction="row">
                                    <Typography>{fm(messages.removeCriterionLabel)}</Typography>
                                    <IconButton>
                                        <Delete />
                                    </IconButton>
                                </Stack>
                            </MenuItem>
                        </Stack>
                    </Menu>
                </Stack>
            </Grid>
        </Grid>
    );
};
