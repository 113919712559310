import { Autocomplete, TableCell, TableRow, TextField } from "@mui/material";
import React from "react";
import { DataColumn } from "@/Types/DataColumn";
import { InputSourceItem } from "@/Types/InputSourceItem";
import { createColumnTypeMessages } from "@/components/CreateDataColumnForm/helpers";
import { fm } from "@/contexts/IntlContext";
import { DataRepository, InputOutputConnection } from "@/generated/client";
import { isRelevantSource } from "./helpers";

interface Props {
    dataColumn: DataColumn;
    dataRepositories: DataRepository[];
    inputOutputConnections: InputOutputConnection[];
    sourcesByRepository: { [repositoryId: string]: InputSourceItem[] };
    handleSourceSelection: (
        from: InputSourceItem,
        toDataColumnId: string,
        currentInputOutputConnectionId?: string
    ) => Promise<void>;
    handleSourceClear: (source: string) => void;
}
export const InputOutputMappingRow: React.FC<Props> = ({
    dataColumn,
    dataRepositories,
    sourcesByRepository,
    inputOutputConnections,
    handleSourceClear,
    handleSourceSelection,
}: Props) => {
    const dataType = dataColumn.dataType === "DATE_AGGREGATION" ? "AGGREGATION" : dataColumn.dataType;
    return (
        <TableRow key={dataColumn.id}>
            <TableCell>{dataColumn.name}</TableCell>
            <TableCell>{fm(createColumnTypeMessages()[dataType].labelMessage)}</TableCell>
            {dataRepositories.map((repository) => {
                const connection = inputOutputConnections.find(
                    (inputOutputConnection) => inputOutputConnection.to.referencedId === dataColumn.id
                );
                const sources = repository.id in sourcesByRepository ? sourcesByRepository[repository.id] : [];

                return (
                    <TableCell key={`${repository.id}-${dataColumn.id}`}>
                        <Autocomplete
                            fullWidth
                            options={sources.filter((s) => isRelevantSource(s, dataColumn.dataType))}
                            onChange={(_, value) =>
                                value
                                    ? handleSourceSelection(value, dataColumn.id, connection?.id)
                                    : connection && handleSourceClear(connection.id)
                            }
                            getOptionLabel={(option) => option.label}
                            value={sources.find(
                                (source) =>
                                    source.type === connection?.from.type &&
                                    source.id === connection?.from.referencedId &&
                                    connection.to.type === "DATA_TABLE_COLUMN" &&
                                    connection.to.referencedId === dataColumn.id
                            )}
                            renderInput={(params) => <TextField {...params} />}
                        />
                    </TableCell>
                );
            })}
        </TableRow>
    );
};
