import React from "react";
import { SUPPLIER_GT } from "@ignite-analytics/global-types";
import { Stack } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid-pro";

import { DataColumn } from "@/Types/DataColumn";
import { Cell } from "@/components/DataTableRow/Cell";
import { DataTable } from "@/generated/client";

export function getColumnTypeDef(column: DataColumn, width?: number, path?: string): GridColDef {
    switch (column.dataType) {
        default:
            return {
                headerName: column.name,
                width: width || 150,
                field: column.id,
                cellClassName: "mui-cell-container",
                renderCell: (params) => {
                    if (!params.row) {
                        return;
                    }
                    return (
                        <Stack height="100%" direction="row" alignItems="center">
                            <Cell
                                column={column}
                                rowId={`${params.rowNode.id}`}
                                data={params.row[column.id]}
                                path={path}
                            />
                        </Stack>
                    );
                },
            };
    }
}

export function getPinnedColumnsFromStorage(dataTableId: string): { right?: string[]; left?: string[] } | undefined {
    const pinnedColumns = localStorage.getItem(`DATATABLE_${dataTableId}_pinnedColumnsMuiDataGrid`);
    if (!pinnedColumns) {
        return undefined;
    }
    return JSON.parse(pinnedColumns) as { right?: string[]; left?: string[] };
}

export function setPinnedColumnsToStorage(dataTableId: string, pinnedColumns: { right?: string[]; left?: string[] }) {
    localStorage.setItem(`DATATABLE_${dataTableId}_pinnedColumnsMuiDataGrid`, JSON.stringify(pinnedColumns));
}

export function getDefaultPinnedColumns(
    dataTable: DataTable,
    columns: DataColumn[]
): { right?: string[]; left?: string[] } {
    if (dataTable.nameColumnId) {
        const nameColumn = columns.find((col) => col.id === dataTable.nameColumnId);
        if (nameColumn) {
            return {
                left: [nameColumn.id],
            };
        }
    }
    return {
        left: columns.filter((col) => col.globalTypeKey === SUPPLIER_GT).map((col) => col.id),
        right: [],
    };
}
