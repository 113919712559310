import { Edit } from "@mui/icons-material";
import { Card, CardContent, IconButton, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import { fm } from "@/contexts/IntlContext";
import { DataPipelineOperation } from "@/generated/client";
import messages from "../../messages";
import ExternalRepositoryDetails from "../ExternalRepositoryDetails";
import ChangeOperationDetailView from "./ChangeOperationDetailView";
import { getOperationSpecificDetails } from "./helpers";

interface Props {
    operation: DataPipelineOperation;
}
const DefaultOperationDetail: React.FC<Props> = ({ operation }) => {
    const operationInfoDisplay = getOperationSpecificDetails(operation);

    const [edit, setEdit] = useState(false);

    return (
        <Stack>
            <Typography variant="subtitle1">{fm(messages.configuration)}</Typography>
            <Stack direction="row" gap={1} alignItems="center">
                <Card>
                    <CardContent>
                        {edit ? (
                            <ChangeOperationDetailView
                                operation={operation}
                                onUpdate={() => {
                                    setEdit(false);
                                }}
                            />
                        ) : ["GET_OR_CREATE", "LOOKUP"].includes(operation.operationType) &&
                          operationInfoDisplay?.displayValue ? (
                            <ExternalRepositoryDetails repositoryId={operationInfoDisplay.displayValue} />
                        ) : (
                            <>
                                <Typography noWrap variant="buttonSmall">
                                    {operationInfoDisplay?.label.toUpperCase()}
                                </Typography>

                                <Typography noWrap variant="subtitle2">
                                    {operationInfoDisplay?.displayValue}
                                </Typography>
                            </>
                        )}
                    </CardContent>
                </Card>
                <IconButton onClick={() => setEdit(!edit)}>
                    <Edit />
                </IconButton>
            </Stack>
        </Stack>
    );
};

export default DefaultOperationDetail;
