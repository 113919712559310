import { Change } from "./ApplyChangesButtons/helpers";

export function groupChangesByPipeline(changes: Change[]): Record<string, Change[]> {
    return changes.reduce<Record<string, Change[]>>((acc, change) => {
        if (!acc[change.pipelineInfo.pipelineId]) {
            acc[change.pipelineInfo.pipelineId] = [];
        }
        acc[change.pipelineInfo.pipelineId].push(change);
        return acc;
    }, {});
}
