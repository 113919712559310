import { Button } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";

interface Props {
    message: string;
    href: string;
    onClick?: () => void;
    icon?: JSX.Element;
}

export const LinkButton: React.FC<Props> = ({ message, href, onClick, icon }) => (
        <Button sx={{ width: 300 }} variant="outlined" size="small" onClick={onClick} startIcon={icon}>
            <Link href={href} to={href}>
                {message}
            </Link>
        </Button>
    );
