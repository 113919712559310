import {
    Button,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from "@mui/material";
import React, { useState } from "react";
import { InputSourceItem } from "@/Types/InputSourceItem";
import { fm } from "@/contexts/IntlContext";
import { DataPipeline, InputOutputConnection } from "@/generated/client";
import messages from "../messages";
import { CreateAndConnectUnusedSourceModal } from "./NewColumnModal";

interface Props {
    dataPipeline: DataPipeline;
    sourcesOfPipeline: InputSourceItem[];
    inputOutputConnections: InputOutputConnection[];
    onColumnCreation: () => void;
}

export const UnmappedSources: React.FC<Props> = ({
    dataPipeline,
    sourcesOfPipeline,
    inputOutputConnections,
    onColumnCreation,
}) => {
    const [sourceToCreate, setSourceToCreate] = useState<InputSourceItem>();
    // eslint-disable-next-line fp/no-mutating-methods
    const unMappedSources = sourcesOfPipeline
        .filter((source) => !inputOutputConnections.find((ioc) => ioc.from.referencedId === source.id))
        .sort((a, b) => {
            if (a.type === "OPERATION" && b.type !== "OPERATION") return 1;
            if (a.type !== "OPERATION" && b.type === "OPERATION") return -1;
            return 0;
        });
    return (
        <TableContainer>
            {sourceToCreate && (
                <CreateAndConnectUnusedSourceModal
                    dataTableId={dataPipeline.targetDataTableId}
                    dataPipelineId={dataPipeline.id}
                    source={sourceToCreate}
                    onCreate={() => {
                        setSourceToCreate(undefined);
                        onColumnCreation();
                    }}
                    onClose={() => setSourceToCreate(undefined)}
                />
            )}
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell> {fm(messages.unused)}</TableCell>
                        <TableCell />
                    </TableRow>
                </TableHead>
                <TableBody>
                    {unMappedSources.map((source) => (
                        <TableRow key={source.id}>
                            <TableCell>{source.label}</TableCell>

                            <TableCell>
                                <Stack direction="row" justifyContent="space-between">
                                    <Typography color="GrayText">{` New column: ${source.label}`}</Typography>
                                    <Button size="small" color="success" onClick={() => setSourceToCreate(source)}>
                                        {fm(messages.createAndConnect)}
                                    </Button>
                                </Stack>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};
