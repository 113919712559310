import { defineMessages } from "react-intl";

const messages = defineMessages({
    globalType: {
        id: "uploadPage.dataRepositoryPage.manageGlobalColumnTypes.globalType",
        defaultMessage: "Tag (Global column type)",
    },
    blankGlobalType: {
        id: "uploadPage.dataRepositoryPage.manageGlobalColumnTypes.blankGlobalType",
        defaultMessage: "---",
    },
    dataColumns: {
        id: "uploadPage.dataRepositoryPage.manageGlobalTypes.dataColumns",
        defaultMessage: "Data columns",
    },
    editColumnName: {
        id: "uploadDataPage.dataColumnModal.manageGlobalTypes.editColumnName",
        defaultMessage: "Edit name",
    },
    discardChanges: {
        id: "uploadDataPage.dataColumnModal.manageGlobalTypes.discardChanges",
        defaultMessage: "Discard changes",
    },
    updateName: {
        id: "uploadDataPage.dataColumnModal.manageGlobalTypes.updateName",
        defaultMessage: "Update name",
    },
    columnDisabled: {
        id: "uploadDataPage.dataColumnModal.manageGlobalTypes.columnDisabled",
        defaultMessage: "This column is in the process of being deleted. You can therefore not edit it.",
    },
    hideTooltip: {
        id: "uploadDataPage.dataColumnModal.manageGlobalTypes.hideTooltip",
        defaultMessage: "Hide column from your personal table view. This will not affect other users of the platform",
    },
});

export default messages;
