import { Alert, AlertColor, Snackbar, Stack, Typography } from "@mui/material";
import React, { useCallback } from "react";
import { TranslatedString } from "@/Types/translation";

interface Props {
    message: TranslatedString;
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    severity: AlertColor;
    extraContent?: JSX.Element;
}

export const UserInfoSnackbar: React.FC<Props> = ({ message, open, setOpen, severity, extraContent }) => {
    const close = useCallback(() => setOpen(false), [setOpen]);
    return (
        <Snackbar
            open={open}
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            onClose={close}
            autoHideDuration={8000}
            message
        >
            <Alert variant="filled" severity={severity}>
                <Stack gap={1}>
                    <Typography>{message.toString()}</Typography>
                    {extraContent}
                </Stack>
            </Alert>
        </Snackbar>
    );
};
