import { defineMessages } from "react-intl";

const messages = defineMessages({
    title: {
        id: "aggregatedView.create.title",
        defaultMessage: "Create new aggregated table for {dataTableName}",
    },
    aggregatedColumnsHeaderText: {
        id: "aggregatedView.create.aggregatedColumnsHeaderText",
        defaultMessage: "Create new columns to represent aggregated values",
    },
    tableViewSelection: {
        id: "aggregatedView.create.tableViewSelection",
        defaultMessage: "Table view selection",
    },
    createAggregatedViewTooltipText: {
        id: "aggregatesView.create.createAggregatedViewTooltipText",
        defaultMessage: "Create new aggregated table view",
    },
});

export default messages;
