import { Autocomplete, TextField } from "@mui/material";
import React from "react";
import { fm } from "@/contexts/IntlContext";
import {
    DataPipelineOperation,
    DataPipelineOperationInputOutput,
    InputOutputConnection,
    useCreateInputOutputConnectionMutation,
    useDeleteInputOutputConnectionMutation,
} from "@/generated/client";
import { InputSourceItem } from "@/Types/InputSourceItem";
import messages from "../messages";
import { removeInvalidOptions } from "./helper";

interface Props {
    inputOutputConnections: InputOutputConnection[];
    input: DataPipelineOperationInputOutput;
    sources: InputSourceItem[];
    operation: DataPipelineOperation;
}

export const InputSelect: React.FC<Props> = ({ inputOutputConnections, input, sources, operation }) => {
    const [connectInputOutput] = useCreateInputOutputConnectionMutation({
        refetchQueries: ["getInputOutputConnections"],
    });
    const [deleteInputOutputConnection] = useDeleteInputOutputConnectionMutation({
        refetchQueries: ["getInputOutputConnections"],
    });

    const onSelection = async (
        from: InputSourceItem,
        operationInputId: string,
        currentInputOutputConnectionId?: string
    ) => {
        if (currentInputOutputConnectionId) {
            await deleteInputOutputConnection({ input: { inputOutputConnectionId: currentInputOutputConnectionId } });
        }

        connectInputOutput({
            input: {
                dataPipelineId: operation.dataPipelineId,
                from: {
                    referencedId: from.id,
                    type: from.type,
                },
                to: {
                    referencedId: operationInputId,
                    type: "OPERATION",
                },
            },
        });
    };

    const onClear = (inputOutputConnectionId?: string) => {
        inputOutputConnectionId && deleteInputOutputConnection({ input: { inputOutputConnectionId } });
    };

    const relevantConnection = inputOutputConnections
        ? inputOutputConnections.find((inputOutputConnection) => inputOutputConnection.to.referencedId === input.id)
        : undefined;
    return (
        <Autocomplete
            key={input.id}
            size="small"
            options={removeInvalidOptions(sources, operation)}
            onChange={(_, value) =>
                value ? onSelection(value, input.id, relevantConnection?.id) : onClear(relevantConnection?.id)
            }
            getOptionLabel={(option) => option.label}
            value={sources.find((source) => source.id === relevantConnection?.from.referencedId)}
            renderInput={(params) => (
                <TextField label={`${fm(messages.input)} ${input.label ?? input.key}`} {...params} />
            )}
        />
    );
};
