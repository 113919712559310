import { formatDatetime } from "@ignite-analytics/locale2";
import { Stack, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import React from "react";
import { UserList } from "@/components/User/UserList";
import { fm } from "@/contexts/IntlContext";
import { Change } from "../../ApplyChangesButtons/helpers";
import messages from "../../ApplyChangesButtons/messages";

interface Props {
    changes: Change[];
}
const InProgressChangeTable: React.FC<Props> = ({ changes }) => (
        <Stack gap={1}>
            <Typography variant="h5">{changes[0]?.pipelineInfo.sourceName}</Typography>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell width="60%">{fm(messages.description)}</TableCell>
                        <TableCell width="30%">{fm(messages.date)}</TableCell>
                        <TableCell width="10%">{fm(messages.user)}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {changes.map((change) => (
                        <TableRow key={change.id}>
                            <TableCell component="th" scope="row">
                                <Stack direction="row" alignItems="center" gap={1}>
                                    {change.description.icon}
                                    <Typography>{change.description.text}</Typography>
                                </Stack>
                            </TableCell>
                            <TableCell>
                                <Typography>{formatDatetime(change.createdAt)}</Typography>
                            </TableCell>
                            <TableCell>
                                <Stack direction="row" justifyContent="center">
                                    <UserList size={24} userIds={[change.userId]} />
                                </Stack>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </Stack>
    );

export default InProgressChangeTable;
