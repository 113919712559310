import { track } from "@ignite-analytics/track";
import { Check } from "@mui/icons-material";
import { Alert, Autocomplete, IconButton, Stack, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import { fm } from "@/contexts/IntlContext";
import { DataPipelineOperation, useUpdateDataPipelineOperationMutation } from "@/generated/client";
import { formatGrpcErrorMessage } from "@/helpers";
import { getOperationSpecificDetails } from "../helpers";
import messages from "./messages";

interface Props {
    operation: DataPipelineOperation;
    onUpdate: () => void;
}
const OperationDetailView: React.FC<Props> = ({ operation, onUpdate }) => {
    const [updateOperation, { result: updateResult }] = useUpdateDataPipelineOperationMutation({
        refetchQueries: ["getDataPipelineOperations"],
    });
    const operationSpecific = getOperationSpecificDetails(operation) ?? undefined;
    const [values, setValues] = useState<{ key: string; value: string; allowedValues?: string[] }[] | undefined>(
        operationSpecific?.value
    );

    const [name, setName] = useState(operation.name);
    const handleSubmit = async () => {
        await updateOperation({
            input: {
                id: operation.id,
                name,
                operationType: operation.operationType,
                operationSpecific: values?.map((val) => ({ field: val.key, value: val.value })) || [],
            },
        });
        track("Pipeline: Update Operation", { operationType: operation.operationType });

        onUpdate();
    };

    const handleStateChange = (value: string, i: number) => {
        if (values) {
            const newValues = [...values];
            newValues[i].value = value;
            setValues(newValues);
        }
    };
    return (
        <Stack gap={1}>
            <Typography variant="subtitle2">{operation.name}</Typography>
            <Typography variant="buttonSmall">{operation.operationType.replace(/_/g, " ")}</Typography>
            <TextField
                label={fm(messages.name)}
                placeholder={fm(messages.name).toString()}
                type="text"
                value={name}
                fullWidth
                onChange={(e) => setName(e.currentTarget.value)}
            />
            {values?.map((valueTuple, i) => {
                const { allowedValues } = valueTuple;
                return operationSpecific?.changeable ? (
                    allowedValues ? (
                        <Autocomplete
                            key={valueTuple.key}
                            fullWidth
                            options={allowedValues}
                            onChange={(_, value) => value && handleStateChange(value, i)}
                            value={valueTuple.value}
                            renderInput={(params) => <TextField {...params} label={valueTuple.key} />}
                        />
                    ) : (
                        <TextField
                            fullWidth
                            key={valueTuple.key}
                            label={fm(messages.operationSpecific, {
                                type: valueTuple.key,
                            })}
                            type="text"
                            value={valueTuple.value}
                            onChange={(e) => handleStateChange(e.currentTarget.value, i)}
                        />
                    )
                ) : null;
            })}
            {updateResult.type === "error" && (
                <Alert severity="error">{formatGrpcErrorMessage(updateResult.error.message)}</Alert>
            )}
            <IconButton color="success" onClick={handleSubmit}>
                <Check />
            </IconButton>
        </Stack>
    );
};

export default OperationDetailView;
