import { GraphqlRequestContainer } from "@ignite-analytics/graphql-utilities";
import { track } from "@ignite-analytics/track";
import { InfoOutlined, PlayArrowOutlined } from "@mui/icons-material";
import { Button, Dialog, DialogContent, DialogContentText, DialogTitle, IconButton, Stack } from "@mui/material";
import React, { useMemo, useState } from "react";
import { UserInfoSnackbar } from "@/components/UserInfoSnackBar";
import { fm } from "@/contexts/IntlContext";
import { DataPipeline, useApplyChangesMutation } from "@/generated/client";
import { Change } from "../ApplyChangesButtons/helpers";
import { groupChangesByPipeline } from "../helpers";
import ChangesTable from "./ChangesTable";
import messages from "./messages";

interface Props {
    open: boolean;
    onClose: () => void;
    changes: Change[];
    dataPipelines: DataPipeline[];
}

export const UnappliedChangesDialog: React.FC<Props> = ({ open, onClose, changes, dataPipelines }) => {
    const [userFeedbackOpen, setUserFeedbackOpen] = useState(false);
    const [showExplanation, setShowExplanation] = useState(false);
    const [selectedPipelineIds, setSelectedPipelineIds] = useState<string[]>([]);
    const handleSelect = (ids: string[], add: boolean) => {
        if (add) {
            setSelectedPipelineIds(Array.from(new Set([...selectedPipelineIds, ...ids])));
            return;
        }
        setSelectedPipelineIds(selectedPipelineIds.filter((id) => !ids.includes(id)));
    };

    const changesByPipeline = useMemo(() => groupChangesByPipeline(changes), [changes]);

    const [applyChanges, applySelectedResult] = useApplyChangesMutation();
    const handleApplyChanges = async () => {
        const uniquePipelineIds = new Set(changes.map((c) => c.pipelineInfo.pipelineId));
        track("Pipeline: Apply Pending Changes", {
            appliedAllChanges: uniquePipelineIds.size === selectedPipelineIds.length,
            numberOfPipelines: dataPipelines.length,
        });
        await applyChanges({
            input: {
                dataPipelineIds: selectedPipelineIds,
            },
        });
        setUserFeedbackOpen(true);
        onClose();
    };

    return (
        <>
            <UserInfoSnackbar
                open={userFeedbackOpen}
                setOpen={setUserFeedbackOpen}
                message={fm(messages.applyStarted)}
                severity={applySelectedResult.result.type === "error" ? "error" : "success"}
                extraContent={<GraphqlRequestContainer asyncData={applySelectedResult.result} />}
            />
            <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
                <DialogTitle>
                    <Stack direction="row" alignItems="center">
                        {fm(messages.unAppliedChangesTitle)}
                        <IconButton
                            onClick={() => {
                                setShowExplanation(!showExplanation);
                            }}
                        >
                            <InfoOutlined fontSize="small" />
                        </IconButton>
                    </Stack>
                    {showExplanation && (
                        <DialogContentText>{fm(messages.unAppliedChangesDescription)}</DialogContentText>
                    )}
                </DialogTitle>
                <DialogContent>
                    <Stack spacing={2}>
                        <ChangesTable
                            changesByPipelineId={changesByPipeline}
                            selectedPipelineIds={selectedPipelineIds}
                            select={handleSelect}
                        />
                        <Stack direction="row" justifyContent="flex-end" spacing={2}>
                            <Button
                                color="success"
                                data-testid="applyChanges"
                                onClick={handleApplyChanges}
                                startIcon={<PlayArrowOutlined />}
                                disabled={selectedPipelineIds.length === 0}
                            >
                                {fm(messages.applySelectedChanges)}
                            </Button>
                        </Stack>
                    </Stack>
                </DialogContent>
            </Dialog>
        </>
    );
};
