import { GraphqlRequestContainer } from "@ignite-analytics/graphql-utilities";
import { track } from "@ignite-analytics/track";
import { LoadingButton } from "@mui/lab";
import { Stack, Table, TableBody, TableCell, TableContainer, TableHead, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { testIdPrefix } from "@/components/testIdprefix";
import { useDataManagementProcessEventListener } from "@/contexts/EventContexts/DataManagementEventContext";
import { fm } from "@/contexts/IntlContext";
import {
    DataPipeline,
    DataPipelineExecution,
    DataPipelineOperation,
    useGetAllDataPipelineExecutionsQuery,
    useGetInputOutputConnectionsQuery,
    useGetPipelineOperationExecutionRowsByBatchIdLazyQuery,
    useStartTestDataPipelineMutation,
} from "@/generated/client";
import { InputSourceItem } from "@/Types/InputSourceItem";
import messages from "./messages";
import { TableRows } from "./TableRows";

interface Props {
    operation: DataPipelineOperation;
    sources: InputSourceItem[];
    dataPipeline: DataPipeline;
}

export const OperationExecutionTable: React.FC<Props> = ({ operation, sources, dataPipeline }: Props) => {
    const [selectedExecution, setSelectedExecution] = useState<DataPipelineExecution>();
    const [testPipelineLoading, setTestPipelineLoading] = useState(false);
    const [startTestPipeline] = useStartTestDataPipelineMutation();
    const { result, refetch } = useGetAllDataPipelineExecutionsQuery({
        input: { dataPipelineId: dataPipeline.id, dryRunsOnly: true },
    });
    const [query, executionRowsResult] = useGetPipelineOperationExecutionRowsByBatchIdLazyQuery({
        fetchPolicy: "no-cache",
    });
    const { result: inputOutputsResult } = useGetInputOutputConnectionsQuery({
        input: { dataPipelineIds: [dataPipeline.id] },
    });

    useEffect(() => {
        if (!selectedExecution) return;
        query({
            input: {
                batchId: selectedExecution.batchIds[0],
                pipelineExecutionId: selectedExecution.id,
                operationId: operation.id,
            },
        });
    }, [selectedExecution, query, operation.id]);

    useDataManagementProcessEventListener("TestPipeline", (process) => {
        if (process.type === "PROCESS_FINISHED") {
            setTestPipelineLoading(false);
            refetch();
            setSelectedExecution(undefined);
        }
    });
    const handleRunPipeline = async () => {
        setTestPipelineLoading(true);
        await startTestPipeline({
            input: { dataPipelineId: dataPipeline.id },
        });
        track(`Pipeline: Click Test Pipeline`);
    };

    return (
        <GraphqlRequestContainer asyncData={result}>
            {(response) => {
                const sortedExecutions = [...response.dataPipelineExecutions].sort(
                    (objA, objB) => new Date(objB.createdAt).getTime() - new Date(objA.createdAt).getTime()
                );
                if (sortedExecutions.length) {
                    setSelectedExecution(sortedExecutions[0]);
                }
                return (
                    <Stack gap={1}>
                        <Typography variant="subtitle2">{fm(messages.header)}</Typography>
                        <LoadingButton
                            color="secondary"
                            size="small"
                            variant="contained"
                            onClick={handleRunPipeline}
                            loading={testPipelineLoading}
                            data-testid={`${testIdPrefix}-start-pipeline-test-run-button`}
                        >
                            {fm(messages.testOperation)}
                        </LoadingButton>
                        <GraphqlRequestContainer asyncData={inputOutputsResult}>
                            {(inputoutputResponse) => (
                                    <GraphqlRequestContainer asyncData={executionRowsResult.result}>
                                        {(executionRowsResponse) => (
                                                <TableContainer style={{ maxHeight: 350 }}>
                                                    <Table stickyHeader>
                                                        <TableHead>
                                                            <TableCell>{fm(messages.outputLabel)}</TableCell>
                                                            {operation.input.map((inputNode) => (
                                                                <TableCell key={inputNode.id}>{`${
                                                                    inputNode.label || inputNode.key
                                                                }`}</TableCell>
                                                            ))}
                                                            {operation.output.map((outputNode) => (
                                                                <TableCell key={outputNode.id}>{`${
                                                                    outputNode.label || outputNode.key
                                                                }`}</TableCell>
                                                            ))}
                                                        </TableHead>
                                                        <TableBody>
                                                            <TableRows
                                                                sources={sources}
                                                                dataPipelineExecutionRows={
                                                                    executionRowsResponse.dataPipelineExecutionRows
                                                                }
                                                                operation={operation}
                                                                inputOutputConnections={
                                                                    inputoutputResponse.inputOutputConnections
                                                                }
                                                            />
                                                        </TableBody>
                                                    </Table>
                                                    {!executionRowsResponse.dataPipelineExecutionRows.length && (
                                                        <Typography variant="subtitle2" noWrap>
                                                            {fm(messages.noRowsMessage)}
                                                        </Typography>
                                                    )}
                                                </TableContainer>
                                            )}
                                    </GraphqlRequestContainer>
                                )}
                        </GraphqlRequestContainer>
                    </Stack>
                );
            }}
        </GraphqlRequestContainer>
    );
};
