import { Alert, Dialog, DialogContent, DialogTitle, Stack } from "@mui/material";
import React, { useState } from "react";
import {
    CreateInputOutputConnectionInput,
    DataPipeline,
    DataPipelineOperation,
    InputOutputConnection,
    OperationType,
    useCreateConvertCountryDataPipelineOperationMutation,
    useCreateDateParserDataPipelineOperationMutation,
    useCreateFillBlanksDataPipelineOperationMutation,
    useCreateInputOutputConnectionMutation,
    useCreateKeywordTaggerDataPipelineOperationMutation,
    useCreateLetterCasingDataPipelineOperationMutation,
    useCreateListParserDataPipelineOperationMutation,
    useCreateMappingDataPipelineOperationMutation,
    useCreateMatchCompanyDataPipelineOperationMutation,
    useCreateMonetaryAmountDataPipelineOperationMutation,
    useCreateRegexDataPipelineOperationMutation,
    useCreateReplaceDataPipelineOperationMutation,
    useCreateSplitDataPipelineOperationMutation,
    useCreateTemplateStringDataPipelineOperationMutation,
    useCreateTranslationDataPipelineOperationMutation,
} from "@/generated/client";

import { RegexHelper } from "@/components/RegexHelper";
import { OPERATION_NODE_IMPLEMENTATION } from "@/containers/PipelinePageV2/Nodes/constants";
import { fm } from "@/contexts/IntlContext";
import CreateGetOrCreateOperationForm from "./CreateGetOrCreateForm";
import CreateLookupOperationForm from "./CreateLookupOperationForm";
import CreateMathematicalExpressionOperation from "./CreateMathematicalExpressionOperation";
import { commonFormats } from "./constants";
import ObjectForm, { FormData as CreateOperationFormData } from "./genericCreateOperationForm";
import messages from "./messages";
import { ObjectSpec as CreateOperationObjectSpec } from "./types";

type CreateOperationSpec = {
    mapFormvaluesToMutationInput: (data: CreateOperationFormData) => Promise<void>;
    spec: CreateOperationObjectSpec;
};

export interface OperationComponentProps {
    dataPipeline: DataPipeline;
    onFormSubmit: (newOperation?: DataPipelineOperation) => void;
    connectInputOutput?: (input: CreateInputOutputConnectionInput) => Promise<InputOutputConnection | undefined>;
}

export const SpecialCases: Record<string, React.FC<OperationComponentProps>> = {
    LOOKUP: CreateLookupOperationForm,
    GET_OR_CREATE: CreateGetOrCreateOperationForm,
    MATHEMATICAL_EXPRESSION: CreateMathematicalExpressionOperation,
};

export const CreateOperation = ({
    dataPipeline,
    operationType,
    onClose,
}: {
    dataPipeline: DataPipeline;
    operationType: OperationType;
    onClose: (operation?: DataPipelineOperation) => void;
}) => {
    const [error, setError] = useState<string | undefined>();
    const defaultRefetchQuery = {
        refetchQueries: ["getDataPipelineOperations"],
    };
    const [createRegex] = useCreateRegexDataPipelineOperationMutation(defaultRefetchQuery);
    const [createMonetaryAmount] = useCreateMonetaryAmountDataPipelineOperationMutation(defaultRefetchQuery);
    const [createConvertCountry] = useCreateConvertCountryDataPipelineOperationMutation(defaultRefetchQuery);
    const [createDateParser] = useCreateDateParserDataPipelineOperationMutation(defaultRefetchQuery);
    const [createSplitOperation] = useCreateSplitDataPipelineOperationMutation(defaultRefetchQuery);
    const [createTemplate] = useCreateTemplateStringDataPipelineOperationMutation(defaultRefetchQuery);
    const [createTranslationOperation] = useCreateTranslationDataPipelineOperationMutation(defaultRefetchQuery);
    const [createMappingOperation] = useCreateMappingDataPipelineOperationMutation(defaultRefetchQuery);
    const [createLetterCasing] = useCreateLetterCasingDataPipelineOperationMutation(defaultRefetchQuery);
    const [createMatchCompany] = useCreateMatchCompanyDataPipelineOperationMutation(defaultRefetchQuery);
    const [createKeywordTagger] = useCreateKeywordTaggerDataPipelineOperationMutation(defaultRefetchQuery);
    const [createFillBlanks] = useCreateFillBlanksDataPipelineOperationMutation(defaultRefetchQuery);
    const [createReplace] = useCreateReplaceDataPipelineOperationMutation(defaultRefetchQuery);
    const [createListParser] = useCreateListParserDataPipelineOperationMutation(defaultRefetchQuery);
    const [connectInputOutput] = useCreateInputOutputConnectionMutation({
        refetchQueries: ["getInputOutputConnections"],
    });

    const basicNameSpec: CreateOperationObjectSpec = {
        name: { type: "string", label: fm(messages.setOperationName) },
    };

    const basicNameAndPipelineMapping = (data: CreateOperationFormData) => ({
        dataPipelineId: dataPipeline.id,
        name: data.name.toString(),
    });

    const handleClose = (operation?: DataPipelineOperation) => {
        onClose(operation);
        setError(undefined);
    };
    const OperationSpecificationMap: Record<string, CreateOperationSpec> = {
        LETTER_CASING: {
            mapFormvaluesToMutationInput: async (data) => {
                const result = await createLetterCasing({
                    input: {
                        ...basicNameAndPipelineMapping(data),
                        letterCasing: data.letterCasing.toString(),
                    },
                });
                handleClose(result.data?.createLetterCasingDataPipelineOperation.dataPipelineOperation);
            },
            spec: {
                ...basicNameSpec,
                letterCasing: {
                    type: "string",
                    label: fm(messages.letterCasing),
                    allowedValues: ["UPPER", "LOWER"],
                },
            },
        },
        FILL_BLANKS: {
            mapFormvaluesToMutationInput: async (data) => {
                const result = await createFillBlanks({
                    input: {
                        ...basicNameAndPipelineMapping(data),
                        blankValue: data.blankValue?.toString(),
                        withColumn: Boolean(data.withColumn),
                    },
                });
                handleClose(result.data?.createFillBlanksDataPipelineOperation.dataPipelineOperation);
            },
            spec: {
                ...basicNameSpec,
                blankValue: {
                    type: "string",
                    label: fm(messages.blankValue),
                    hideFn: (data) => "withColumn" in data && Boolean(data.withColumn),
                    optional: true,
                },
                withColumn: {
                    type: "boolean",
                    label: fm(messages.withColumnToggle),
                    explanation: fm(messages.withColumnTooltip),
                },
            },
        },
        REPLACE: {
            mapFormvaluesToMutationInput: async (data) => {
                if (data.isRegex)
                    try {
                        RegExp(data.replaceString.toString());
                    } catch (e) {
                        if (e instanceof Error) setError(e.message);
                        return;
                    }
                const result = await createReplace({
                    input: {
                        ...basicNameAndPipelineMapping(data),
                        isRegex: Boolean(data.isRegex),
                        replaceString: data.replaceString.toString(),
                        withString: (data.withString ?? "").toString(),
                    },
                });
                handleClose(result.data?.createReplaceDataPipelineOperation.dataPipelineOperation);
            },
            spec: {
                ...basicNameSpec,
                replaceString: { type: "string", label: fm(messages.replace), helperComponent: <RegexHelper /> },
                isRegex: { type: "boolean", label: fm(messages.isRegex) },
                withString: { optional: true, type: "string", label: fm(messages.with) },
            },
        },
        CONVERT_COUNTRY: {
            mapFormvaluesToMutationInput: async (data) => {
                const result = await createConvertCountry({
                    input: {
                        ...basicNameAndPipelineMapping(data),
                    },
                });
                handleClose(result.data?.createConvertCountryDataPipelineOperation.dataPipelineOperation);
            },
            spec: basicNameSpec,
        },
        DATE_PARSER: {
            mapFormvaluesToMutationInput: async (data) => {
                const result = await createDateParser({
                    input: {
                        ...basicNameAndPipelineMapping(data),
                        dateFormat:
                            data.dateFormat.toString() === "Other"
                                ? data.customFormat?.toString()
                                : data.dateFormat.toString(),
                    },
                });
                handleClose(result.data?.createDateParserDataPipelineOperation.dataPipelineOperation);
            },
            spec: {
                ...basicNameSpec,
                dateFormat: {
                    type: "string",
                    label: fm(messages.dateFormat),
                    allowedValues: commonFormats.map((f) => f),
                },
                customFormat: {
                    optional: true,
                    type: "string",
                    label: fm(messages.otherFormat),
                    hideFn: (data) => !("dateFormat" in data) || data.dateFormat !== "Other",
                },
            },
        },
        LIST_PARSER: {
            mapFormvaluesToMutationInput: async (data) => {
                const result = await createListParser({
                    input: {
                        ...basicNameAndPipelineMapping(data),
                        splitString: data.splitString.toString(),
                    },
                });
                handleClose(result.data?.createListParserDataPipelineOperation.dataPipelineOperation);
            },
            spec: {
                ...basicNameSpec,
                splitString: { type: "string", label: fm(messages.splitString) },
            },
        },
        KEYWORD_TAGGER: {
            mapFormvaluesToMutationInput: async (data) => {
                const result = await createKeywordTagger({
                    input: {
                        ...basicNameAndPipelineMapping(data),
                    },
                });
                handleClose(result.data?.createKeywordTaggerDataPipelineOperation.dataPipelineOperation);
            },
            spec: basicNameSpec,
        },
        MATCH_COMPANY: {
            mapFormvaluesToMutationInput: async (data) => {
                const result = await createMatchCompany({
                    input: {
                        ...basicNameAndPipelineMapping(data),
                    },
                });
                handleClose(result.data?.createMatchCompanyDataPipelineOperation.dataPipelineOperation);
            },
            spec: basicNameSpec,
        },
        MONETARY_AMOUNT: {
            mapFormvaluesToMutationInput: async (data) => {
                const result = await createMonetaryAmount({
                    input: {
                        ...basicNameAndPipelineMapping(data),
                    },
                });
                handleClose(result.data?.createMonetaryAmountDataPipelineOperation.dataPipelineOperation);
            },
            spec: basicNameSpec,
        },
        REGEX: {
            mapFormvaluesToMutationInput: async (data) => {
                try {
                    RegExp(data.regex.toString());
                } catch (e) {
                    if (e instanceof Error) setError(e.message);
                    return;
                }
                const result = await createRegex({
                    input: {
                        ...basicNameAndPipelineMapping(data),
                        regex: data.regex.toString(),
                    },
                });
                handleClose(result.data?.createRegexDataPipelineOperation.dataPipelineOperation);
            },
            spec: {
                ...basicNameSpec,
                regex: { type: "string", label: fm(messages.expression), helperComponent: <RegexHelper /> },
            },
        },
        SPLIT: {
            mapFormvaluesToMutationInput: async (data) => {
                const result = await createSplitOperation({
                    input: {
                        ...basicNameAndPipelineMapping(data),
                        splitString: data.splitString.toString(),
                    },
                });
                handleClose(result.data?.createSplitDataPipelineOperation.dataPipelineOperation);
            },
            spec: {
                ...basicNameSpec,
                splitString: { type: "string", label: fm(messages.splitString) },
            },
        },
        TEMPLATE_STRING: {
            mapFormvaluesToMutationInput: async (data) => {
                const result = await createTemplate({
                    input: {
                        ...basicNameAndPipelineMapping(data),
                        templateString: data.templateString.toString(),
                    },
                });
                handleClose(result.data?.createTemplateStringDataPipelineOperation.dataPipelineOperation);
            },
            spec: {
                ...basicNameSpec,
                templateString: {
                    type: "string",
                    label: fm(messages.templateString),
                    // eslint-disable-next-line no-template-curly-in-string
                    explanation: fm(messages.templateStringExplanation, { template: "`${input_1}`" }),
                },
            },
        },
        TRANSLATION: {
            mapFormvaluesToMutationInput: async (data) => {
                const result = await createTranslationOperation({
                    input: {
                        ...basicNameAndPipelineMapping(data),
                    },
                });
                handleClose(result.data?.createTranslationDataPipelineOperation.dataPipelineOperation);
            },
            spec: basicNameSpec,
        },
        MAPPING: {
            mapFormvaluesToMutationInput: async (data) => {
                const result = await createMappingOperation({
                    input: {
                        ...basicNameAndPipelineMapping(data),
                    },
                });
                handleClose(result.data?.createMappingDataPipelineOperation.dataPipelineOperation);
            },
            spec: basicNameSpec,
        },
    };
    if (operationType in SpecialCases) {
        const Component = SpecialCases[operationType];
        return (
            <Dialog open onClose={() => onClose(undefined)} fullWidth maxWidth="md">
                <DialogTitle>{OPERATION_NODE_IMPLEMENTATION[operationType].name}</DialogTitle>
                <DialogContent>
                    <Component
                        dataPipeline={dataPipeline}
                        onFormSubmit={onClose}
                        connectInputOutput={(input) =>
                            connectInputOutput({ input }).then(
                                (res) => res.data?.createInputOutputConnection.inputOutputConnection
                            )
                        }
                    />
                </DialogContent>
            </Dialog>
        );
    }

    const { spec, mapFormvaluesToMutationInput } = OperationSpecificationMap[operationType];

    return (
        <Dialog open onClose={() => onClose(undefined)}>
            <DialogTitle>{OPERATION_NODE_IMPLEMENTATION[operationType].name}</DialogTitle>
            <DialogContent>
                <Stack gap={1} padding={1}>
                    <ObjectForm
                        objectSpec={spec}
                        mapFormvaluesToMutationInput={async (data) => {
                            await mapFormvaluesToMutationInput(data);
                        }}
                        operationType={operationType}
                    />
                </Stack>
                {error && <Alert severity="error">{error}</Alert>}
            </DialogContent>
        </Dialog>
    );
};
