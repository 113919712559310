import { defineMessages } from "react-intl";

const messages = defineMessages({
    list: {
        id: "list",
        defaultMessage: "list",
    },
});

export default messages;
