import { GraphqlRequestContainer } from "@ignite-analytics/graphql-utilities";
import { formatDatetime } from "@ignite-analytics/locale2";
import { track } from "@ignite-analytics/track";
import { Cancel, Download } from "@mui/icons-material";
import {
    CircularProgress,
    Collapse,
    IconButton,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from "@mui/material";
import React, { useState } from "react";
import { fm } from "@/contexts/IntlContext";
import { useGetExportDownloadUrlsLazyQuery, useGetExportsByTableIdQuery } from "@/generated/client";
import messages from "./messages";
import { UrlList } from "./urlList";

export const ExportHistoryPage: React.FC<{ dataTableId: string }> = ({ dataTableId }) => {
    const { result: allExports } = useGetExportsByTableIdQuery(
        {
            input: {
                dataTableId,
            },
        },
        {
            fetchPolicy: "network-only",
        }
    );
    const [urls, setUrls] = useState<{ id: string; urls: string[] }>();

    const [getUrl, { result }] = useGetExportDownloadUrlsLazyQuery();
    const handleClick = async (id: string) => {
        const exportUrls = await getUrl({
            input: {
                exportId: id,
            },
        });
        track("Data Table view: Download table");
        if (exportUrls.data?.getExportDownloadUrls.downloadUrls.length === 1) {
            const link = document.createElement("a");
            link.href = exportUrls.data?.getExportDownloadUrls.downloadUrls[0];
            link.click();
        } else {
            setUrls({ id, urls: exportUrls.data?.getExportDownloadUrls.downloadUrls ?? [] });
        }
    };

    return (
        <TableContainer>
            <Table stickyHeader>
                <TableHead>
                    <TableRow>
                        <TableCell>{fm(messages.history)}</TableCell>
                        <TableCell />
                    </TableRow>
                </TableHead>
                <TableBody>
                    <GraphqlRequestContainer asyncData={allExports}>
                        {(response) => (
                                <>
                                    {response.exports.map((exp) => (
                                        <TableRow key={exp.id}>
                                            <TableCell>
                                                <Stack direction="row" gap={1} alignItems="center">
                                                    <Typography noWrap>{formatDatetime(exp.exportDate)}</Typography>
                                                    {result.type === "loading" && urls?.id === exp.id && (
                                                        <CircularProgress />
                                                    )}
                                                    {urls && urls.id === exp.id && (
                                                        <IconButton onClick={() => setUrls(undefined)}>
                                                            <Cancel />
                                                        </IconButton>
                                                    )}
                                                </Stack>
                                                <Collapse unmountOnExit in={urls && urls.id === exp.id}>
                                                    <UrlList urls={urls?.urls ?? []} />
                                                </Collapse>
                                            </TableCell>
                                            <TableCell>
                                                <IconButton
                                                    onClick={
                                                        urls && urls.id === exp.id
                                                            ? () => setUrls(undefined)
                                                            : () => handleClick(exp.id)
                                                    }
                                                >
                                                    {urls?.id !== exp.id && <Download color="primary" />}
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </>
                            )}
                    </GraphqlRequestContainer>
                </TableBody>
            </Table>
        </TableContainer>
    );
};
