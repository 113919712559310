import { Info } from "@mui/icons-material";
import { Autocomplete, Stack, TextField, Tooltip, Typography } from "@mui/material";
import React from "react";
import { fm } from "@/contexts/IntlContext";
import { DataRepository, DataRepositoryField } from "@/generated/client";
import { Criterion } from "..";
import messages from "./messages";

interface Props {
    dataRepository: DataRepository;
    criteria: Criterion[];
    onChange: (criteria: Criterion[]) => void;
}
const AdditionalFields: React.FC<Props> = ({ dataRepository, criteria, onChange }) => {
    const isSelectable = (field: DataRepositoryField) => (
            !criteria.some((criterion) => criterion.dataRepositoryField.id === field.id) &&
            !dataRepository.uniqueIdentifierConfiguration?.uniqueIdentifierFields.some(
                (fieldId) => field.id === fieldId
            )
        );
    return (
        <>
            <Stack direction="row" gap={1} alignItems="center">
                <Typography variant="subtitle2"> {fm(messages.additionalFieldsHeader)}</Typography>
                <Tooltip followCursor title={fm(messages.additionalFieldsTooltip)}>
                    <Info />
                </Tooltip>
            </Stack>
            <Autocomplete
                multiple
                fullWidth
                value={criteria.map((c) => c.dataRepositoryField)}
                options={dataRepository.fields.filter((field) => isSelectable(field))}
                onChange={(_, v) => {
                    v && onChange(v.map((field) => ({ dataRepositoryField: field, criterionType: "ON_CREATE" })));
                }}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => (
                    <TextField {...params} label={fm(messages.addGetOrCreateFieldSelectLabel).toString()} />
                )}
            />
        </>
    );
};

export default AdditionalFields;
