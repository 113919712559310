import { defineMessages } from "react-intl";

const messages = defineMessages({
    header: {
        id: "containers.CubeBuilderPage.header",
        defaultMessage: "Build data cubes",
    },
    nameInput: {
        id: "containers.CubeBuilderPage.nameInput",
        defaultMessage: "Name your data cube",
    },
    selectBase: {
        id: "containers.CubeBuilderPage.selectBase",
        defaultMessage: "Select base table",
    },
    createCube: {
        id: "containers.CubeBuilderPage.createCube",
        defaultMessage: "Create data cube",
    },
    addConnection: {
        id: "containers.CubeBuilderPage.addConnection",
        defaultMessage: "Add connection",
    },
    applyConnections: {
        id: "containers.CubeBuilderPage.applyConnections",
        defaultMessage: "Apply changes",
    },
    applyTooltip: {
        id: "containers.CubeBuilderPage.applyTooltip",
        defaultMessage: "Update the data in your data table to reflect the changes you have made to your connections",
    },
    applyPendingChanges: {
        id: "containers.CubeBuilderPage.applyPendingChanges",
        defaultMessage: "Apply pending changes",
    },
    applyPendingChangesTooltip: {
        id: "containers.CubeBuilderPage.applyPendingChangesTooltip",
        defaultMessage: "Update the data in your data table to reflect the pending changes made to your connections",
    },
    applyStartedMessage: {
        id: "containers.CubeBuilderPage.applyStartedMessage",
        defaultMessage:
            "Applying your changes. You can follow the progress in the notifications in your upper right corner.",
    },
});

export default messages;
