import { MenuItem } from "@mui/material";
import React from "react";
import { fm } from "@/contexts/IntlContext";
import { ContentType } from "@/generated/client";
import messages from "./messages";
import { ImportConfigurationValues } from "./types";

export const getContentTypeFromFile = (fileName?: string): ContentType => {
    if (fileName?.toLowerCase().endsWith(".csv")) {
        return "CSV";
    }
    if (fileName?.toLowerCase().endsWith(".xlsx")) {
        return "XLSX";
    }
    return "CSV";
};

export const toOptions = (options: { value: string | undefined; text: { id: string; defaultMessage: string } }[]) => options.map((option) => (
        <MenuItem value={option.value} key={option.value}>
            {fm(option.text).toString()}
        </MenuItem>
    ));

export function createMutationInput(values: ImportConfigurationValues) {
    switch (values.contentType) {
        case "CSV":
            return {
                name: values.name,
                contentType: values.contentType,
                csvConfiguration: {
                    delimiter: values.delimiter,
                    encoding: values.encoding,
                    escapeCharacter: values.escapeCharacter,
                    quoteCharacter: values.quoteCharacter,
                },
            };
        case "XLSX":
            return {
                name: values.name,
                contentType: values.contentType,
                xlsxConfiguration: {
                    headerRowIndex: values.headerRowIndex,
                    dataStartIndex: values.dataStartIndex,
                    numberOfEndLinesToDrop: values.numberOfEndLinesToDrop,
                },
            };
        case "JSON":
        case "NJSON":
            return {
                name: values.name,
                contentType: values.contentType,
            };
    }
}

export function validateXlsxConfig(config: {
    dataStartIndex: number;
    headerRowIndex: number;
    numberOfEndLinesToDrop: number;
}): string | null {
    if (Object.values(config).some((no) => !no && no !== 0)) return fm(messages.requiredFieldsValidation).toString();
    if (config.dataStartIndex <= 0 || config.headerRowIndex <= 0)
        return fm(messages.positiveNumbersOnlyValidation).toString();
    if (config.headerRowIndex >= config.dataStartIndex) return fm(messages.headerBelowDataStartValidation).toString();
    return null;
}
