import CloseOutlined from "@mui/icons-material/CloseOutlined";
import { Search } from "@ignite-analytics/icons";
import IconButton from "@mui/material/IconButton";
import React, { useEffect, useState } from "react";
import { useDebounce } from "@ignite-analytics/general-tools";
import { Stack, TextField } from "@mui/material";

interface Props {
    term: string;
    onChangeSearchTerm: (term: string) => void;
}

export const ExpandingSearchField = ({ onChangeSearchTerm, term }: Props) => {
    // using internal state to handle the value in the input field
    const [inputValue, setInputValue] = useState(term);
    const [open, setOpen] = useState(false);
    useEffect(() => {
        if (term === "") {
            setInputValue(term);
        }
    }, [term]);

    const handleClose = () => {
        setOpen(false);
        setInputValue("");
        onChangeSearchTerm("");
    };
    const onChange = useDebounce(onChangeSearchTerm, 400);
    return open ? (
        <Stack direction="row" alignItems="center">
            <TextField
                // eslint-disable-next-line jsx-a11y/no-autofocus
                value={inputValue}
                autoFocus
                size="small"
                onChange={(e) => {
                    setInputValue(e.target.value);
                    onChange(e.target.value);
                }}
                sx={{ width: "300px" }}
                InputProps={{
                    startAdornment: <Search fontSize="small" />,
                }}
            />
            <IconButton data-testid="data-tables-search-field-close-button" onClick={() => handleClose()} size="small">
                <CloseOutlined fontSize="inherit" />
            </IconButton>
        </Stack>
    ) : (
        <IconButton data-testid="data-tables-search-field-open-button" onClick={() => setOpen(true)}>
            <Search fontSize="small" />
        </IconButton>
    );
};
