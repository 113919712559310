import { GraphqlRequestContainer } from "@ignite-analytics/graphql-utilities";
import { track } from "@ignite-analytics/track";
import { CopyAll, Psychology } from "@mui/icons-material";
import {
    Button,
    CardContent,
    CircularProgress,
    IconButton,
    Popover,
    Stack,
    TextField,
    Tooltip,
    Typography,
} from "@mui/material";
import React, { useState } from "react";
import { fm } from "@/contexts/IntlContext";
import { useGetRegexSuggestionLazyQuery } from "@/generated/client";
import messages from "./messages";

export const RegexHelper = () => {
    const [getRegex, mutation] = useGetRegexSuggestionLazyQuery();
    const [description, setDescription] = useState<string>();

    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const hanleGetRegex = async () => {
        track("Get Regex suggestion", {});
        description &&
            (await getRegex({
                input: {
                    descriptionOfRegex: description,
                },
            }));
    };

    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;
    return (
        <>
            <Tooltip title={fm(messages.tooltip)}>
                <IconButton onClick={handleClick}>
                    <Psychology />
                </IconButton>
            </Tooltip>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "center",
                    horizontal: "left",
                }}
            >
                <CardContent sx={{ width: "400px" }}>
                    <Stack>
                        <TextField
                            value={description}
                            placeholder={fm(messages.placeholder).toString()}
                            onChange={(e) => setDescription(e.target.value)}
                            variant="outlined"
                            margin="normal"
                            fullWidth
                        />
                        <Button size="small" onClick={hanleGetRegex}>
                            {fm(messages.getSuggestion)}
                        </Button>

                        <GraphqlRequestContainer loading={<CircularProgress />} asyncData={mutation.result}>
                            {(r) => (
                                <Stack direction="row" alignItems="center" gap={1}>
                                        <Typography>{r.response}</Typography>
                                        <IconButton onClick={() => navigator.clipboard.writeText(r.response)}>
                                            <CopyAll />
                                        </IconButton>
                                    </Stack>
                            )}
                        </GraphqlRequestContainer>
                    </Stack>
                </CardContent>
            </Popover>
        </>
    );
};
