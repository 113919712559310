import { Cloud } from "@mui/icons-material";
import { CardContent, Chip, Popover, Stack, Typography } from "@mui/material";
import React from "react";
import { fm } from "@/contexts/IntlContext";
import { useCurrencyCode } from "@/contexts/useCurrency";
import type { EmissionsCell } from "../../interfaces";
import messages from "./messages";

interface Props {
    cell: EmissionsCell;
}

/**
 * @param emissionFactorDescription Emisison factor description on format "Product > Supporting and auxiliary transport services; travel agency services - Norway (2020)"
 */
function extractExiobaseInformation(emissionFactorDescription: string): {
    category: string | undefined;
    region: string | undefined;
    year: string | undefined;
} {
    // Define the regex pattern to match the desired groups
    // Expected format: Category L1 > Category L2 - Region (Year)
    const regex = /^(.*?)\s*-\s*(.*?)\s*\((\d{4})\)$/;

    const match = emissionFactorDescription.match(regex);

    const category = match?.[1]?.trim();
    const region = match?.[2]?.trim();
    const year = match?.[3];

    return {
        category,
        region,
        year,
    };
}

const EmissionsCellPopUp: React.FC<Props & { currency: string | undefined }> = ({ cell, currency }) => {
    const { category, region, year } = extractExiobaseInformation(cell.emissionFactorDescription);
    return (
        <CardContent>
            <Stack direction="row" justifyContent="space-between" gap={2}>
                <Typography color="GrayText">{fm(messages.emissionsHeader)}</Typography>
                <Typography>{fm(messages.emissions, { emissions: cell.emissions.toFixed(2) })}</Typography>
            </Stack>
            <Stack direction="row" justifyContent="space-between" gap={2}>
                <Typography color="GrayText">{fm(messages.categoryHeader)}</Typography>
                <Typography>{category}</Typography>
            </Stack>
            <Stack direction="row" justifyContent="space-between" gap={2}>
                <Typography color="GrayText">{fm(messages.regionHeader)}</Typography>
                <Typography>{region}</Typography>
            </Stack>
            <Stack direction="row" justifyContent="space-between" gap={2}>
                <Typography color="GrayText">{fm(messages.yearHeader)}</Typography>
                <Typography>{year}</Typography>
            </Stack>
            {currency && (
                <Stack direction="row" justifyContent="space-between" gap={2}>
                    <Typography color="GrayText">{fm(messages.emissionFactorHeader)}</Typography>
                    <Typography>
                        {fm(messages.emissionFactor, {
                            emissionFactor: cell.emissionFactor.toFixed(4),
                            currency,
                        })}
                    </Typography>
                </Stack>
            )}
        </CardContent>
    );
};

export const EmissionsTableCell: React.FC<Props> = ({ cell }) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const currency = useCurrencyCode();

    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    return (
        <>
            <Chip
                icon={<Cloud />}
                label={fm(messages.emissions, { emissions: cell.emissions.toFixed(2) })}
                onClick={handleClick}
            />
            {open && (
                <Popover
                    anchorOrigin={{
                        vertical: "center",
                        horizontal: "center",
                    }}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                >
                    <EmissionsCellPopUp cell={cell} currency={currency} />
                </Popover>
            )}
        </>
    );
};
