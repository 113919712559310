import { DataPipelineOperation, InputOutputConnection } from "@/generated/client";

export function isFromDataRepositoryField(inputOutputConnection: InputOutputConnection) {
    return inputOutputConnection.from.type === "DATA_REPOSITORY_FIELD";
}
export function isFromOperationOutput(inputOutputConnection: InputOutputConnection) {
    return inputOutputConnection.from.type === "OPERATION";
}

export function getInitialOperations(
    operations: DataPipelineOperation[],
    inputOutputConnections: InputOutputConnection[]
): DataPipelineOperation[] {
    const dataRepositoryConnections = inputOutputConnections.filter((ioc) => ioc.from.type === "DATA_REPOSITORY_FIELD");
    return operations.filter((operation) => operation.input.every(
            (input) =>
                dataRepositoryConnections.some(
                    (dataRepositoryConnection) =>
                        dataRepositoryConnection.to.type === "OPERATION" &&
                        dataRepositoryConnection.to.referencedId === input.id
                ) ||
                !inputOutputConnections.some(
                    (connection) => connection.to.type === "OPERATION" && connection.to.referencedId === input.id
                ) ||
                !inputOutputConnections.find((connection) => connection.to.referencedId === input.id)
        ));
}

export function getInputConnectionsOfOperation(
    operation: DataPipelineOperation,
    inputOutputConnections: InputOutputConnection[]
) {
    return inputOutputConnections.filter(
        (inputOutputConnection) =>
            inputOutputConnection.to.type === "OPERATION" &&
            operation.input.some((input) => input.id === inputOutputConnection.to.referencedId)
    );
}

export function getOperationDependencies(
    operation: DataPipelineOperation,
    operations: DataPipelineOperation[],
    inputOutputConnections: InputOutputConnection[]
) {
    const inputConnectionsOfOperation = getInputConnectionsOfOperation(operation, inputOutputConnections);
    const sourceOutputIds = inputConnectionsOfOperation
        .filter(isFromOperationOutput)
        .map((inputOutputConnection) => inputOutputConnection.from.referencedId);

    return operations.filter((o) =>
        o.output.some((aOutput) => sourceOutputIds.some((bOutput) => aOutput.id === bOutput))
    );
}

export const orderOperations: (
    operations: DataPipelineOperation[],
    connections: InputOutputConnection[]
) => DataPipelineOperation[] = (operations, connections) => {
    const operationConnections = connections.filter(
        (connection) => connection.from.type === "OPERATION" || connection.to.type === "OPERATION"
    );
    const order: DataPipelineOperation[] = getInitialOperations(operations, operationConnections);

    let remaining = operations.filter((op) => !order.find((op2) => op.id === op2.id));
    const dependencies: { [key: string]: DataPipelineOperation[] } = remaining.reduce(
        (acc, curr) => ({ ...acc, [curr.id]: getOperationDependencies(curr, operations, operationConnections) }),
        {}
    );
    for (let i = 0; i <= remaining.length; i += 1) {
        const ready = remaining.filter((operation) =>
            dependencies[operation.id].every((op) => order.find((op2) => op.id === op2.id))
        );
        remaining = remaining.filter((op) => !ready.find((op2) => op2.id === op.id));
        order.push(...ready);
        if (operations.length === order.length) {
            return order;
        }
    }
    return operations;
};
