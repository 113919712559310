import { defineMessages } from "react-intl";

const messages = defineMessages({
    deleteAggregatedViewTooltip: {
        id: "aggregatedView.deleteAggregatedView",
        defaultMessage: "Delete aggregated view",
    },
    deleteConfirmTitle: {
        id: "aggregatedView.deleteConfirmTitle",
        defaultMessage: "Delete aggregated view",
    },
    areYouSureText: {
        id: "aggregatedView.areYouSureText",
        defaultMessage: "Are you sure you want to delete this aggregated view?",
    },
});

export default messages;
