import { Skeleton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import React from "react";

export const LoadingTable = () => {
    const columns = [1, 2, 3, 4, 5];
    const rows = [1, 2, 3, 4, 5];
    return (
        <TableContainer>
            <Table>
                <TableHead>
                    <TableRow>
                        {columns.map((c) => (
                            <TableCell key={c}>
                                <Skeleton />
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.map((r) => (
                        <TableRow key={r}>
                            {columns.map((c) => (
                                <TableCell key={c}>
                                    <Skeleton />
                                </TableCell>
                            ))}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};
