import { GraphqlRequestContainer } from "@ignite-analytics/graphql-utilities";
import { track } from "@ignite-analytics/track";
import { CircularProgress, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { UrlList } from "@/components/ExportDownloadPage/urlList";
import { fm } from "@/contexts/IntlContext";
import { useGetExportDownloadUrlsLazyQuery } from "@/generated/client";
import messages from "./messages";

export const DirectExportDownload: React.FC = () => {
    const { exportId } = useParams<{ exportId: string }>();
    const [getUrl, result] = useGetExportDownloadUrlsLazyQuery();
    const [urls, setUrls] = useState<string[]>();

    useEffect(() => {
        getUrl({
            input: {
                exportId,
            },
        }).then((res) => {
            if (res.data?.getExportDownloadUrls.downloadUrls.length === 1) {
                const link = document.createElement("a");
                link.href = res.data?.getExportDownloadUrls.downloadUrls[0];
                track("Data Table view: Download table", {});
                link.click();
            } else {
                setUrls(res.data?.getExportDownloadUrls.downloadUrls);
            }
        });
    }, [exportId, getUrl]);

    return (
        <GraphqlRequestContainer asyncData={result.result} loading={<CircularProgress />}>
            {() => (
                <>
                    {!urls && (
                        <Stack>
                            <Typography variant="subtitle1">{fm(messages.downloadStarted)}</Typography>
                        </Stack>
                    )}
                    {urls && <UrlList urls={urls} />}
                </>
            )}
        </GraphqlRequestContainer>
    );
};
