import React from "react";
import { Stack, Divider } from "@mui/material";

import CreateAggregatedViewButton from "@/components/AggregatedView";
import { ExpandingSearchField } from "../../ExpandingSearchField";
import { ExportOptions } from "../../ExportOptions";
import { FilterButton } from "../../FilterButton";
import { DataTable } from "@/generated/client";

type Props = {
    baseDataTable: DataTable;
    selectedDataTable: DataTable;
    setSelectedDataTableViewId: (id: string) => void;
    selectedDataTableViewId: string;
    searchTerm: string;
    handleSearch: (term: string) => void;
    total: number;
};
export const DataTableListMenu: React.FC<Props> = ({
    baseDataTable,
    selectedDataTable,
    setSelectedDataTableViewId,
    selectedDataTableViewId,
    searchTerm,
    handleSearch,
    total,
}) => (
    <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Stack direction="row" spacing={1} alignItems="flex-end" divider={<Divider orientation="vertical" flexItem />}>
            <CreateAggregatedViewButton
                dataTableId={baseDataTable.id}
                dataTableName={baseDataTable.name}
                dataTable={baseDataTable}
                selectedDataTableView={selectedDataTable}
                onDataTableViewChange={setSelectedDataTableViewId}
            />
            <ExpandingSearchField onChangeSearchTerm={handleSearch} term={searchTerm} />
            <FilterButton name={selectedDataTable.name} />
        </Stack>
        <Stack direction="row" spacing={2} alignItems="center" divider={<Divider orientation="vertical" flexItem />}>
            <ExportOptions selectedDataTableViewId={selectedDataTableViewId} searchTerm={searchTerm} total={total} />
        </Stack>
    </Stack>
);
