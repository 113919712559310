import { defineMessages } from "react-intl";

const messages = defineMessages({
    mappingText: {
        id: "dataTables.pipelinePageV2.modals.inputOutputMapping.mappingText",
        defaultMessage:
            "Select what data repository columns and operation outputs should go into the columns of your data table.",
    },
    mapping: {
        id: "dataTables.pipelinePageV2.modals.inputOutputMapping.mapping",
        defaultMessage: "Mapping",
    },
    search: {
        id: "dataTables.pipelinePageV2.modals.inputOutputMapping.searchColumns",
        defaultMessage: "Search columns",
    },
    sourceHeader: {
        id: "dataTables.pipelinePageV2.modals.inputOutputMapping.sourceHeader",
        defaultMessage: "Repository: {repository}",
    },
    unused: {
        id: "dataTables.pipelinePageV2.modals.inputOutputMapping.unused",
        defaultMessage: "Unused source field",
    },
    createAndConnect: {
        id: "dataTables.pipelinePageV2.modals.inputOutputMapping.createAndConnect",
        defaultMessage: "Create and connect",
    },
    showUnused: {
        id: "dataTables.pipelinePageV2.modals.inputOutputMapping.showUnused",
        defaultMessage: "Show unused source data",
    },
    warning: {
        id: "dataTables.pipelinePageV2.modals.inputOutputMapping.warning",
        defaultMessage:
            "Column with this name already exists! Are you sure you want to create new column? If not please go back to the column mapping and inspect the input of the column.",
    },
});

export default messages;
