import { defineMessages } from "react-intl";

const messages = defineMessages({
    general: {
        id: "dataTables.adminPage.general",
        defaultMessage: "General",
    },
    adminPanel: {
        id: "dataTables.adminPage.adminPanel",
        defaultMessage: "Admin panel",
    },
    dataTableName: {
        id: "dataTables.adminPage.dataTableName",
        defaultMessage: "Table name",
    },
    editButton: {
        id: "dataTables.adminPage.editButton",
        defaultMessage: "Edit",
    },
    saveChanges: {
        id: "dataTables.adminPage.saveChanges",
        defaultMessage: "Save changes",
    },
    resyncHeader: {
        id: "dataTables.adminPage.resyncHeader",
        defaultMessage: "Update data table",
    },
    resyncDescription: {
        id: "dataTables.adminPage.resyncDescription",
        defaultMessage: "Will update your table with the latest data from related tables.",
    },
    optimizeHeader: {
        id: "dataTables.adminPage.optimizeHeader",
        defaultMessage: "Optimize data table",
    },
    optimizeDescription: {
        id: "dataTables.adminPage.optimizeDescription",
        defaultMessage:
            "This table has {indexFieldCount} indexed and {cubeConfigurationCount} checked off in the cube builder. Ignite can remove {difference} fields and simultaneously optimize storage to increase overall performance. Analysis will remain available, but processing will not.",
    },
    optimizeButton: {
        id: "dataTables.adminPage.optimizeButton",
        defaultMessage: "Optimize table",
    },
    optimizeTableModalTitle: {
        id: "dataTables.adminPage.optimizeTableModalTitle",
        defaultMessage: "Optimizing...",
    },
    runAllPipelinesHeader: {
        id: "dataTables.adminPage.runAllPipelinesHeader",
        defaultMessage: "Run all data pipelines",
    },
    runAllPipelinesDescription: {
        id: "dataTables.adminPage.runAllPipelinesDescription",
        defaultMessage: "Run all pipelines in this data flow.",
    },
    runAllPipelinesButton: {
        id: "dataTables.adminPage.runAllPipelinesButton",
        defaultMessage: "Run data flows",
    },
    dangerZone: {
        id: "dataTables.adminPage.dangerZone",
        defaultMessage: "Danger zone",
    },
    deleteDataTableHeader: {
        id: "dataTables.adminPage.deleteDataTableHeader",
        defaultMessage: "Delete data table",
    },
    deleteDataTableDescription: {
        id: "dataTables.adminPage.deleteDataTableDescription",
        defaultMessage: "Deleting the data table is an irreversible action.",
    },
    deleteDataTableButton: {
        id: "dataTables.adminPage.deleteDataTableButton",
        defaultMessage: "Delete this table",
    },
    deleteDataPipelineHeader: {
        id: "dataTables.adminPage.deleteDataPipelineHeader",
        defaultMessage: "Delete data flow",
    },
    deleteDataPipelineDescription: {
        id: "dataTables.adminPage.deleteDataPipelineDescription",
        defaultMessage:
            "Deleting a flow is an irreversible action, and will delete all data rows associated with the data flow from all data tables. This action does not affect any data repository.",
    },
    noPipelines: {
        id: "dataTables.adminPage.noPipelines",
        defaultMessage: "No data flows exist",
    },
    deleteDataPipelineButton: {
        id: "dataTables.adminPage.deleteDataPipelineButton",
        defaultMessage: "Delete selected flow",
    },
    removeGhostRowsHeader: {
        id: "dataTables.adminPage.removeGhostRowsHeader",
        defaultMessage: "Remove ghost rows",
    },
    removeGhostRowsDescription: {
        id: "dataTables.adminPage.removeGhostRowsDescription",
        defaultMessage:
            "This will remove rows present in the table without a corresponding row in the data repository.",
    },
    removeGhostRowsButton: {
        id: "dataTables.adminPage.removeGhostRowsButton",
        defaultMessage: "Delete ghost rows",
    },
    deletePromptDescription: {
        id: "dataTables.adminPage.deletePromptDescription",
        defaultMessage: "Are you sure you want to delete {name}?",
    },
    unauthorizedAdminPanelTooltip: {
        id: "dataTables.adminPage.unauthorizedAdminPanelTooltip",
        defaultMessage: "Unauthorized",
    },
});

export default messages;
