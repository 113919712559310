import { defineMessages } from "react-intl";

const messages = defineMessages({
    dataRepositorySelectLabel: {
        id: "uploadPage.createOperationButton.lookupOperationForm.dataRepositorySelectLabel",
        defaultMessage: "Select target data source",
    },
    lookupCriteriaHeader: {
        id: "uploadPage.createOperationButton.lookupOperationForm.lookupCriteriaHeader",
        defaultMessage: "Lookup criteria",
    },
    lookupOutputFieldHeader: {
        id: "uploadPage.createOperationButton.lookupOperationForm.lookupOutputFieldHeader",
        defaultMessage: "Fields to include",
    },
    addLookupFieldSelectLabel: {
        id: "uploadPage.createOperationButton.lookupOperationForm.addLookupFieldSelectLabel",
        defaultMessage: "Select data repository field",
    },
    nameOperation: {
        id: "uploadPage.createOperationButton.lookupOperationForm.nameOperation",
        defaultMessage: "Name operation",
    },
    selectAll: {
        id: "uploadPage.createOperationButton.lookupOperationForm.selectAllFields",
        defaultMessage: "Select all fields",
    },
});

export default messages;
