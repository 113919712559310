import { ChevronDown } from "@ignite-analytics/icons";
import { IconButton, Menu, MenuItem, Stack } from "@mui/material";
import React from "react";
import { TYPE_ICONS } from "@/components/DataTypeIcons";
import { PrimitiveDataColumnTypeInput } from "@/generated/client";

const allowedTypes = ["BOOLEAN", "DATE", "NUMBER", "TEXT"] as const;
export const DataTypeSelection: React.FC<{
    setType?: (type: PrimitiveDataColumnTypeInput) => void;
    type: PrimitiveDataColumnTypeInput;
}> = ({ setType, type }) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleSelect = (selectedType: PrimitiveDataColumnTypeInput) => {
        setType?.(selectedType);
        handleClose();
    };

    return (
        <div>
            <IconButton
                aria-controls={open ? "demo-positioned-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
            >
                <Stack direction="row" alignItems="center">
                    {TYPE_ICONS[type]}
                    <ChevronDown fontSize="small" />
                </Stack>
            </IconButton>

            <Menu
                id="demo-positioned-menu"
                aria-labelledby="demo-positioned-button"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "left",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                }}
            >
                {allowedTypes.map((allowedType) => (
                    <MenuItem key={allowedType} onClick={() => handleSelect(allowedType)}>
                        <Stack direction="row" gap={1} alignItems="center">
                            {TYPE_ICONS[allowedType]}
                            {allowedType}
                        </Stack>
                    </MenuItem>
                ))}
            </Menu>
        </div>
    );
};
