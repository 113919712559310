import { LoadingButton } from "@mui/lab";
import { Stack, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { testIdPrefix } from "@/components/testIdprefix";
import { fm } from "@/contexts/IntlContext";
import { useUpdateDataTableAction } from "@/generated/DataTableEntity";
import { DataTable } from "@/generated/client";
import messages from "@/messages";

interface Props {
    dataTable: DataTable;
    onUpdate?: () => void;
}

export const RenameTable: React.FC<Props> = ({ dataTable, onUpdate }) => {
    const [updatedName, setUpdatedName] = useState<string>(dataTable.name);
    const [editNameState, setEditNameState] = useState(false);
    const [updateDataTable, mutation] = useUpdateDataTableAction();

    useEffect(() => {
        setUpdatedName(dataTable.name);
    }, [setUpdatedName, dataTable]);

    const handleNameChange = async () => {
        setEditNameState(false);
        if (!updatedName.length || updatedName === dataTable.name) return;
        await updateDataTable(dataTable.id, { name: updatedName });
        onUpdate?.();
    };

    return (
        <Stack direction="row" alignItems="center" spacing={2}>
            <TextField
                label="Name"
                name="name"
                value={updatedName}
                size="small"
                onChange={(e) => setUpdatedName(e.target.value)}
            />
            <LoadingButton
                variant="contained"
                color={editNameState ? "primary" : "secondary"}
                size="small"
                loading={mutation.isLoading}
                disabled={updatedName === dataTable.name}
                onClick={() => {
                    if (updatedName !== dataTable.name) handleNameChange();
                }}
                data-testid={`${testIdPrefix}-change-table-name-button`}
            >
                {fm(messages.save)}
            </LoadingButton>
        </Stack>
    );
};
