import { memoize } from "@ignite-analytics/general-tools";
import { getMessages as getNotifMessages } from "@ignite-analytics/notifications";
import localeDataEnUS from "./en-US.json";
import localeDataNbNO from "./nb-NO.json";

const getRawProjectMessages = (locale: string): Record<string, { defaultMessage: string }> => {
    switch (locale) {
        case "nb-NO":
            return localeDataNbNO;
        case "en-US":
            return localeDataEnUS;
        default:
            return localeDataEnUS;
    }
};

const getProjectMessages = (locale: string): Record<string, string> => {
    const rawMessages = getRawProjectMessages(locale);
    return Object.entries(rawMessages).reduce(
        (acc, [key, message]) => {
            acc[key] = message.defaultMessage;
            return acc;
        },
        {} as Record<string, string>
    );
};

export const getMessages = memoize((locale: string) => ({
    ...getProjectMessages(locale),
    ...getNotifMessages(locale),
}));
