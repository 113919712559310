import { Api } from "@ignite-analytics/api-client";
import { CONTACT_PERSON, EMISSIONS_GT, GlobalColumnType } from "@ignite-analytics/global-types";
import {
    DataPipelineOperation,
    DataPipelineOperationInputOutput,
    DataRepository,
    DataRepositoryField,
} from "@/generated/client";
import { DataColumn } from "@/Types/DataColumn";
import { InputSourceItem } from "@/Types/InputSourceItem";

export function createInputSourceItems(operations?: DataPipelineOperation[], dataRepositories?: DataRepository[]) {
    const fieldToInputSourceItem: (field: DataRepositoryField) => InputSourceItem = (field) => ({
        id: field.id,
        label: field.name,
        type: "DATA_REPOSITORY_FIELD",
    });
    const fieldSources: InputSourceItem[] = dataRepositories?.length
        ? dataRepositories.map((dataRepository) => dataRepository.fields.map(fieldToInputSourceItem)).flat()
        : [];

    const buildOutputToSource: (
        operation: DataPipelineOperation
    ) => (output: DataPipelineOperationInputOutput) => InputSourceItem = (operation) => (output) => ({
        id: output.id,
        label: `${operation.name}-${output.label ?? output.key ?? "output"}`,
        type: "OPERATION",
        sourceOperationType: operation.operationType,
    });
    const operationSources: InputSourceItem[] = operations
        ? operations
              .map((operation) => {
                  const outputToSource = buildOutputToSource(operation);
                  return operation.output.map(outputToSource);
              })
              .flat()
        : [];
    return operationSources.concat(fieldSources);
}

export function getGlobalColumnTypes(): Promise<GlobalColumnType[]> {
    const url = `/global-types/columns/`;
    return Api.get(url);
}

export function getRelevantGlobalColumnTypes(globalColumnTypes: GlobalColumnType[], dataColumn: DataColumn) {
    switch (dataColumn.dataType) {
        case "MONETARY_AMOUNT":
        case "NUMBER":
            return globalColumnTypes.filter((globalColumn) => globalColumn.dataType === "number");
        case "DATE":
            return globalColumnTypes.filter((globalColumn) => globalColumn.dataType === "date");
        case "TEXT":
        case "TAG":
            return globalColumnTypes.filter((globalColumn) => globalColumn.dataType === "text");
        case "TABLE_RELATION":
            // Expand the list of relational global column types when more are added
            return globalColumnTypes.filter((gc) => ["suppliers", "contracts", "relational"].includes(gc.dataType));
        case "GROUP_STRUCTURE":
            return globalColumnTypes.filter((globalColumn) => globalColumn.dataType === "grouping");
        case "USER":
            return globalColumnTypes.filter((globalColumn) => globalColumn.dataType === "user");
        case "CONTACT":
            return globalColumnTypes.filter((globalColumn) => [CONTACT_PERSON].includes(globalColumn.key));
        case "FILE":
            return globalColumnTypes.filter((globalColumn) => globalColumn.dataType === "file");
        case "COMPANY":
            return globalColumnTypes.filter((globalColumn) => globalColumn.dataType === "company");
        case "BOOLEAN":
            return globalColumnTypes.filter((globalColumn) => globalColumn.dataType === "boolean");
        case "EMISSIONS":
            return globalColumnTypes.filter((globalColumn) => globalColumn.key === EMISSIONS_GT);
        default:
            return [];
    }
}
