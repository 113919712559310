import { FilterAlt, Input, UploadFile } from "@mui/icons-material";
import React from "react";
import { DataColumn } from "@/Types/DataColumn";
import { hasValue } from "@/containers/CubeBuilderPage/components/ConnectionModal/helpers";
import { OPERATION_NODE_IMPLEMENTATION, PIPELINE_COLORS } from "@/containers/PipelinePageV2/Nodes/constants";
import { TinyIcon } from "@/components/TinyIcon";
import { fm } from "@/contexts/IntlContext";
import {
    DataPipeline,
    DataPipelineOperation,
    DataRepository,
    InputOutputNodeType,
    PendingChange,
} from "@/generated/client";
import messages from "./messages";

interface BaseChange {
    id: string;
    pipelineInfo: { pipelineId: string; sourceName: string; sourceId: string };
    isBeingProcessed: boolean;
    userId: string;
    createdAt: string;
    description: { text: string; icon: React.ReactNode };
}

type ImportChange = BaseChange & { type: "IMPORT_CHANGE"; importId: string };
type PipelineFilterChange = BaseChange & { type: "PIPELINE_FILTER_CHANGE" };
type OperationChange = BaseChange & { type: "OPERATION_CHANGE"; operationId: string };
type MappingChange = BaseChange & {
    type: "INPUT_OUTPUT_CONNECTION_CHANGE";
    from: { referencedId: string; type: InputOutputNodeType };
    to: { referencedId: string; type: InputOutputNodeType };
};

export type Change = ImportChange | PipelineFilterChange | OperationChange | MappingChange;

export function mapApiToFrontendChanges(
    changes: PendingChange[],
    dataSources: DataRepository[],
    dataPipelines: DataPipeline[],
    dataPipelineOperations: DataPipelineOperation[],
    dataColumns: DataColumn[],
    imports: { id: string; file: { name: string } }[]
): Change[] {
    function getPipelineInfo(pipelineId: string) {
        const pipeline = dataPipelines.find((p) => p.id === pipelineId);
        const source = dataSources.find((s) => s.id === pipeline?.sourceDataRepositoryIds[0]);
        return {
            pipelineId,
            sourceName: source?.name ?? "Not found",
            sourceId: source?.id ?? "Not found",
        };
    }

    function getMappingName(referencedId: string, type: InputOutputNodeType) {
        if (type === "DATA_TABLE_COLUMN") {
            const column = dataColumns.find((c) => c.id === referencedId);
            return column?.name;
        }
        if (type === "DATA_REPOSITORY_FIELD") {
            const field = dataSources.flatMap((s) => s.fields).find((f) => f.id === referencedId);
            return field?.name;
        }
        if (type === "OPERATION") {
            return dataPipelineOperations.find(
                (o) =>
                    o.output.some((output) => output.id === referencedId) ||
                    o.input.some((input) => input.id === referencedId)
            )?.name;
        }
    }

    function toFrontendChange(change: PendingChange): Change | null {
        const { from, to, importId, pipelineOperationId } = change;
        const operation = pipelineOperationId && dataPipelineOperations.find((o) => o.id === pipelineOperationId);
        switch (change.type) {
            case "IMPORT_CHANGE":
                if (!importId) return null;
                return {
                    id: change.id,
                    type: "IMPORT_CHANGE" as const,
                    pipelineInfo: getPipelineInfo(change.dataPipelineId),
                    isBeingProcessed: change.isBeingProcessed,
                    userId: change.userId ?? "",
                    createdAt: change.createdAt,
                    description: {
                        text: fm(messages.newImport, {
                            importName: imports.find((i) => i.id === change.importId)?.file.name,
                        }).toString(),
                        icon: <TinyIcon color={PIPELINE_COLORS.source} iconComponent={<UploadFile />} />,
                    },
                    importId: change.importId ?? "",
                };
            case "OPERATION_CHANGE":
                if (!operation) return null;
                return {
                    id: change.id,
                    type: "OPERATION_CHANGE" as const,
                    operationId: change.pipelineOperationId ?? "",
                    pipelineInfo: getPipelineInfo(change.dataPipelineId),
                    isBeingProcessed: change.isBeingProcessed,
                    userId: change.userId ?? "",
                    createdAt: change.createdAt,
                    description: {
                        text: fm(messages.operationChange, {
                            operationName: operation.name,
                        }).toString(),
                        icon: (
                            <TinyIcon
                                color={OPERATION_NODE_IMPLEMENTATION[operation.operationType].color}
                                iconComponent={OPERATION_NODE_IMPLEMENTATION[operation.operationType].iconComponent}
                            />
                        ),
                    },
                };
            case "PIPELINE_FILTER_CHANGE":
                return {
                    id: change.id,
                    type: "PIPELINE_FILTER_CHANGE" as const,
                    pipelineInfo: getPipelineInfo(change.dataPipelineId),
                    isBeingProcessed: change.isBeingProcessed,
                    userId: change.userId ?? "",
                    createdAt: change.createdAt,
                    description: {
                        text: fm(messages.filterChange).toString(),
                        icon: <TinyIcon color={PIPELINE_COLORS.table} iconComponent={<FilterAlt />} />,
                    },
                };
            case "INPUT_OUTPUT_CONNECTION_CHANGE":
                if (!(from && to)) return null;
                return {
                    id: change.id,
                    type: "INPUT_OUTPUT_CONNECTION_CHANGE" as const,
                    pipelineInfo: getPipelineInfo(change.dataPipelineId),
                    isBeingProcessed: change.isBeingProcessed,
                    userId: change.userId ?? "",
                    createdAt: change.createdAt,
                    description: {
                        text: fm(messages.mappingChange, {
                            from: getMappingName(from.referencedId, from.type),
                            to: getMappingName(to.referencedId, to.type),
                        }).toString(),
                        icon: <TinyIcon color={PIPELINE_COLORS.union} iconComponent={<Input />} />,
                    },
                    from,
                    to,
                };
            default:
                throw new Error("Unhandled change type");
        }
    }
    return changes.map(toFrontendChange).filter(hasValue);
}

export function getImportIdsFromChanges(changes: PendingChange[]): string[] {
    return Array.from(new Set(changes.map((c) => c.importId).filter(hasValue)));
}
