import { CONTRACT_TABLE_GT, SUPPLIER_TABLE_GT } from "@ignite-analytics/global-types";
import { Chip, Popover, capitalize } from "@mui/material";
import React from "react";
import { RowReference, TableType } from "@/components/RowReference";
import { useManyDataTables } from "@/generated/DataTableEntity";
import { ReferenceType } from "../../interfaces";
import { RelationPopUp } from "./RelationPopUp";

interface Props {
    rowId: string;
    columnId: string;
    data: ReferenceType[];
    relatedTableId: string;
    path?: string;
}

export const RelationCell: React.FC<Props> = ({ rowId, columnId, data, relatedTableId, path }) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    const tables = useManyDataTables();
    const contractsTableId = tables?.data?.find((table) => table.globalTypeKey === CONTRACT_TABLE_GT)?.id;
    const suppliersTableId = tables?.data?.find((table) => table.globalTypeKey === SUPPLIER_TABLE_GT)?.id;
    const getGlobalTypeForLink = (): TableType | undefined => {
        if (relatedTableId === suppliersTableId) {
            return "SUPPLIER";
        }
        if (relatedTableId === contractsTableId) {
            return "CONTRACT";
        }
        return undefined;
    };

    const globalTypeForLink = getGlobalTypeForLink();
    if (globalTypeForLink) {
        return (
            <>
                {data.map((cell) => (
                    <Chip
                        key={`${rowId}-${columnId}-selfRef`}
                        label={
                            <RowReference label={cell.label} id={cell.data.id} type={globalTypeForLink} prefix={path} />
                        }
                    />
                ))}
            </>
        );
    }

    return (
        <>
            {data.map((cell) => (
                    <React.Fragment key={`${rowId}-${columnId}-${cell.data.id}`}>
                        <Chip label={capitalize(cell.label)} onClick={handleClick} />
                        {open && (
                            <Popover
                                anchorOrigin={{
                                    vertical: "center",
                                    horizontal: "left",
                                }}
                                open={open}
                                anchorEl={anchorEl}
                                onClose={handleClose}
                            >
                                <RelationPopUp
                                    rowLabel={cell.label}
                                    relatedTableId={relatedTableId}
                                    rowId={cell.data.id}
                                />
                            </Popover>
                        )}
                    </React.Fragment>
                ))}
        </>
    );
};
