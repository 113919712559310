import { defineMessages } from "react-intl";

const messages = defineMessages({
    deleteTaskStarted: {
        id: "components.DataTableNode.deleteTaskStarted",
        defaultMessage: "Deleting a connection can take a while. Come back later when the notification is finished.",
    },
    base: {
        id: "components.DataTableNode.base",
        defaultMessage: "Base",
    },
    pipelineOperation: {
        id: "components.DataTableNode.pipelineOperation",
        defaultMessage: "Operation connection",
    },
    selectAll: {
        id: "components.DataTableNode.selectAll",
        defaultMessage: "Select/Deselect all",
    },
    unknownDataTable: {
        id: "components.DataTableNode.unknownDataTable",
        defaultMessage: "Not able to find data table",
    },
});

export default messages;
