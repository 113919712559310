import { defineMessages } from "react-intl";

const messages = defineMessages({
    dataColumnNameInputLabel: {
        id: "components.dataColumns.dataColumnNameInputLabel",
        defaultMessage: "Name",
    },
    dataColumnNameInputPlaceholder: {
        id: "components.dataColumns.dataColumnNameInputPlaceholder",
        defaultMessage: "Column name",
    },
    dataColumnDataTypeSelectionLabel: {
        id: "components.dataColumns.dataTypeSelectionLabel",
        defaultMessage: "Data type",
    },
    textLabel: {
        id: "components.dataColumns.text",
        defaultMessage: "Text",
    },
    dateLabel: {
        id: "components.dataColumns.date",
        defaultMessage: "Date",
    },

    numberLabel: {
        id: "components.dataColumns.number",
        defaultMessage: "Number",
    },
    userLabel: {
        id: "components.dataColumns.user",
        defaultMessage: "User",
    },
    contactLabel: {
        id: "components.dataColumns.contact",
        defaultMessage: "Contact",
    },
    supplierLabel: {
        id: "components.dataColumns.supplier",
        defaultMessage: "Supplier",
    },
    monetaryAmountLabel: {
        id: "components.dataColumns.monetaryAmount",
        defaultMessage: "Monetary amount",
    },
    groupStructureLabel: {
        id: "components.dataColumns.groupStructure",
        defaultMessage: "Group structure",
    },
    tableRelationLabel: {
        id: "components.dataColumns.tableRelation",
        defaultMessage: "Table relation",
    },
    companyLabel: {
        id: "components.dataColumns.company",
        defaultMessage: "Company",
    },
    fileLabel: {
        id: "components.dataColumns.file",
        defaultMessage: "File",
    },
    dataTableSelect: {
        id: "components.dataColumns.dataTableSelect",
        defaultMessage: "Select table",
    },
    booleanLabel: {
        id: "components.dataColumns.booleanLabel",
        defaultMessage: "Boolean",
    },
    tagLabel: {
        id: "components.dataColumns.tagLabel",
        defaultMessage: "Tag",
    },
    aggregationLabel: {
        id: "components.dataColumns.aggregationLabel",
        defaultMessage: "Aggregation",
    },
    dateAggregationLabel: {
        id: "components.dataColumns.dateAggregationLabel",
        defaultMessage: "Date aggregation",
    },
    listType: {
        id: "components.dataColumns.listType",
        defaultMessage: "Add as list type",
    },
    addTagPlaceholder: {
        id: "components.dataColumns.addTagPlaceholder",
        defaultMessage: "Add tag values",
    },
    emissionsLabel: {
        id: "components.dataColumns.emissionsLabel",
        defaultMessage: "Emissions",
    },
});

export default messages;
