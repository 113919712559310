import { Button, CircularProgress, Stack, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useBasePath } from "@/contexts/BasePathContext";
import { fm } from "@/contexts/IntlContext";
import { useCreateIntegrationMutation } from "@/generated/client";
import messages from "./messages";

export const XLedgerSetup: React.FC<{}> = () => {
    const [token, setToken] = useState<string>();
    const [createXLedgerIntegration, mutation] = useCreateIntegrationMutation();
    const history = useHistory();
    const basePath = useBasePath();

    const handleClick = async () => {
        const result =
            token &&
            (await createXLedgerIntegration({
                input: {
                    integrationToken: token,
                    type: "xLedger v2",
                },
            }));

        result && result.data?.createIntegration.id && history.push(basePath);
    };
    return (
        <Stack direction="row" justifyContent="center">
            <Stack>
                <Typography variant="h5">{fm(messages.header)}</Typography>
                <Stack direction="row" gap={1}>
                    <TextField label={fm(messages.input)} onChange={(e) => setToken(e.target.value)} />
                    <Button disabled={mutation.result.type === "loading"} size="small" onClick={handleClick}>
                        {fm(messages.integrate)}
                    </Button>
                    {mutation.result.type === "loading" && <CircularProgress />}
                </Stack>
            </Stack>
        </Stack>
    );
};
