import { DataColumn } from "@/Types/DataColumn";
import { formatDataColumnType } from "@/helpers";

export function searchRelevantColumn(dataColumn: DataColumn, searchTerm: string | undefined) {
    if (searchTerm === undefined) return true;
    if (
        dataColumn.name.toLowerCase().includes(searchTerm) ||
        formatDataColumnType(dataColumn).toLowerCase().includes(searchTerm) ||
        (dataColumn.globalTypeKey && dataColumn.globalTypeKey.toLowerCase().includes(searchTerm))
    )
        return true;
    return false;
}
