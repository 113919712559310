import { TableCell, TableRow } from "@mui/material";
import React from "react";
import { DataPipelineOperation, InputOutputConnection, PipelineExecutionRow } from "@/generated/client";
import { InputSourceItem } from "@/Types/InputSourceItem";
import { findOperationInputValue } from "./helpers";

interface Props {
    sources: InputSourceItem[];
    dataPipelineExecutionRows: PipelineExecutionRow[];
    operation: DataPipelineOperation;
    inputOutputConnections: InputOutputConnection[];
}

export const TableRows = ({ sources, dataPipelineExecutionRows, operation, inputOutputConnections }: Props) => {
    const parsedRows = dataPipelineExecutionRows.map((row) =>
        row.outputResultsJson !== "" ? JSON.parse(row.outputResultsJson) : []
    );
    const parsedFieldInputs = dataPipelineExecutionRows.map((row) =>
        row.dataRepositoryFieldsJson !== "" ? JSON.parse(row.dataRepositoryFieldsJson) : []
    );
    const parsedOperationInput: Record<string, unknown>[] = dataPipelineExecutionRows.map((row) =>
        row.operationInputsJson !== "" ? JSON.parse(row.operationInputsJson) : {}
    );
    const operationOutputIds = operation.output.map((output) => output.id);
    const rows = sources
        .filter((source) => operationOutputIds.includes(source.id))
        .map((source) =>
            parsedRows.map((row, i) => {
                const fieldIdValueTuples = Object.entries(parsedFieldInputs[i]);
                return {
                    key: row.id,
                    name: operation.name,
                    sourceLabel: source.label,
                    inputs: fieldIdValueTuples.map(([fieldId, value]) => [
                        sources.find((s) => s.id === fieldId)?.label,
                        value,
                        fieldId,
                    ]),
                    operationInputs: parsedOperationInput[i],
                    outputs: Object.values(row),
                };
            })
        )
        .flat();

    return (
        <>
            {rows.map((row) => (
                <TableRow key={row.key}>
                    <TableCell>{row.sourceLabel}</TableCell>
                    {operation.input.map((inputNode, _) => (
                        <TableCell key={`${row.key}${inputNode.id}`}>
                            {findOperationInputValue(inputNode, inputOutputConnections, row)}
                        </TableCell>
                    ))}
                    {row.outputs.map((output, i) => (
                        // eslint-disable-next-line react/no-array-index-key
                        <TableCell key={`${output}_${i}`}>{`${output}`}</TableCell>
                    ))}
                </TableRow>
            ))}
        </>
    );
};
