import { GraphqlRequestContainer } from "@ignite-analytics/graphql-utilities";
import { Company } from "@ignite-analytics/icons";
import { CardContent, Chip, Divider, Popover, Stack, Typography, capitalize } from "@mui/material";
import React from "react";
import { fm } from "@/contexts/IntlContext";
import { useGetCompanyQuery } from "@/generated/client";
import messages from "./messages";

interface Props {
    companyId: string;
    companyLabel: string;
}

const CompanyPopUp: React.FC<Props> = ({ companyId, companyLabel }) => {
    const { result } = useGetCompanyQuery({ input: { id: companyId, enrichmentPrecedence: ["ENIN_ENTITY_INSIGHTS"] } });

    return (
        <GraphqlRequestContainer asyncData={result}>
            {(response) => (
                <CardContent>
                    <Typography paddingBottom={1} variant="subtitle1">
                        {capitalize(companyLabel)}
                    </Typography>
                    <Stack gap={1}>
                        <Stack direction="row" justifyContent="space-between" gap={2}>
                            <Typography color="GrayText">{fm(messages.orgNumber)}</Typography>
                            <Typography>{response.company.orgNumber}</Typography>
                        </Stack>

                        <Stack direction="row" justifyContent="space-between" gap={2}>
                            <Typography color="GrayText">{fm(messages.country)}</Typography>
                            <Typography>{response.company.country}</Typography>
                        </Stack>

                        <Stack direction="row" justifyContent="space-between" gap={2}>
                            <Typography color="GrayText">{fm(messages.industry)}</Typography>
                            <Typography>{response.company.localCategory?.name}</Typography>
                        </Stack>
                        <Stack direction="row" justifyContent="space-between" gap={2}>
                            <Typography color="GrayText">{fm(messages.industryCode)}</Typography>
                            <Typography>{response.company.localCategory?.code}</Typography>
                        </Stack>

                        <Divider />

                        {response.company.latestFinancialStatement && (
                            <>
                                <Stack direction="row" justifyContent="space-between" gap={2}>
                                    <Typography color="GrayText">
                                        {fm(messages.revenue, {
                                            year: response.company.latestFinancialStatement.year,
                                        })}
                                    </Typography>
                                    <Typography>
                                        {`${response.company.latestFinancialStatement.revenue.toLocaleString()} ${
                                            response.company.latestFinancialStatement.currency
                                        }`}
                                    </Typography>
                                </Stack>

                                <Stack direction="row" justifyContent="space-between" gap={2}>
                                    <Typography color="GrayText">
                                        {fm(messages.ebitMargin, {
                                            year: response.company.latestFinancialStatement.year,
                                        })}
                                    </Typography>
                                    <Typography>
                                        {`${(response.company.latestFinancialStatement.ebitMargin * 100).toFixed(1)} %`}
                                    </Typography>
                                </Stack>
                            </>
                        )}
                    </Stack>
                </CardContent>
            )}
        </GraphqlRequestContainer>
    );
};

export const CompanyCell: React.FC<Props> = ({ companyId, companyLabel }) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    return (
        <>
            <Chip icon={<Company fontSize="small" />} label={capitalize(companyLabel)} onClick={handleClick} />
            {open && (
                <Popover
                    anchorOrigin={{
                        vertical: "center",
                        horizontal: "center",
                    }}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                >
                    <CompanyPopUp companyId={companyId} companyLabel={companyLabel} />
                </Popover>
            )}
        </>
    );
};
