import { uncacheElasticFields } from "@ignite-analytics/elastic-fields";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { Alert, Autocomplete, TextField, Tooltip } from "@mui/material";
import Stack from "@mui/material/Stack";
import React, { useState } from "react";
import { TranslatedString } from "@/Types/translation";
import { useDataColumnsByDataTableId } from "@/contexts/EventContexts/DataColumnEventContext";
import { fm } from "@/contexts/IntlContext";
import { DataTable, RelativeDateSetting } from "@/generated/client";
import { useUpdateDataTableAction } from "@/generated/DataTableEntity";
import { formatGrpcErrorMessage } from "@/helpers";
import { DataColumn } from "@/Types/DataColumn";
import messages from "./messages";

interface Props {
    dataTable: DataTable;
}

const needsColumn: Record<RelativeDateSetting, boolean> = {
    TODAY: false,
    MAX_DATE_COLUMN: false,
    MAX_DATE_ON_SPECIFIC_COLUMN: true,
    MAX_DATE_BEFORE_TODAY_SPECIFIC_COLUMN: true,
};

const RELATIVE_DATE_OPTIONS: Record<RelativeDateSetting, TranslatedString> = {
    TODAY: fm(messages.todayLabel),
    MAX_DATE_COLUMN: fm(messages.maxDateColumnLabel),
    MAX_DATE_ON_SPECIFIC_COLUMN: fm(messages.maxDateOnSpecificColumnLabel),
    MAX_DATE_BEFORE_TODAY_SPECIFIC_COLUMN: fm(messages.maxDateBeforeTodaySpecificColumnLabel),
};

export const elasticIndexFromTableId = (tableId: string): string => `datatable_v2_${tableId}`;

export const RelativeDateSettings: React.FC<Props> = ({ dataTable }: Props) => {
    const columns = useDataColumnsByDataTableId(dataTable.id);
    const [selectedRelativeDateSetting, setSelectedRelativeDateSetting] = useState<RelativeDateSetting>(
        dataTable.relativeDateSetting ?? "TODAY"
    );
    const [updateDatatable, mutation] = useUpdateDataTableAction();

    const isDateColumn = ({ dataType }: DataColumn): boolean => dataType === "DATE";

    const handleSelect = async (setting: RelativeDateSetting, columnId?: string) => {
        setSelectedRelativeDateSetting(setting);
        const isValidSetting = !needsColumn[setting] || columnId;
        const hasChanged =
            selectedRelativeDateSetting !== dataTable.relativeDateSetting ||
            columnId !== dataTable.relativeDateColumnId;
        const canSubmit = isValidSetting && hasChanged;
        if (!canSubmit) {
            return;
        }
        await updateDatatable(dataTable.id, {
            relativeDateColumnId: columnId,
            relativeDateSetting: setting,
        });
        uncacheElasticFields(elasticIndexFromTableId(dataTable.id));
    };
    return (
        <Stack>
            <Stack direction="row" gap={1} alignItems="center">
                <Autocomplete
                    size="small"
                    sx={{ minWidth: 220, maxWidth: 220 }}
                    disableClearable
                    value={selectedRelativeDateSetting}
                    onChange={(_, dateSetting) => {
                        handleSelect(dateSetting);
                    }}
                    defaultValue={selectedRelativeDateSetting}
                    options={Object.keys(RELATIVE_DATE_OPTIONS) as RelativeDateSetting[]}
                    getOptionLabel={(option: RelativeDateSetting) => RELATIVE_DATE_OPTIONS[option].toString()}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            InputProps={{
                                ...params.InputProps,
                                startAdornment: (
                                    <Tooltip title={fm(messages.tooltipDateType)}>
                                        <HelpOutlineIcon fontSize="small" />
                                    </Tooltip>
                                ),
                            }}
                            placeholder={fm(messages.selectRelativeDateColumnPlaceholder).toString()}
                            label={fm(messages.relativeDateSettingPlaceholder).toString()}
                        />
                    )}
                />
                {needsColumn[selectedRelativeDateSetting] && (
                    <Autocomplete
                        size="small"
                        sx={{ minWidth: 220, maxWidth: 220 }}
                        disableClearable
                        onChange={(_, dataColumn) => {
                            handleSelect(selectedRelativeDateSetting, dataColumn.id);
                        }}
                        options={columns.filter(isDateColumn)}
                        defaultValue={columns.find(({ id }) => id === dataTable.relativeDateColumnId)}
                        getOptionLabel={(option) => option.name}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                InputProps={{
                                    ...params.InputProps,
                                    startAdornment: (
                                        <Tooltip title={fm(messages.tooltipColumn)}>
                                            <HelpOutlineIcon fontSize="small" />
                                        </Tooltip>
                                    ),
                                }}
                                placeholder={fm(messages.selectRelativeDateColumn).toString()}
                                label={fm(messages.selectRelativeDateColumn)}
                            />
                        )}
                    />
                )}
            </Stack>
            {mutation.error && <Alert severity="error">{formatGrpcErrorMessage(mutation.error.message)}</Alert>}
        </Stack>
    );
};
