import { useContext } from "react";
import { Context } from "./context";

export function useCurrencyCode(): string {
    const context = useContext(Context);
    if (context === null) {
        throw new Error("useCurrencyCode must be used within a CurrencyProvider");
    }
    return context;
}
