import { TextField } from "@mui/material";
import { GraphqlRequestContainer } from "@ignite-analytics/graphql-utilities";
import LoadingButton from "@mui/lab/LoadingButton";
import Autocomplete from "@mui/material/Autocomplete";
import React, { useState } from "react";
import { DataColumn } from "@/Types/DataColumn";
import { fm } from "@/contexts/IntlContext";
import { useManyDataTables } from "@/generated/DataTableEntity";
import { useCreateDataColumnsMutation } from "@/generated/client";
import globalMessages from "@/messages";
import messages from "../../messages";

interface Props {
    dataTableId: string;
    onDataColumnCreated?: (newColumn?: DataColumn) => void;
    name?: string;
    isList: boolean;
}

export const CreateTableRelationColumn: React.FC<Props> = ({ dataTableId, onDataColumnCreated, name, isList }) => {
    const [createDataColumns, mutation] = useCreateDataColumnsMutation({ refetchQueries: ["getAllDataColumns"] });
    const result = useManyDataTables();
    const [relatedTableId, setRelatedTableId] = useState<string>();
    const onSubmit = () => {
        if (mutation.result.type === "loading") {
            return;
        }
        if (!relatedTableId || !name) return;
        createDataColumns({
            input: {
                dataTableId,
                dataColumns: [
                    {
                        dataType: "TABLE_RELATION",
                        name,
                        referencedId: relatedTableId,
                        isList,
                    },
                ],
            },
        }).then((res) => onDataColumnCreated && onDataColumnCreated(res.data?.createDataColumns.dataColumns.at(0)));
    };
    return (
        <>
            <GraphqlRequestContainer asyncData={result}>
                {(dataTablesResponse) => (
                    <Autocomplete
                        options={dataTablesResponse}
                        getOptionLabel={(table) => table.name}
                        onChange={(_, value) => {
                            setRelatedTableId(value?.id);
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label={fm(messages.dataTableSelect)}
                                name="relatedTable"
                                inputProps={{ ...params.inputProps }}
                            />
                        )}
                    />
                )}
            </GraphqlRequestContainer>
            <LoadingButton
                disabled={!name || !relatedTableId}
                loading={mutation.result.type === "loading"}
                variant="outlined"
                onClick={onSubmit}
            >
                {fm(globalMessages.submitButton)}
            </LoadingButton>
            <GraphqlRequestContainer asyncData={mutation.result} loading={null} />
        </>
    );
};
