import { CircularProgress, Dialog, DialogContent, DialogTitle, Stack } from "@mui/material";
import React, { useMemo } from "react";
import { fm } from "@/contexts/IntlContext";
import { Change } from "../ApplyChangesButtons/helpers";
import { groupChangesByPipeline } from "../helpers";
import InProgressChangeTable from "./InProgressChangeTable";
import messages from "./messages";

interface Props {
    open: boolean;
    onClose: () => void;
    inProgressChanges: Change[];
}
const InProgressChangesDialog: React.FC<Props> = ({ open, onClose, inProgressChanges }) => {
    const changesByPipeline = useMemo(() => groupChangesByPipeline(inProgressChanges), [inProgressChanges]);
    return (
        <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
            <DialogTitle>
                <Stack direction="row" gap={1} alignItems="center">
                    {inProgressChanges.length ? fm(messages.inProgressChangesTitle) : fm(messages.noInProgressChanges)}
                    {inProgressChanges.length ? <CircularProgress size={30} /> : null}
                </Stack>
            </DialogTitle>
            <DialogContent>
                <Stack spacing={1}>
                    {inProgressChanges.length
                        ? Object.entries(changesByPipeline).map(([pipelineId, changes]) => (
                              <InProgressChangeTable key={pipelineId} changes={changes} />
                          ))
                        : null}
                </Stack>
            </DialogContent>
        </Dialog>
    );
};

export default InProgressChangesDialog;
