import { Abc, CalendarMonth, Numbers, Search } from "@mui/icons-material";
import {
    capitalize,
    MenuItem,
    Select,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Typography,
} from "@mui/material";
import React, { useState } from "react";
import { fm } from "@/contexts/IntlContext";
import { PrimitiveDataColumnTypeInput } from "@/generated/client";
import globalMessages from "@/messages";
import messages from "./messages";

export type FilePreviewData = {
    fieldKey: string;
    samples: string[];
}[];

const typeIcons = {
    NUMBER: <Numbers />,
    TEXT: <Abc />,
    DATE: <CalendarMonth />,
} as const;

const primitves = ["TEXT", "NUMBER", "DATE"] as const;

function isPrimitive(type: string): type is PrimitiveDataColumnTypeInput {
    return ["TEXT", "NUMBER", "DATE"].includes(type);
}

interface Props {
    fileData: FilePreviewData;
    setTypeMappings: (state: Record<string, PrimitiveDataColumnTypeInput>) => void;
    typeMappings: Record<string, PrimitiveDataColumnTypeInput>;
    searchBar?: boolean;
    maxHeight?: string;
    message?: string;
}

export const DataTypeMapping: React.FC<Props> = ({
    fileData,
    setTypeMappings,
    typeMappings,
    searchBar,
    maxHeight,
    message,
}) => {
    const [search, setSearch] = useState("");
    const handleTypeSelect = (key: string, type: PrimitiveDataColumnTypeInput) => {
        setTypeMappings({ ...typeMappings, [key]: type });
    };

    return (
        <Stack gap={1}>
            {message && <Typography>{message}</Typography>}
            {searchBar && (
                <TextField
                    // eslint-search-next-line jsx-a11y/no-autofocus
                    value={search}
                    sx={{ width: "300px" }}
                    placeholder={fm(globalMessages.search).toString()}
                    onChange={(e) => {
                        setSearch(e.target.value);
                    }}
                    InputProps={{
                        endAdornment: <Search fontSize="small" />,
                    }}
                />
            )}
            <TableContainer sx={{ maxHeight: maxHeight ?? undefined }}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>{fm(messages.columnName)}</TableCell>
                            <TableCell>{fm(messages.dataType)}</TableCell>
                            <TableCell>{fm(messages.samples)}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {fileData.map((fileColumn) => (
                                <TableRow key={fileColumn.fieldKey}>
                                    <TableCell>{fileColumn.fieldKey}</TableCell>
                                    <TableCell>
                                        <Select
                                            value={typeMappings[fileColumn.fieldKey] ?? "TEXT"}
                                            sx={{ width: "300px", maxHeight: "45px" }}
                                            onChange={(e) =>
                                                isPrimitive(e.target.value) &&
                                                handleTypeSelect(fileColumn.fieldKey, e.target.value)
                                            }
                                        >
                                            {primitves.map((type) => (
                                                <MenuItem key={type} value={type}>
                                                    <Stack direction="row" gap={1} alignItems="center">
                                                        {typeIcons[type]}
                                                        {capitalize(type.toLocaleLowerCase())}
                                                    </Stack>
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </TableCell>
                                    <TableCell>{fileColumn.samples.slice(0, 5).join(" | ")}</TableCell>
                                </TableRow>
                            ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Stack>
    );
};
