import { Typography, TypographyProps } from "@mui/material";
import React from "react";
import { DataColumn } from "@/Types/DataColumn";
import { hasValue } from "@/containers/CubeBuilderPage/components/ConnectionModal/helpers";
import { DataType, isEmissionsCell, isReferenceCell } from "../interfaces";
import { CompanyCell } from "./CompanyCell";
import { EmissionsTableCell } from "./EmissionsTableCell";
import { GroupCell } from "./GroupCell";
import { RelationCell } from "./RelationCell";

interface Props {
    data: DataType;
    rowId: string;
    column: DataColumn;
    path?: string;
}

function isMonetaryAmountCell(value: unknown): value is { amount: number; currency: string } {
    return typeof value === "object" && value !== null && "amount" in value && "currency" in value;
}

function formatNumber(value: number, decimals: number) {
    const valueString = value.toFixed(decimals).toString();
    // formats numbers to have spaces between thousands
    return valueString.replace(/\B(?=(\d{3})+(?!\d))/g, " ");
}

export const Cell: React.FC<Props> = ({ rowId, data, column, path }) => {
    const style: TypographyProps = { overflow: "unset", noWrap: true, variant: "body2" };

    if (column.dataType === "COMPANY" && isReferenceCell(data)) {
        const value = data.length ? data[0] : undefined;
        if (value) return <CompanyCell companyLabel={value.label} companyId={value.data.id} />;
    }
    if (column.dataType === "GROUP_STRUCTURE" && isReferenceCell(data)) {
        return (
            <>
                {data.map((cell) => (
                    <GroupCell key={cell.data.id} meta={cell.meta} label={cell.label} />
                ))}
            </>
        );
    }
    if (isReferenceCell(data) && column.referencedId) {
        return (
            <RelationCell
                rowId={rowId}
                columnId={column.id}
                data={data}
                relatedTableId={column.referencedId}
                path={path}
            />
        );
    }

    if (isEmissionsCell(data)) {
        const value = data.length ? data[0] : undefined;
        if (value) {
            return <EmissionsTableCell cell={value.data} />;
        }
    }
    if (column.dataType === "NUMBER" || column.dataType === "MONETARY_AMOUNT") {
        return (
            <Typography {...style} align="right">
                {data
                    ?.map((value) => {
                        if (isMonetaryAmountCell(value.data)) {
                            return `${formatNumber(value.data.amount, 0)} ${value.data.currency}`;
                        }
                        return typeof value.data === "number" ? formatNumber(value.data, 2) : value.label;
                    })
                    .filter(hasValue)
                    .join("\n")}
            </Typography>
        );
    }

    const label = data ? data.map((value) => value.label).join(", ") : "";
    return <Typography {...style}> {label}</Typography>;
};
