import {
    COMPANY_REG_NR_GT,
    CONTRACT_DESCRIPTION_GT,
    CONTRACT_END_DATE,
    CONTRACT_START_DATE,
    CONTRACT_TABLE_GT,
    CONTRACT_TITLE_GT,
    SUPPLIER_COUNTRY_GT,
    SUPPLIER_NAME_GT,
    SUPPLIER_TABLE_GT,
    TRANSACTIONS_GT,
    TRANSACTION_DATE_GT,
    TRANSACTION_VALUE_GT,
} from "@ignite-analytics/global-types";
import { CalendarMonth, Euro, Factory, Flag, LocalShipping, RequestQuote } from "@mui/icons-material";
import { Stack, Typography } from "@mui/material";
import React from "react";
import { fm } from "@/contexts/IntlContext";
import messages from "../../messages";
import { TagSelect } from "./tagSelect";

interface Props {
    fields: string[];
    mappings: Record<string, string | null>;
    setMappings: React.Dispatch<React.SetStateAction<Record<string, string | null>>>;
    tableType: typeof CONTRACT_TABLE_GT | typeof SUPPLIER_TABLE_GT | typeof TRANSACTIONS_GT;
}

export type TagDefinition = {
    tag: string;
    optional: boolean;
    message: string;
    icon?: JSX.Element;
    explanation?: string;
};

export const tableSpecificTags: Record<string, TagDefinition[]> = {
    [TRANSACTIONS_GT]: [
        {
            tag: TRANSACTION_VALUE_GT,
            optional: false,
            message: fm(messages.spendAmount).toString(),
            icon: <RequestQuote />,
        },
        {
            tag: "CURRENCY",
            optional: true,
            message: fm(messages.currency).toString(),
            icon: <Euro />,
            explanation: fm(messages.currencyExplanation).toString(),
        },
        {
            tag: TRANSACTION_DATE_GT,
            optional: false,
            message: fm(messages.spendDate).toString(),
            icon: <CalendarMonth />,
        },
    ],
    [SUPPLIER_TABLE_GT]: [
        {
            tag: SUPPLIER_NAME_GT,
            optional: false,
            message: fm(messages.supplierName).toString(),
            icon: <LocalShipping />,
        },
        {
            tag: SUPPLIER_COUNTRY_GT,
            optional: true,
            message: fm(messages.supplierCountry).toString(),
            icon: <Flag />,
            explanation: fm(messages.supplierExplanations).toString(),
        },
        {
            tag: COMPANY_REG_NR_GT,
            optional: true,
            message: fm(messages.OrgNr).toString(),
            icon: <Factory />,
            explanation: fm(messages.supplierExplanations).toString(),
        },
    ],
    [CONTRACT_TABLE_GT]: [
        { tag: CONTRACT_TITLE_GT, optional: false, message: fm(messages.contractTitle).toString() },
        { tag: CONTRACT_DESCRIPTION_GT, optional: true, message: fm(messages.contractDescription).toString() },
        { tag: CONTRACT_START_DATE, optional: false, message: fm(messages.startDate).toString() },
        { tag: CONTRACT_END_DATE, optional: false, message: fm(messages.endDate).toString() },
    ],
};

const headerProps = {
    [TRANSACTIONS_GT]: fm(messages.transaction).toString(),
    [SUPPLIER_TABLE_GT]: fm(messages.supplier).toString(),
    [CONTRACT_TABLE_GT]: fm(messages.contract).toString(),
};

export const SimpleGlobalTagConfiguration: React.FC<Props> = ({ fields, setMappings, mappings, tableType }) => {
    const firstUnmappedTag = tableSpecificTags[tableType].find(({ tag }) => !(tag in mappings));

    return (
        <Stack gap={2}>
            <Typography variant="h6">{fm(messages.selectPrompt, { type: headerProps[tableType] })}</Typography>
            <Stack direction="row" gap={3}>
                {tableSpecificTags[tableType].map(({ tag, optional, message, icon, explanation }) =>
                    firstUnmappedTag?.tag === tag || tag in mappings ? (
                        <TagSelect
                            key={tag}
                            firstUnmappedTag={firstUnmappedTag}
                            mappings={mappings}
                            setMappings={setMappings}
                            fields={fields}
                            tag={tag}
                            optional={optional}
                            message={message}
                            icon={icon}
                            explanation={explanation}
                        />
                    ) : null
                )}
            </Stack>
        </Stack>
    );
};
