import { DataGridPro } from "@mui/x-data-grid-pro";
import { LicenseInfo } from "@mui/x-license";
import React from "react";

import { DataColumn } from "@/Types/DataColumn";
import { DataTable } from "@/generated/client";
import { useSharedLocalStorageState } from "@/hooks";
import { CustomColumnMenu } from "./CustomColumnMenu";
import { getColumnTypeDef, getDefaultPinnedColumns } from "./helpers";

if (process.env.REACT_APP_MUI_TOKEN) {
    LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_TOKEN);
}

interface Props {
    dataTable: DataTable;
    columns: DataColumn[];
    pagination: {
        page: number;
        setPage: (page: number) => void;
        rowsPerPage: number;
        setRowsPerPage: (n: number) => void;
        total: number;
    };
    path: string;
    rows: { id: string; data: any }[];
    loading: boolean;
    sortColumn: { dataColumnId: string; order: "asc" | "desc" } | undefined;
    onSort: (sorting?: { colId: string; direction: "asc" | "desc" }) => void;
}

export const TableView: React.FC<Props> = ({
    dataTable,
    columns,
    pagination,
    path,
    rows,
    loading,
    sortColumn,
    onSort,
}) => {
    const { rowsPerPage, setRowsPerPage, page, setPage, total } = pagination;

    const [pinnedState, setPinnedState] = useSharedLocalStorageState<{ left?: string[]; right?: string[] }>(
        `DATATABLE_${dataTable.id}_pinnedColumnsMuiDataGrid`,
        getDefaultPinnedColumns(dataTable, columns)
    );

    const [columnSizeState, setColumnSizeState] = useSharedLocalStorageState<Record<string, number>>(
        `DATATABLE_${dataTable.id}_columnSizeMuiDataGrid`,
        {}
    );

    return (
        <DataGridPro
            loading={loading}
            rows={rows.map((row) => ({ id: row.id, ...row.data }))}
            columns={columns.map((c) => getColumnTypeDef(c, columnSizeState[c.id], path))}
            density="compact"
            pagination
            paginationModel={{
                page,
                pageSize: rowsPerPage,
            }}
            onPaginationModelChange={(paginationModel) => {
                setPage(paginationModel.page);
                setRowsPerPage(paginationModel.pageSize);
            }}
            rowCount={total}
            sortModel={sortColumn ? [{ field: sortColumn.dataColumnId, sort: sortColumn.order }] : []}
            onSortModelChange={(sortModel) => {
                const [first] = sortModel;
                if (first && first.sort) {
                    onSort({ colId: first.field, direction: first.sort });
                } else {
                    onSort();
                }
            }}
            paginationMode="server"
            slots={{
                columnMenu: CustomColumnMenu,
            }}
            onColumnResize={(columnResize) =>
                setColumnSizeState({
                    ...columnSizeState,
                    [columnResize.colDef.field]: columnResize.width,
                })
            }
            pinnedColumns={pinnedState}
            onPinnedColumnsChange={(newPinnedColumns) => setPinnedState(newPinnedColumns)}
            disableRowSelectionOnClick
            disableColumnReorder
            disableColumnFilter
        />
    );
};
