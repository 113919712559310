import { useEffect } from "react";

const url = process.env.REACT_APP_PUBLIC_URL;

export function useInjectedReactFlowCss() {
    useEffect(() => {
        if (process.env.NODE_ENV === "development") {
            // Vite seems to automatically put this in a style tag with
            // data-vite-dev-id=".../main/apps/data-tables/node_modules/reactflow/dist/style.css"

            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore - temporary hack in local dev
            import("reactflow/dist/style.css").catch((e) => {
                console.error("Could not import reactflow style - run `npm build`");
                throw e;
            });
            return;
        }

        const style = document.createElement("style");

        const promise = fetch(`${url}/assets/reactflow.css`)
            .then((res) => res.text())
            .then((css) => {
                style.textContent = css;
                document.head.append(style);
            });
        return () => {
            promise.then(() => {
                document.head.removeChild(style);
            });
        };
    }, []);
}
