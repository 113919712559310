import { defineMessages } from "react-intl";

const messages = defineMessages({
    groupStructureLabel: {
        id: "components.dataColumns.groupStructureLabel",
        defaultMessage: "Search for group structure",
    },
});

export default messages;
