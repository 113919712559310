import { defineMessages } from "react-intl";

const messages = defineMessages({
    tooltipDateType: {
        id: "uploadPage.relativeDateSettings.tooltip",
        defaultMessage:
            "This settings determine how to calculate the maximum date used in relative date filters throughout the platform.",
    },
    tooltipColumn: {
        id: "uploadPage.relativeDateSettings.tooltipColumn",
        defaultMessage:
            "This setting is used to select the column that will be used to calculate the maximum date used in relative date filters throughout the platform.",
    },
    selectRelativeDateSetting: {
        id: "uploadPage.relativeDateSettings.selectRelativeDateSetting",
        defaultMessage: "Select relative date setting for filters",
    },
    relativeDateSettingPlaceholder: {
        id: "uploadPage.relativeDateSettings.relativeDateSettingPlaceholder",
        defaultMessage: "Select relative date setting",
    },
    selectRelativeDateColumn: {
        id: "uploadPage.selectRelativeDateColumn.selectRelativeDateColumn",
        defaultMessage: "Select column for relative date option",
    },
    selectRelativeDateColumnPlaceholder: {
        id: "uploadPage.selectRelativeDateColumn.selectRelativeDateColumnPlaceholder",
        defaultMessage: "Date column",
    },
    todayLabel: {
        id: "uploadPage.relativeDateSettings.todayLabel",
        defaultMessage: "Todays date",
    },
    maxDateColumnLabel: {
        id: "uploadPage.relativeDateSettings.maxDateColumnLabel",
        defaultMessage: "The maximum date of column used in filter",
    },
    maxDateOnSpecificColumnLabel: {
        id: "uploadPage.relativeDateSettings.maxDateOnSpecificColumnLabel",
        defaultMessage: "The maximum date of specified column",
    },
    maxDateBeforeTodaySpecificColumnLabel: {
        id: "uploadPage.relativeDateSettings.maxDateBeforeTodaySpecificColumnLabel",
        defaultMessage: "The maximum date of specified column, no later than today",
    },
});

export default messages;
