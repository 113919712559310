/* eslint-disable react/jsx-no-literals */
/* eslint-disable react/destructuring-assignment */
import { GraphqlRequestContainer } from "@ignite-analytics/graphql-utilities";
import React, { useState } from "react";
import {
    Card,
    CardContent,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField,
    Typography,
} from "@mui/material";
import { Search } from "@mui/icons-material";
import { fm } from "@/contexts/IntlContext";
import { DataRepository, useGetDataRepositoryRowsQuery } from "@/generated/client";
import messages from "../messages";

interface Props {
    dataRepository: DataRepository;
}

export const DataRepositoryPreview: React.FC<Props> = ({ dataRepository }: Props) => {
    const [searchTerm, setSearchTerm] = useState<string>();
    const { result: dataRepositoryRows } = useGetDataRepositoryRowsQuery({
        input: { request: { dataRepositoryId: dataRepository.id }, range: 30, index: 0 },
    });

    return dataRepositoryRows.type === "success" ? (
        <Card sx={{ width: "100%" }}>
            <CardContent>
                <GraphqlRequestContainer asyncData={dataRepositoryRows}>
                    {(response) => {
                        const exampleList = response.rows
                            .map((row) => JSON.parse(row.dataJson))
                            .slice(0, 5);
                        return (
                            <Stack justifyContent="flex-start">
                                <Typography variant="subtitle1">{dataRepository.name}</Typography>

                                <TextField
                                    size="small"
                                    label={fm(messages.search)}
                                    placeholder={fm(messages.search).toString()}
                                    InputProps={{
                                        startAdornment: <Search />,
                                    }}
                                    onChange={(e) => {
                                        setSearchTerm(e.target.value);
                                    }}
                                />
                                <Stack maxHeight="50vh" overflow="auto">
                                    <Table size="small" stickyHeader>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell key="dataFields">{fm(messages.repositoryColumn)}</TableCell>
                                                <TableCell key="examples">{fm(messages.examples)}</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {dataRepository.fields.map((field) => searchTerm === undefined ||
                                                    field.name.toUpperCase().includes(searchTerm.toUpperCase()) ? (
                                                    <TableRow key={field.id}>
                                                        <TableCell>{field.name}</TableCell>
                                                        <TableCell>
                                                            {exampleList
                                                                .map((row) => row[field.id])
                                                                .join(" | ")}
                                                        </TableCell>
                                                    </TableRow>
                                                ) : null)}
                                        </TableBody>
                                    </Table>
                                </Stack>
                            </Stack>
                        );
                    }}
                </GraphqlRequestContainer>
            </CardContent>
        </Card>
    ) : (
        <Typography>{fm(messages.noData)}</Typography>
    );
};
