import { ArrowRight } from "@mui/icons-material";
import { Stack } from "@mui/material";
import React from "react";
import { GroupStructureMeta } from "../interfaces";

interface Props {
    meta?: GroupStructureMeta;
    label: string;
}

export const GroupCell: React.FC<Props> = ({ meta, label }) => (
    <Stack direction="row" gap={1} alignItems="center">
        {meta &&
            meta.fullPath.map((parent) => (
                <Stack key={parent.id} alignItems="center" direction="row" gap={1}>
                    {parent.label}
                    <ArrowRight fontSize="small" />
                </Stack>
            ))}
        {label}
    </Stack>
);
