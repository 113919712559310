import { defineMessages } from "react-intl";

const messages = defineMessages({
    dataTable: {
        id: "uploadPage.dataRepositoryPage.manageDataTableTab.dataTable",
        defaultMessage: "Data table",
    },
    helpTextTableType: {
        id: "uploadPage.dataRepositoryPage.manageDataTableTab.helpTextTableType",
        defaultMessage:
            "The global table type will inform Ignite about which data table to use for analyses. Global table tags require that certain columns are set it advance. For example, the 'Spend data' tag requires the three global column tags: Spend amount, Spend date, and Supplier relation.",
    },
    setGlobalTableType: {
        id: "uploadPage.dataRepositoryPage.manageDataTableTab.setGlobalTableType",
        defaultMessage: "Set global table type",
    },
    setGlobalTableTypePlaceHolder: {
        id: "uploadPage.dataRepositoryPage.manageDataTableTab.setGlobalTableTypePlaceHolder",
        defaultMessage: "Global type",
    },
});

export default messages;
