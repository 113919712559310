import { DragIndicator, ExpandLess } from "@mui/icons-material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Grid, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import { DataTable, DataTableCollection } from "@/generated/client";
import { EditDataTableCollection } from "./EditDataTableCollection";

interface Props {
    collection: DataTableCollection | { name: string };
    tables: DataTable[];
    onUpdate: () => void;
    onDelete?: () => void;
    onClick: () => void;
    open: boolean;
}

export const CollectionHeader = ({ collection, tables, onUpdate, onClick, onDelete, open }: Props) => {
    const [editType, setEditType] = useState<"ADD" | "DELETE" | "EDIT" | undefined>();
    const [hoverId, setHoverOverId] = useState<string>();

    return (
        <div
            id={"id" in collection ? collection.id : collection.name}
            onFocus={() => null}
            onMouseOver={(ev) => {
                setHoverOverId(ev.currentTarget.id ?? undefined);
            }}
            onMouseLeave={() => {
                setHoverOverId(undefined);
            }}
        >
            <Grid
                container
                direction="row"
                height="50px"
                padding={1}
                justifyContent="space-between"
                alignItems="center"
                sx={(theme) => ({ cursor: "pointer", "&:hover": { color: theme.palette.primary.main } })}
            >
                <Grid sm={10} item onClick={onClick}>
                    <Stack direction="row" alignItems="center">
                        {hoverId &&
                            collection.name !== "Others" &&
                            (hoverId ? (
                                <DragIndicator fontSize="small" sx={{ cursor: "grab" }} />
                            ) : (
                                <DragIndicator fontSize="small" sx={{ visibility: "hidden" }} />
                            ))}
                        <Typography
                            variant="subtitle2"
                            sx={(theme) => ({
                                "&:hover": { color: theme.palette.primary.main },
                            })}
                        >
                            {collection.name}
                        </Typography>
                    </Stack>
                </Grid>
                <Grid item sm={2}>
                    <Stack direction="row" alignItems="center" gap={1}>
                        {("id" in collection && hoverId === collection.id) || collection.name === hoverId ? (
                            <EditDataTableCollection
                                editType={editType}
                                setEditType={setEditType}
                                onUpdate={onUpdate}
                                onDelete={onDelete}
                                collection={collection}
                                tables={tables}
                            />
                        ) : open ? (
                            <ExpandMoreIcon fontSize="small" />
                        ) : (
                            <ExpandLess fontSize="small" />
                        )}
                    </Stack>
                </Grid>
            </Grid>
        </div>
    );
};
