import React, { useState } from "react";
import { useQuery } from "@apollo/client";
import { Context } from "./context";
import { getCurrentCustomerQuery } from "./queries";

type Props = {
    children?: React.ReactNode;
};

export const CurrencyProvider: React.FC<Props> = ({ children }) => {
    const [value, setValue] = useState("");
    useQuery(getCurrentCustomerQuery, {
        onCompleted: (data) => {
            setValue(data.getCurrentCustomer.currency);
        },
    });
    return <Context.Provider value={value}>{children}</Context.Provider>;
};
