import { defineMessages } from "react-intl";

const messages = defineMessages({
    noColumnDependencies: {
        id: "uploadPage.dataRepositoryPage.manageGlobalColumnTypes.noColumnDependencies",
        defaultMessage: "No dependencies. OK to delete.",
    },
});

export default messages;
