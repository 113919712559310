import { Autocomplete, Button, Stack, TextField } from "@mui/material";
import React, { useState } from "react";
import { fm } from "@/contexts/IntlContext";
import globalMessages from "@/messages";

import { DataTable, DataTableCollection } from "@/generated/client";
import { useUpdateDataTableAction } from "@/generated/DataTableEntity";
import messages from "../messages";

interface Props {
    collection: DataTableCollection | { name: string };
    tables: DataTable[];
    onCancel: () => void;
    onUpdate: () => void;
}

export const AddTablesToCollection: React.FC<Props> = ({ onCancel, onUpdate, collection, tables }) => {
    const [selectedTables, setSelectedTables] = useState<DataTable[]>();

    const [updateTable] = useUpdateDataTableAction();

    const handleAddDataTables = () => {
        selectedTables &&
            Promise.all(
                selectedTables.map((table) =>
                    updateTable(table.id, { dataTableCollectionId: "id" in collection ? collection.id : null })
                )
            ).then(() => {
                onUpdate();
                onCancel();
            });
    };
    return (
        <Stack gap={2} paddingX={2}>
            <Autocomplete
                multiple
                id="tags-standard"
                options={tables}
                onChange={(_, v) => setSelectedTables(v)}
                getOptionLabel={(option) => option.name}
                defaultValue={[
                    ...tables.filter((dt) => "id" in collection && dt.DataTableCollectionId === collection.id),
                ]}
                onKeyPress={(e) => e.key === "Enter" && handleAddDataTables()}
                renderInput={(params) => <TextField {...params} variant="standard" label={fm(messages.dataTables)} />}
            />

            <Stack gap={1} direction="row" justifyContent="right">
                <Button size="small" variant="text" onClick={onCancel}>
                    {fm(globalMessages.cancel)}
                </Button>

                <Button size="small" color="primary" onClick={handleAddDataTables}>
                    {fm(globalMessages.add)}
                </Button>
            </Stack>
        </Stack>
    );
};
