import { ArrowBack, ArrowForward, InfoOutlined } from "@mui/icons-material";
import { Alert, Autocomplete, Button, Stack, TextField, Tooltip } from "@mui/material";
import React, { useState } from "react";
import { testIdPrefix } from "@/components/testIdprefix";
import { fm } from "@/contexts/IntlContext";
import { DELIMITER_OPTIONS, ENCODING_OPTIONS, ESCAPECHAR_OPTIONS, QUOTECHAR_OPTIONS } from "./constants";
import { getContentTypeFromFile, validateXlsxConfig } from "./helpers";
import messages from "./messages";
import { ImportConfigurationValues } from "./types";

export const getInitialValues: (fileName?: string) => ImportConfigurationValues = (file) => ({
    name: `${getContentTypeFromFile(file)} - Import configuration`,
    contentType: getContentTypeFromFile(file),
    headerRowIndex: 1,
    dataStartIndex: 2,
    numberOfEndLinesToDrop: 0,
    delimiter: "SEMICOLON",
    encoding: "UTF",
    escapeCharacter: "DOUBLE_QUOTE",
    quoteCharacter: "DOUBLE_QUOTE_QUOTING",
});

interface Props {
    fileName?: string;
    onSubmit?: (values: ImportConfigurationValues) => void;
    onChange?: (values: ImportConfigurationValues) => void;
    onBack?: () => void;
    horizontal?: boolean;
}

const CreateImportConfiguration: React.FC<Props> = ({ onSubmit, fileName, onBack, onChange, horizontal }) => {
    const contentType = fileName?.toLowerCase().endsWith(".csv") ? "CSV" : "XLSX";
    const [config, setConfig] = useState<ImportConfigurationValues>(getInitialValues(fileName));
    const [error, setError] = useState<string | undefined>();
    const handleChange = (values: Partial<ImportConfigurationValues>) => {
        setError(undefined);
        const updateErrors =
            contentType === "XLSX"
                ? validateXlsxConfig({
                      headerRowIndex: values.headerRowIndex || 1,
                      dataStartIndex: values.dataStartIndex || 2,
                      numberOfEndLinesToDrop: values.numberOfEndLinesToDrop || 0,
                  })
                : null;
        if (updateErrors) {
            setError(updateErrors);
            return;
        }
        setConfig((prev) => ({ ...prev, ...values }));
        onChange?.({ ...config, ...values });
    };
    return (
        <Stack gap={1} direction={horizontal ? "row" : undefined}>
            {!horizontal && (
                <TextField
                    type="text"
                    name="name"
                    defaultValue={`${contentType} - import configuration`}
                    label={fm(messages.formNameInputLabel)}
                    onChange={(e) => handleChange({ name: e.currentTarget.value || config.name })}
                />
            )}
            {contentType === "XLSX" && (
                <>
                    <TextField
                        disabled={!fileName}
                        value={config.headerRowIndex}
                        type="number"
                        name="headerRowIndex"
                        label={fm(messages.headerRowIndex)}
                        onChange={(e) =>
                            handleChange({
                                headerRowIndex: Number(e.currentTarget.value) ?? config.headerRowIndex,
                                dataStartIndex: config.dataStartIndex,
                                numberOfEndLinesToDrop: config.numberOfEndLinesToDrop,
                            })
                        }
                        InputProps={{
                            endAdornment: (
                                <Tooltip title={fm(messages.headerRowIndexExplanation)}>
                                    <InfoOutlined fontSize="small" />
                                </Tooltip>
                            ),
                        }}
                    />
                    <TextField
                        disabled={!fileName}
                        value={config.dataStartIndex}
                        type="number"
                        name="dataStartIndex"
                        label={fm(messages.dataStartIndex)}
                        onChange={(e) =>
                            handleChange({
                                headerRowIndex: config.headerRowIndex,
                                dataStartIndex: Number(e.currentTarget.value) ?? config.dataStartIndex,
                                numberOfEndLinesToDrop: config.numberOfEndLinesToDrop,
                            })
                        }
                        InputProps={{
                            endAdornment: (
                                <Tooltip title={fm(messages.dataStartIndexExplanation)}>
                                    <InfoOutlined fontSize="small" />
                                </Tooltip>
                            ),
                        }}
                    />
                    <TextField
                        disabled={!fileName}
                        value={config.numberOfEndLinesToDrop}
                        type="number"
                        name="numberOfEndLinesToDrop"
                        label={fm(messages.numberOfEndLinesToDrop)}
                        onChange={(e) =>
                            handleChange({
                                numberOfEndLinesToDrop: Number(e.currentTarget.value) ?? config.numberOfEndLinesToDrop,
                            })
                        }
                        InputProps={{
                            endAdornment: (
                                <Tooltip title={fm(messages.numberOfEndLinesToDropExplanation)}>
                                    <InfoOutlined fontSize="small" />
                                </Tooltip>
                            ),
                        }}
                    />
                    {!horizontal && <Alert severity="info">{fm(messages.sheetMessage)}</Alert>}
                    {error && <Alert severity="error">{error}</Alert>}
                </>
            )}
            {contentType === "CSV" && (
                <>
                    <Autocomplete
                        disablePortal
                        options={DELIMITER_OPTIONS}
                        onChange={(_e, v) =>
                            handleChange({
                                delimiter: v?.value ?? config.delimiter,
                            })
                        }
                        sx={{ width: 300 }}
                        defaultValue={DELIMITER_OPTIONS.find((o) => o.value === config.delimiter)}
                        renderInput={(params) => <TextField {...params} label={fm(messages.delimiter)} />}
                        getOptionLabel={(o) => o.label}
                    />
                    <Autocomplete
                        disablePortal
                        options={ENCODING_OPTIONS}
                        sx={{ width: 300 }}
                        defaultValue={ENCODING_OPTIONS.find((o) => o.value === config.encoding)}
                        onChange={(_e, v) =>
                            handleChange({
                                encoding: v?.value ?? config.encoding,
                            })
                        }
                        renderInput={(params) => <TextField {...params} label={fm(messages.encoding)} />}
                        getOptionLabel={(o) => o.label}
                    />
                    <Autocomplete
                        disablePortal
                        options={ESCAPECHAR_OPTIONS}
                        sx={{ width: 300 }}
                        getOptionLabel={(o) => o.label || "disabled"}
                        defaultValue={ESCAPECHAR_OPTIONS.find((o) => o.value === config.escapeCharacter)}
                        onChange={(_e, v) =>
                            handleChange({
                                escapeCharacter: v?.value ?? config.escapeCharacter,
                            })
                        }
                        renderInput={(params) => <TextField {...params} label={fm(messages.escapechar)} />}
                    />
                    <Autocomplete
                        disablePortal
                        options={QUOTECHAR_OPTIONS}
                        defaultValue={QUOTECHAR_OPTIONS.find((o) => o.value === config.quoteCharacter)}
                        sx={{ width: 300 }}
                        onChange={(_e, v) =>
                            handleChange({
                                quoteCharacter: v?.value ?? config.quoteCharacter,
                            })
                        }
                        renderInput={(params) => <TextField {...params} label={fm(messages.quotechar)} />}
                        getOptionLabel={(o) => o.label || "disabled"}
                    />
                </>
            )}
            {!horizontal && (
                <Stack direction="row" justifyContent="space-between">
                    <Button variant="outlined" color="secondary" onClick={onBack}>
                        <ArrowBack />
                    </Button>
                    <Button
                        type="submit"
                        onClick={() => onSubmit?.({ ...config, contentType })}
                        data-testid={`${testIdPrefix}-upload-forward-button`}
                    >
                        <ArrowForward />
                    </Button>
                </Stack>
            )}
        </Stack>
    );
};

export default CreateImportConfiguration;
