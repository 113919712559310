import { ElasticField } from "@ignite-analytics/elastic-fields";
import { ArrowBack, Search } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
    Alert,
    FormControl,
    FormControlLabel,
    FormLabel,
    IconButton,
    Radio,
    RadioGroup,
    Stack,
    TextField,
    Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useDataColumnsByDataTableId } from "@/contexts/EventContexts/DataColumnEventContext";
import { fm } from "@/contexts/IntlContext";
import { useHiddenColumnsContext } from "@/contexts/hiddenColumnsContext";
import { ExportFormats } from "@/generated/client";
import GlobalMessages from "@/messages";
import { FieldSelectionList } from "./FieldList";
import { groupFieldsByColumn, matchElasticFieldToColumn } from "./helpers";
import messages from "./messages";

interface Props {
    elasticFields: ElasticField[];
    selectedDataTableViewId: string;
    onConfirm: (fields: string[], format: ExportFormats) => void;
    onClose: () => void;
    loading: boolean;
}

const FIELDS_TO_REMOVE = ["batchId", "importId", "lastModified"];

export const ExportFieldsSelection: React.FC<Props> = ({
    elasticFields,
    onConfirm,
    onClose,
    selectedDataTableViewId,
    loading,
}) => {
    const [searchTerm, setSearchTerm] = useState("");
    const [format, setFormat] = useState<ExportFormats>("CSV");
    const columns = useDataColumnsByDataTableId(selectedDataTableViewId);
    const { columnsToHide } = useHiddenColumnsContext();

    const [selected, setSelected] = useState<string[]>(
        elasticFields
            .filter(
                (field) =>
                    !(
                        columnsToHide.find((dc) => matchElasticFieldToColumn(dc, field.field)) ||
                        FIELDS_TO_REMOVE.includes(field.field)
                    )
            )
            .map((f) => f.field)
    );
    const orderedFields = groupFieldsByColumn(columns, elasticFields, searchTerm);
    const limitReached = format === "XLSX" && selected.length > 100;

    const handleSelect = (fields: string[], checked: boolean) => {
        checked && setSelected((prev) => [...new Set([...prev, ...fields])]);
        !checked && setSelected((prev) => prev.filter((f) => !fields.includes(f)));
    };

    const handleSelectAll = (checked: boolean) => {
        checked &&
            setSelected(elasticFields.map(({ field }) => field).filter((field) => !FIELDS_TO_REMOVE.includes(field)));
        !checked && setSelected([]);
    };

    return (
        <Stack gap={1}>
            <Stack direction="row" gap={1} alignItems="center">
                <IconButton>
                    <ArrowBack onClick={onClose} />
                </IconButton>
                <Typography variant="subtitle1">{fm(messages.configure)}</Typography>
            </Stack>
            <FormControl>
                <FormLabel id="demo-row-radio-buttons-group-label">{fm(messages.format)}</FormLabel>
                <RadioGroup row aria-labelledby="demo-row-radio-buttons-group-label" name="row-radio-buttons-group">
                    <FormControlLabel
                        onClick={() => setFormat("CSV")}
                        checked={format === "CSV"}
                        control={<Radio />}
                        label="CSV"
                    />
                    <FormControlLabel
                        checked={format === "XLSX"}
                        value="XLSX"
                        onClick={() => setFormat("XLSX")}
                        control={<Radio />}
                        label="XLSX"
                    />
                </RadioGroup>
            </FormControl>
            <Typography variant="subtitle1">
                {fm(messages.select)} ({selected.length})
            </Typography>
            {limitReached && (
                <Alert severity="error">{fm(messages.limitMessage, { number: selected.length - 100 })}</Alert>
            )}
            <TextField
                size="small"
                onChange={(e) => {
                    setSearchTerm(e.target.value);
                }}
                placeholder={fm(GlobalMessages.search).toString()}
                InputProps={{
                    endAdornment: <Search fontSize="small" />,
                }}
            />
            <Stack height="calc(100vh - 350px)" overflow="scroll">
                <FieldSelectionList
                    handleSelect={handleSelect}
                    handleSelectAll={handleSelectAll}
                    selected={selected}
                    columns={columns}
                    orderedFields={orderedFields}
                />
            </Stack>
            <LoadingButton
                variant="contained"
                disabled={limitReached}
                loading={loading}
                onClick={() => onConfirm(selected, format)}
            >
                {fm(messages.start)}
            </LoadingButton>
        </Stack>
    );
};
