import { GraphqlRequestContainer } from "@ignite-analytics/graphql-utilities";
import { Add } from "@mui/icons-material";
import { Button, CardContent, Dialog, DialogTitle, Stack, Typography } from "@mui/material";
import React from "react";
import { createInputSourceItems } from "@/containers/PipelinePageV2/PipelineUtils";
import { fm } from "@/contexts/IntlContext";
import {
    DataPipeline,
    DataPipelineOperation,
    DataRepository,
    useCreatePipelineFilterCombinationMutation,
    useGetDataPipelineOperationsQuery,
    useGetDataRepositoryQuery,
} from "@/generated/client";
import messages from "./messages";
import PipelineFilterCombination from "./PipelineFilterCombination";

interface ContentProps {
    dataPipeline: DataPipeline;
    dataRepository: DataRepository;
    operations: DataPipelineOperation[];
}
const DataPipelineFilterModalContent: React.FC<ContentProps> = ({ dataPipeline, dataRepository, operations }) => {
    const inputSources = createInputSourceItems(operations, [dataRepository]);
    const [createFilterCombination] = useCreatePipelineFilterCombinationMutation({
        refetchQueries: ["getAllPendingChanges"],
    });
    return (
        <CardContent>
            <Stack gap={1}>
                <Typography>{fm(messages.helpText)}</Typography>
                {dataPipeline.dataFilterCombinations.map((fc, i) => (
                        <>
                            <PipelineFilterCombination
                                key={fc.id}
                                fcIndex={i}
                                filterCombination={fc}
                                sources={inputSources}
                            />
                            {i !== dataPipeline.dataFilterCombinations.length - 1 && (
                                <Stack direction="row" justifyContent="center">
                                    <Typography variant="h5">{fm(messages.or)}</Typography>
                                </Stack>
                            )}
                        </>
                    ))}
                <Stack width="300px">
                    <Button
                        startIcon={<Add />}
                        onClick={() => createFilterCombination({ input: { dataPipelineId: dataPipeline.id } })}
                        size="small"
                        variant="outlined"
                        color="secondary"
                    >
                        {fm(messages.addFilterCombination)}
                    </Button>
                </Stack>
            </Stack>
        </CardContent>
    );
};

interface ModalProps {
    dataPipeline: DataPipeline;
    onClose: () => void;
}
const DataPipelineFilterModal: React.FC<ModalProps> = ({ dataPipeline, onClose }) => {
    const { result: operationsResult } = useGetDataPipelineOperationsQuery(
        {
            input: { dataPipelineIds: [dataPipeline.id] },
        },
        { fetchPolicy: "no-cache" }
    );
    const { result: dataRepositoryResult } = useGetDataRepositoryQuery({
        input: { id: dataPipeline.sourceDataRepositoryIds[0] },
    });
    return (
        <Dialog open onClose={onClose} maxWidth="xl">
            <DialogTitle>{fm(messages.manageFilter)}</DialogTitle>
            <GraphqlRequestContainer asyncData={operationsResult}>
                {(operationsResponse) => (
                    <GraphqlRequestContainer asyncData={dataRepositoryResult}>
                        {(repositoryResponse) => (
                            <DataPipelineFilterModalContent
                                dataPipeline={dataPipeline}
                                dataRepository={repositoryResponse.dataRepository}
                                operations={operationsResponse.dataPipelineOperations}
                            />
                        )}
                    </GraphqlRequestContainer>
                )}
            </GraphqlRequestContainer>
        </Dialog>
    );
};

export default DataPipelineFilterModal;
