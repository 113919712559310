import React from "react";
import { useApolloClient } from "@apollo/client";
import { uncacheElasticFields } from "@ignite-analytics/elastic-fields";
import { useAllDataColumns } from "@/contexts/EventContexts/DataColumnEventContext";
import { useDataManagementProcessEventListener } from "@/contexts/EventContexts/DataManagementEventContext";
import { useEntityEventListener } from "@/contexts/EventContexts/EntityEventChangeContext";
import { useIndexMappingChangeProcessEventListener } from "@/contexts/EventContexts/IndexMappingChangeEventContext";
import { elasticIndexFromTableId } from "../Settings/ManageDataTablePage/RelativeDateSettings";

interface Props {
    children: React.ReactNode;
}

export const EnsureUpdatedElasticFieldsWrapper: React.FC<Props> = ({ children }) => {
    const dataColumns = useAllDataColumns();
    const client = useApolloClient();
    useIndexMappingChangeProcessEventListener((event) => {
        uncacheElasticFields(event.elasticIndexShort);
        client.cache.evict({ id: "ROOT_QUERY", fieldName: "getDataTableElasticFields" });
    });
    useEntityEventListener("DataColumn", (event) => {
        if (event.type === "DELETED") {
            client.cache.evict({ id: "ROOT_QUERY", fieldName: "getDataTableElasticFields" });

            const dataColumn = dataColumns.find((entity) => entity.id === event.id);
            if (!dataColumn) return;
            uncacheElasticFields(elasticIndexFromTableId(dataColumn.dataTableId));
        }
    });
    useDataManagementProcessEventListener("DataTableDataChange", (event) => {
        if (event.type === "PROCESS_FINISHED" && event.id) {
            uncacheElasticFields(elasticIndexFromTableId(event.id));
        }
    });
    return <>{children}</>;
};
