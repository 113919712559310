import React, { useState } from "react";
import { Checkbox, Stack, Table, TableBody, TableCell, TableRow, Typography } from "@mui/material";
import { DataColumn } from "@/Types/DataColumn";
import { cubeBuilderPageTestIdPrefix } from "@/containers/CubeBuilderPage";
import { useChangedConnectionsContext } from "@/containers/CubeBuilderPage/context";
import { SelectableCubeConfigurationField } from "@/containers/CubeBuilderPage/types";
import { fm } from "@/contexts/IntlContext";
import {
    DataCubeConfiguration,
    DataTableConnection,
    useAddOrRemoveFieldsFromDataCubeConfigurationMutation,
} from "@/generated/client";
import { createIsFieldOnCurrentTable } from "./helpers";
import messages from "./messages";

interface Props {
    relatedDataColumnId?: string;
    connection?: DataTableConnection;
    searchString: string;
    base?: boolean;
    pipelineOperationOutputColumn?: DataColumn;
    baseDataCubeConfiguration: DataCubeConfiguration;
    selectableCubeConfigurationFields: SelectableCubeConfigurationField[];
}

const extractPostfix = (field: string) => field.replace(/data_column_[0-9]+|-/g, "").slice(1);

const filterSearch = (field: SelectableCubeConfigurationField, searchString: string) =>
    searchString !== "" ? field.label.toUpperCase().includes(searchString.toUpperCase()) : true;

const POSTFIXES_TO_EXCLUDE = ["precedence", "rulePrecedence", "ruleId", "original_id"];

export const NodeTable: React.FC<Props> = ({
    relatedDataColumnId,
    base,
    connection,
    pipelineOperationOutputColumn,
    searchString,
    baseDataCubeConfiguration,
    selectableCubeConfigurationFields,
}) => {
    const [updatingFields, setUpdatingFields] = useState<
        {
            field: string;
            add: boolean;
        }[]
    >([]);

    const [, dispatch] = useChangedConnectionsContext();

    const [updateBaseTableConfig] = useAddOrRemoveFieldsFromDataCubeConfigurationMutation();

    const updateDataCubeConfiguration = async (fields: string[], add: boolean) => {
        setUpdatingFields((prev) => prev.concat(fields.map((field) => ({ field, add }))));
        await updateBaseTableConfig({
            input: {
                addOrRemove: add ? "ADD" : "REMOVE",
                dataTableId: baseDataCubeConfiguration.dataTableId,
                fields,
            },
        });
        setUpdatingFields((prev) => prev.filter(({ field }) => !fields.includes(field)));

        if (connection?.dataColumnId) {
            dispatch({ type: "INCLUDED_FIELDS_CHANGE", columnsWithChangedCubeConfiguratioId: connection.dataColumnId });
        } else if (pipelineOperationOutputColumn) {
            dispatch({
                type: "INCLUDED_FIELDS_CHANGE",
                columnsWithChangedCubeConfiguratioId: pipelineOperationOutputColumn.id,
            });
        }
    };

    const handleCheck = async (field: string, add: boolean, labelField?: string) => {
        await updateDataCubeConfiguration(labelField ? [field, labelField] : [field], add);
    };

    const handleCheckAll = (fields: SelectableCubeConfigurationField[], add: boolean) => {
        const fieldStrings = fields
            .map((field) => {
                if (field.labelField) return [field.field, field.labelField];
                return [field.field];
            })
            .flat();

        updateDataCubeConfiguration(fieldStrings, add);
    };

    const fieldsToExclude = updatingFields.filter(({ add }) => !add).map(({ field }) => field);
    const fieldsToAdd = updatingFields.filter(({ add }) => add).map(({ field }) => field);
    const fields = baseDataCubeConfiguration.fieldsToInclude
        .filter((field) => !fieldsToExclude.includes(field))
        .concat(fieldsToAdd);

    const selectableFieldsOnCurrentTable = selectableCubeConfigurationFields
        .filter(({ field }) => !POSTFIXES_TO_EXCLUDE.includes(extractPostfix(field)))
        .filter(createIsFieldOnCurrentTable(relatedDataColumnId));

    return (
        <Table size="small" className="nowheel">
            <TableBody>
                {!base && (
                    <TableRow>
                        <TableCell>
                            <Stack direction="row" alignItems="center">
                                <Checkbox
                                    name="include field"
                                    checked={selectableFieldsOnCurrentTable.every((field) =>
                                        fields.includes(field.field)
                                    )}
                                    onChange={(e) => handleCheckAll(selectableFieldsOnCurrentTable, e.target.checked)}
                                    data-testid={`${cubeBuilderPageTestIdPrefix}-include-field-in-connection-checkbox`}
                                />
                                <Typography color="primary.dark" variant="buttonSmall">
                                    {fm(messages.selectAll)}
                                </Typography>
                            </Stack>
                        </TableCell>
                    </TableRow>
                )}
                {selectableFieldsOnCurrentTable
                    .filter((field) => filterSearch(field, searchString))
                    .map((field) => (
                        <TableRow key={field.field}>
                            <TableCell>
                                <Stack direction="row" alignItems="center">
                                    {(!base || !fields.includes(field.field)) && (
                                        <Checkbox
                                            disabled={updatingFields.some(
                                                (updatingField) => field.field === updatingField.field
                                            )}
                                            name="include field"
                                            checked={fields.includes(field.field)}
                                            onChange={(e) =>
                                                handleCheck(
                                                    field.field,
                                                    e.target.checked,
                                                    field.labelField ?? undefined
                                                )
                                            }
                                            data-testid={`${cubeBuilderPageTestIdPrefix}-include-field-in-connection-checkbox`}
                                        />
                                    )}
                                    <Typography>{field.label}</Typography>
                                </Stack>
                            </TableCell>
                        </TableRow>
                    ))}
            </TableBody>
        </Table>
    );
};
