import { Stack, Typography } from "@mui/material";
import React from "react";

interface Props {
    data: {
        name: string;
    };
}

export const TextBoxNode = ({ data }: Props) => (
        <Stack minWidth="150px" maxWidth="150px">
            <Stack direction="row" minHeight="20px" maxHeight="20px" justifyContent="center">
                <Typography noWrap>{data.name}</Typography>
            </Stack>
        </Stack>
    );
