import { GraphqlRequestContainer } from "@ignite-analytics/graphql-utilities";
import { List, Visibility } from "@mui/icons-material";
import { Button, Stack, Tooltip } from "@mui/material";
import React, { useState } from "react";
import { useDataColumnsByDataTableId } from "@/contexts/EventContexts/DataColumnEventContext";
import { useEntityEventListener } from "@/contexts/EventContexts/EntityEventChangeContext";
import { useGetPipelinesByTableId } from "@/contexts/EventContexts/PipelineContext";
import { fm } from "@/contexts/IntlContext";
import {
    GetAllPendingChangesResponse,
    PendingChange,
    useGetAllDataRepositoriesQuery,
    useGetAllPendingChangesQuery,
    useGetDataPipelineOperationsQuery,
    useGetImportsQuery,
} from "@/generated/client";
import InProgressChangesDialog from "../InProgressChangesDialog";
import { UnappliedChangesDialog } from "../UnappliedChangesDialog";
import { getImportIdsFromChanges, mapApiToFrontendChanges } from "./helpers";
import messages from "./messages";
import { StyledBadge } from "./style";

export interface Props {
    dataTableId: string;
    pendingChanges: PendingChange[];
}

export const getCombinedCount = (changes: GetAllPendingChangesResponse) => changes.pendingChanges.length;

export const ApplyChangesButton: React.FC<Props> = ({ dataTableId, pendingChanges }) => {
    const dataColumns = useDataColumnsByDataTableId(dataTableId);
    const dataPipelines = useGetPipelinesByTableId(dataTableId);

    const unappliedChanges = pendingChanges
        .filter((pc) => dataPipelines.map((dp) => dp.id).includes(pc.dataPipelineId))
        .filter((pc) => !pc.isBeingProcessed);
    const inProgressChanges = pendingChanges
        .filter((pc) => dataPipelines.map((dp) => dp.id).includes(pc.dataPipelineId))
        .filter((pc) => pc.isBeingProcessed);

    const [openInProgressChanges, setOpenInProgressChanges] = useState(false);
    const [openApplyChanges, setOpenApplyChanges] = useState(false);

    const { result: operationsResult } = useGetDataPipelineOperationsQuery({
        input: { dataPipelineIds: dataPipelines.map((dp) => dp.id) },
    });

    const importIds = getImportIdsFromChanges(inProgressChanges);
    const { result: importsResult } = useGetImportsQuery({
        input: {
            ids: Array.from(importIds),
        },
    });
    const { result: dataRepositoriesResult } = useGetAllDataRepositoriesQuery({});
    return (
        <>
            <Stack direction="row" gap={2}>
                {inProgressChanges.length > 0 ? (
                    <StyledBadge badgeContent={inProgressChanges.length} max={9} color="warning">
                        <Button
                            onClick={() => setOpenInProgressChanges(true)}
                            sx={{ maxHeight: 30 }}
                            color="secondary"
                            variant="outlined"
                            size="small"
                            startIcon={<List />}
                        >
                            {fm(messages.inProcessChangesButton)}
                        </Button>
                    </StyledBadge>
                ) : null}
                <Tooltip
                    title={
                        unappliedChanges.length > 0
                            ? fm(messages.changesButtonTooltip)
                            : fm(messages.noChangesButtonTooltip)
                    }
                >
                    <StyledBadge badgeContent={unappliedChanges.length} max={9} color="warning">
                        <Button
                            onClick={() => setOpenApplyChanges(true)}
                            color="success"
                            size="small"
                            startIcon={<Visibility />}
                            disabled={unappliedChanges.length === 0}
                        >
                            {fm(messages.changesButton)}
                        </Button>
                    </StyledBadge>
                </Tooltip>
            </Stack>
            <GraphqlRequestContainer asyncData={operationsResult}>
                {({ dataPipelineOperations }) => (
                    <GraphqlRequestContainer asyncData={importsResult}>
                        {({ imports }) => (
                            <GraphqlRequestContainer asyncData={dataRepositoriesResult}>
                                {({ dataRepositories }) => (
                                    <>
                                        <UnappliedChangesDialog
                                            open={openApplyChanges}
                                            onClose={() => setOpenApplyChanges(false)}
                                            dataPipelines={dataPipelines}
                                            changes={mapApiToFrontendChanges(
                                                unappliedChanges,
                                                dataRepositories,
                                                dataPipelines,
                                                dataPipelineOperations,
                                                dataColumns,
                                                imports
                                            )}
                                        />
                                        <InProgressChangesDialog
                                            open={openInProgressChanges}
                                            onClose={() => setOpenInProgressChanges(false)}
                                            inProgressChanges={mapApiToFrontendChanges(
                                                inProgressChanges,
                                                dataRepositories,
                                                dataPipelines,
                                                dataPipelineOperations,
                                                dataColumns,
                                                imports
                                            )}
                                        />
                                    </>
                                )}
                            </GraphqlRequestContainer>
                        )}
                    </GraphqlRequestContainer>
                )}
            </GraphqlRequestContainer>
        </>
    );
};

export const ApplyChangesButtonContainer: React.FC<{ dataTableId: string }> = ({ dataTableId }) => {
    const { result, refetch } = useGetAllPendingChangesQuery({ input: {} }, { fetchPolicy: "network-only" });

    useEntityEventListener("PendingChange", () => refetch());

    return (
        <GraphqlRequestContainer asyncData={result}>
            {({ pendingChanges }) => <ApplyChangesButton dataTableId={dataTableId} pendingChanges={pendingChanges} />}
        </GraphqlRequestContainer>
    );
};

export default ApplyChangesButtonContainer;
