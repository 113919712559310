import { defineMessages } from "react-intl";

const messages = defineMessages({
    dataTablesPageHeader: {
        id: "dataTables.pipelinePageV2.header",
        defaultMessage: "Select table",
    },
    input: {
        id: "dataTables.pipelinePageV2.input",
        defaultMessage: "Input",
    },
    bundled: {
        id: "dataTables.pipelinePageV2.bundled",
        defaultMessage: "Bundle",
    },
    nOperations: {
        id: "dataTables.pipelinePageV2.nOperations",
        defaultMessage: "{n} operations",
    },
    singleOperation: {
        id: "dataTables.pipelinePageV2.singleOperation",
        defaultMessage: "1 operation",
    },
    bundleMode: {
        id: "dataTables.pipelinePageV2.bundleMode",
        defaultMessage: "Bundle mode",
    },
    dataRepository: {
        id: "dataTables.pipelinePageV2.dataRepository",
        defaultMessage: "Data source",
    },
    emptyPrompt1: {
        id: "dataTables.pipelinePageV2.emptyPrompt1",
        defaultMessage: "Drop operations here",
    },
    emptyPrompt2: {
        id: "dataTables.pipelinePageV2.emptyPrompt2",
        defaultMessage: "to build your flow",
    },
    searchPlaceholder: {
        id: "dataTables.pipelinePageV2.toolbar.searchPlaceholder",
        defaultMessage: "Search canvas",
    },
});

export default messages;
