import { AggregationType } from "@/generated/client";

export const PossibleAggregations = [
    "AGGREGATION_SUM",
    "AGGREGATION_AVERAGE",
    "AGGREGATION_MINIMUM",
    "AGGREGATION_MAXIMUM",
    "AGGREGATION_COUNT",
    "AGGREGATION_COUNT_UNIQUE",
] as const;
export function isPossibleForAggregationType(aggregationType: AggregationType) {
    return (field: { type: string; field: string }) => {
        switch (aggregationType) {
            case "AGGREGATION_SUM":
            case "AGGREGATION_AVERAGE":
                return ["float"].includes(field.type);
            case "AGGREGATION_MINIMUM":
            case "AGGREGATION_MAXIMUM":
                return ["date", "float"].includes(field.type);
            case "AGGREGATION_COUNT":
                return ["keyword", "float", "date"].includes(field.type);
            case "AGGREGATION_COUNT_UNIQUE":
                return ["keyword"].includes(field.type);
        }
    };
}
