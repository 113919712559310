import { useFeatureToggle } from "@ignite-analytics/feature-toggle";
import { SUPPLIER_TABLE_GT } from "@ignite-analytics/global-types";
import { GraphqlRequestContainer } from "@ignite-analytics/graphql-utilities";
import { track } from "@ignite-analytics/track";
import { Autocomplete, Checkbox, FormControlLabel, Stack, TextField, Tooltip, Typography } from "@mui/material";
import React, { useState } from "react";
import { DataColumn } from "@/Types/DataColumn";
import { NotificationModal } from "@/components/NotificationModal";
import Prompt from "@/components/Prompt";
import { fm } from "@/contexts/IntlContext";
import { DataColumnType, DataTable, useReplaceDataColumnWithNewDataTypeMutation } from "@/generated/client";
import { formatDataColumnType } from "@/helpers";
import globalMessages from "@/messages";
import messages from "./messages";

export const COLUMN_TYPES_ALLOWED_TO_EDIT: DataColumnType[] = ["BOOLEAN", "DATE", "NUMBER", "TEXT"];

export const EditDataType: React.FC<{
    dataColumn: DataColumn;
    setEditState?: React.Dispatch<React.SetStateAction<boolean>>;
    hide?: boolean;
    showLabel?: boolean;
    dataTable: DataTable;
}> = ({ dataColumn, setEditState, hide, showLabel, dataTable }) => {
    const canEdit = useFeatureToggle("edit-data-types");
    const isSupplierTable = dataTable.globalTypeKey === SUPPLIER_TABLE_GT;

    const [updateColumn, mutation] = useReplaceDataColumnWithNewDataTypeMutation();
    const [changeModal, setChangeModal] = useState(false);
    const [newDataType, setNewDataType] = useState<DataColumnType | null>(dataColumn.dataType);
    const [makeList, setMakeList] = useState<boolean | undefined>(dataColumn.isList);

    const [notificationIds, setNotificationIds] = useState<string[]>();
    if (!canEdit || hide) return <Typography variant="body2">{formatDataColumnType(dataColumn)}</Typography>;

    const handleUpdate = async () => {
        if (!(newDataType || makeList !== undefined)) return;
        const result = await updateColumn({
            input: {
                dataColumnId: dataColumn.id,
                dataTableId: dataColumn.dataTableId,
                newDataType: newDataType || dataColumn.dataType,
                toList: makeList ?? dataColumn.isList,
            },
        });
        track("Pipeline: Updated Data Column", { type: dataColumn.dataType });
        setNotificationIds(result?.data?.replaceDataColumnWithNewDataType.notificationIds);
        setChangeModal(false);
    };

    const handleChange = (value: DataColumnType | null) => {
        setNewDataType(value);
        setChangeModal(true);
    };

    const handleMakeList = (value: boolean) => {
        setMakeList(value);
        setChangeModal(true);
    };

    const disabled = !COLUMN_TYPES_ALLOWED_TO_EDIT.includes(dataColumn.dataType) || isSupplierTable;
    const tooltipMsg = isSupplierTable ? messages.supplierTable : messages.notPrimitive;

    return (
        <>
            <Tooltip title={disabled ? fm(tooltipMsg) : undefined}>
                <Stack direction="row" gap={1}>
                    <Autocomplete
                        fullWidth
                        options={COLUMN_TYPES_ALLOWED_TO_EDIT}
                        id="firstName"
                        size="small"
                        disabled={disabled}
                        sx={{ width: "250px" }}
                        value={newDataType}
                        onChange={(_, value) => handleChange(value)}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                placeholder={formatDataColumnType(dataColumn)}
                                label={showLabel ? fm(globalMessages.dataType) : undefined}
                            />
                        )}
                    />

                    {!dataColumn.isList && (
                        <FormControlLabel
                            control={
                                <Checkbox
                                    disabled={disabled}
                                    checked={makeList ?? dataColumn.isList}
                                    onChange={(_, value) => handleMakeList(value)}
                                />
                            }
                            label="Make list"
                        />
                    )}
                </Stack>
            </Tooltip>
            <Prompt
                open={changeModal}
                isProcessing={mutation.result.type === "loading"}
                onAccept={handleUpdate}
                description={fm(messages.description).toString()}
                onClose={() => {
                    setChangeModal(false);
                    setNewDataType(dataColumn.dataType);
                    setMakeList(undefined);
                }}
            >
                <GraphqlRequestContainer asyncData={mutation.result} />
            </Prompt>
            {notificationIds && (
                <NotificationModal
                    open={notificationIds !== undefined}
                    onClose={() => setEditState?.(false)}
                    notificationIds={notificationIds}
                    title={fm(messages.changingType, { newDataType: makeList ?? newDataType }).toString()}
                />
            )}
        </>
    );
};
