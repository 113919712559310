import { defineMessages } from "react-intl";

const messages = defineMessages({
    emissionsHeader: {
        id: "emissionsTableCell.emissionsHeader",
        defaultMessage: "Emissions",
    },
    emissions: {
        id: "emissionsTableCell.emissions",
        defaultMessage: "{emissions} tCO₂e",
    },
    categoryHeader: {
        id: "emissionsTableCell.categoryHeader",
        defaultMessage: "Exiobase category",
    },
    regionHeader: {
        id: "emissionsTableCell.regionHeader",
        defaultMessage: "Region",
    },
    yearHeader: {
        id: "emissionsTableCell.yearHeader",
        defaultMessage: "Year",
    },
    emissionFactorHeader: {
        id: "emissionsTableCell.emissionFactorHeader",
        defaultMessage: "Emission factor",
    },
    emissionFactor: {
        id: "emissionsTableCell.emissionFactor",
        defaultMessage: "{emissionFactor} kgCO₂e/{currency}",
    },
});

export default messages;
