import { GraphqlRequestContainer } from "@ignite-analytics/graphql-utilities";
import WarningIcon from "@mui/icons-material/Warning";
import { Stack, Typography } from "@mui/material";
import React from "react";
import { DataColumn } from "@/Types/DataColumn";
import { fm } from "@/contexts/IntlContext";
import { useGetDataColumnDependenciesQuery } from "@/generated/client";
import messages from "./messages";

interface Props {
    dataColumn: DataColumn;
    number: number;
}
const DataColumnDeleteInfoItem: React.FC<Props> = ({ dataColumn, number }) => {
    const { result: dataColumnDependenciesResult } = useGetDataColumnDependenciesQuery({
        input: { dataColumnId: dataColumn.id },
    });
    const text = `${number}: ${dataColumn.name}`;
    return (
        <Stack direction="row">
            <GraphqlRequestContainer asyncData={dataColumnDependenciesResult}>
                {(dataColumnDependenciesResponse) => dataColumnDependenciesResponse.hasDeps ? (
                        <Stack direction="row" gap={2} alignItems="center">
                            <Typography>{text}</Typography>
                            <WarningIcon color="error" fontSize="small" />
                            <Typography color="error">{dataColumnDependenciesResponse.messageToUser}</Typography>
                        </Stack>
                    ) : (
                        <Stack direction="row" gap={2}>
                            <Typography>{text}</Typography>
                            <Typography>{fm(messages.noColumnDependencies)}</Typography>
                        </Stack>
                    )}
            </GraphqlRequestContainer>
        </Stack>
    );
};

export default DataColumnDeleteInfoItem;
