import { defineMessages } from "react-intl";

const messages = defineMessages({
    startAllPipelineButtonLabel: {
        id: "uploadPage.startAllPipelines.startAllPipelineButtonLabel",
        defaultMessage: "Run all pipelines",
    },
    runningPipelinesMessage: {
        id: "uploadPage.startAllPipelines.runningPipelines",
        defaultMessage: "Running all pipelines in flow",
    },
});

export default messages;
