import axios from "axios";
import { fm } from "@/contexts/IntlContext";
import { useCreateUploadUrlsMutation, useUpdateFilesUploadStatusMutation } from "@/generated/client";
import messages from "./messages";

const MAX_FILE_SIZE = 200_000_000;

export class FileSizeError extends Error {
    constructor(message: string) {
        super();
        this.name = FileSizeError.name;
        this.message = message;
    }
}

const useFileManager = (serviceId: string) => {
    const [createUploadUrls] = useCreateUploadUrlsMutation();
    const [updateFilesUploadStatusMutation] = useUpdateFilesUploadStatusMutation();

    const createUploadUrl = async (file: File): Promise<{ name: string; url: string; id: string }> => {
        const uploadUrlsObjects = await createUploadUrls({
            input: {
                serviceId,
                files: [{ fileName: file.name, fileSize: file.size.toString(), fileType: file.type }],
            },
        });
        const uploadUrl = uploadUrlsObjects.data?.createUploadUrls.urls[0];
        if (!uploadUrl) throw new Error("Something went wrong");
        return {
            name: uploadUrl.fileName,
            url: uploadUrl.url,
            id: uploadUrl.fileId,
        };
    };

    const uploadFile = async (file: File, onProgress: (progress: number) => void) => {
        if (file.size > MAX_FILE_SIZE) {
            return {
                error: new FileSizeError(
                    fm(messages.maxFileSizeError, {
                        fileSize: Math.round(file.size / 10 ** 6),
                        maxSize: 200,
                    }).toString()
                ),
            };
        }
        try {
            const fileInformation = await createUploadUrl(file);
            await axios({
                method: "PUT",
                url: fileInformation.url,
                headers: { "Content-Type": file.type },
                data: file,
                onUploadProgress: (progressEvent: ProgressEvent) => {
                    onProgress(Math.round((progressEvent.loaded * 100) / progressEvent.total));
                },
            });

            await updateFilesUploadStatusMutation({
                input: {
                    files: [{ fileId: fileInformation.id || "", uploadStatus: "SUCCESS" }],
                },
            });
            return { data: fileInformation };
        } catch (error) {
            if (error instanceof Error) {
                return { error };
            }
            return { error: new Error("Something went wrong") };
        }
    };

    return {
        uploadFile,
    };
};

export default useFileManager;
