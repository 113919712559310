import { isNotNullish } from "@ignite-analytics/general-tools";
import { groupBy } from "lodash-es";
import { CoordinateExtent, Node } from "reactflow";
import {
    DataPipeline,
    DataPipelineLayout,
    DataPipelineOperation,
    DataRepository,
    DataTable,
    OperationType,
} from "@/generated/client";
import { BUNDLE_HEIGHT, INPUT_NODE_SPACING, OPERATION_NODE_IMPLEMENTATION, X_PADDING } from "./constants";
import { DataTableNodeProps, OperationNodeProps, RepositoryNodeProps, TextBoxNodeProps } from "./nodeTypes";

export const getOperationNodeWidth = (operationType: OperationType) => {
    const NAME_TO_WIDTH_FACTOR = 10;
    return OPERATION_NODE_IMPLEMENTATION[operationType].name.toString().length * NAME_TO_WIDTH_FACTOR;
};

export const createUnionNodeId = (dataPipelineId: string) => `${dataPipelineId}-union`;
export const createBundleId = (id: string) => `${id}_bundle`;

const getLayoutObject = (pipelineLayout?: DataPipelineLayout) => {
    if (!pipelineLayout) return {};
    const parsedLayout = JSON.parse(pipelineLayout.layoutJson);
    return typeof parsedLayout === "object" ? parsedLayout : {};
};

const extractCoordinatesFromLayout: (
    nodeId: string,
    layout?: Record<string, { x: number; y: number }>
) => { x: number; y: number } | undefined = (nodeId, layout) =>
    layout && nodeId in layout && "x" in layout[nodeId] && "y" in layout[nodeId]
        ? { x: layout[nodeId].x, y: layout[nodeId].y }
        : undefined;

const getRepositoriesUsedInJoinByPipeline = (
    dataPipeline: DataPipeline,
    operationsByPipeline: Record<string, DataPipelineOperation[]>
) =>
    dataPipeline.id in operationsByPipeline
        ? operationsByPipeline[dataPipeline.id]
              .filter((op) => op.operationType === "LOOKUP")
              .map((op) => op.lookupOperationAdapter?.dataRepositoryId)
              .filter(isNotNullish)
        : [];

const getXSpacingForRepositories = (
    dataPipeline: DataPipeline,
    operationsByPipeline: Record<string, DataPipelineOperation[]>
) => {
    const averageOperationNodeWidthForPipeline =
        dataPipeline.id in operationsByPipeline
            ? operationsByPipeline[dataPipeline.id].reduce(
                  (sum, current) => sum + getOperationNodeWidth(current.operationType),
                  50
              ) / operationsByPipeline[dataPipeline.id].length
            : 0;
    return averageOperationNodeWidthForPipeline > 175 ? averageOperationNodeWidthForPipeline : 175;
};

const getOperationIndex = (
    dataPipeline: DataPipeline,
    operationsByPipeline: Record<string, DataPipelineOperation[]>,
    dataRepositoryId: string
) =>
    dataPipeline.id in operationsByPipeline
        ? operationsByPipeline[dataPipeline.id].findIndex(
              (operation) => operation.lookupOperationAdapter?.dataRepositoryId === dataRepositoryId
          )
        : -1;

const incrementCounter = (
    joinRepositoryIdsInPipeline: string[],
    allSourceRepositoryIds: string[],
    increasedJoinLevelFlag: boolean,
    joinLevelCounter: number
) => {
    if (
        joinRepositoryIdsInPipeline.some((id) => !allSourceRepositoryIds.includes(id)) &&
        !!joinRepositoryIdsInPipeline.length &&
        !increasedJoinLevelFlag
    ) {
        return joinLevelCounter + 1;
    }
    return joinLevelCounter;
};

const getIncreasedJoinLevelFlag = (
    joinRepositoryIdsInPipeline: string[],
    allSourceRepositoryIds: string[],
    increasedJoinLevelFlag: boolean
) => {
    if (
        joinRepositoryIdsInPipeline.some((id) => !allSourceRepositoryIds.includes(id)) &&
        !!joinRepositoryIdsInPipeline.length &&
        !increasedJoinLevelFlag
    ) {
        return true;
    }
    return increasedJoinLevelFlag;
};

const shouldRenderRepositoryNode = (
    dataRepositoryId: string,
    renderedIds: string[],
    allSourceRepositoryIds: string[],
    joinOperationRepositories: (string | undefined)[]
) =>
    renderedIds.includes(dataRepositoryId) ||
    (allSourceRepositoryIds.includes(dataRepositoryId) && joinOperationRepositories.includes(dataRepositoryId));

const getRepositoryNodeWithTextbox = (
    dataRepository: DataRepository,
    dataPipeline: DataPipeline,
    position: { x: number; y: number } | undefined,
    operationIndex: number,
    xSpacing: number,
    y: number
) => [
    {
        id: dataRepository.id,
        type: "repositoryNode",
        data: {
            dataRepository,
        },
        position: position || {
            x: operationIndex === -1 ? 0 : (operationIndex + 1) * xSpacing,
            y,
        },
    },
    {
        id: `${dataRepository.id}-textBox`,
        type: "textBoxNode",
        data: {
            name: dataRepository.name,
        },
        position: {
            x: 0,
            y: 85,
        },
        parentNode: dataRepository.id,
        draggable: false,
    },
];

const createRepositoryNodes: (
    dataPipelines: DataPipeline[],
    dataRepositories: DataRepository[],
    operationsByPipeline: Record<string, DataPipelineOperation[]>,
    layout?: Record<string, { x: number; y: number }>
) => Node<RepositoryNodeProps | TextBoxNodeProps>[] = (
    dataPipelines,
    dataRepositories,
    operationsByPipeline,
    layout
) => {
    const renderedIds: string[] = [];
    const allSourceRepositoryIds = dataPipelines.map((pipeline) => pipeline.sourceDataRepositoryIds).flat();
    // Counter used to ensure nodes are rendered at the correct level
    let joinLevelCounter = 0;

    return dataPipelines
        .map((dataPipeline, i) => {
            const joinRepositoryIdsInPipeline = getRepositoriesUsedInJoinByPipeline(dataPipeline, operationsByPipeline);
            const xSpacing = getXSpacingForRepositories(dataPipeline, operationsByPipeline);
            const repositoriesInPipeline = [...dataPipeline.sourceDataRepositoryIds, ...joinRepositoryIdsInPipeline];
            let increasedJoinLevelFlag = false;

            return repositoriesInPipeline
                .map((dataRepositoryId) => {
                    if (
                        shouldRenderRepositoryNode(
                            dataRepositoryId,
                            renderedIds,
                            allSourceRepositoryIds,
                            joinRepositoryIdsInPipeline
                        )
                    )
                        return undefined;

                    renderedIds.push(dataRepositoryId);

                    const operationIndex = getOperationIndex(dataPipeline, operationsByPipeline, dataRepositoryId);
                    const dataRepositoryObject = dataRepositories.find(
                        (repository) => repository.id === dataRepositoryId
                    );
                    const y = (i + joinLevelCounter) * INPUT_NODE_SPACING;

                    joinLevelCounter = incrementCounter(
                        joinRepositoryIdsInPipeline,
                        allSourceRepositoryIds,
                        increasedJoinLevelFlag,
                        joinLevelCounter
                    );
                    increasedJoinLevelFlag = getIncreasedJoinLevelFlag(
                        joinRepositoryIdsInPipeline,
                        allSourceRepositoryIds,
                        increasedJoinLevelFlag
                    );
                    if (!dataRepositoryObject) return undefined;
                    const position = extractCoordinatesFromLayout(dataRepositoryId, layout);
                    return getRepositoryNodeWithTextbox(
                        dataRepositoryObject,
                        dataPipeline,
                        position,
                        operationIndex,
                        xSpacing,
                        y
                    );
                })
                .flat();
        })
        .flat()
        .filter(isNotNullish);
};

const createDataTableNode: (
    dataTable: DataTable,
    operationsByPipeline: Record<string, DataPipelineOperation[]>,
    dataPipelines: DataPipeline[],
    layout?: Record<string, { x: number; y: number }>
) => Node<DataTableNodeProps | TextBoxNodeProps>[] = (dataTable, operationsByPipeline, dataPipelines, layout) => {
    const numberOfRepositoryNodes = dataPipelines.reduce((acc, curr) => acc + curr.sourceDataRepositoryIds.length, 0);
    let longestPipeline = 0;
    const approximatelyVerticalAvergaOfAllInputModes = ((numberOfRepositoryNodes - 1) / 2) * INPUT_NODE_SPACING;
    Object.entries(operationsByPipeline).forEach(([, pipelineOps]) => {
        const widthOfOperations = pipelineOps.reduce((acc, op) => acc + getOperationNodeWidth(op.operationType), 0);
        const totalPaddingBetweenOperations = (pipelineOps.length + 1) * X_PADDING;
        const currentPipeLineWidth = widthOfOperations + totalPaddingBetweenOperations;
        if (longestPipeline < currentPipeLineWidth) longestPipeline = currentPipeLineWidth;
    });
    const position = extractCoordinatesFromLayout(dataTable.id, layout);
    return [
        {
            id: dataTable.id,
            type: "dataTableNode",
            data: {
                dataTable,
            },
            position: position || {
                x: longestPipeline + 800,
                y: approximatelyVerticalAvergaOfAllInputModes,
            },
        },
        {
            id: `${dataTable.id}-textBox`,
            type: "textBoxNode",
            data: {
                name: dataTable.name,
            },
            position: {
                x: 0,
                y: 85,
            },
            parentNode: dataTable.id,
            draggable: false,
        },
    ];
};

export const createUnionNode: (
    operations: DataPipelineOperation[],
    dataPipeline: DataPipeline,
    dataRepositories: DataRepository[],
    bundleEndX: number,
    bundleEndY: number,
    pipelineLength: number,
    bundledParent: boolean,
    dataTable?: DataTable,
    layout?: Record<string, { x: number; y: number }>
) => Node = (
    operations,
    dataPipeline,
    dataRepositories,
    bundleEndX,
    bundleEndY,
    pipelineLength,
    bundledParent,
    dataTable,
    layout
) => {
    const id = bundledParent ? createBundleId(createUnionNodeId(dataPipeline.id)) : createUnionNodeId(dataPipeline.id);
    const startX = bundledParent ? bundleEndX + pipelineLength : bundleEndX + 150;
    const position = extractCoordinatesFromLayout(id, layout);
    return {
        id,
        type: "unionNode",
        data: {
            operations,
            dataPipeline,
            dataRepositories,
            dataTable,
        },
        position: position || {
            x: startX,
            y: bundleEndY + BUNDLE_HEIGHT / 4,
        },
    };
};

const createBundleWithUnionNodes = (
    operationsByPipeline: Record<string, DataPipelineOperation[]>,
    dataPipelines: DataPipeline[],
    dataRepositories: DataRepository[],
    bundledPipelineIds: string[],
    setbundledPipelineIds: (pipelineIds: string[]) => void,
    dataTable?: DataTable,
    layout?: Record<string, { x: number; y: number }>
) => {
    let numberOfRepositoryNodes = 0;
    const allSourceRepositoryIds = dataPipelines.map((pipeline) => pipeline.sourceDataRepositoryIds).flat();
    let unionLevelCounter = 0;
    return dataPipelines
        .map((pipeline) => {
            const joinRepositoryIdsInPipeline =
                pipeline.id in operationsByPipeline
                    ? operationsByPipeline[pipeline.id]
                          .filter((op) => op.operationType === "LOOKUP")
                          .map((op) => op.lookupOperationAdapter?.dataRepositoryId)
                          .filter(isNotNullish)
                    : [];
            const operations = operationsByPipeline[pipeline.id] || [];
            numberOfRepositoryNodes += pipeline.sourceDataRepositoryIds.length;
            const BUNDLE_START = 300;
            const middleOfOwnRrepositories = -0.16 * BUNDLE_HEIGHT;
            const verticalDisplacement =
                (numberOfRepositoryNodes - pipeline.sourceDataRepositoryIds.length + unionLevelCounter) *
                INPUT_NODE_SPACING;

            const bundleEnd = !bundledPipelineIds.includes(pipeline.id)
                ? BUNDLE_START +
                  operations.reduce((acc, op) => acc + getOperationNodeWidth(op.operationType), 0) +
                  (operations.length + 1) * X_PADDING
                : BUNDLE_HEIGHT * 3 + 5;
            const bundleWidth = bundledPipelineIds.includes(pipeline.id)
                ? BUNDLE_HEIGHT
                : Math.max(
                      operations.reduce((acc, op) => acc + getOperationNodeWidth(op.operationType), 0) +
                          (operations.length + 1) * X_PADDING,
                      BUNDLE_HEIGHT + 5
                  );
            const style = bundledPipelineIds.includes(pipeline.id)
                ? {
                      width: bundleWidth,
                      height: BUNDLE_HEIGHT / 2,
                  }
                : {
                      width: bundleWidth,
                      height: BUNDLE_HEIGHT,
                      borderColor: "gray",
                      borderStyle: operations.length ? "dashed" : undefined,
                      borderRadius: "5px",
                      borderWidth: "2px",
                  };
            const isBundled = bundledPipelineIds.includes(pipeline.id);
            const id = isBundled ? createBundleId(pipeline.id) : pipeline.id;
            const position = extractCoordinatesFromLayout(id, layout);
            const bundleEndX = isBundled ? bundleEnd + BUNDLE_HEIGHT / 2 - 95 : bundleEnd + BUNDLE_HEIGHT / 2;
            const y = verticalDisplacement + middleOfOwnRrepositories;
            // Increase counter if pipeline has repositories that is only used in joins
            if (joinRepositoryIdsInPipeline.some((repository) => !allSourceRepositoryIds.includes(repository))) {
                unionLevelCounter += 1;
            }
            return [
                {
                    id,
                    type: "bundleNode",
                    data: {
                        dataPipeline: pipeline,
                        operations,
                        bundledPipelines: dataPipelines.filter((dataPipeline) =>
                            bundledPipelineIds.includes(dataPipeline.id)
                        ),
                        setbundledPipelines: setbundledPipelineIds,
                    },
                    position: position || {
                        x: BUNDLE_START,
                        y,
                    },
                    style,
                },
                createUnionNode(
                    operations,
                    pipeline,
                    dataRepositories,
                    bundleEndX,
                    verticalDisplacement + middleOfOwnRrepositories,
                    bundleWidth,
                    isBundled,
                    dataTable,
                    layout
                ),
            ];
        })
        .flat();
};

const createOperationNodes: (
    operationsByPipeline: Record<string, DataPipelineOperation[]>,
    dataPipelines: DataPipeline[],
    bundledPipelineIds: string[],
    layout?: Record<string, { x: number; y: number }>
) => Node<OperationNodeProps>[] = (operationsByPipeline, dataPipelines, bundledPipelineIds) => {
    const unbundledPipelines = dataPipelines.filter((dp) => !bundledPipelineIds.includes(dp.id)).filter(isNotNullish);
    return unbundledPipelines
        .map((pipeline) => {
            let prevX = 0;
            if (operationsByPipeline[pipeline.id]) {
                const y = BUNDLE_HEIGHT / 2 / 2;
                return operationsByPipeline[pipeline.id].map((operation) => {
                    const x = X_PADDING + prevX;
                    const end =
                        operationsByPipeline[pipeline.id].reduce(
                            (acc, op) => acc + getOperationNodeWidth(op.operationType),
                            0
                        ) +
                        (operationsByPipeline[pipeline.id].length + 1) * X_PADDING -
                        getOperationNodeWidth(operation.operationType);
                    prevX = prevX + getOperationNodeWidth(operation.operationType) + X_PADDING;
                    const cords = <CoordinateExtent>[
                        [0, 0],
                        [end, BUNDLE_HEIGHT / 2 - 12],
                    ];

                    return {
                        id: operation.id,
                        type: "operationNode",
                        data: {
                            operation,
                            allOperations: operationsByPipeline[pipeline.id],
                            dataPipeline: pipeline,
                        },
                        position: {
                            x,
                            y,
                        },
                        parentNode: pipeline.id,
                        extent: cords,
                    };
                });
            }
            return null;
        })
        .filter(isNotNullish)
        .flat();
};

export const createNodes: (
    operations: DataPipelineOperation[],
    dataPipelines: DataPipeline[],
    dataRepositories: DataRepository[],
    bundledPipelineIds: string[],
    setbundledPipeline: (dataPipelineIds: string[]) => void,
    dataTable?: DataTable,
    pipelineLayout?: DataPipelineLayout
) => Node[] = (
    operations,
    dataPipelines,
    dataRepositories,
    bundledPipelines,
    setbundledPipeline,
    dataTable,
    pipelineLayout
) => {
    const pipelineLayoutObject: Record<string, { x: number; y: number }> = getLayoutObject(pipelineLayout);

    const operationsByPipeline = groupBy(operations, (op) => op.dataPipelineId);
    const repositoryNodes = createRepositoryNodes(
        dataPipelines,
        dataRepositories,
        operationsByPipeline,
        pipelineLayoutObject
    );
    const bundles = createBundleWithUnionNodes(
        operationsByPipeline,
        dataPipelines,
        dataRepositories,
        bundledPipelines,
        setbundledPipeline,
        dataTable,
        pipelineLayoutObject
    );
    const operationNodes = createOperationNodes(
        operationsByPipeline,
        dataPipelines,
        bundledPipelines,
        pipelineLayoutObject
    );
    const dataTableNodes = dataTable
        ? createDataTableNode(dataTable, operationsByPipeline, dataPipelines, pipelineLayoutObject)
        : [];

    return [...repositoryNodes, ...bundles, ...operationNodes, ...dataTableNodes];
};
