import { GraphqlRequestContainer } from "@ignite-analytics/graphql-utilities";
import FormControlLabel from "@mui/material/FormControlLabel";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Switch from "@mui/material/Switch";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import React, { useState } from "react";
import { useDataColumnsByDataTableId } from "@/contexts/EventContexts/DataColumnEventContext";
import { fm } from "@/contexts/IntlContext";
import { DataTable, DataTableConnectionInput, useGetRowsSearchWithLabelsQuery } from "@/generated/client";
import { hasValue } from "../../helpers";
import messages from "./messages";
import { ResultsTableRow } from "./row";

interface Props {
    dataTableConnection: DataTableConnectionInput;
    matchesJSON: string;
    target: DataTable;
}
const extractColumnId = (field: string) => {
    const withoutPrefix = field.replace("data_column_", "");
    return `${Number(withoutPrefix.split("_")[0])}`;
};

export const TestResultsTable: React.FC<Props> = ({ matchesJSON, target, dataTableConnection }) => {
    const matches = JSON.parse(matchesJSON);
    const columns = useDataColumnsByDataTableId(target.id);
    const [showFullRow, setShowFullRow] = useState(false);

    const targetColumnsFromCriteria = dataTableConnection.criteria.map((c) => c.targetField).map(extractColumnId);

    const { result } = useGetRowsSearchWithLabelsQuery({
        input: {
            index: 0,
            range: 100,
            request: {
                dataTableId: target.id,
                filterJSON: JSON.stringify([
                    {
                        field: "_id",
                        type: "long",
                        include: Object.keys(matches),
                        filterType: "includefilter",
                        fieldId: "_id",
                    },
                ]),
                sortingArray: [],
            },
        },
    });
    return (
        <Stack maxHeight="30vw" gap={1}>
            <Stack direction="row" justifyContent="space-between">
                <Typography noWrap maxWidth="700px" fontWeight={700} variant="body1">
                    {fm(messages.targetRowsTable, { target: target.name })}
                </Typography>
                <FormControlLabel
                    control={<Switch value={showFullRow} onChange={(_e, checked) => setShowFullRow(checked)} />}
                    label={fm(messages.showFullRow)}
                />
            </Stack>

            <TableContainer elevation={0} component={Paper}>
                <Table aria-labelledby="tableTitle" size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                <Typography noWrap maxWidth="700px" fontWeight={700} variant="caption">
                                    {fm(messages.numMatches)}
                                </Typography>
                            </TableCell>
                            {columns
                                .filter((c) => showFullRow || targetColumnsFromCriteria.includes(c.id))
                                .map((c) => (
                                    <TableCell key={`Column-${c.id}`}>
                                        <Typography noWrap maxWidth="700px" fontWeight={700} variant="caption">
                                            {c.name}
                                        </Typography>
                                    </TableCell>
                                ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <GraphqlRequestContainer asyncData={result}>
                            {(dataRows) => (
                                <>
                                    {dataRows.dataTableRowsWithLabel
                                        .map((r) => ({ id: r.id, data: JSON.parse(r.dataJsonWithLabel) }))
                                        .filter(hasValue)
                                        .map((row) => (
                                            <ResultsTableRow
                                                key={row.id}
                                                row={row}
                                                columns={columns.filter(
                                                    (c) => showFullRow || targetColumnsFromCriteria.includes(c.id)
                                                )}
                                                numMatches={matches[row.id]?.ids?.length ?? 0}
                                            />
                                        ))}
                                </>
                            )}
                        </GraphqlRequestContainer>
                    </TableBody>
                </Table>
            </TableContainer>
        </Stack>
    );
};
