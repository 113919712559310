import { defineMessages } from "react-intl";

const messages = defineMessages({
    columnNameTextLabel: {
        id: "aggregatedView.create.aggregationConfiguration.columnNameTextLabel",
        defaultMessage: "Name of the new column",
    },
    columnSelectLabel: {
        id: "aggregatedView.create.aggregationConfiguration.columnSelectLabel",
        defaultMessage: "Column to aggregate",
    },
    missingNameValidationError: {
        id: "aggregatedView.create.aggregationConfiguration.missingNameValidationError",
        defaultMessage: "Missing name",
    },
    deleteTooltip: {
        id: "aggregatedView.create.aggregationConfiguration.deleteTooltip",
        defaultMessage: "Can only delete existing configs",
    },
});

export default messages;
