import { GraphqlRequestContainer } from "@ignite-analytics/graphql-utilities";
import { track } from "@ignite-analytics/track";
import { Button, Stack, Typography } from "@mui/material";
import React from "react";
import { DataColumn } from "@/Types/DataColumn";
import { fm } from "@/contexts/IntlContext";
import { useDeleteDataColumnsMutation, useGetDataColumnDependenciesQuery } from "@/generated/client";
import globalMessages from "@/messages";
import messages from "./messages";

interface Props {
    dataColumn: DataColumn;
    onAction: () => void;
}

export const DeleteDataColumn: React.FC<Props> = ({ dataColumn, onAction }) => {
    const [deleteDataColumns, { result: deleteResult }] = useDeleteDataColumnsMutation();
    const { result: dataColumnDependenciesResult } = useGetDataColumnDependenciesQuery({
        input: { dataColumnId: dataColumn.id },
    });
    const onDelete = async () => {
        await deleteDataColumns({
            input: { dataTableId: dataColumn.dataTableId, dataColumnIds: [dataColumn.id] },
        });
        track("Data column menu: Delete Data column", { dataType: dataColumn.dataType });
        onAction();
    };

    return (
        <GraphqlRequestContainer asyncData={dataColumnDependenciesResult}>
            {(response) => (
                <Stack gap={2}>
                    {response.hasDeps ? (
                        <>
                            <Typography variant="subtitle1">{fm(messages.cantDelete)}</Typography>
                            <Typography>{response.messageToUser}</Typography>
                        </>
                    ) : (
                        <Typography variant="subtitle1">{fm(messages.areYouSure)}</Typography>
                    )}
                    <Stack direction="row" alignItems="center" justifyContent="space-between">
                        <Button size="small" variant="outlined" onClick={onAction}>
                            {fm(globalMessages.cancel)}
                        </Button>
                        <Button size="small" disabled={response.hasDeps} onClick={onDelete} color="warning">
                            {fm(globalMessages.delete)}
                            <GraphqlRequestContainer asyncData={deleteResult} />
                        </Button>
                    </Stack>
                </Stack>
            )}
        </GraphqlRequestContainer>
    );
};
