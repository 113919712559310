import { CONTRACT_TABLE_GT, SUPPLIER_TABLE_GT, TRANSACTIONS_GT } from "@ignite-analytics/global-types";
import { GraphqlRequestContainer } from "@ignite-analytics/graphql-utilities";
import { Card, CardContent, Stack, Typography } from "@mui/material";
import React from "react";
import { Analytics, BlueBall, Carbon, Contract, Truck } from "@/components/svgs";
import { fm } from "@/contexts/IntlContext";
import { useManyDataTables } from "@/generated/DataTableEntity";
import { useGetDataTableRowCountQuery } from "@/generated/client";
import { SetUpButton } from "./Button";
import messages from "./messages";

const svgMap = {
    [SUPPLIER_TABLE_GT]: <Truck />,
    [CONTRACT_TABLE_GT]: <Contract />,
    [TRANSACTIONS_GT]: <Analytics />,
    carbon: <Carbon />,
    integrations: <Analytics />,
};

const messageKeys = {
    [SUPPLIER_TABLE_GT]: { title: messages.suppliers, subTitle: messages.suppliersDescription },
    [CONTRACT_TABLE_GT]: { title: messages.contracts, subTitle: messages.contractsDescription },
    [TRANSACTIONS_GT]: { title: messages.analytics, subTitle: messages.analyticsDescription },
    carbon: { title: messages.carbon, subTitle: messages.carbonDescription },
    integrations: { title: messages.integration, subTitle: messages.integrationDescription },
} as const;

interface Props {
    type: typeof SUPPLIER_TABLE_GT | typeof CONTRACT_TABLE_GT | typeof TRANSACTIONS_GT | "carbon" | "integrations";
    href?: string;
    moduleHref?: string;
}
export const SetupCard: React.FC<Props> = ({ type, href, moduleHref }) => {
    const tables = useManyDataTables();
    const tableOfType = tables.data?.find((table) => table.globalTypeKey === type);
    const { result } = useGetDataTableRowCountQuery(
        {
            input: { dataTableId: tableOfType?.id || "" },
        },
        { skip: !tableOfType }
    );

    return (
        <Card variant="outlined" sx={{ width: 500, height: 150 }}>
            <CardContent>
                <Stack direction="row" gap={2}>
                    <Stack>
                        <div>
                            <BlueBall />
                            <div style={{ position: "relative", bottom: "70px" }}>{svgMap[type]} </div>
                        </div>
                    </Stack>
                    <Stack>
                        <Typography variant="subtitle1">{fm(messageKeys[type].title)}</Typography>
                        <Typography>{fm(messageKeys[type].subTitle)}</Typography>
                        <Stack direction="row" justifyContent="end">
                            <Stack maxWidth="170px">
                                {tableOfType ? (
                                    <GraphqlRequestContainer asyncData={result}>
                                        {(response) => (
                                                <SetUpButton
                                                    type={response.count ? "goToModule" : "getStarted"}
                                                    href={response.count ? moduleHref : href}
                                                />
                                            )}
                                    </GraphqlRequestContainer>
                                ) : (
                                    <SetUpButton
                                        type={type === "carbon" ? "goToModule" : "getStarted"}
                                        href={type === "carbon" ? moduleHref : href}
                                    />
                                )}
                            </Stack>
                        </Stack>
                    </Stack>
                </Stack>
            </CardContent>
        </Card>
    );
};
