import { defineMessages } from "react-intl";

const messages = defineMessages({
    header: {
        id: "uploadPage.createDataTableFromRepository.newCollection.header",
        defaultMessage: "Let's create a new collection",
    },
    dataTableToIncludePrompt: {
        id: "uploadPage.createDataTableFromRepository.newCollection.dataTableToIncludePrompt",
        defaultMessage: "Which data tables should be included in this collection?",
    },
    selectedDataTables: {
        id: "uploadPage.createDataTableFromRepository.newCollection.selectedDataTables",
        defaultMessage: "Selected data tables",
    },
    createButtonLabel: {
        id: "uploadPage.createDataTableFromRepository.newCollection.createButtonLabel",
        defaultMessage: "Create",
    },
    name: {
        id: "uploadPage.createDataTableFromRepository.newCollection.name",
        defaultMessage: "Collection name",
    },
    placeholder: {
        id: "uploadPage.createDataTableFromRepository.newCollection.dataTables",
        defaultMessage: "Data tables",
    },
});
export default messages;
