import { defineMessages } from "react-intl";

const messages = defineMessages({
    dataTablesPageHeader: {
        id: "dataTables.dataTableOverview.header",
        defaultMessage: "Data Tables",
    },
    noTable: {
        id: "dataTables.dataTableOverview.noTable",
        defaultMessage: "No data table selected",
    },
    dataTablesMenuHeader: {
        id: "dataTables.dataTableOverview.menuHeader",
        defaultMessage: "Data Tables",
    },
    pipelineTab: {
        id: "dataTables.dataTableOverview.pipelineTab",
        defaultMessage: "Data flow",
    },
    tableViewTab: {
        id: "dataTables.dataTableOverview.tableViewTab",
        defaultMessage: "Data table",
    },

    adminPanel: {
        id: "dataTables.dataTableOverview.adminPanel",
        defaultMessage: "Admin panel",
    },
    connections: {
        id: "dataTables.dataTableOverview.connections",
        defaultMessage: "Connected tables",
    },
});

export default messages;
