import { DataColumn } from "@/Types/DataColumn";
import { AggregatedView } from "@/generated/client";
import { SelectableField } from "../CreateAggregatedView/AggregationConfigurationView/types";

export const addLabelsToGroupingConfigurations = (
    aggregatedView: AggregatedView,
    groupingFieldsToDelete: string[],
    selectableFields: SelectableField[]
) =>
    aggregatedView.groupingConfigurations
        .filter(({ id }) => !groupingFieldsToDelete?.includes(id))
        .map((groupingField) => ({
            ...groupingField,
            label: selectableFields.find((field) => field.field === groupingField.field)?.label,
        }));

export const addLabelsToAggregationConfigurations = (
    aggregatedView: AggregatedView,
    aggregationConfigurationsToDelete: string[],
    columns: DataColumn[]
) =>
    aggregatedView.aggregationConfigurations
        .filter(({ id }) => !aggregationConfigurationsToDelete.includes(id))
        .map((agg) => ({
            ...agg,
            name: columns.find((dc) => dc.referencedId === agg.id)?.name || agg.field,
        }));
