import React, { createContext, useContext } from "react";
import { Session } from "@/types";
// Create the context
const SessionContext = createContext<Session | null>(null);

interface Props {
    sessionContext: Session;
    children: React.ReactNode;
}

const SessionContextProvider: React.FC<Props> = ({ sessionContext, children }) => (
    <SessionContext.Provider value={sessionContext}>{children}</SessionContext.Provider>
);

// Custom hook to access the Session from the context
export const useSessionContext = (): Session => {
    const sessionContext = useContext(SessionContext);
    if (!sessionContext) throw Error("Could not find any current session context");
    return sessionContext;
};

// Export the context
export default SessionContextProvider;
