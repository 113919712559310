import { Stack, Typography } from "@mui/material";
import React from "react";
import { DataColumn } from "@/Types/DataColumn";
import { fm } from "@/contexts/IntlContext";
import { DataPipeline, DataPipelineOperation, InputOutputConnection } from "@/generated/client";
import { OperationOutputMapping } from "../OperationOutputMapping";
import messages from "../messages";

interface Props {
    operation: DataPipelineOperation;
    dataPipeline: DataPipeline;
    inputOutputConnections: InputOutputConnection[];
    outputDataTableColumns: DataColumn[];
}

export const OutputMappingSection: React.FC<Props> = ({
    operation,
    dataPipeline,
    inputOutputConnections,
    outputDataTableColumns,
}) => (
        <Stack gap={1}>
            <Typography variant="subtitle2">{fm(messages.output)}</Typography>
            <Stack gap={1} maxHeight="250px">
                {operation.output.map((operationOutput) => (
                    <OperationOutputMapping
                        operation={operation}
                        key={operationOutput.id}
                        inputOutputConnections={inputOutputConnections}
                        operationOutput={operationOutput}
                        dataPipeline={dataPipeline}
                        dataColumns={outputDataTableColumns}
                    />
                ))}
            </Stack>
        </Stack>
    );
