import { groupBy } from "lodash-es";
import { Edge, MarkerType } from "reactflow";
import { DataPipeline, DataPipelineOperation, DataTable } from "@/generated/client";
import { createUnionNodeId, createBundleId } from "./nodeHelpers";

const createEdgesForPipeline = (operationsFromSamePipeline: DataPipelineOperation[]) => {
    let edges: Edge[] = [];
    let previous: DataPipelineOperation | null = null;

    operationsFromSamePipeline.forEach((operation) => {
        if (previous)
            edges = [
                ...edges,
                {
                    id: `edge-${operation.id}`,
                    target: operation.id,
                    source: previous.id,
                    type: "step",
                    animated: true,
                    markerEnd: MarkerType.Arrow,
                },
            ];
        if (operation.lookupOperationAdapter?.dataRepositoryId) {
            edges = [
                ...edges,
                {
                    id: `joinedge-${operation.id}`,
                    target: operation.id,
                    source: operation.lookupOperationAdapter?.dataRepositoryId,
                    targetHandle: "bottom",
                    type: "step",
                    animated: true,
                    markerEnd: MarkerType.Arrow,
                },
            ];
        }
        previous = operation;
    });

    return edges;
};
export const createEdges: (
    operations: DataPipelineOperation[],
    dataPipelines: DataPipeline[],
    bundledPipelineIds: string[],
    dataTable?: DataTable
) => Edge[] = (operations, dataPipelines, bundledPipelineIds, dataTable) => {
    const operationsByPipeline = groupBy(operations, (op) => op.dataPipelineId);
    const operationEdges = Object.values(operationsByPipeline)
        .map((pipelineOperations) => createEdgesForPipeline(pipelineOperations))
        .flat();

    const unionEdges = dataPipelines.map((dataPipeline) => {
        const isBundled = bundledPipelineIds.includes(dataPipeline.id);
        return {
            id: `${createUnionNodeId(dataPipeline.id)}-edge`,
            target: isBundled ? createBundleId(createUnionNodeId(dataPipeline.id)) : createUnionNodeId(dataPipeline.id),
            source: isBundled ? createBundleId(dataPipeline.id) : dataPipeline.id,
            type: "filterEdge",
            data: {
                dataPipeline,
            },
            animated: true,
            markerEnd: {
                type: MarkerType.Arrow,
            },
        };
    });

    const repositoryEdges = dataPipelines
        .map((pipeline) => {
            const isBundled = bundledPipelineIds.includes(pipeline.id);
            return pipeline.sourceDataRepositoryIds.map((repositoryId) => ({
                id: `edge-${repositoryId}-${pipeline.id}`,
                target: isBundled ? createBundleId(pipeline.id) : pipeline.id,
                source: repositoryId,
                type: "step",
                animated: true,
                markerEnd: MarkerType.Arrow,
            }));
        })
        .flat();

    const tableEdges = dataPipelines.map((pipeline) => {
        const isBundled = bundledPipelineIds.includes(pipeline.id);
        return {
            id: `edge-${pipeline.id}`,
            target: dataTable?.id || "null",
            source: isBundled ? createBundleId(createUnionNodeId(pipeline.id)) : createUnionNodeId(pipeline.id),
            type: "step",
            animated: true,
            markerEnd: MarkerType.Arrow,
        };
    });

    return [...operationEdges, ...repositoryEdges, ...tableEdges, ...unionEdges];
};
