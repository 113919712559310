import { track } from "@ignite-analytics/track";
import { Add, Delete } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
    Alert,
    Checkbox,
    Dialog,
    DialogContent,
    DialogTitle,
    IconButton,
    Stack,
    TextField,
    Typography,
} from "@mui/material";
import uniqueId from "lodash-es/uniqueId";
import React, { useState } from "react";
import { fm } from "@/contexts/IntlContext";
import {
    DataRepository,
    useAddManyDataRepositoryFieldsMutation,
    useCreateDataRepositoryMutation,
    useCreateUniqueIdentifierConfigurationMutation,
} from "@/generated/client";
import messages from "./messages";

interface Props {
    onCreate: (newRepository: DataRepository) => void;
    onClose: () => void;
}
export const NewGetOrCreateRepository: React.FC<Props> = ({ onCreate, onClose }) => {
    const [newFields, setNewFields] = useState<{ name: string; unique: boolean; key: string }[]>([
        { name: "", unique: true, key: "uniqkey" },
    ]);
    const [name, setName] = useState("Generated repository");

    const [createRepository, createRepositoryMutation] = useCreateDataRepositoryMutation();
    const [AddFields, addFieldsMutation] = useAddManyDataRepositoryFieldsMutation();
    const [createUniqueConfig, uniqueConfigMutation] = useCreateUniqueIdentifierConfigurationMutation({
        refetchQueries: ["getAllDataRepositories", "getManyUniqueIdentifierConfigurations"],
    });

    const isLoading = [createRepositoryMutation, addFieldsMutation, uniqueConfigMutation].some(
        (m) => m.result.type === "loading"
    );

    const handleCreate = async () => {
        const newRepository = await createRepository({ input: { name } });
        track("Pipeline: Create Repository");
        const newRepositoryWithFields =
            newRepository.data?.createDataRepository.dataRepository &&
            (
                await AddFields({
                    input: {
                        dataRepositoryId: newRepository.data?.createDataRepository.dataRepository.id,
                        fieldNames: newFields.map((f) => f.name),
                    },
                })
            ).data?.addManyDataRepositoryFields.dataRepository;

        newRepositoryWithFields &&
            (await createUniqueConfig({
                input: {
                    repositoryId: newRepositoryWithFields.id,
                    retroactive: true,
                    partialReplace: false,
                    uniqueIdentifierFields: newRepositoryWithFields.fields
                        .filter((repositoryField) =>
                            newFields.find((newField) => newField.name === repositoryField.name && newField.unique)
                        )
                        .map((repositoryField) => repositoryField.id),
                },
            }));

        newRepositoryWithFields && onCreate(newRepositoryWithFields);
    };

    return (
        <Dialog open onClose={onClose}>
            <DialogTitle>{fm(messages.header)}</DialogTitle>
            <DialogContent>
                <Stack gap={1}>
                    <TextField onChange={(e) => setName(e.target.value)} label="Name" name="" value={name} />

                    <Stack gap={1}>
                        <Typography variant="subtitle2">{fm(messages.fieldsHeader)}</Typography>

                        {newFields.map((field, fieldIndex) => (
                            <Stack
                                justifyContent="space-between"
                                direction="row"
                                key={field.key}
                                alignItems="center"
                                gap={1}
                            >
                                <Stack direction="row" alignItems="center">
                                    <Checkbox
                                        checked={field.unique}
                                        onChange={(e) => {
                                            setNewFields((prev) =>
                                                prev.map((f, i) =>
                                                    i === fieldIndex
                                                        ? { ...f, unique: e.currentTarget?.checked ?? false }
                                                        : f
                                                )
                                            );
                                        }}
                                        name=""
                                    />
                                    <Typography> {fm(messages.uniqueLabel)}</Typography>
                                </Stack>

                                <TextField
                                    onChange={(e) => {
                                        e.persist();
                                        setNewFields((prev) =>
                                            prev.map((f, i) => (i === fieldIndex ? { ...f, name: e.target.value } : f))
                                        );
                                    }}
                                    label={fm(messages.nameLabel)}
                                    placeholder="Field name"
                                    name=""
                                    value={field.name}
                                />

                                <IconButton
                                    onClick={() => setNewFields((prev) => prev.filter((f, i) => i !== fieldIndex))}
                                >
                                    <Delete />
                                </IconButton>
                            </Stack>
                        ))}
                        <Stack alignItems="center">
                            <IconButton
                                onClick={() =>
                                    setNewFields((prev) => [...prev, { name: "", key: uniqueId(), unique: true }])
                                }
                            >
                                <Add />
                            </IconButton>
                        </Stack>
                    </Stack>
                    <LoadingButton variant="contained" loading={isLoading} color="success" onClick={handleCreate}>
                        {fm(messages.create)}
                    </LoadingButton>
                    {uniqueConfigMutation.result.type === "error" && (
                        <Alert title={uniqueConfigMutation.result.error.message} severity="error">
                            {uniqueConfigMutation.result.error.message}
                        </Alert>
                    )}
                </Stack>
            </DialogContent>
        </Dialog>
    );
};
