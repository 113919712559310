import { Company, DiagramSubtask, Hashtag, Table, Text, Cloud } from "@ignite-analytics/icons";
import {
    Calculate,
    CalculateRounded,
    CalendarMonth,
    CurrencyPound,
    FileCopy,
    Label,
    Person,
    RadioButtonChecked,
} from "@mui/icons-material";
import React from "react";
import { DataColumnType } from "@/generated/client";

export const TYPE_ICONS: Record<DataColumnType, JSX.Element> = {
    NUMBER: <Hashtag fontSize="small" />,
    TEXT: <Text fontSize="small" />,
    DATE: <CalendarMonth fontSize="small" />,
    BOOLEAN: <RadioButtonChecked fontSize="small" />,
    MONETARY_AMOUNT: <CurrencyPound />,
    GROUP_STRUCTURE: <DiagramSubtask fontSize="small" />,
    TABLE_RELATION: <Table fontSize="small" />,
    COMPANY: <Company fontSize="small" />,
    USER: <Person />,
    CONTACT: <Person />,
    FILE: <FileCopy />,
    AGGREGATION: <Calculate />,
    DATE_AGGREGATION: <CalculateRounded />,
    TAG: <Label />,
    EMISSIONS: <Cloud />,
} as const;
