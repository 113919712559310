import { defineMessages } from "react-intl";

const messages = defineMessages({
    manageFilter: {
        id: "pipelinePage.dataPipelineFilterModal.manageFilter",
        defaultMessage: "Manage data filters",
    },
    helpText: {
        id: "pipelinePage.dataPipelineFilterModal.helpText",
        defaultMessage:
            "Create an exclude filter for rows you do not wish to insert into the data table. Filters within a combination are applied with an AND operator, meaning a row will be excluded only if ALL clauses hit. Mulitple filter combinations are applied with the OR operator, meaning a row is exluded if one of the combinations hit.",
    },
    addFilterCombination: {
        id: "pipelinePage.dataPipelineFilterModal.addFilterCombination",
        defaultMessage: "Add filter combination",
    },
    or: {
        id: "pipelinePage.dataPipelineFilterModal.or",
        defaultMessage: "OR",
    },
});

export default messages;
