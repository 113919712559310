import { defineMessages } from "react-intl";

const messages = defineMessages({
    targetRepository: {
        id: "uploadPage.pipelineConfiguration.operationOverview.operationDetailView.ExternalRepositoryDetails.target",
        defaultMessage: "Target repository",
    },
});

export default messages;
