import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { Autocomplete, TextField, Tooltip } from "@mui/material";
import React from "react";
import { testIdPrefix } from "@/components/testIdprefix";
import { useDataColumnsByDataTableId } from "@/contexts/EventContexts/DataColumnEventContext";
import { fm } from "@/contexts/IntlContext";
import { DataTable } from "@/generated/client";
import { useUpdateDataTableAction } from "@/generated/DataTableEntity";
import messages from "./messages";

interface Props {
    dataTable: DataTable;
}

export const PickNameColumn: React.FC<Props> = ({ dataTable }: Props) => {
    const [updateDataTable] = useUpdateDataTableAction();
    const columns = useDataColumnsByDataTableId(dataTable.id);
    const handleSelect = (value?: string | null) => {
        updateDataTable(dataTable.id, {
            nameColumnId: typeof value === "undefined" ? null : value,
        });
    };
    return (
        <Autocomplete
            size="small"
            sx={{ minWidth: 220, maxWidth: 220 }}
            data-testid={`${testIdPrefix}-pick-name-column`}
            getOptionLabel={(option) => columns.find((c) => c.id === option)?.name ?? ""}
            options={columns.map((c) => c.id)}
            onChange={(_, value) => handleSelect(value ?? null)}
            value={dataTable.nameColumnId}
            renderInput={(params) => (
                <TextField
                    {...params}
                    InputProps={{
                        ...params.InputProps,
                        startAdornment: (
                            <Tooltip title={fm(messages.tooltip)}>
                                <HelpOutlineIcon fontSize="small" />
                            </Tooltip>
                        ),
                    }}
                    label={fm(messages.selectLabel).toString()}
                    placeholder={fm(messages.selectPlaceholder).toString()}
                />
            )}
        />
    );
};
