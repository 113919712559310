import { GraphqlRequestContainer } from "@ignite-analytics/graphql-utilities";
import {
    Button,
    Checkbox,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Typography,
} from "@mui/material";
import React, { useMemo, useState } from "react";
import { DataColumn } from "@/Types/DataColumn";
import { fm } from "@/contexts/IntlContext";
import {
    DataPipeline,
    DataPipelineOperation,
    DataRepository,
    InputOutputConnection,
    LookupOperationAdapter,
    useAddLookupOperationFieldOutputsMutation,
    useRemoveLookupOperationFieldOutputsMutation,
} from "@/generated/client";
import { OperationOutputMapping } from "../../OperationOutputMapping";
import messages from "./messages";

interface Props {
    operation: DataPipelineOperation & { lookupOperationAdapter: LookupOperationAdapter };
    targetDataRepository: DataRepository;
    inputOutputConnections: InputOutputConnection[];
    dataPipeline: DataPipeline;
    outputDataTableColumns: DataColumn[];
}

export const LookupOperationFieldOutputsView: React.FC<Props> = ({
    operation,
    targetDataRepository,
    inputOutputConnections,
    dataPipeline,
    outputDataTableColumns,
}) => {
    const [fieldSearch, setFieldSearch] = useState("");
    const [allSelected, setAllSelected] = useState(false);
    const [showAll, setShowAll] = useState(false);
    const fieldSelections = useMemo(
        () =>
            targetDataRepository.fields
                .filter((field) => field.name.toLowerCase().indexOf(fieldSearch.toLowerCase()) >= 0)
                .map((field) => {
                    const operationOutputId = operation.lookupOperationAdapter.lookupFieldOutputs.find(
                        (fieldOutput) => fieldOutput.dataRepositoryFieldId === field.id
                    )?.operationOutputId;
                    return {
                        name: field.name,
                        id: field.id,
                        selected: !!operation.lookupOperationAdapter.lookupFieldOutputs.find(
                            (fieldOutput) => fieldOutput.dataRepositoryFieldId === field.id
                        ),
                        operationOutput: operation.output.find((output) => output.id === operationOutputId),
                    };
                }),
        [
            targetDataRepository.fields,
            operation.lookupOperationAdapter.lookupFieldOutputs,
            fieldSearch,
            operation.output,
        ]
    );

    const [addLookupFieldOutputs, addMutation] = useAddLookupOperationFieldOutputsMutation();
    const [removeLookupFieldOutputs, removeMutation] = useRemoveLookupOperationFieldOutputsMutation();
    const handleSelectFieldClick = (dataRepositoryFieldId: string, selected: boolean) => {
        setAllSelected(false);
        if (selected) {
            removeLookupFieldOutputs({
                input: {
                    dataPipelineOperationId: operation.id,
                    dataRepositoryFieldIds: [dataRepositoryFieldId],
                },
            });
        } else {
            addLookupFieldOutputs({
                input: {
                    dataPipelineOperationId: operation.id,
                    dataRepositoryFieldIds: [dataRepositoryFieldId],
                },
            });
        }
    };

    const handleSelectAll = () => {
        if (allSelected) {
            removeLookupFieldOutputs({
                input: {
                    dataPipelineOperationId: operation.id,
                    dataRepositoryFieldIds: fieldSelections.map((field) => field.id),
                },
            });
        } else {
            addLookupFieldOutputs({
                input: {
                    dataPipelineOperationId: operation.id,
                    dataRepositoryFieldIds: fieldSelections.map((field) => field.id),
                },
            });
        }
        setAllSelected(!allSelected);
    };

    return (
        <Stack>
            <Stack maxWidth="200px">
                <TextField
                    autoFocus
                    value={fieldSearch}
                    onChange={(event) => setFieldSearch(event.target.value)}
                    label={fm(messages.searchForFieldsInputLabel)}
                />
            </Stack>
            <TableContainer sx={{ maxHeight: "40vh" }}>
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell align="center" width="10%">
                                <Checkbox checked={allSelected} onClick={handleSelectAll} />
                            </TableCell>
                            <TableCell>{fm(messages.fieldColumnTableHeader)}</TableCell>
                            <TableCell>{fm(messages.outputColumnTableHeader)}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {fieldSelections.slice(0, showAll ? undefined : 10).map((field) => (
                            <TableRow key={field.id}>
                                <TableCell align="center">
                                    <Checkbox
                                        checked={field.selected}
                                        onClick={() => handleSelectFieldClick(field.id, field.selected)}
                                    />
                                </TableCell>
                                <TableCell>
                                    <Typography>{field.name}</Typography>
                                </TableCell>
                                <TableCell>
                                    {field.operationOutput && (
                                        <OperationOutputMapping
                                            operation={operation}
                                            inputOutputConnections={inputOutputConnections}
                                            operationOutput={field.operationOutput}
                                            dataPipeline={dataPipeline}
                                            dataColumns={outputDataTableColumns}
                                        />
                                    )}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                <Stack direction="row" justifyContent="center">
                    <Button variant="text" onClick={() => setShowAll((p) => !p)}>
                        {fm(showAll ? messages.showLess : messages.showAll, { number: fieldSelections.length })}
                    </Button>
                </Stack>
            </TableContainer>
            <GraphqlRequestContainer asyncData={addMutation.result} loading={null} />
            <GraphqlRequestContainer asyncData={removeMutation.result} loading={null} />
        </Stack>
    );
};
