import { defineMessages } from "react-intl";

const messages = defineMessages({
    downloadStarted: {
        id: "DirectDownload.download",
        defaultMessage: "Download started...",
    },
});

export default messages;
