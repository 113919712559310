import { defineMessages } from "react-intl";

const messages = defineMessages({
    input: {
        id: "dataTables.modals.ConnectRepositoryModal.input",
        defaultMessage: "Input",
    },
    output: {
        id: "dataTables.modals.ConnectRepositoryModal.output",
        defaultMessage: "Output",
    },
    configureInput: {
        id: "dataTables.modals.ConnectRepositoryModal.configureInput",
        defaultMessage: "Configure input source",
    },
    preview: {
        id: "dataTables.modals.ConnectRepositoryModal.preview",
        defaultMessage: "Select and preview the repository you wish to use as your input source",
    },
    loading: {
        id: "dataTables.modals.ConnectRepositoryModal.loading",
        defaultMessage: "Loading...",
    },
    createNew: {
        id: "dataTables.modals.ConnectRepositoryModal.createNew",
        defaultMessage: "Upload new file",
    },
    noData: {
        id: "dataTables.modals.ConnectRepositoryModal.noData",
        defaultMessage: "No Data",
    },
    repositoryColumn: {
        id: "dataTables.modals.ConnectRepositoryModal.repositoryColumn",
        defaultMessage: "Repository column",
    },
    examples: {
        id: "dataTables.modals.ConnectRepositoryModal.examples",
        defaultMessage: "Examples",
    },
    create: {
        id: "dataTables.pipelinePageV2.modals.inputOutputMapping.create",
        defaultMessage: "Create columns",
    },
    noColumns: {
        id: "dataTables.pipelinePageV2.modals.inputOutputMapping.noColumns",
        defaultMessage: "Please specify the name and datatype of the columns you want in your table",
    },
    notNow: {
        id: "dataTables.pipelinePageV2.modals.inputOutputMapping.notNow",
        defaultMessage: "Not now",
    },
    include: {
        id: "dataTables.pipelinePageV2.modals.inputOutputMapping.Include",
        defaultMessage: "Include",
    },
    name: {
        id: "dataTables.pipelinePageV2.modals.inputOutputMapping.name",
        defaultMessage: "Name",
    },
    dataType: {
        id: "dataTables.pipelinePageV2.modals.inputOutputMapping.dataType",
        defaultMessage: "Data type",
    },
    search: {
        id: "dataTables.pipelinePageV2.modals.inputOutputMapping.search",
        defaultMessage: "Search",
    },
    newFile: {
        id: "dataTables.pipelinePageV2.modals.inputOutputMapping.newFile",
        defaultMessage: "New file",
    },
    confirm: {
        id: "dataTables.pipelinePageV2.modals.inputOutputMapping.confirm",
        defaultMessage: "Confirm selection",
    },
    connectHeader: {
        id: "dataTables.pipelinePageV2.modals.inputOutputMapping.connectHeader",
        defaultMessage: "Connect repostiory fields to columns",
    },
    connectColumnsPrompt: {
        id: "dataTables.pipelinePageV2.modals.inputOutputMapping.connectColumnsPrompt",
        defaultMessage: "Please confirm or edit the automatic mapping from repository to columns",
    },
    repositoryField: {
        id: "dataTables.pipelinePageV2.modals.inputOutputMapping.repositoryField",
        defaultMessage: "Data repository field",
    },
});

export default messages;
