import { defineMessages } from "react-intl";

const messages = defineMessages({
    deleteDataTableHeader: {
        id: "dataTables.adminPage.deleteDataTableHeader",
        defaultMessage: "Delete data table",
    },
    deleteDataTableDescription: {
        id: "dataTables.adminPage.deleteDataTableDescription",
        defaultMessage: "Deleting the data table is an irreversible action.",
    },
});

export default messages;
