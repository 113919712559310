import uniqueId from "lodash-es/uniqueId";
import { PossibleAggregations, isPossibleForAggregationType } from "./AggregationConfigurationView/helpers";
import { AggregationConfiguration, SelectableField } from "./AggregationConfigurationView/types";

export function isPossibleForGrouping(field: { field: string; type: string }) {
    return field.type === "keyword" && field.field.match(/data_column_[0-9]+/g);
}

export function createEmptyAggregationConfiguration(
    selectableFields: SelectableField[]
): AggregationConfiguration | undefined {
    return PossibleAggregations.reduce<AggregationConfiguration | undefined>(
        (emptyAggregation, possibleAggregation) => {
            if (emptyAggregation) {
                return emptyAggregation;
            }
            const field = selectableFields.find(isPossibleForAggregationType(possibleAggregation));
            if (field) {
                return {
                    id: uniqueId(),
                    type: possibleAggregation,
                    field: field.field,
                    filters: [],
                    name: "",
                };
            }
            return undefined;
        },
        undefined
    );
}
