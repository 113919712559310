import { defineMessages } from "react-intl";

const messages = defineMessages({
    configure: {
        id: "dataTables.exportConfiguration.configure",
        defaultMessage: "Configure export",
    },
    format: {
        id: "dataTables.exportConfiguration.format",
        defaultMessage: "File format",
    },
    select: {
        id: "dataTables.exportConfiguration.select",
        defaultMessage: "Select fields to export",
    },
    start: {
        id: "dataTables.exportConfiguration.start",
        defaultMessage: "Start export",
    },
    selectAll: {
        id: "dataTables.exportConfiguration.selectAll",
        defaultMessage: "Select/deselect all",
    },
    limitMessage: {
        id: "dataTables.exportConfiguration.limitMessage",
        defaultMessage:
            "Maximum 100 fields can be selected for xlsx format. Please deselect {number} fields, or use csv format.",
    },
});

export default messages;
