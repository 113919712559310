import DefaultOperationDetail from "./Default";
import MappingOperationDetailView from "./MappingOperation";

export const operationComponents = {
    REGEX: DefaultOperationDetail,
    SPLIT: DefaultOperationDetail,
    LOOKUP: DefaultOperationDetail,
    TEMPLATE_STRING: DefaultOperationDetail,
    FILL_BLANKS: DefaultOperationDetail,
    DATE_PARSER: DefaultOperationDetail,
    MONETARY_AMOUNT: DefaultOperationDetail,
    LIST_PARSER: DefaultOperationDetail,
    GET_OR_CREATE: DefaultOperationDetail,
    CONVERT_COUNTRY: DefaultOperationDetail,
    REPLACE: DefaultOperationDetail,
    TRANSLATION: DefaultOperationDetail,
    KEYWORD_TAGGER: DefaultOperationDetail,
    LETTER_CASING: DefaultOperationDetail,
    MATCH_COMPANY: DefaultOperationDetail,
    MATHEMATICAL_EXPRESSION: DefaultOperationDetail,
    MAPPING: MappingOperationDetailView,
} as const;
