import { Check, InfoOutlined } from "@mui/icons-material";
import { Autocomplete, Checkbox, FormControlLabel, Slide, Stack, TextField, Tooltip, Typography } from "@mui/material";
import cloneDeep from "lodash-es/cloneDeep";
import React from "react";
import { fm } from "@/contexts/IntlContext";
import { TagDefinition } from ".";
import messages from "../../messages";

const deleteTag = (prev: Record<string, string | null>, tag: string) => {
    const keep = cloneDeep(prev);
    delete keep[tag];
    return keep;
};
interface TagProps extends TagDefinition {
    firstUnmappedTag?: TagDefinition;
    mappings: Record<string, string | null>;
    setMappings: React.Dispatch<React.SetStateAction<Record<string, string | null>>>;
    fields: string[];
}

export const TagSelect: React.FC<TagProps> = ({
    tag,
    icon,
    message,
    firstUnmappedTag,
    setMappings,
    mappings,
    explanation,
    fields,
    optional,
}) => (
    <Slide key={tag} in={!!(firstUnmappedTag?.tag === tag || tag in mappings)} direction="left">
        <Stack minWidth={300} gap={1}>
            <Stack direction="row" alignItems="center" gap={1}>
                {icon}
                <Typography noWrap>{message}</Typography>
                {tag in mappings && <Check color="success" />}
            </Stack>
            <Autocomplete
                fullWidth
                value={mappings[tag] ?? null}
                options={fields}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        fullWidth
                        autoFocus
                        size="small"
                        placeholder={fm(messages.selectColumn).toString()}
                    />
                )}
                onChange={(_, value) => {
                    value
                        ? setMappings((prev) => ({ ...prev, [tag]: value }))
                        : setMappings((prev) => deleteTag(prev, tag));
                }}
            />
            {optional && (!(tag in mappings) || mappings[tag] === null) && (
                <Stack direction="row" alignItems="center">
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={mappings[tag] === null}
                                onChange={(e) =>
                                    e.currentTarget.checked
                                        ? setMappings((prev) => ({
                                              ...prev,
                                              [tag]: null,
                                          }))
                                        : setMappings((prev) => deleteTag(prev, tag))
                                }
                            />
                        }
                        label={fm(messages.notPresent)}
                    />
                    {explanation && (
                        <Tooltip title={explanation}>
                            <InfoOutlined />
                        </Tooltip>
                    )}
                </Stack>
            )}
        </Stack>
    </Slide>
);
