import { DialogTitle, Stack, Autocomplete, DialogContent, Typography, TextField } from "@mui/material";
import React from "react";
import { fm } from "@/contexts/IntlContext";
import { DataTable } from "@/generated/client";
import messages from "./messages";

interface Props {
    targetTable?: DataTable;
    dataTables: DataTable[];
    baseTable: DataTable;
    updateTargetFieldState?: (target: DataTable) => Promise<void>;
}

export const CreateConnectionModalHeader = ({ dataTables, targetTable, updateTargetFieldState, baseTable }: Props) => (
        <Stack>
            <DialogTitle>
                <Stack direction="row" alignItems="center" gap={1}>
                    {fm(messages.createHeader)}
                    <Stack width="300px">
                        {updateTargetFieldState && (
                            <Autocomplete
                                fullWidth
                                value={targetTable}
                                options={dataTables.filter((dt) => dt.id !== baseTable.id)}
                                getOptionLabel={(option) => option.name}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        fullWidth
                                        label={fm(messages.selectTarget)}
                                        size="small"
                                        placeholder={fm(messages.selectTarget).toString()}
                                    />
                                )}
                                onChange={(_, value) => {
                                    value && updateTargetFieldState(value);
                                }}
                            />
                        )}
                    </Stack>
                </Stack>
            </DialogTitle>
            <DialogContent>
                <Typography>{fm(messages.explanation)}</Typography>
            </DialogContent>
        </Stack>
    );
