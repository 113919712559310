import React from "react";

import { useDataColumnsByDataTableId } from "@/contexts/EventContexts/DataColumnEventContext";
import { DataTable } from "@/generated/client";

import { TablePage } from "./Page";

interface Props {
    dataTable: DataTable;
    setSelectedDataTableViewId: (id: string) => void;
    selectedDataTableViewId: string;
}
export const TableViewV2: React.FC<Props> = ({ selectedDataTableViewId, setSelectedDataTableViewId, dataTable }) => {
    const columns = useDataColumnsByDataTableId(selectedDataTableViewId);

    return (
        <TablePage
            baseDataTable={dataTable}
            dataColumns={columns}
            setSelectedDataTableViewId={setSelectedDataTableViewId}
            selectedDataTableViewId={selectedDataTableViewId}
        />
    );
};
